/** @define ConsumptionDetailsDialog */
$componentClass: '.ConsumptionDetailsDialog';
.ConsumptionDetailsDialog {
  min-width: rem-calc(350);
  .TextDataList {
    width: 100%;
    dt { width: 60%; }
    dd {
      width: 35%;
      text-align: right;
    }

    span {
      display: block;
    }

    &:first-child {
      border-bottom: 1px solid $color-gray50;
      padding-bottom: $global-padding * .5;
      margin-bottom: $global-padding * .5;
    }
  }
}
