/** @define TeaserProduct */
$componentClass: '.TeaserProduct';
.TeaserProduct{
  &-iconPlus {
    &:before,
    &:after {
      content: '';
      position: relative;
      display: inline-block;
      width: calc(50% - 20px);
      height: rem-calc(2);
      vertical-align: top;
      margin-top: rem-calc(7);
      background-color: $color-gray15;
      z-index: -1;
    }

    .Icon {
      display: inline-block;
      width: rem-calc(15);
      height: rem-calc(15);
      margin: 0 rem-calc(10);
      Svg {
        width: inherit;
        height: inherit;
      }
    }

    .Icon-base {
      fill: $color-orange;
    }
  }

  &-singlePaymentFee {
    .TextUnit {
      white-space: normal;
    }
  }

  &-link {
    @include media($media_s) {
      width: rem-calc(150);
    }
  }

  .Callout--badge {
    position: absolute;
    top: $global-padding * 2;
    left: 50%;
    transform: scale(0.75);
    transform-origin: top left;
  }

  .Callout--sticker {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    left: 20px;
    .TextCopy {
      font-size: 1rem !important;
    }
  }

  &--hardware {
    #{$componentClass}-headline {
      position: absolute;
      width: 100%;
      top: 0;
    }

    #{$componentClass}-image {
      position: relative;
      .MediaImage {
        display: inline-block;
        height: rem-calc(190);
        img {
          display: block;
          max-width: rem-calc(150);
          max-height: rem-calc(190);
        }
      }
    }
  }

  &--bundle {
    #{$componentClass}-content {
      max-width: rem-calc(250);
    }

    #{$componentClass}-image {
      font-size: 0;
      img {
        display: block;
        height: rem-calc(250);
      }
    }

    .Callout--badge {
      right: auto;
      top: 50%;
      left: 0;
      transform: scale(0.75) translate(-50%, -50%);
    }

    @include media($media_m-l) {
      #{$componentClass}-image {
        img {
          display: block;
          height: rem-calc(340);
        }
      }
    }
  }
}
