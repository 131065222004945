/** @define FieldAccordion */
$componentClass: '.FieldAccordion';
.FieldAccordion {
  width: 100%;

  .FieldRadio {
    display: inline-block;
  }

  &-content {
    overflow: hidden;
    height: 0;
    transition: all $timing-duration $timing-easing;
    padding-left: $global-padding;
    #{$componentClass}-inner {
      padding-top: $global-padding * .5;
      > * + * {
        margin-top: $global-padding * .5;
      }
    }
  }

  &--inverted {
    #{$componentClass}-label {
      color: $color-white;
    }
  }

  &.is-open {
    > #{$componentClass}-content {
      overflow: visible;
      height: 100%;
    }
  }
}
