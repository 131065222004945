@use "sass:math";

$componentClass: '.ESimDeviceCheck';
.ESimDeviceCheck {
  &-selectGroup {
    position: relative;
    max-width: rem-calc(480);

    #{$componentClass}-deviceList {
      position: relative;
    }

    #{$componentClass}-deviceListIcon {
      position: absolute;
      top: 50%;
      right: $global-padding;
      transform: translateY(-50%);
      line-height: 0;

      &--esim {
        right: $global-padding + rem-calc(16);
      }
    }

    // custom dropdowm option overrides
    .deviceListOption {
      position: relative;

      &:after, &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: math.div($global-padding, 6);
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        transform: translateY(-50%);
      }

      &.is-esim {
        &:after {
          right: $global-padding;
          background-image: svg-url('<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7.5" cy="8" r="7.5" fill="#FA5A14"/></svg>');
        }
      }

      &.is-sim {
        &:before {
          background-image: svg-url('<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.5 0L15 15H0L7.5 0Z" fill="#00A9B2"/></svg>');
        }
      }
    }
  }

  &-hint {
    .AccordionSection-label {
      display: inline-block;
    }
  }
}
