/** @define Icon */
.Icon {
  color: $color-default;
  /* @todo
    deprecated selector / class .Icon-base
    use .Icon-fill instead. Eventually we should refactor selectors / overrides throughout our project and replace them with .Icon-fill
    In conjuction SVG markup would have to be refactored as well.
    Also there are new utilities for svg styling since this update (see u-colors.scss).
  */
  &-base {
    fill: currentColor;
  }

  &-fill {
    fill: currentColor;
  }

  &-stroke {
    stroke: currentColor;
  }

  &-highlight {
    fill: $color-primary;
    stroke: $color-primary;
  }

  &-fillPrimary {
    fill: $color-primary;
  }

  &-strokePrimary {
    stroke: $color-primary;
  }

  &--inverted {
    color: $color-inverted;
  }
}
