/** @define BlogList */
$componentClass: '.BlogArticle';
.BlogArticle {
  width: 100%;

  @include media($media_l) {
    display: flex;
    flex-wrap: wrap;
  }

  &-inner {
    height: 100%;
    margin: 0 auto;
    @include media($media_m-l) {
      max-width: rem-calc(578);
    }
  }

  &-content {
    position: relative;
  }

  &-section {
    iframe {
      max-width: rem-calc(578);
    }
  }

  &-comments {
    margin-top: $global-padding;
    #{$componentClass}-socialLinks {
      padding-bottom: $global-padding * .25;
      @include borderDashed($color: $color-gray15, $bottom: true);
      .MediaSocialSharing {
        justify-content: flex-end;
      }
    }
  }

  &-image {
    margin-bottom: $global-padding;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  &-tags {
    margin-bottom: $global-padding * .25;
    > *:not(:first-child) {
      margin-left: $global-padding * .5;
    }
    .TextLink {
      line-height: 1;
    }
  }

  &-headline {
    margin-bottom: $global-padding * .5;
  }

  &-tags {
    color: $color-primary;
  }

  &-infos {
    display: flex;
    justify-content: space-between;
  }

  &-date {
    color: $color-gray50;
  }

  &-excerpt {
    padding: $global-padding 0 $global-padding * 2;
  }

  &-link {
    margin-bottom: $global-padding;
    @include media($media_s) {
      text-align: center;
    }
    @include media($media_m-l) {
      width: 19.8958%;
    }
  }

  &-content > &-socialLinks {
    position: absolute;
    right: -50px;
    height: 100%;
    padding-top: 170px;
    @include media($media_s) {
      display: none;
    }
    .MediaSocialSharing {
      position: sticky;
      top: 70px;
      @include media($media_m) {
        top: 30px;
      }
    }
  }
}
