$componentClass: '.MySimReplacementForm';
.MySimReplacementForm {

  .FieldRadio-label {
    display: flex;
  }
  .FieldRadio-label::before {
    margin-top: rem-calc(4);
  }
}
