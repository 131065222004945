/** @define NavigationMainFlyout */
$componentClass: '.NavigationMainFlyout';
.NavigationMainFlyout {
  width: 100%;
  overflow: hidden;
  background-color: $color-black;

  @include media($media_l) {
    background: linear-gradient(to bottom, #000000 1%,#252525 100%);
  }

  .TextLink {
    font-family: $font-family-condensed;
    color: $color-white;
    transition: color $timing-duration * .75 $timing-easing;
    &:after {
      display: none;
    }
    .TextLink-text::before,
    .TextLink-text::after {
      color: $color-primary;
      transition: none;
    }
    &:hover {
      color: $color-primary;
      text-decoration: none;
      transition: color $timing-duration * .5 $timing-easing;
      &:after {
        display: none;
      }
      .TextLink-text::after {
        transition: none;
      }
    }
  }

  &-navi {
    @include media($media_s) {
      padding: $global-padding * .5 $global-padding * .5 0;
    }
    @include media($media_m) {
      padding: $global-padding * .5 $global-padding 0;
    }
    @include media($media_l) {
      display: flex;
      padding: $global-padding*1.5 0;
      @include outerContainer($max-width);
    }
  }
  &-section {
    @include media($media_s-m) {
      padding: $global-padding 0;
      @include borderDashed($color: $color-gray100, $top: true);
    }
    @include media($media_l) {
      width: 33.3333%;
    }
  }
  &-header {
    margin-bottom: $global-padding;
    @include media($media_l) {
      display: none;
    }

    #{$componentClass}-item {
      margin-bottom: $global-padding;
    }
  }

  &-title,
  &-item {
    display: block;
  }

  &-title {
    @include media($media_s-m) {
      .TextLink {
        font-size: 1.2rem;
      }
    }
    @include media($media_l) {
      .TextLink {
        font-size: rem-calc(24);
      }
    }
    text-transform: uppercase;
    margin-bottom: $global-padding * .5;
  }
  &-item {
    font-size: 0.8em;
    letter-spacing: 0.02em;
    margin-bottom: $global-padding * .25;
  }
}
