/** @define UITransitionSpinner */
$componentClass: '.UITransitionSpinner';
.UITransitionSpinner{
  width:100%;
  text-align: center;
  @include media($media_s) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .TextCopy,
  .TextHeadline {
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
  }

  &-animation {
    margin-bottom: $global-padding;
  }

  &-headline + &-copy {
    margin-top: $global-padding * .5;
  }

  &-headline {
    color: $color-primary;
  }

  &-animation {
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-left: 5px;
      background: rgba(255,255,255,0.25);
      border-radius: 50%;
      transform: translateY(0);
      animation: wave 2s infinite ease-in-out;
    }

    span:nth-child(1) { animation-delay: 0s; }
    span:nth-child(2) { animation-delay: 0.1s; }
    span:nth-child(3) { animation-delay: 0.2s; }
    span:nth-child(4) { animation-delay: 0.3s; }
    span:nth-child(5) { animation-delay: 0.4s; }
  }

  $maxHeight: rem-calc(13);

  @keyframes wave {
    0%, 60%, 100% {
      background: rgba(255,255,255,0.25);
      transform: translateY(0);
    }

    20% {
      background: rgba(255,255,255,0.75);
      transform: translateY($maxHeight);
    }

    40% {
      background: rgba(255,255,255,0.75);
      transform: translateY($maxHeight * -1);
    }

  }
}
