/** @define CheckoutCart */
$componentClass: '.CheckoutCart';
.CheckoutCart {

  > * + * {
    margin-top: $global-padding;
  }

  @include media($media_s) {
    &-header,
    &-footer {
      > * + * {
        margin-top: $global-padding;
      }

      .TextLink {
        width: 100%;
      }
    }
  }

  @include media($media_xs) {
    &-header,
    &-footer {
      padding: 0 rem-calc(10);
    }
  }

  @include media($media_m-l) {
    &-header,
    &-footer {
      display: flex;
      .TextLink {
        margin-left: auto;
      }
    }

    &-header {
      align-items: flex-end;
    }

    &-header > *:first-child {
      text-align: left;
    }
  }

  &-thumb {
    display: inline-flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: rem-calc(32);
      height: rem-calc(32);
      background-image: svg-url('<svg id="a" xmlns="http://www.w3.org/2000/svg" width="8.46mm" height="8.60mm" viewBox="0 0 30.05 29.55"><path d="M16.76,1.37c.74-.28,1.91-.29,2.82,.3,.86,.55,1.62,1.73,1.4,4.12-.15,1.56-.36,2.73-.54,3.51-.09,.39-.17,.68-.23,.87-.03,.1-.05,.17-.07,.21l-.02,.05h0l-.29,.81h6.39c1.06,0,1.73,.23,2.13,.7,.4,.48,.64,1.35,.44,2.97h0c-.02,.15-.03,.3-.05,.45h0c-.24,2.07-.57,4.97-1.29,7.44-.39,1.33-.88,2.49-1.49,3.32-.61,.82-1.29,1.27-2.11,1.32H12.41c-.66,0-1.28-.18-1.8-.41-.06,.4-.2,.78-.4,1.11,.64,.27,1.39,.48,2.21,.48h11.5c1.28-.08,2.26-.8,3-1.8,.74-.99,1.27-2.31,1.68-3.69,.75-2.58,1.09-5.57,1.33-7.62h0c.02-.16,.03-.31,.05-.45,.22-1.73,.01-3.02-.71-3.87-.73-.86-1.84-1.12-3.03-1.12h-4.76c.04-.14,.08-.3,.12-.48,.19-.83,.42-2.05,.57-3.67,.25-2.7-.61-4.36-1.94-5.22-1.27-.82-2.85-.8-3.88-.41-.55,.21-.95,.53-1.22,.95-.27,.41-.38,.87-.45,1.32-.06,.41-.07,.86-.08,1.29h0v.11c-.02,.48-.04,.96-.1,1.44-.13,.9-.65,1.88-1.24,2.8-.18,.29-.36,.55-.53,.79-.1,.15-.2,.3-.3,.45-.14,.21-.27,.42-.38,.62h-1.64c.16,.36,.25,.76,.25,1.18h2.2l.13-.4c.05-.14,.18-.38,.43-.75,.08-.13,.18-.27,.28-.42h0c.17-.26,.37-.55,.56-.84,.59-.93,1.25-2.09,1.42-3.27,.08-.55,.1-1.09,.11-1.57v-.1c.02-.45,.03-.84,.07-1.18,.05-.37,.13-.64,.27-.84,.12-.19,.31-.36,.65-.49Z" fill-rule="evenodd"/><rect x=".59" y="8.86" width="8.27" height="20.09" rx="2.36" ry="2.36" fill="none" stroke="#fa5a14" stroke-width="1.18"/><path d="M5.91,24.82c0,.65-.53,1.18-1.18,1.18s-1.18-.53-1.18-1.18,.53-1.18,1.18-1.18,1.18,.53,1.18,1.18" fill="#f60" fill-rule="evenodd"/></svg>');
      background-size: contain;
      background-repeat: no-repeat;
    }

    .TextCopy {
      margin: 0;
    }
  }

  &-note {
    margin-top: $global-padding;
    width: 100%;
  }

  &-noteInner {
    padding: $global-padding;
    background: $color-gray15;
    @include media($media_s) {
      margin-right: 0;
    }
  }

  &--compact {

    #{$componentClass}-header,
    #{$componentClass}-footer {
        padding: 0;
    }

    #{$componentClass}-header {
      display: block;

      > * + * {
        margin-top: $global-padding * .5;
      }
    }

    #{$componentClass}-cart {
      margin-top: 0;
    }

    #{$componentClass}-hint {
      margin-top: rem-calc(12);
    }

    #{$componentClass}-footer {
      margin-top: 2px;
    }

    .TextLink {
      width: 100%;
    }

    @include media($media_m-l) {
      #{$componentClass}-footer {
        margin-top: $global-padding * 1.5;
      }
    }
  }

}

[data-color-mode="dark"] {
  #{$componentClass} {
     &-noteInner {
      background-color: $color-gray-light-darkmode;
    }
    &-thumb {
      &::before {
        background-image: svg-url('<svg id="a" xmlns="http://www.w3.org/2000/svg" width="8.46mm" height="8.60mm" viewBox="0 0 30.05 29.55"><path fill="#{$color-white-darkmode}" d="M16.76,1.37c.74-.28,1.91-.29,2.82,.3,.86,.55,1.62,1.73,1.4,4.12-.15,1.56-.36,2.73-.54,3.51-.09,.39-.17,.68-.23,.87-.03,.1-.05,.17-.07,.21l-.02,.05h0l-.29,.81h6.39c1.06,0,1.73,.23,2.13,.7,.4,.48,.64,1.35,.44,2.97h0c-.02,.15-.03,.3-.05,.45h0c-.24,2.07-.57,4.97-1.29,7.44-.39,1.33-.88,2.49-1.49,3.32-.61,.82-1.29,1.27-2.11,1.32H12.41c-.66,0-1.28-.18-1.8-.41-.06,.4-.2,.78-.4,1.11,.64,.27,1.39,.48,2.21,.48h11.5c1.28-.08,2.26-.8,3-1.8,.74-.99,1.27-2.31,1.68-3.69,.75-2.58,1.09-5.57,1.33-7.62h0c.02-.16,.03-.31,.05-.45,.22-1.73,.01-3.02-.71-3.87-.73-.86-1.84-1.12-3.03-1.12h-4.76c.04-.14,.08-.3,.12-.48,.19-.83,.42-2.05,.57-3.67,.25-2.7-.61-4.36-1.94-5.22-1.27-.82-2.85-.8-3.88-.41-.55,.21-.95,.53-1.22,.95-.27,.41-.38,.87-.45,1.32-.06,.41-.07,.86-.08,1.29h0v.11c-.02,.48-.04,.96-.1,1.44-.13,.9-.65,1.88-1.24,2.8-.18,.29-.36,.55-.53,.79-.1,.15-.2,.3-.3,.45-.14,.21-.27,.42-.38,.62h-1.64c.16,.36,.25,.76,.25,1.18h2.2l.13-.4c.05-.14,.18-.38,.43-.75,.08-.13,.18-.27,.28-.42h0c.17-.26,.37-.55,.56-.84,.59-.93,1.25-2.09,1.42-3.27,.08-.55,.1-1.09,.11-1.57v-.1c.02-.45,.03-.84,.07-1.18,.05-.37,.13-.64,.27-.84,.12-.19,.31-.36,.65-.49Z" fill-rule="evenodd"/><rect x=".59" y="8.86" width="8.27" height="20.09" rx="2.36" ry="2.36" fill="none" stroke="#fa5a14" stroke-width="1.18"/><path d="M5.91,24.82c0,.65-.53,1.18-1.18,1.18s-1.18-.53-1.18-1.18,.53-1.18,1.18-1.18,1.18,.53,1.18,1.18" fill="#f60" fill-rule="evenodd"/></svg>');
      }
    }

  }
}
