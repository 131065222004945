/** @define UIAlertBar */
$componentClass: '.UIAlertBar';
.UIAlertBar{
  position: relative;
  width: 100%;
  @include globalPadding($topRatio: 0, $bottomRatio:0);
  background-color: $color-gray15;
  text-align: center;

  &-inner {
    position: relative;
    color: $color-black;
    @include outerContainer($max-width);
    .TextCopy, .TextLink {
      position: relative;
      z-index: 1;
    }
  }


  &--default,
  &--highlighted {
    &::after{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: rem-calc(5);
      background: $gradient-primary;
    }

    #{$componentClass}-inner {
      padding: $global-padding * .5 0 $global-padding * .62;
      @include media($media_s) {
        .TextCopy {
          display: block;
          text-align: center;
        }
        .TextCopy > span {
          display: block;
        }
      }
      @include media($media_m-l) {
        > * {
          display: inline-block;
        }
        > * + * {
          margin-left: $global-padding * .5;
        }
       .TextCopy {
          p, h1, h2, h3, h4, h5, h6 {
            display: inline-block;
            margin: 0;
          }
        }
      }
    }
  }

  &--highlighted {
    text-align: left;
    background: $gradient-primary;
    &::before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: $color-white;
      z-index: -1;
    }

    #{$componentClass}-inner {
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
      }
    }

    .TextLink {
      color: $color-white;
    }
  }

  &--primary,
  &--secondary,
  &--tertiary {
    #{$componentClass}-inner {
      padding: $global-padding * .19 0 $global-padding * .24;
      > * {
        display: inline-block;
      }
      > * + * {
        margin-left: $global-padding * .5;
      }
     .TextCopy {
        p, h1, h2, h3, h4, h5, h6 {
          display: inline-block;
          margin: 0;
        }
      }
    }
    .TextCopy a, .TextCopy a:hover, .TextCopy a:visited {
      color: $color-white;
      border-color: $color-white;
    }

    .TextLink {
      color: $color-white;
    }
  }

  &--primary {
    background: $gradient-primary;
  }

  &--secondary {
    background-color: $color-gray150;
    #{$componentClass}-inner {
      color: $color-white;
    }
  }

  &--tertiary {
    background: $gradient-tertiary;
    #{$componentClass}-inner {
      color: $color-white;
    }
  }
}
