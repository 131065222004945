/** @define DashboardNavigation */
// @todo create new TabSliderWrapper Component / Refactor TabWrapper Component
$componentClass: '.DashboardNavigation';
.DashboardNavigation {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  .FormOptionTab:first-child {
    margin-right: rem-calc(12);
  }
  .Slider {
    padding-top: $global-padding;
  }
}
