$componentClass: '.GiveMeYourPriceContainer';
.GiveMeYourPriceContainer {
  background-color: $color-black;

  &-divider {

    max-width: $max-width;
    @include globalPadding($vertical: false);
    @include media($media_m-l) {
      margin: $global-padding * 3 auto $global-padding * 2 auto;
    }
  }

  &-hardwareDetails {
    background-color: $color-white;
    padding-bottom: $global-padding;
  }

  &-tariffDetails {
  }

  &--asBundle {

    #{$componentClass}-hardwareDetails {
      padding-top: $global-padding;
    }

    #{$componentClass}-tariffDetails {
      position: relative;
      padding-bottom: $global-padding;
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: rem-calc(70);
        height: rem-calc(70);
        border-radius: 100%;
        background-color: $color-black;
        background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ><polygon fill="#{$color-orange}" points="22,9 13,9 13,0 9,0 9,9 0,9 0,13 9,13 9,22 13,22 13,13 22,13"/></svg>');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 55%;
        transform: translate(-50%, 50%);
        z-index: 1;
      }
    }
  }
}
