/** @define TeaserKeyFact */
.TeaserKeyFact{

    &--inverted{
        background: $color-black;
        color: white;
    }

    text-align: center;

}
