/** @define ButtonCloseIcon */

$componentClass: '.ButtonCloseIcon';
.ButtonCloseIcon{
  cursor: pointer;
  > * {
    transition: color $timing-duration $timing-easing;
  }

  .Icon {
    display: inline-block;
    width: rem-calc(10);
    height: rem-calc(10);
    svg {
      vertical-align: top;
    }
  }
  .Icon-base{
    fill: $color-black;
    transition: fill $timing-duration $timing-easing;
  }
  &:hover{
    > * { color: $color-primary; }
    .Icon-base{
      fill: $color-primary;
    }
  }
}

