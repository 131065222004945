/* column utility classes */

@for $i from 1 through $grid-columns {
  @include column($col: $i);
}

@include media($media_s) {
  @for $i from 1 through $grid-columns {
    @include column($col: $i, $breakpoint: 's');
  }
}

@include media($media_m) {
  @for $i from 1 through $grid-columns {
    @include column($col: $i, $breakpoint: 'm');
  }
}

@include media($media_s-m) {
  @for $i from 1 through $grid-columns {
    @include column($col: $i, $breakpoint: 'sm');
  }
}

@include media($media_m-l) {
  @for $i from 1 through $grid-columns {
    @include column($col: $i, $breakpoint: 'ml');
  }
}

@include media($media_l) {
  @for $i from 1 through $grid-columns {
    @include column($col: $i, $breakpoint: 'l');
  }
}

/* grid utility classes */

.u-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$global-padding;
  margin-top: -$global-padding;
  > * {
    margin-left: $global-padding;
    margin-top: $global-padding;
  }

  @include column($gutter: $global-padding);

  @for $i from 1 through $grid-columns {
    @include column($col: $i, $gutter: $global-padding);
  }

  &.u-collapse { @include collapse($gutter: $global-padding); }

  @include media($media_s) {
    @for $i from 1 through $grid-columns {
      @include column($col: $i, $gutter: $global-padding, $breakpoint: 's');
    }
    &.u-sCollapse { @include collapse($gutter: $global-padding); }
  }

  @include media($media_m) {
    @for $i from 1 through $grid-columns {
      @include column($col: $i, $gutter: $global-padding, $breakpoint: 'm');
    }
    &.u-mCollapse { @include collapse($gutter: $global-padding); }
  }

  @include media($media_s-m) {
    @for $i from 1 through $grid-columns {
      @include column($col: $i, $gutter: $global-padding, $breakpoint: 'sm');
    }
    &.u-smCollapse { @include collapse($gutter: $global-padding); }
  }

  @include media($media_m-l) {
    @for $i from 1 through $grid-columns {
      @include column($col: $i, $gutter: $global-padding, $breakpoint: 'ml');
    }
    &.u-mlCollapse { @include collapse($gutter: $global-padding); }
  }

  @include media($media_l) {
    @for $i from 1 through $grid-columns {
      @include column($col: $i, $gutter: $global-padding, $breakpoint: 'l');
    }
    &.u-lCollapse { @include collapse($gutter: $global-padding); }
  }

  > .u-grid {
    margin-left: 0;
    margin-top: 0;
  }
}
