$componentClass: '.HotlineOptionsDialog';
.HotlineOptionsDialog {
  .UIOverlayWrapper-container {
    background-color: transparent;
  }
  &-hotlineOptions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 256px;
    border-radius: 6px;
    background-color: transparent;
    @include media($media_s) {
      width: auto;
    }
  }

  &-option {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 256px;
    background-color: $color-inverted;
    border-radius: 6px;
    margin-bottom: 8px;
    padding: 16px 24px 24px 24px;
  }

  &-headline {
    font-family: $font-family-condensed;
    font-size: $h-xs_font-size;
    margin-bottom: 12px;
  }

  &-number {
    font-family: $font-family-condensed;
    font-weight: $font-weight-bold;
    font-size: $h-s_font-size;
    color: $color-orange;
    margin-bottom: 12px;
    cursor: pointer;
  }

  &-copy {
    font-family: $font-family-regular;
    font-size: $h-xxxs_font-size;
    line-height: 1.33rem;
    letter-spacing: 1px;
    text-align: center;
  }
}
