/** @define MediaSocialSharing */
$componentClass: '.MediaSocialSharing';
.MediaSocialSharing {

  &-link {
    .Icon-base {
      fill: $color-gray50;
      transition: fill $timing-duration $timing-easing;
    }

    &:hover {
      .Icon-base {
        fill: $color-primary-dark;
      }
    }
  }

  &:not(#{$componentClass}--vertical) {
    display: flex;

    .Icon svg{
      height: rem-calc(18);
    }

    #{$componentClass}-link{
      &:not(:last-child) {
        margin-right: $global-padding * .5;
      }
    }
  }

  &--vertical{
    .Icon svg{
      width: rem-calc(18);
    }
    #{$componentClass}-link{
      display: block;
      &:not(:last-child) {
        margin-bottom: $global-padding * 0.25;
      }
    }
  }

 &--fixed {
    position: fixed;
    opacity: 0;
    animation: ani-visibility $timing-duration $timing-easing forwards;
    transition: opacity $timing-duration $timing-easing;
    .MediaSocialSharing {
      display: inline-block;
    }
    &.is-active {
      opacity: 1;
      visibility: visible;
      animation: none;
    }
  }

  @keyframes ani-visibility {
    0% {
      visibility: visible;
    }
    100% {
      visibility: hidden;
    }
  }
}
