/** @define FooterLegals */
.FooterLegals{

  @include media($media_m-l) {
    margin: 0 auto;
    max-width: rem-calc(648);
  }

  &-item {
    strong {
      color: $color-black;
    }
    &:first-child {
      margin-top: $global-padding * 2;
    }
    &:not(:last-child) {
      margin-bottom: $global-padding * 2;
    }
  }

  &-headline {
    margin-bottom: $global-padding * .25;
  }

}
