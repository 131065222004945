/** @define BlogList */
$componentClass: '.BlogArticleRelated';
.BlogArticleRelated {
  position: relative;
  width: 100%;
  margin-top: $global-padding;
  padding-top: $global-padding * 2;
  @include borderDashed($color: $color-gray50, $top: true);
  &-headline {
    text-align: center;
    margin-bottom: $global-padding;
  }
  &-item {

    @include media($media_s) {
      width:100%;
      &:first-child {
        margin-bottom: $global-padding;
      }
    }
    @include media($media_m-l) {
      width: 50%;
      &:first-child {
        padding-right: $global-padding * .5;
      }
      &:last-child {
        padding-left: $global-padding * .5;
      }
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
  }
}
