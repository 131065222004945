/** @define TextMessage */
.TextMessage {
  &:not(&--standalone),
  &-standaloneInner {
    padding: rem-calc(25);
    margin-bottom: $global-padding;
  }

  &--error {
    color: $color-red;
    border: 2px solid $color-red;
    border-radius: rem-calc(5);
    background-color: rgba(248, 234, 233, 1);
    .TextCopy ul li {
      padding-bottom: 0;
      &::before {
        background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#{$color-red}" cx="5" cy="5" r="5"/></svg>');
      }
    }
  }

  &--success {
    color: $color-green;
    border: 2px solid $color-green;
    border-radius: rem-calc(5);
    background-color: white;
    .TextCopy ul li {
      padding-bottom: 0;
      &::before {
        background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#{$color-green}" cx="5" cy="5" r="5"/></svg>');
      }
    }
  }

  // todo use function to create warning, success, error styles because they all have the same structure
  &--warning {
    color: $color-primary;
    border: 2px solid $color-primary;
    border-radius: rem-calc(5);
    background-color: white;
    .TextCopy ul li {
      padding-bottom: 0;
      &::before {
        background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><circle fill="#{$color-primary}" cx="5" cy="5" r="5"/></svg>');
      }
    }
  }

  &--info {
    color: $color-black;
    border: 1px solid $color-gray15;
  }

  &--standalone {
    background: $color-white;
    padding-top: $global-padding;
  }

  &-standaloneInner {
    @include outerContainer($max-width);
    height: rem-calc(130);
    display: flex;
  }

  &-standaloneText {
    margin: auto;
  }
}
