/** @define TextGroup */
.TextGroup {
  display: flex;
  flex-wrap: wrap;
  &-item {
    vertical-align: top;
    margin-bottom: $global-padding*2;

    &:last-child {
       margin-bottom: $global-padding;
    }

    ul {
      margin-top: 1em;
      margin-bottom: 1em;
      padding-left: 1.5em;
    }

    @include media($media_m-l) {
      width: 50%;
      display: inline-block;

      &:not(&--main){
        // add border to every first block that has no main modifier
        &:nth-of-type(even) {
          @include borderDashed($right: true);
          padding-right: $global-padding;
          &.TextGroup-item--inverted{
            @include borderDashed($right: true, $color: $color-white);
          }
        }
        &:nth-of-type(odd) {
          padding-left: $global-padding;
        }
      }
      &:nth-last-child(2):nth-child(even) {
        margin-bottom: $global-padding;
      }
    }

    @include media($media_s) {
      &:not(:last-child) {
        &:after {
          content: '';
          display: block;
          width: 100%;
          margin-top: $global-padding*2;
          @include borderDashed($bottom: true);
        }

        &.TextGroup-item--inverted {
          &:after {
            @include borderDashed($bottom: true, $color: $color-white);
          }
        }
      }
    }
    &--main {
      width: 100%;
    }
  }
}
