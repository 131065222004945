// Margin & Padding Utilities
.u-globalPadding { @include globalPadding(); }
.u-globalPaddingV { @include globalPadding($horizontal: false); }
.u-globalPaddingH { @include globalPadding($vertical: false); }
.u-outerContainer { @include outerContainer($max-width); }

.u-marginInit { margin: initial !important; }
.u-marginAuto { margin: auto !important; }
.u-marginVAuto { margin-top: auto !important; margin-bottom: auto !important; }
.u-marginHAuto { margin-left: auto !important; margin-right: auto !important; }
.u-marginTopAuto { margin-top: auto !important; }
.u-marginBottomAuto { margin-bottom: auto !important; }
.u-marginLeftAuto { margin-left: auto !important; }
.u-marginRightAuto { margin-right: auto !important; }

.u-margin { @include spacing($horizontal: true, $vertical: true); }
.u-marginV { @include spacing($vertical: true); }
.u-marginH { @include spacing($horizontal: true); }
.u-marginTop { @include spacing($top: true); }
.u-marginBottom { @include spacing($bottom: true); }
.u-marginLeft { @include spacing($left: true); }
.u-marginRight { @include spacing($right: true); }

.u-marginXXS { @include spacing($horizontal: true, $vertical: true, $ratio: .15); }
.u-marginVXXS { @include spacing($vertical: true, $ratio: .15); }
.u-marginHXXS { @include spacing($horizontal: true, $ratio: .15); }
.u-marginTopXXS { @include spacing($top: true, $ratio: .15); }
.u-marginBottomXXS { @include spacing($bottom: true, $ratio: .15); }
.u-marginLeftXXS { @include spacing($left: true, $ratio: .15); }
.u-marginRightXXS { @include spacing($right: true, $ratio: .15); }

.u-marginXS { @include spacing($horizontal: true, $vertical: true, $ratio: .25); }
.u-marginVXS { @include spacing($vertical: true, $ratio: .25); }
.u-marginHXS { @include spacing($horizontal: true, $ratio: .25); }
.u-marginTopXS { @include spacing($top: true, $ratio: .25); }
.u-marginBottomXS { @include spacing($bottom: true, $ratio: .25); }
.u-marginLeftXS { @include spacing($left: true, $ratio: .25); }
.u-marginRightXS { @include spacing($right: true, $ratio: .25); }

.u-marginS { @include spacing($horizontal: true, $vertical: true, $ratio: .5); }
.u-marginVS { @include spacing($vertical: true, $ratio: .5); }
.u-marginHS { @include spacing($horizontal: true, $ratio: .5); }
.u-marginTopS { @include spacing($top: true, $ratio: .5); }
.u-marginBottomS { @include spacing($bottom: true, $ratio: .5); }
.u-marginLeftS { @include spacing($left: true, $ratio: .5); }
.u-marginRightS { @include spacing($right: true, $ratio: .5); }

.u-marginM { @include spacing($horizontal: true, $vertical: true, $ratio: 1.5); }
.u-marginVM { @include spacing($vertical: true, $ratio: 1.5); }
.u-marginHM { @include spacing($horizontal: true, $ratio: 1.5); }
.u-marginTopM { @include spacing($top: true, $ratio: 1.5); }
.u-marginBottomM { @include spacing($bottom: true, $ratio: 1.5); }
.u-marginLeftM { @include spacing($left: true, $ratio: 1.5); }
.u-marginRightM { @include spacing($right: true, $ratio: 1.5); }

.u-marginL { @include spacing($horizontal: true, $vertical: true, $ratio: 1.75); }
.u-marginVL { @include spacing($vertical: true, $ratio: 1.75); }
.u-marginHL { @include spacing($horizontal: true, $ratio: 1.75); }
.u-marginTopL { @include spacing($top: true, $ratio: 1.75); }
.u-marginBottomL { @include spacing($bottom: true, $ratio: 1.75); }
.u-marginLeftL { @include spacing($left: true, $ratio: 1.75); }
.u-marginRightL { @include spacing($right: true, $ratio: 1.75); }

.u-marginXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2); }
.u-marginVXL { @include spacing($vertical: true, $ratio: 2); }
.u-marginHXL { @include spacing($horizontal: true, $ratio: 2); }
.u-marginTopXL { @include spacing($top: true, $ratio: 2); }
.u-marginBottomXL { @include spacing($bottom: true, $ratio: 2); }
.u-marginLeftXL { @include spacing($left: true, $ratio: 2); }
.u-marginRightXL { @include spacing($right: true, $ratio: 2); }

.u-marginXXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2.5); }
.u-marginVXXL { @include spacing($vertical: true, $ratio: 2.5); }
.u-marginHXXL { @include spacing($horizontal: true, $ratio: 2.5); }
.u-marginTopXXL { @include spacing($top: true, $ratio: 2.5); }
.u-marginBottomXXL { @include spacing($bottom: true, $ratio: 2.5, $type: 'padding'); }
.u-marginLeftXXL { @include spacing($left: true, $ratio: 2.5); }
.u-marginRightXXL { @include spacing($right: true, $ratio: 2.5); }

.u-paddingInit { padding: initial !important; }

.u-padding { @include spacing($horizontal: true, $vertical: true, $type: 'padding'); }
.u-paddingV { @include spacing($vertical: true, $type: 'padding'); }
.u-paddingH { @include spacing($horizontal: true, $type: 'padding'); }
.u-paddingTop { @include spacing($top: true, $type: 'padding'); }
.u-paddingBottom { @include spacing($bottom: true, $type: 'padding'); }
.u-paddingLeft { @include spacing($left: true, $type: 'padding'); }
.u-paddingRight { @include spacing($right: true, $type: 'padding'); }

.u-paddingXXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .15); }
.u-paddingVXXS { @include spacing($vertical: true, $type: 'padding', $ratio: .15); }
.u-paddingHXXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .15); }
.u-paddingTopXXS { @include spacing($top: true, $type: 'padding', $ratio: .15); }
.u-paddingBottomXXS { @include spacing($bottom: true, $type: 'padding', $ratio: .15); }
.u-paddingLeftXXS { @include spacing($left: true, $type: 'padding', $ratio: .15); }
.u-paddingRightXXS { @include spacing($right: true, $type: 'padding', $ratio: .15); }

.u-paddingXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .25); }
.u-paddingVXS { @include spacing($vertical: true, $type: 'padding', $ratio: .25); }
.u-paddingHXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .25); }
.u-paddingTopXS { @include spacing($top: true, $type: 'padding', $ratio: .25); }
.u-paddingBottomXS { @include spacing($bottom: true, $type: 'padding', $ratio: .25); }
.u-paddingLeftXS { @include spacing($left: true, $type: 'padding', $ratio: .25); }
.u-paddingRightXS { @include spacing($right: true, $type: 'padding', $ratio: .25); }

.u-paddingS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .5); }
.u-paddingVS { @include spacing($vertical: true, $type: 'padding', $ratio: .5); }
.u-paddingHS { @include spacing($horizontal: true, $type: 'padding', $ratio: .5); }
.u-paddingTopS { @include spacing($top: true, $type: 'padding', $ratio: .5); }
.u-paddingBottomS { @include spacing($bottom: true, $type: 'padding', $ratio: .5); }
.u-paddingLeftS { @include spacing($left: true, $type: 'padding', $ratio: .5); }
.u-paddingRightS { @include spacing($right: true, $type: 'padding', $ratio: .5); }

.u-paddingM { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.5); }
.u-paddingVM { @include spacing($vertical: true, $type: 'padding', $ratio: 1.5); }
.u-paddingHM { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.5); }
.u-paddingTopM { @include spacing($top: true, $type: 'padding', $ratio: 1.5); }
.u-paddingBottomM { @include spacing($bottom: true, $type: 'padding', $ratio: 1.5); }
.u-paddingLeftM { @include spacing($left: true, $type: 'padding', $ratio: 1.5); }
.u-paddingRightM { @include spacing($right: true, $type: 'padding', $ratio: 1.5); }

.u-paddingL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.75); }
.u-paddingVL { @include spacing($vertical: true, $type: 'padding', $ratio: 1.75); }
.u-paddingHL { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.75); }
.u-paddingTopL { @include spacing($top: true, $type: 'padding', $ratio: 1.75); }
.u-paddingBottomL { @include spacing($bottom: true, $type: 'padding', $ratio: 1.75); }
.u-paddingLeftL { @include spacing($left: true, $type: 'padding', $ratio: 1.75); }
.u-paddingRightL { @include spacing($right: true, $type: 'padding', $ratio: 1.75); }

.u-paddingXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2); }
.u-paddingVXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2); }
.u-paddingHXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2); }
.u-paddingTopXL { @include spacing($top: true, $type: 'padding', $ratio: 2); }
.u-paddingBottomXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2); }
.u-paddingLeftXL { @include spacing($left: true, $type: 'padding', $ratio: 2); }
.u-paddingRightXL { @include spacing($right: true, $type: 'padding', $ratio: 2); }

.u-paddingXXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2.5); }
.u-paddingVXXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2.5); }
.u-paddingHXXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2.5); }
.u-paddingTopXXL { @include spacing($top: true, $type: 'padding', $ratio: 2.5); }
.u-paddingBottomXXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2.5); }
.u-paddingLeftXXL { @include spacing($left: true, $type: 'padding', $ratio: 2.5); }
.u-paddingRightXXL { @include spacing($right: true, $type: 'padding', $ratio: 2.5); }


@include media($media_s) {

  .u-sMarginAuto { margin: auto !important; }
  .u-sMarginVAuto { margin-top: auto !important; margin-bottom: auto !important; }
  .u-sMarginHAuto { margin-left: auto !important; margin-right: auto !important; }
  .u-sMarginTopAuto { margin-top: auto !important; }
  .u-sMarginBottomAuto { margin-bottom: auto !important; }
  .u-sMarginLeftAuto { margin-left: auto !important; }
  .u-sMarginRightAuto { margin-right: auto !important; }
  .u-sMarginInit { margin: initial !important; }

  .u-sMargin { @include spacing( $horizontal: true, $vertical: true); }
  .u-sMarginV { @include spacing($vertical: true); }
  .u-sMarginH { @include spacing($horizontal: true); }
  .u-sMarginTop { @include spacing($top: true); }
  .u-sMarginBottom { @include spacing($bottom: true); }
  .u-sMarginLeft { @include spacing($left: true); }
  .u-sMarginRight { @include spacing($right: true); }

  .u-sMarginXXS { @include spacing($horizontal: true, $vertical: true, $ratio: .15); }
  .u-sMarginVXXS { @include spacing($vertical: true, $ratio: .15); }
  .u-sMarginHXXS { @include spacing($horizontal: true, $ratio: .15); }
  .u-sMarginTopXXS { @include spacing($top: true, $ratio: .15); }
  .u-sMarginBottomXXS { @include spacing($bottom: true, $ratio: .15); }
  .u-sMarginLeftXXS { @include spacing($left: true, $ratio: .15); }
  .u-sMarginRightXXS { @include spacing($right: true, $ratio: .15); }

  .u-sMarginXS { @include spacing($horizontal: true, $vertical: true, $ratio: .25); }
  .u-sMarginVXS { @include spacing($vertical: true, $ratio: .25); }
  .u-sMarginHXS { @include spacing($horizontal: true, $ratio: .25); }
  .u-sMarginTopXS { @include spacing($top: true, $ratio: .25); }
  .u-sMarginBottomXS { @include spacing($bottom: true, $ratio: .25); }
  .u-sMarginLeftXS { @include spacing($left: true, $ratio: .25); }
  .u-sMarginRightXS { @include spacing($right: true, $ratio: .25); }

  .u-sMarginS { @include spacing($horizontal: true, $vertical: true, $ratio: .5); }
  .u-sMarginVS { @include spacing($vertical: true, $ratio: .5); }
  .u-sMarginHS { @include spacing($horizontal: true, $ratio: .5); }
  .u-sMarginTopS { @include spacing($top: true, $ratio: .5); }
  .u-sMarginBottomS { @include spacing($bottom: true, $ratio: .5); }
  .u-sMarginLeftS { @include spacing($left: true, $ratio: .5); }
  .u-sMarginRightS { @include spacing($right: true, $ratio: .5); }

  .u-sMarginM { @include spacing($horizontal: true, $vertical: true, $ratio: 1.5); }
  .u-sMarginVM { @include spacing($vertical: true, $ratio: 1.5); }
  .u-sMarginHM { @include spacing($horizontal: true, $ratio: 1.5); }
  .u-sMarginTopM { @include spacing($top: true, $ratio: 1.5); }
  .u-sMarginBottomM { @include spacing($bottom: true, $ratio: 1.5); }
  .u-sMarginLeftM { @include spacing($left: true, $ratio: 1.5); }
  .u-sMarginRightM { @include spacing($right: true, $ratio: 1.5); }

  .u-sMarginL { @include spacing($horizontal: true, $vertical: true, $ratio: 1.75); }
  .u-sMarginVL { @include spacing($vertical: true, $ratio: 1.75); }
  .u-sMarginHL { @include spacing($horizontal: true, $ratio: 1.75); }
  .u-sMarginTopL { @include spacing($top: true, $ratio: 1.75); }
  .u-sMarginBottomL { @include spacing($bottom: true, $ratio: 1.75); }
  .u-sMarginLeftL { @include spacing($left: true, $ratio: 1.75); }
  .u-sMarginRightL { @include spacing($right: true, $ratio: 1.75); }

  .u-sMarginXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2); }
  .u-sMarginVXL { @include spacing($vertical: true, $ratio: 2); }
  .u-sMarginHXL { @include spacing($horizontal: true, $ratio: 2); }
  .u-sMarginTopXL { @include spacing($top: true, $ratio: 2); }
  .u-sMarginBottomXL { @include spacing($bottom: true, $ratio: 2); }
  .u-sMarginLeftXL { @include spacing($left: true, $ratio: 2); }
  .u-sMarginRightXL { @include spacing($right: true, $ratio: 2); }

  .u-sMarginXXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2.5); }
  .u-sMarginVXXL { @include spacing($vertical: true, $ratio: 2.5); }
  .u-sMarginHXXL { @include spacing($horizontal: true, $ratio: 2.5); }
  .u-sMarginTopXXL { @include spacing($top: true, $ratio: 2.5); }
  .u-sMarginBottomXXL { @include spacing($bottom: true, $ratio: 2.5); }
  .u-sMarginLeftXXL { @include spacing($left: true, $ratio: 2.5); }
  .u-sMarginRightXXL { @include spacing($right: true, $ratio: 2.5); }

  .u-sPaddingInit { padding: initial !important; }

  .u-sPadding { @include spacing($horizontal: true, $vertical: true, $type: 'padding'); }
  .u-sPaddingV { @include spacing($vertical: true, $type: 'padding'); }
  .u-sPaddingH { @include spacing($horizontal: true, $type: 'padding'); }
  .u-sPaddingTop { @include spacing($top: true, $type: 'padding'); }
  .u-sPaddingBottom { @include spacing($bottom: true, $type: 'padding'); }
  .u-sPaddingLeft { @include spacing($left: true, $type: 'padding'); }
  .u-sPaddingRight { @include spacing($right: true, $type: 'padding'); }

  .u-sPaddingXXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .15); }
  .u-sPaddingVXXS { @include spacing($vertical: true, $type: 'padding', $ratio: .15); }
  .u-sPaddingHXXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .15); }
  .u-sPaddingTopXXS { @include spacing($top: true, $type: 'padding', $ratio: .15); }
  .u-sPaddingBottomXXS { @include spacing($bottom: true, $type: 'padding', $ratio: .15); }
  .u-sPaddingLeftXXS { @include spacing($left: true, $type: 'padding', $ratio: .15); }
  .u-sPaddingRightXXS { @include spacing($right: true, $type: 'padding', $ratio: .15); }

  .u-sPaddingXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .25); }
  .u-sPaddingVXS { @include spacing($vertical: true, $type: 'padding', $ratio: .25); }
  .u-sPaddingHXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .25); }
  .u-sPaddingTopXS { @include spacing($top: true, $type: 'padding', $ratio: .25); }
  .u-sPaddingBottomXS { @include spacing($bottom: true, $type: 'padding', $ratio: .25); }
  .u-sPaddingLeftXS { @include spacing($left: true, $type: 'padding', $ratio: .25); }
  .u-sPaddingRightXS { @include spacing($right: true, $type: 'padding', $ratio: .25); }

  .u-sPaddingS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .5); }
  .u-sPaddingVS { @include spacing($vertical: true, $type: 'padding', $ratio: .5); }
  .u-sPaddingHS { @include spacing($horizontal: true, $type: 'padding', $ratio: .5); }
  .u-sPaddingTopS { @include spacing($top: true, $type: 'padding', $ratio: .5); }
  .u-sPaddingBottomS { @include spacing($bottom: true, $type: 'padding', $ratio: .5); }
  .u-sPaddingLeftS { @include spacing($left: true, $type: 'padding', $ratio: .5); }
  .u-sPaddingRightS { @include spacing($right: true, $type: 'padding', $ratio: .5); }

  .u-sPaddingM { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.5); }
  .u-sPaddingVM { @include spacing($vertical: true, $type: 'padding', $ratio: 1.5); }
  .u-sPaddingHM { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.5); }
  .u-sPaddingTopM { @include spacing($top: true, $type: 'padding', $ratio: 1.5); }
  .u-sPaddingBottomM { @include spacing($bottom: true, $type: 'padding', $ratio: 1.5); }
  .u-sPaddingLeftM { @include spacing($left: true, $type: 'padding', $ratio: 1.5); }
  .u-sPaddingRightM { @include spacing($right: true, $type: 'padding', $ratio: 1.5); }

  .u-sPaddingL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.75); }
  .u-sPaddingVL { @include spacing($vertical: true, $type: 'padding', $ratio: 1.75); }
  .u-sPaddingHL { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.75); }
  .u-sPaddingTopL { @include spacing($top: true, $type: 'padding', $ratio: 1.75); }
  .u-sPaddingBottomL { @include spacing($bottom: true, $type: 'padding', $ratio: 1.75); }
  .u-sPaddingLeftL { @include spacing($left: true, $type: 'padding', $ratio: 1.75); }
  .u-sPaddingRightL { @include spacing($right: true, $type: 'padding', $ratio: 1.75); }

  .u-sPaddingXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2); }
  .u-sPaddingVXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2); }
  .u-sPaddingHXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2); }
  .u-sPaddingTopXL { @include spacing($top: true, $type: 'padding', $ratio: 2); }
  .u-sPaddingBottomXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2); }
  .u-sPaddingLeftXL { @include spacing($left: true, $type: 'padding', $ratio: 2); }
  .u-sPaddingRightXL { @include spacing($right: true, $type: 'padding', $ratio: 2); }

  .u-sPaddingXXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2.5); }
  .u-sPaddingVXXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2.5); }
  .u-sPaddingHXXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2.5); }
  .u-sPaddingTopXXL { @include spacing($top: true, $type: 'padding', $ratio: 2.5); }
  .u-sPaddingBottomXXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2.5); }
  .u-sPaddingLeftXXL { @include spacing($left: true, $type: 'padding', $ratio: 2.5); }
  .u-sPaddingRightXXL { @include spacing($right: true, $type: 'padding', $ratio: 2.5); }
}

@include media($media_m) {

  .u-mMarginAuto { margin: auto !important; }
  .u-mMarginVAuto { margin-top: auto !important; margin-bottom: auto !important; }
  .u-mMarginHAuto { margin-left: auto !important; margin-right: auto !important; }
  .u-mMarginTopAuto { margin-top: auto !important; }
  .u-mMarginBottomAuto { margin-bottom: auto !important; }
  .u-mMarginLeftAuto { margin-left: auto !important; }
  .u-mMarginRightAuto { margin-right: auto !important; }
  .u-mMarginInit { margin: initial !important; }

  .u-mMargin { @include spacing( $horizontal: true, $vertical: true); }
  .u-mMarginV { @include spacing($vertical: true); }
  .u-mMarginH { @include spacing($horizontal: true); }
  .u-mMarginTop { @include spacing($top: true); }
  .u-mMarginBottom { @include spacing($bottom: true); }
  .u-mMarginLeft { @include spacing($left: true); }
  .u-mMarginRight { @include spacing($right: true); }

  .u-mMarginXXS { @include spacing($horizontal: true, $vertical: true, $ratio: .15); }
  .u-mMarginVXXS { @include spacing($vertical: true, $ratio: .15); }
  .u-mMarginHXXS { @include spacing($horizontal: true, $ratio: .15); }
  .u-mMarginTopXXS { @include spacing($top: true, $ratio: .15); }
  .u-mMarginBottomXXS { @include spacing($bottom: true, $ratio: .15); }
  .u-mMarginLeftXXS { @include spacing($left: true, $ratio: .15); }
  .u-mMarginRightXXS { @include spacing($right: true, $ratio: .15); }

  .u-mMarginXS { @include spacing($horizontal: true, $vertical: true, $ratio: .25); }
  .u-mMarginVXS { @include spacing($vertical: true, $ratio: .25); }
  .u-mMarginHXS { @include spacing($horizontal: true, $ratio: .25); }
  .u-mMarginTopXS { @include spacing($top: true, $ratio: .25); }
  .u-mMarginBottomXS { @include spacing($bottom: true, $ratio: .25); }
  .u-mMarginLeftXS { @include spacing($left: true, $ratio: .25); }
  .u-mMarginRightXS { @include spacing($right: true, $ratio: .25); }

  .u-mMarginS { @include spacing($horizontal: true, $vertical: true, $ratio: .5); }
  .u-mMarginVS { @include spacing($vertical: true, $ratio: .5); }
  .u-mMarginHS { @include spacing($horizontal: true, $ratio: .5); }
  .u-mMarginTopS { @include spacing($top: true, $ratio: .5); }
  .u-mMarginBottomS { @include spacing($bottom: true, $ratio: .5); }
  .u-mMarginLeftS { @include spacing($left: true, $ratio: .5); }
  .u-mMarginRightS { @include spacing($right: true, $ratio: .5); }

  .u-mMarginM { @include spacing($horizontal: true, $vertical: true, $ratio: 1.5); }
  .u-mMarginVM { @include spacing($vertical: true, $ratio: 1.5); }
  .u-mMarginHM { @include spacing($horizontal: true, $ratio: 1.5); }
  .u-mMarginTopM { @include spacing($top: true, $ratio: 1.5); }
  .u-mMarginBottomM { @include spacing($bottom: true, $ratio: 1.5); }
  .u-mMarginLeftM { @include spacing($left: true, $ratio: 1.5); }
  .u-mMarginRightM { @include spacing($right: true, $ratio: 1.5); }

  .u-mMarginL { @include spacing($horizontal: true, $vertical: true, $ratio: 1.75); }
  .u-mMarginVL { @include spacing($vertical: true, $ratio: 1.75); }
  .u-mMarginHL { @include spacing($horizontal: true, $ratio: 1.75); }
  .u-mMarginTopL { @include spacing($top: true, $ratio: 1.75); }
  .u-mMarginBottomL { @include spacing($bottom: true, $ratio: 1.75); }
  .u-mMarginLeftL { @include spacing($left: true, $ratio: 1.75); }
  .u-mMarginRightL { @include spacing($right: true, $ratio: 1.75); }

  .u-mMarginXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2); }
  .u-mMarginVXL { @include spacing($vertical: true, $ratio: 2); }
  .u-mMarginHXL { @include spacing($horizontal: true, $ratio: 2); }
  .u-mMarginTopXL { @include spacing($top: true, $ratio: 2); }
  .u-mMarginBottomXL { @include spacing($bottom: true, $ratio: 2); }
  .u-mMarginLeftXL { @include spacing($left: true, $ratio: 2); }
  .u-mMarginRightXL { @include spacing($right: true, $ratio: 2); }

  .u-mMarginXXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2.5); }
  .u-mMarginVXXL { @include spacing($vertical: true, $ratio: 2.5); }
  .u-mMarginHXXL { @include spacing($horizontal: true, $ratio: 2.5); }
  .u-mMarginTopXXL { @include spacing($top: true, $ratio: 2.5); }
  .u-mMarginBottomXXL { @include spacing($bottom: true, $ratio: 2.5); }
  .u-mMarginLeftXXL { @include spacing($left: true, $ratio: 2.5); }
  .u-mMarginRightXXL { @include spacing($right: true, $ratio: 2.5); }

  .u-mPaddingInit { padding: initial !important; }

  .u-mPadding { @include spacing($horizontal: true, $vertical: true, $type: 'padding'); }
  .u-mPaddingV { @include spacing($vertical: true, $type: 'padding'); }
  .u-mPaddingH { @include spacing($horizontal: true, $type: 'padding'); }
  .u-mPaddingTop { @include spacing($top: true, $type: 'padding'); }
  .u-mPaddingBottom { @include spacing($bottom: true, $type: 'padding'); }
  .u-mPaddingLeft { @include spacing($left: true, $type: 'padding'); }
  .u-mPaddingRight { @include spacing($right: true, $type: 'padding'); }

  .u-mPaddingXXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .15); }
  .u-mPaddingVXXS { @include spacing($vertical: true, $type: 'padding', $ratio: .15); }
  .u-mPaddingHXXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .15); }
  .u-mPaddingTopXXS { @include spacing($top: true, $type: 'padding', $ratio: .15); }
  .u-mPaddingBottomXXS { @include spacing($bottom: true, $type: 'padding', $ratio: .15); }
  .u-mPaddingLeftXXS { @include spacing($left: true, $type: 'padding', $ratio: .15); }
  .u-mPaddingRightXXS { @include spacing($right: true, $type: 'padding', $ratio: .15); }

  .u-mPaddingXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .25); }
  .u-mPaddingVXS { @include spacing($vertical: true, $type: 'padding', $ratio: .25); }
  .u-mPaddingHXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .25); }
  .u-mPaddingTopXS { @include spacing($top: true, $type: 'padding', $ratio: .25); }
  .u-mPaddingBottomXS { @include spacing($bottom: true, $type: 'padding', $ratio: .25); }
  .u-mPaddingLeftXS { @include spacing($left: true, $type: 'padding', $ratio: .25); }
  .u-mPaddingRightXS { @include spacing($right: true, $type: 'padding', $ratio: .25); }

  .u-mPaddingS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .5); }
  .u-mPaddingVS { @include spacing($vertical: true, $type: 'padding', $ratio: .5); }
  .u-mPaddingHS { @include spacing($horizontal: true, $type: 'padding', $ratio: .5); }
  .u-mPaddingTopS { @include spacing($top: true, $type: 'padding', $ratio: .5); }
  .u-mPaddingBottomS { @include spacing($bottom: true, $type: 'padding', $ratio: .5); }
  .u-mPaddingLeftS { @include spacing($left: true, $type: 'padding', $ratio: .5); }
  .u-mPaddingRightS { @include spacing($right: true, $type: 'padding', $ratio: .5); }

  .u-mPaddingM { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.5); }
  .u-mPaddingVM { @include spacing($vertical: true, $type: 'padding', $ratio: 1.5); }
  .u-mPaddingHM { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.5); }
  .u-mPaddingTopM { @include spacing($top: true, $type: 'padding', $ratio: 1.5); }
  .u-mPaddingBottomM { @include spacing($bottom: true, $type: 'padding', $ratio: 1.5); }
  .u-mPaddingLeftM { @include spacing($left: true, $type: 'padding', $ratio: 1.5); }
  .u-mPaddingRightM { @include spacing($right: true, $type: 'padding', $ratio: 1.5); }

  .u-mPaddingL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.75); }
  .u-mPaddingVL { @include spacing($vertical: true, $type: 'padding', $ratio: 1.75); }
  .u-mPaddingHL { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.75); }
  .u-mPaddingTopL { @include spacing($top: true, $type: 'padding', $ratio: 1.75); }
  .u-mPaddingBottomL { @include spacing($bottom: true, $type: 'padding', $ratio: 1.75); }
  .u-mPaddingLeftL { @include spacing($left: true, $type: 'padding', $ratio: 1.75); }
  .u-mPaddingRightL { @include spacing($right: true, $type: 'padding', $ratio: 1.75); }

  .u-mPaddingXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2); }
  .u-mPaddingVXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2); }
  .u-mPaddingHXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2); }
  .u-mPaddingTopXL { @include spacing($top: true, $type: 'padding', $ratio: 2); }
  .u-mPaddingBottomXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2); }
  .u-mPaddingLeftXL { @include spacing($left: true, $type: 'padding', $ratio: 2); }
  .u-mPaddingRightXL { @include spacing($right: true, $type: 'padding', $ratio: 2); }

  .u-mPaddingXXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2.5); }
  .u-mPaddingVXXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2.5); }
  .u-mPaddingHXXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2.5); }
  .u-mPaddingTopXXL { @include spacing($top: true, $type: 'padding', $ratio: 2.5); }
  .u-mPaddingBottomXXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2.5); }
  .u-mPaddingLeftXXL { @include spacing($left: true, $type: 'padding', $ratio: 2.5); }
  .u-mPaddingRightXXL { @include spacing($right: true, $type: 'padding', $ratio: 2.5); }
}

@include media($media_s-m) {

  .u-smMarginAuto { margin: auto !important; }
  .u-smMarginVAuto { margin-top: auto !important; margin-bottom: auto !important; }
  .u-smMarginHAuto { margin-left: auto !important; margin-right: auto !important; }
  .u-smMarginTopAuto { margin-top: auto !important; }
  .u-smMarginBottomAuto { margin-bottom: auto !important; }
  .u-smMarginLeftAuto { margin-left: auto !important; }
  .u-smMarginRightAuto { margin-right: auto !important; }
  .u-smMarginInit { margin: initial !important; }

  .u-smMargin { @include spacing( $horizontal: true, $vertical: true); }
  .u-smMarginV { @include spacing($vertical: true); }
  .u-smMarginH { @include spacing($horizontal: true); }
  .u-smMarginTop { @include spacing($top: true); }
  .u-smMarginBottom { @include spacing($bottom: true); }
  .u-smMarginLeft { @include spacing($left: true); }
  .u-smMarginRight { @include spacing($right: true); }

  .u-smMarginXXS { @include spacing($horizontal: true, $vertical: true, $ratio: .15); }
  .u-smMarginVXXS { @include spacing($vertical: true, $ratio: .15); }
  .u-smMarginHXXS { @include spacing($horizontal: true, $ratio: .15); }
  .u-smMarginTopXXS { @include spacing($top: true, $ratio: .15); }
  .u-smMarginBottomXXS { @include spacing($bottom: true, $ratio: .15); }
  .u-smMarginLeftXXS { @include spacing($left: true, $ratio: .15); }
  .u-smMarginRightXXS { @include spacing($right: true, $ratio: .15); }

  .u-smMarginXS { @include spacing($horizontal: true, $vertical: true, $ratio: .25); }
  .u-smMarginVXS { @include spacing($vertical: true, $ratio: .25); }
  .u-smMarginHXS { @include spacing($horizontal: true, $ratio: .25); }
  .u-smMarginTopXS { @include spacing($top: true, $ratio: .25); }
  .u-smMarginBottomXS { @include spacing($bottom: true, $ratio: .25); }
  .u-smMarginLeftXS { @include spacing($left: true, $ratio: .25); }
  .u-smMarginRightXS { @include spacing($right: true, $ratio: .25); }

  .u-smMarginS { @include spacing($horizontal: true, $vertical: true, $ratio: .5); }
  .u-smMarginVS { @include spacing($vertical: true, $ratio: .5); }
  .u-smMarginHS { @include spacing($horizontal: true, $ratio: .5); }
  .u-smMarginTopS { @include spacing($top: true, $ratio: .5); }
  .u-smMarginBottomS { @include spacing($bottom: true, $ratio: .5); }
  .u-smMarginLeftS { @include spacing($left: true, $ratio: .5); }
  .u-smMarginRightS { @include spacing($right: true, $ratio: .5); }

  .u-smMarginM { @include spacing($horizontal: true, $vertical: true, $ratio: 1.5); }
  .u-smMarginVM { @include spacing($vertical: true, $ratio: 1.5); }
  .u-smMarginHM { @include spacing($horizontal: true, $ratio: 1.5); }
  .u-smMarginTopM { @include spacing($top: true, $ratio: 1.5); }
  .u-smMarginBottomM { @include spacing($bottom: true, $ratio: 1.5); }
  .u-smMarginLeftM { @include spacing($left: true, $ratio: 1.5); }
  .u-smMarginRightM { @include spacing($right: true, $ratio: 1.5); }

  .u-smMarginL { @include spacing($horizontal: true, $vertical: true, $ratio: 1.75); }
  .u-smMarginVL { @include spacing($vertical: true, $ratio: 1.75); }
  .u-smMarginHL { @include spacing($horizontal: true, $ratio: 1.75); }
  .u-smMarginTopL { @include spacing($top: true, $ratio: 1.75); }
  .u-smMarginBottomL { @include spacing($bottom: true, $ratio: 1.75); }
  .u-smMarginLeftL { @include spacing($left: true, $ratio: 1.75); }
  .u-smMarginRightL { @include spacing($right: true, $ratio: 1.75); }

  .u-smMarginXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2); }
  .u-smMarginVXL { @include spacing($vertical: true, $ratio: 2); }
  .u-smMarginHXL { @include spacing($horizontal: true, $ratio: 2); }
  .u-smMarginTopXL { @include spacing($top: true, $ratio: 2); }
  .u-smMarginBottomXL { @include spacing($bottom: true, $ratio: 2); }
  .u-smMarginLeftXL { @include spacing($left: true, $ratio: 2); }
  .u-smMarginRightXL { @include spacing($right: true, $ratio: 2); }

  .u-smMarginXXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2.5); }
  .u-smMarginVXXL { @include spacing($vertical: true, $ratio: 2.5); }
  .u-smMarginHXXL { @include spacing($horizontal: true, $ratio: 2.5); }
  .u-smMarginTopXXL { @include spacing($top: true, $ratio: 2.5); }
  .u-smMarginBottomXXL { @include spacing($bottom: true, $ratio: 2.5); }
  .u-smMarginLeftXXL { @include spacing($left: true, $ratio: 2.5); }
  .u-smMarginRightXXL { @include spacing($right: true, $ratio: 2.5); }

  .u-smPaddingInit { padding: initial !important; }

  .u-smPadding { @include spacing($horizontal: true, $vertical: true, $type: 'padding'); }
  .u-smPaddingV { @include spacing($vertical: true, $type: 'padding'); }
  .u-smPaddingH { @include spacing($horizontal: true, $type: 'padding'); }
  .u-smPaddingTop { @include spacing($top: true, $type: 'padding'); }
  .u-smPaddingBottom { @include spacing($bottom: true, $type: 'padding'); }
  .u-smPaddingLeft { @include spacing($left: true, $type: 'padding'); }
  .u-smPaddingRight { @include spacing($right: true, $type: 'padding'); }

  .u-smPaddingXXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .15); }
  .u-smPaddingVXXS { @include spacing($vertical: true, $type: 'padding', $ratio: .15); }
  .u-smPaddingHXXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .15); }
  .u-smPaddingTopXXS { @include spacing($top: true, $type: 'padding', $ratio: .15); }
  .u-smPaddingBottomXXS { @include spacing($bottom: true, $type: 'padding', $ratio: .15); }
  .u-smPaddingLeftXXS { @include spacing($left: true, $type: 'padding', $ratio: .15); }
  .u-smPaddingRightXXS { @include spacing($right: true, $type: 'padding', $ratio: .15); }

  .u-smPaddingXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .25); }
  .u-smPaddingVXS { @include spacing($vertical: true, $type: 'padding', $ratio: .25); }
  .u-smPaddingHXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .25); }
  .u-smPaddingTopXS { @include spacing($top: true, $type: 'padding', $ratio: .25); }
  .u-smPaddingBottomXS { @include spacing($bottom: true, $type: 'padding', $ratio: .25); }
  .u-smPaddingLeftXS { @include spacing($left: true, $type: 'padding', $ratio: .25); }
  .u-smPaddingRightXS { @include spacing($right: true, $type: 'padding', $ratio: .25); }

  .u-smPaddingS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .5); }
  .u-smPaddingVS { @include spacing($vertical: true, $type: 'padding', $ratio: .5); }
  .u-smPaddingHS { @include spacing($horizontal: true, $type: 'padding', $ratio: .5); }
  .u-smPaddingTopS { @include spacing($top: true, $type: 'padding', $ratio: .5); }
  .u-smPaddingBottomS { @include spacing($bottom: true, $type: 'padding', $ratio: .5); }
  .u-smPaddingLeftS { @include spacing($left: true, $type: 'padding', $ratio: .5); }
  .u-smPaddingRightS { @include spacing($right: true, $type: 'padding', $ratio: .5); }

  .u-smPaddingM { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.5); }
  .u-smPaddingVM { @include spacing($vertical: true, $type: 'padding', $ratio: 1.5); }
  .u-smPaddingHM { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.5); }
  .u-smPaddingTopM { @include spacing($top: true, $type: 'padding', $ratio: 1.5); }
  .u-smPaddingBottomM { @include spacing($bottom: true, $type: 'padding', $ratio: 1.5); }
  .u-smPaddingLeftM { @include spacing($left: true, $type: 'padding', $ratio: 1.5); }
  .u-smPaddingRightM { @include spacing($right: true, $type: 'padding', $ratio: 1.5); }

  .u-smPaddingL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.75); }
  .u-smPaddingVL { @include spacing($vertical: true, $type: 'padding', $ratio: 1.75); }
  .u-smPaddingHL { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.75); }
  .u-smPaddingTopL { @include spacing($top: true, $type: 'padding', $ratio: 1.75); }
  .u-smPaddingBottomL { @include spacing($bottom: true, $type: 'padding', $ratio: 1.75); }
  .u-smPaddingLeftL { @include spacing($left: true, $type: 'padding', $ratio: 1.75); }
  .u-smPaddingRightL { @include spacing($right: true, $type: 'padding', $ratio: 1.75); }

  .u-smPaddingXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2); }
  .u-smPaddingVXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2); }
  .u-smPaddingHXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2); }
  .u-smPaddingTopXL { @include spacing($top: true, $type: 'padding', $ratio: 2); }
  .u-smPaddingBottomXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2); }
  .u-smPaddingLeftXL { @include spacing($left: true, $type: 'padding', $ratio: 2); }
  .u-smPaddingRightXL { @include spacing($right: true, $type: 'padding', $ratio: 2); }

  .u-smPaddingXXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2.5); }
  .u-smPaddingVXXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2.5); }
  .u-smPaddingHXXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2.5); }
  .u-smPaddingTopXXL { @include spacing($top: true, $type: 'padding', $ratio: 2.5); }
  .u-smPaddingBottomXXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2.5); }
  .u-smPaddingLeftXXL { @include spacing($left: true, $type: 'padding', $ratio: 2.5); }
  .u-smPaddingRightXXL { @include spacing($right: true, $type: 'padding', $ratio: 2.5); }
}

@include media($media_m-l) {

  .u-mlMarginAuto { margin: auto !important; }
  .u-mlMarginVAuto { margin-top: auto !important; margin-bottom: auto !important; }
  .u-mlMarginHAuto { margin-left: auto !important; margin-right: auto !important; }
  .u-mlMarginTopAuto { margin-top: auto !important; }
  .u-mlMarginBottomAuto { margin-bottom: auto !important; }
  .u-mlMarginLeftAuto { margin-left: auto !important; }
  .u-mlMarginRightAuto { margin-right: auto !important; }
  .u-mlMarginInit { margin: initial !important; }

  .u-mlMargin { @include spacing( $horizontal: true, $vertical: true); }
  .u-mlMarginV { @include spacing($vertical: true); }
  .u-mlMarginH { @include spacing($horizontal: true); }
  .u-mlMarginTop { @include spacing($top: true); }
  .u-mlMarginBottom { @include spacing($bottom: true); }
  .u-mlMarginLeft { @include spacing($left: true); }
  .u-mlMarginRight { @include spacing($right: true); }

  .u-mlMarginXXS { @include spacing($horizontal: true, $vertical: true, $ratio: .15); }
  .u-mlMarginVXXS { @include spacing($vertical: true, $ratio: .15); }
  .u-mlMarginHXXS { @include spacing($horizontal: true, $ratio: .15); }
  .u-mlMarginTopXXS { @include spacing($top: true, $ratio: .15); }
  .u-mlMarginBottomXXS { @include spacing($bottom: true, $ratio: .15); }
  .u-mlMarginLeftXXS { @include spacing($left: true, $ratio: .15); }
  .u-mlMarginRightXXS { @include spacing($right: true, $ratio: .15); }

  .u-mlMarginXS { @include spacing($horizontal: true, $vertical: true, $ratio: .25); }
  .u-mlMarginVXS { @include spacing($vertical: true, $ratio: .25); }
  .u-mlMarginHXS { @include spacing($horizontal: true, $ratio: .25); }
  .u-mlMarginTopXS { @include spacing($top: true, $ratio: .25); }
  .u-mlMarginBottomXS { @include spacing($bottom: true, $ratio: .25); }
  .u-mlMarginLeftXS { @include spacing($left: true, $ratio: .25); }
  .u-mlMarginRightXS { @include spacing($right: true, $ratio: .25); }

  .u-mlMarginS { @include spacing($horizontal: true, $vertical: true, $ratio: .5); }
  .u-mlMarginVS { @include spacing($vertical: true, $ratio: .5); }
  .u-mlMarginHS { @include spacing($horizontal: true, $ratio: .5); }
  .u-mlMarginTopS { @include spacing($top: true, $ratio: .5); }
  .u-mlMarginBottomS { @include spacing($bottom: true, $ratio: .5); }
  .u-mlMarginLeftS { @include spacing($left: true, $ratio: .5); }
  .u-mlMarginRightS { @include spacing($right: true, $ratio: .5); }

  .u-mlMarginM { @include spacing($horizontal: true, $vertical: true, $ratio: 1.5); }
  .u-mlMarginVM { @include spacing($vertical: true, $ratio: 1.5); }
  .u-mlMarginHM { @include spacing($horizontal: true, $ratio: 1.5); }
  .u-mlMarginTopM { @include spacing($top: true, $ratio: 1.5); }
  .u-mlMarginBottomM { @include spacing($bottom: true, $ratio: 1.5); }
  .u-mlMarginLeftM { @include spacing($left: true, $ratio: 1.5); }
  .u-mlMarginRightM { @include spacing($right: true, $ratio: 1.5); }

  .u-mlMarginL { @include spacing($horizontal: true, $vertical: true, $ratio: 1.75); }
  .u-mlMarginVL { @include spacing($vertical: true, $ratio: 1.75); }
  .u-mlMarginHL { @include spacing($horizontal: true, $ratio: 1.75); }
  .u-mlMarginTopL { @include spacing($top: true, $ratio: 1.75); }
  .u-mlMarginBottomL { @include spacing($bottom: true, $ratio: 1.75); }
  .u-mlMarginLeftL { @include spacing($left: true, $ratio: 1.75); }
  .u-mlMarginRightL { @include spacing($right: true, $ratio: 1.75); }

  .u-mlMarginXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2); }
  .u-mlMarginVXL { @include spacing($vertical: true, $ratio: 2); }
  .u-mlMarginHXL { @include spacing($horizontal: true, $ratio: 2); }
  .u-mlMarginTopXL { @include spacing($top: true, $ratio: 2); }
  .u-mlMarginBottomXL { @include spacing($bottom: true, $ratio: 2); }
  .u-mlMarginLeftXL { @include spacing($left: true, $ratio: 2); }
  .u-mlMarginRightXL { @include spacing($right: true, $ratio: 2); }

  .u-mlMarginXXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2.5); }
  .u-mlMarginVXXL { @include spacing($vertical: true, $ratio: 2.5); }
  .u-mlMarginHXXL { @include spacing($horizontal: true, $ratio: 2.5); }
  .u-mlMarginTopXXL { @include spacing($top: true, $ratio: 2.5); }
  .u-mlMarginBottomXXL { @include spacing($bottom: true, $ratio: 2.5); }
  .u-mlMarginLeftXXL { @include spacing($left: true, $ratio: 2.5); }
  .u-mlMarginRightXXL { @include spacing($right: true, $ratio: 2.5); }

  .u-mlPadding { @include spacing($horizontal: true, $vertical: true, $type: 'padding'); }
  .u-mlPaddingV { @include spacing($vertical: true, $type: 'padding'); }
  .u-mlPaddingH { @include spacing($horizontal: true, $type: 'padding'); }
  .u-mlPaddingTop { @include spacing($top: true, $type: 'padding'); }
  .u-mlPaddingBottom { @include spacing($bottom: true, $type: 'padding'); }
  .u-mlPaddingLeft { @include spacing($left: true, $type: 'padding'); }
  .u-mlPaddingRight { @include spacing($right: true, $type: 'padding'); }
  .u-mlPaddingInit { padding: initial !important; }

  .u-mlPaddingXXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .15); }
  .u-mlPaddingVXXS { @include spacing($vertical: true, $type: 'padding', $ratio: .15); }
  .u-mlPaddingHXXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .15); }
  .u-mlPaddingTopXXS { @include spacing($top: true, $type: 'padding', $ratio: .15); }
  .u-mlPaddingBottomXXS { @include spacing($bottom: true, $type: 'padding', $ratio: .15); }
  .u-mlPaddingLeftXXS { @include spacing($left: true, $type: 'padding', $ratio: .15); }
  .u-mlPaddingRightXXS { @include spacing($right: true, $type: 'padding', $ratio: .15); }

  .u-mlPaddingXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .25); }
  .u-mlPaddingVXS { @include spacing($vertical: true, $type: 'padding', $ratio: .25); }
  .u-mlPaddingHXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .25); }
  .u-mlPaddingTopXS { @include spacing($top: true, $type: 'padding', $ratio: .25); }
  .u-mlPaddingBottomXS { @include spacing($bottom: true, $type: 'padding', $ratio: .25); }
  .u-mlPaddingLeftXS { @include spacing($left: true, $type: 'padding', $ratio: .25); }
  .u-mlPaddingRightXS { @include spacing($right: true, $type: 'padding', $ratio: .25); }

  .u-mlPaddingS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .5); }
  .u-mlPaddingVS { @include spacing($vertical: true, $type: 'padding', $ratio: .5); }
  .u-mlPaddingHS { @include spacing($horizontal: true, $type: 'padding', $ratio: .5); }
  .u-mlPaddingTopS { @include spacing($top: true, $type: 'padding', $ratio: .5); }
  .u-mlPaddingBottomS { @include spacing($bottom: true, $type: 'padding', $ratio: .5); }
  .u-mlPaddingLeftS { @include spacing($left: true, $type: 'padding', $ratio: .5); }
  .u-mlPaddingRightS { @include spacing($right: true, $type: 'padding', $ratio: .5); }

  .u-mlPaddingM { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.5); }
  .u-mlPaddingVM { @include spacing($vertical: true, $type: 'padding', $ratio: 1.5); }
  .u-mlPaddingHM { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.5); }
  .u-mlPaddingTopM { @include spacing($top: true, $type: 'padding', $ratio: 1.5); }
  .u-mlPaddingBottomM { @include spacing($bottom: true, $type: 'padding', $ratio: 1.5); }
  .u-mlPaddingLeftM { @include spacing($left: true, $type: 'padding', $ratio: 1.5); }
  .u-mlPaddingRightM { @include spacing($right: true, $type: 'padding', $ratio: 1.5); }

  .u-mlPaddingL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.75); }
  .u-mlPaddingVL { @include spacing($vertical: true, $type: 'padding', $ratio: 1.75); }
  .u-mlPaddingHL { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.75); }
  .u-mlPaddingTopL { @include spacing($top: true, $type: 'padding', $ratio: 1.75); }
  .u-mlPaddingBottomL { @include spacing($bottom: true, $type: 'padding', $ratio: 1.75); }
  .u-mlPaddingLeftL { @include spacing($left: true, $type: 'padding', $ratio: 1.75); }
  .u-mlPaddingRightL { @include spacing($right: true, $type: 'padding', $ratio: 1.75); }

  .u-mlPaddingXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2); }
  .u-mlPaddingVXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2); }
  .u-mlPaddingHXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2); }
  .u-mlPaddingTopXL { @include spacing($top: true, $type: 'padding', $ratio: 2); }
  .u-mlPaddingBottomXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2); }
  .u-mlPaddingLeftXL { @include spacing($left: true, $type: 'padding', $ratio: 2); }
  .u-mlPaddingRightXL { @include spacing($right: true, $type: 'padding', $ratio: 2); }

  .u-mlPaddingXXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2.5); }
  .u-mlPaddingVXXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2.5); }
  .u-mlPaddingHXXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2.5); }
  .u-mlPaddingTopXXL { @include spacing($top: true, $type: 'padding', $ratio: 2.5); }
  .u-mlPaddingBottomXXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2.5); }
  .u-mlPaddingLeftXXL { @include spacing($left: true, $type: 'padding', $ratio: 2.5); }
  .u-mlPaddingRightXXL { @include spacing($right: true, $type: 'padding', $ratio: 2.5); }
}

@include media($media_l) {

  .u-lMarginAuto { margin: auto !important; }
  .u-lMarginVAuto { margin-top: auto !important; margin-bottom: auto !important; }
  .u-lMarginHAuto { margin-left: auto !important; margin-right: auto !important; }
  .u-lMarginTopAuto { margin-top: auto !important; }
  .u-lMarginBottomAuto { margin-bottom: auto !important; }
  .u-lMarginLeftAuto { margin-left: auto !important; }
  .u-lMarginRightAuto { margin-right: auto !important; }
  .u-lMarginInit { margin: initial !important; }

  .u-lMargin { @include spacing( $horizontal: true, $vertical: true); }
  .u-lMarginV { @include spacing($vertical: true); }
  .u-lMarginH { @include spacing($horizontal: true); }
  .u-lMarginTop { @include spacing($top: true); }
  .u-lMarginBottom { @include spacing($bottom: true); }
  .u-lMarginLeft { @include spacing($left: true); }
  .u-lMarginRight { @include spacing($right: true); }

  .u-lMarginXXS { @include spacing($horizontal: true, $vertical: true, $ratio: .15); }
  .u-lMarginVXXS { @include spacing($vertical: true, $ratio: .15); }
  .u-lMarginHXXS { @include spacing($horizontal: true, $ratio: .15); }
  .u-lMarginTopXXS { @include spacing($top: true, $ratio: .15); }
  .u-lMarginBottomXXS { @include spacing($bottom: true, $ratio: .15); }
  .u-lMarginLeftXXS { @include spacing($left: true, $ratio: .15); }
  .u-lMarginRightXXS { @include spacing($right: true, $ratio: .15); }

  .u-lMarginXS { @include spacing($horizontal: true, $vertical: true, $ratio: .25); }
  .u-lMarginVXS { @include spacing($vertical: true, $ratio: .25); }
  .u-lMarginHXS { @include spacing($horizontal: true, $ratio: .25); }
  .u-lMarginTopXS { @include spacing($top: true, $ratio: .25); }
  .u-lMarginBottomXS { @include spacing($bottom: true, $ratio: .25); }
  .u-lMarginLeftXS { @include spacing($left: true, $ratio: .25); }
  .u-lMarginRightXS { @include spacing($right: true, $ratio: .25); }

  .u-lMarginS { @include spacing($horizontal: true, $vertical: true, $ratio: .5); }
  .u-lMarginVS { @include spacing($vertical: true, $ratio: .5); }
  .u-lMarginHS { @include spacing($horizontal: true, $ratio: .5); }
  .u-lMarginTopS { @include spacing($top: true, $ratio: .5); }
  .u-lMarginBottomS { @include spacing($bottom: true, $ratio: .5); }
  .u-lMarginLeftS { @include spacing($left: true, $ratio: .5); }
  .u-lMarginRightS { @include spacing($right: true, $ratio: .5); }

  .u-lMarginM { @include spacing($horizontal: true, $vertical: true, $ratio: 1.5); }
  .u-lMarginVM { @include spacing($vertical: true, $ratio: 1.5); }
  .u-lMarginHM { @include spacing($horizontal: true, $ratio: 1.5); }
  .u-lMarginTopM { @include spacing($top: true, $ratio: 1.5); }
  .u-lMarginBottomM { @include spacing($bottom: true, $ratio: 1.5); }
  .u-lMarginLeftM { @include spacing($left: true, $ratio: 1.5); }
  .u-lMarginRightM { @include spacing($right: true, $ratio: 1.5); }

  .u-lMarginL { @include spacing($horizontal: true, $vertical: true, $ratio: 1.75); }
  .u-lMarginVL { @include spacing($vertical: true, $ratio: 1.75); }
  .u-lMarginHL { @include spacing($horizontal: true, $ratio: 1.75); }
  .u-lMarginTopL { @include spacing($top: true, $ratio: 1.75); }
  .u-lMarginBottomL { @include spacing($bottom: true, $ratio: 1.75); }
  .u-lMarginLeftL { @include spacing($left: true, $ratio: 1.75); }
  .u-lMarginRightL { @include spacing($right: true, $ratio: 1.75); }

  .u-lMarginXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2); }
  .u-lMarginVXL { @include spacing($vertical: true, $ratio: 2); }
  .u-lMarginHXL { @include spacing($horizontal: true, $ratio: 2); }
  .u-lMarginTopXL { @include spacing($top: true, $ratio: 2); }
  .u-lMarginBottomXL { @include spacing($bottom: true, $ratio: 2); }
  .u-lMarginLeftXL { @include spacing($left: true, $ratio: 2); }
  .u-lMarginRightXL { @include spacing($right: true, $ratio: 2); }

  .u-lMarginXXL { @include spacing($horizontal: true, $vertical: true, $ratio: 2.5); }
  .u-lMarginVXXL { @include spacing($vertical: true, $ratio: 2.5); }
  .u-lMarginHXXL { @include spacing($horizontal: true, $ratio: 2.5); }
  .u-lMarginTopXXL { @include spacing($top: true, $ratio: 2.5); }
  .u-lMarginBottomXXL { @include spacing($bottom: true, $ratio: 2.5); }
  .u-lMarginLeftXXL { @include spacing($left: true, $ratio: 2.5); }
  .u-lMarginRightXXL { @include spacing($right: true, $ratio: 2.5); }

  .u-lPaddingInit { padding: initial !important; }

  .u-lPadding { @include spacing($horizontal: true, $vertical: true, $type: 'padding'); }
  .u-lPaddingV { @include spacing($vertical: true, $type: 'padding'); }
  .u-lPaddingH { @include spacing($horizontal: true, $type: 'padding'); }
  .u-lPaddingTop { @include spacing($top: true, $type: 'padding'); }
  .u-lPaddingBottom { @include spacing($bottom: true, $type: 'padding'); }
  .u-lPaddingLeft { @include spacing($left: true, $type: 'padding'); }
  .u-lPaddingRight { @include spacing($right: true, $type: 'padding'); }

  .u-lPaddingXXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .15); }
  .u-lPaddingVXXS { @include spacing($vertical: true, $type: 'padding', $ratio: .15); }
  .u-lPaddingHXXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .15); }
  .u-lPaddingTopXXS { @include spacing($top: true, $type: 'padding', $ratio: .15); }
  .u-lPaddingBottomXXS { @include spacing($bottom: true, $type: 'padding', $ratio: .15); }
  .u-lPaddingLeftXXS { @include spacing($left: true, $type: 'padding', $ratio: .15); }
  .u-lPaddingRightXXS { @include spacing($right: true, $type: 'padding', $ratio: .15); }

  .u-lPaddingXS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .25); }
  .u-lPaddingVXS { @include spacing($vertical: true, $type: 'padding', $ratio: .25); }
  .u-lPaddingHXS { @include spacing($horizontal: true, $type: 'padding', $ratio: .25); }
  .u-lPaddingTopXS { @include spacing($top: true, $type: 'padding', $ratio: .25); }
  .u-lPaddingBottomXS { @include spacing($bottom: true, $type: 'padding', $ratio: .25); }
  .u-lPaddingLeftXS { @include spacing($left: true, $type: 'padding', $ratio: .25); }
  .u-lPaddingRightXS { @include spacing($right: true, $type: 'padding', $ratio: .25); }

  .u-lPaddingS { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: .5); }
  .u-lPaddingVS { @include spacing($vertical: true, $type: 'padding', $ratio: .5); }
  .u-lPaddingHS { @include spacing($horizontal: true, $type: 'padding', $ratio: .5); }
  .u-lPaddingTopS { @include spacing($top: true, $type: 'padding', $ratio: .5); }
  .u-lPaddingBottomS { @include spacing($bottom: true, $type: 'padding', $ratio: .5); }
  .u-lPaddingLeftS { @include spacing($left: true, $type: 'padding', $ratio: .5); }
  .u-lPaddingRightS { @include spacing($right: true, $type: 'padding', $ratio: .5); }

  .u-lPaddingM { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.5); }
  .u-lPaddingVM { @include spacing($vertical: true, $type: 'padding', $ratio: 1.5); }
  .u-lPaddingHM { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.5); }
  .u-lPaddingTopM { @include spacing($top: true, $type: 'padding', $ratio: 1.5); }
  .u-lPaddingBottomM { @include spacing($bottom: true, $type: 'padding', $ratio: 1.5); }
  .u-lPaddingLeftM { @include spacing($left: true, $type: 'padding', $ratio: 1.5); }
  .u-lPaddingRightM { @include spacing($right: true, $type: 'padding', $ratio: 1.5); }

  .u-lPaddingL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 1.75); }
  .u-lPaddingVL { @include spacing($vertical: true, $type: 'padding', $ratio: 1.75); }
  .u-lPaddingHL { @include spacing($horizontal: true, $type: 'padding', $ratio: 1.75); }
  .u-lPaddingTopL { @include spacing($top: true, $type: 'padding', $ratio: 1.75); }
  .u-lPaddingBottomL { @include spacing($bottom: true, $type: 'padding', $ratio: 1.75); }
  .u-lPaddingLeftL { @include spacing($left: true, $type: 'padding', $ratio: 1.75); }
  .u-lPaddingRightL { @include spacing($right: true, $type: 'padding', $ratio: 1.75); }

  .u-lPaddingXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2); }
  .u-lPaddingVXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2); }
  .u-lPaddingHXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2); }
  .u-lPaddingTopXL { @include spacing($top: true, $type: 'padding', $ratio: 2); }
  .u-lPaddingBottomXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2); }
  .u-lPaddingLeftXL { @include spacing($left: true, $type: 'padding', $ratio: 2); }
  .u-lPaddingRightXL { @include spacing($right: true, $type: 'padding', $ratio: 2); }

  .u-lPaddingXXL { @include spacing($horizontal: true, $vertical: true, $type: 'padding', $ratio: 2.5); }
  .u-lPaddingVXXL { @include spacing($vertical: true, $type: 'padding', $ratio: 2.5); }
  .u-lPaddingHXXL { @include spacing($horizontal: true, $type: 'padding', $ratio: 2.5); }
  .u-lPaddingTopXXL { @include spacing($top: true, $type: 'padding', $ratio: 2.5); }
  .u-lPaddingBottomXXL { @include spacing($bottom: true, $type: 'padding', $ratio: 2.5); }
  .u-lPaddingLeftXXL { @include spacing($left: true, $type: 'padding', $ratio: 2.5); }
  .u-lPaddingRightXXL { @include spacing($right: true, $type: 'padding', $ratio: 2.5); }
}
