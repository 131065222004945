/** @define HardwareInformation */
$componentClass: '.HardwareInformation';

.HardwareInformation {
  &-details {
    .HardwareDetailBlock {
      &:nth-child(odd) {
        background: $color-gray15;
      }
    }
  }

  &-review {
    @include outerContainer($max-width);
    @include globalPadding($bottomRatio: 2);
  }

  &-inner {
    @include outerContainer($max-width);
    @include globalPadding($topRatio: 0, $bottomRatio: 0);
  }

  &-facts {
    padding-top: $global-padding*0.5;
    @include media($media_l) {
      padding-top: $global-padding;
    }
  }

  &-specsHeadline,
  &-factsHeadline {
    margin-bottom: $global-padding*0.5;

    @include media($media_l) {
      margin-bottom: $global-padding;
    }
  }

  &-specs {}

  &-specsBlock {
    @include borderDashed($color-gray50, true, false, false);
    padding: $global-padding 0;

    &:first-child {
      border: none;
    }

    @include media($media_l) {
      padding: $global-padding * 2 0;
    }
  }

  &-specsCopy {
    @include media($media_m-l) {
      padding: 0 $global-padding * .5;
      ul {
        column-count: 2;
        column-gap: $global-padding;
      }
    }

  }
}

[data-color-mode="dark"] {
  #{$componentClass} {
    &-details {
      .HardwareDetailBlock {
        &:nth-child(odd) {
          background: $color-gray-dark-darkmode;
        }
      }
    }
    &-specsCopy {
      .TextCopy {
        @include listFormater($isThemeDark: true);
      }
    }
  }
}
