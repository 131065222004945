/** @define Smartbanner */
$componentClass: '.smartbanner';
.smartbanner.smartbanner-android {
  background: $color-gray15;
  color: $color-black;
  box-shadow: none;
  height: auto;

  .smartbanner-close {
    box-shadow: none;
    text-shadow: none;
    color: $color-black;
    background: none;
  }

  .smartbanner-info {
    > div {
      text-shadow: none;
      color: $color-black;
    }
  }

  .smartbanner-button {
    box-shadow: none;

    .smartbanner-button-text {
      background: $gradient-primary;
      border-radius: $border-radius-s;
      color: $color-black;
    }
  }
}
