
.TariffItemBox{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $gradient-neutral;
  border-radius: $border-radius-m;
  color: initial;

  &:hover {
    cursor: pointer;
  }

  &-header {
    margin-top: $global-padding * 1.3;
    color: $color-primary;

    .overline-copy {
      font-size: rem-calc(23);
      font-weight: 300;
      letter-spacing: 0.03px;
      text-transform: uppercase;
    }

    .headline-copy {
      margin-right: 1rem;
    }

    .headline-copy, .headline-unit {
      font-size: rem-calc(96);
    }
  }

  &-main {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-content {
    display: flex;
    font-family: $font-family-condensed;
    font-weight: bold;
    font-size: rem-calc(56);
  }

  &-subcontent {
    width: 90%;
  }

  &-tariffDuration {
    font-size: rem-calc(12);
    background-color: transparent;
    color: $color-default;
  }

  &-productInformation {
    display: block;
    text-align: center;
    margin: rem-calc(8) 0;
    font-size: $h-xxxs_font-size;
    line-height: normal;
    letter-spacing: 0.02px;
    text-decoration: underline;
    color: inherit;

    &:hover, &:focus {
      color: $color-gray100;
    }

    &::after {
      content: none;
    }
  }

  &-priceMonthly {
    color: $color-default;
    .TextUnit {
      .TextUnit:not(:only-child) {
        font-size: rem-calc(48);
      }

      .TextUnit-prefixPrice {
        font-size: rem-calc(16);
      }
      .TextUnit-postfix {
        position: relative;
        font-weight: bold;
        > span {
          position: absolute;
          top: rem-calc(6);
          font-size: rem-calc(18);
          font-weight: 300;
          line-height: initial;
        }
      }
    }
  }

  .Callout--content {
    padding-top: 0;
    color: $color-primary;
    background: transparent;
  }

  &--activeItem {
    box-shadow: 0 0 0 2px $color-orange-dark inset;
  }

  &--light {
    background-color: $color-white;
  }

  /*&--dark {
    color: $color-white;
    background-color: $color-gray-dark;
  }*/

  @include media($media_s) {
    &-header,
    &-main {
      @include media($media_xs) {
        margin-left: $global-padding * .35;
      }
    }

    &-header {
      text-align: center;
      line-height: $h-m_line-height;
      margin-top: $global-padding * .7;
      margin-bottom: $global-padding *.005;

      .overline-copy {
        margin-bottom: rem-calc(16);
        font-size: rem-calc(12);
      }

      .headline-copy {
        margin-right: 0;
        font-size: rem-calc(64);
        line-height: $h-xs_line-height;
        @include media($media_xs) {
          font-size: 3.5rem;
          }
      }

      .headline-unit {
        font-size: $h-xs_font-size *1;
        line-height: $h-xs_line-height;
        @include media($media_xs) {
          font-size: 1rem;
        }
      }
    }

    &-content {
      font-size: $h-xxs_font-size * .9;
      .TariffItemBox, .TextUnit {
        display: block;
      }
    }

    &-priceMonthly {
      .TextUnit {
        font-size: rem-calc(26);
        .TextUnit:not(:only-child) {
          font-size: rem-calc(26);
        }
        .TextUnit-prefixPrice {
          font-size: rem-calc(16);
        }
        .TextUnit-postfix {
          margin-left: rem-calc(3);
          font-weight: 300;
          > span {
            top: 0;
            font-size: rem-calc(12);
          }
        }
      }
    }

    .Callout--content {
      margin: 0 auto;
      padding-top: 0;
      .TextHeadline--xs {
        font-size: rem-calc(12);
      }
    }

    &-tariffDuration {
      font-size: rem-calc(8);
      margin-bottom: 0.5rem;
    }

    &-productInformation {
      margin: 0.5rem 0;
      font-size: rem-calc(8);
      letter-spacing: 0.01px;
    }
  }

  // Safari
  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
      @include media($media_s) {
        &-tariffDuration,
        &-productInformation {
          font-size: 8px;
        }
      }
    }
  }

  .Callout--sticker {
    width: max-content;
    position: absolute;
    top: 0;
    transform: translateX(-50%) translateY(-50%) !important;
    left: 50% !important;
    @include media($media_s) {
      width: initial;
      .Tag-copy {
        line-height: 1rem;
        padding: .1rem .5rem;
      }
    }
  }
}
