/** @define MyBelatedMnpForm */
$componentClass: '.MyBelatedMnpForm';
#{$componentClass} {
  ol {
    padding-left: 1.25em;
  }
  #{$componentClass}Confirm {

    &-msisdn {
      // width:100%;
      font-weight: bold;
    }

    &-paymentMethod {
      background-color: $color-gray15;
    }

    &-puaHint {
      background-color: $color-gray15;
      padding: 14px;
    }

    // bold for sim type rows. since there is no identiifer in the list
    // we count the elements
    dl {
      dt:nth-of-type(6), dt:nth-of-type(7),
      dd:nth-of-type(6), dd:nth-of-type(7)
      {
        font-weight: bold;
      }
    }

    @include media($media_m-l) {
      .TextDataList > dt {
        width:50%;
      }
      .TextDataList > dd {
        width:50%;
        margin-left:0;
      }
      .TextLink-text {
        vertical-align: top;
      }
    }
  }
}
