/** @define FieldGroup */
$componentClass: '.FieldGroup';
.FieldGroup {

  width: 100%;

  .FieldMessenger-hint {
    color: $color-gray-dark;
  }

  &-label {
    font-size: $copy-secondary_font-size;
    margin-bottom: $global-padding * 0.5;
    color: $color-gray100;
  }

  &-inner {
    .FieldMessenger-error {
      display: none;
    }
  }

  &--error {}
}
