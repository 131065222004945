/** @define UIContentSpinner */
$componentClass: '.UIContentSpinner';
.UIContentSpinner{
  width: rem-calc(34);
  height: rem-calc(34);
  svg {
    animation: ani-rotate 1s linear infinite;
    transform-origin: center;
    .Icon-stroke {
      fill: none;
      stroke: $color-primary;
    }
  }
}
