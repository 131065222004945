/** @define DownloadList */
.DownloadList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &-headline {
    margin-bottom: $global-padding;
  }

  &-link {
    margin-top: $global-padding;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;

    li {
      width: 100%;
    }
  }
}
