/** @define Checkout Form */
$componentClass: '.FormSection';
.FormSection {
  &--border {
    @include media($media_m-l) {
      border: 1px solid $color-gray15;
      padding: $global-padding;
    }
  }
}
