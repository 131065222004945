/** @define HeaderStage */
.HeaderStage {
  display: flex;
  flex-wrap: wrap;

  &-featured {
    display: flex;
    width: 100%;
    border-bottom: $border-white;
    @include media($media_s) {
      flex-wrap: wrap;
    }
    .HeaderStage-item {
      display: flex;

      @include media($media_s) {
        width: 100%;
        height: auto;
        min-height: rem-calc(432);
        & + .HeaderStage-item {
          border-top: 2px solid $color-white;
        }
      }

      @include media($media_m-l) {
        flex-grow: 1;
        width: 50%;
        &.HeaderStage-item--typeBundle {
          height: rem-calc(432);
        }

        &:not(.HeaderStage-item--typeBundle) {
           min-height: rem-calc(432);
        }

        &:first-child:nth-last-child(2) {
          border-right: $border-white;
        }
      }
    }
  }

  &-teasers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    border-top: $border-white;
    border-bottom: $border-white;
    &.Slider {
      position: relative;
      display: block;
      overflow: hidden;
      .Slider-buttons {
        display: block;
      }
    }
    .HeaderStage-item {
      display: flex;
      height: rem-calc(214);
      border-top: $border-white;

      @include media($media_s) {
        width: 100%;
        &:first-child {
          border-top: none;
        }
      }
      @include media($media_m) {
        flex-grow: 1;
        width: 50%;

        &:nth-child(1),
        &:nth-child(2) {
          border-top: none;
        }


        &:nth-last-child(1):nth-last-child(2),
        &:nth-last-child(1):nth-last-child(2)  ~ .HeaderStage-item {
          border-bottom: none;
        };
        &:nth-child(odd):not(:last-child) {
          border-right: $border-white;
        }
      }
      @include media($media_l) {
        flex-wrap: nowrap;
        flex-grow: 1;
        width: 25%;
        border-top: none;
        &:not(:last-child) {
          border-right: $border-white;
        }
      }
    }
  }

  &-featured + &-teasers {
    border-top: none;
  }

  &--featuredColumns {
    @include media($media_m-l) {
      .HeaderStage-featured {
        border-top: $border-white;
      }
     .HeaderStage-item--featured.HeaderStage-item--typeBundle {
        height: auto;
      }
    }
  }

  &--standalone {
    .HeaderStage-featured {
      border: none;
    }
  }
}

.GlobalSection--adjacentTop{
  .HeaderStageItem-inner {
    padding-top: 0 !important;
  }
}

.GlobalSection--adjacentBottom{
  .HeaderStageItem-inner {
    padding-bottom: 0 !important;
  }
}
