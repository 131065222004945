/** @define ConsumptionDetailsDialog */
$componentClass: '.OptionLayerDialog';
.OptionLayerDialog {

  img {
    width: 100%;
  }
  @include media($media_m-l) {
    &-layer {
      max-width: 650px;
    }
  }

  @include media($media_s) {
    .MediaImage {
      width: 50%;
      margin: 0 auto;
    }

    .u-sMarginTopAuto {
      margin-top: rem-calc(24) !important;
    }
  }
}
