/** @define CheckoutCart */

$componentClass: '.CheckoutCartFaqFooter';
.CheckoutCartFaqFooter {
  @include media($media_s) {
    &-footer {
      > * + * {
        margin-top: $global-padding;
      }

      .TextLink {
        width: 100%;
      }
    }
  }

  @include media($media_xs) {
    &-footer {
      padding: 0 rem-calc(10);
    }
  }

  &-faqs {
    position: relative;

    > button {
      display: block;
      width: 100%;
      text-align: left;
    }

    > * + * {
      margin-top: $global-padding * .5;
    }

    .AccordionSection-label::after {
      display: none;
    }

    .AccordionSection + .AccordionSection {
      margin-top: $global-padding * .25;
    }

    .AccordionSection-content {
      padding-top: $global-padding * .25;
    }

    .AccordionSection-title {
      text-align: left;
    }
    .AccordionSection-label {
      text-align: left;
    }

    @include media($media_s) {
      padding: $global-padding;
      background-color: $color-gray15;

      .AccordionSection-content, .AccordionSection-title {
        background-color: $color-gray15;
      }

      > .TextHeadline {
        color: $color-black;
      }
    }

    @include media($media_m-l) {
      .AccordionSection.is-open {
        .AccordionSection-label {
          color: $color-orange;
        }
      }
      // div:hover
      .AccordionSection-title:hover {
        color: $color-orange;
      }
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass}-faqs {
    background-color: $color-gray-light-darkmode;
  }
}
