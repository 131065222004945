/** @define TextCopy */
$componentClass: '.TextCopy';
.TextCopy {
    @include copyTextFormat();
     // format default inline ul lists
    @include listFormater();

    .TextLink {
      color: $color-primary;
    }

    // Only style anchor links with no class applied
    a:not([class]){
      // Default styling for inline links
      @include RawTextLink();
    }

    p {
      margin-bottom: 0.1em;
    }

    img {
      max-width: 100%;
    }

    h1:not([class]){
      @include headline($size: $h-xxl_font-size, $line-height: $h-xxl_line-height);
    }

    h2:not([class]){
      @include headline($size: $h-xl_font-size, $line-height: $h-xl_line-height);
    }

    h3:not([class]){
      @include headline($size: $h-l_font-size, $line-height: $h-l_line-height);
    }

    h4:not([class]){
      @include headline($size: $h-m_font-size, $line-height: $h-m_line-height);
    }

    h5:not([class]){
      @include headline($size: $h-s_font-size, $line-height: $h-s_line-height);
    }

    h6:not([class]){
      @include headline($size: $h-xs_font-size, $line-height: $h-xs_line-height);
    }

    > *:last-child {
      margin-bottom: 0 !important;
    }

    &--secondary {
      @include copyTextFormat($type: 'secondary');
      @include listFormater($size: 6px);
    }
    &--tertiary {
      @include copyTextFormat($type: 'tertiary');
      @include listFormater();
    }
    &--small {
      @include copyTextFormat($type: 'small');
      @include listFormater($size: 5px);
    }
    &--inverted {
      color: $color-white;
    }
    &--uppercase {
      text-transform: uppercase;
    }
    &--condensed {
      font-family: $font-family-condensed;
    }
    &--embedded {
      margin: 0;
    }
}
