/** @define GlobalSection */

$componentClass: '.GlobalSection';
.GlobalSection {
  position: relative;
  background-color: $color-white;
  @include outerContainer($max-width);

  &-inner {
    margin: 0 auto;
    @include globalPadding($topRatio: 2, $bottomRatio: 2 );
  }

  &--expanded {
    max-width: none;
    #{$componentClass}-inner {
      @include globalPadding($horizontal: false, $topRatio: 2, $bottomRatio: 2);
    }
  }

  &--contained {
    max-width: none;
    #{$componentClass}-inner {
      @include outerContainer($max-width);
    }
  }

  &--centered .GlobalSection-inner {
    text-align: center;
  }

  &--adjacent {
    #{$componentClass}-inner {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &--adjacentTop {
    #{$componentClass}-inner {
      padding-top: 0;
    }
  }

  &--adjacentBottom {
    #{$componentClass}-inner {
      padding-bottom: 0;
    }
  }

  &--borderTop {
    border-top: 2px $color-black solid;
  }

  &--borderBottom {
    border-bottom:  2px $color-black solid;
  }

  &--themeLight {
    background-color: $color-gray15;
  }

  &--themeDark {
    background-color: $color-black;
    &#{$componentClass}--borderTop,
    &#{$componentClass}--borderBottom {
      border-color: $color-white;
    }
  }

  &--imageLeft,
  &--imageRight,
  &--imageCenter {
    background-repeat: no-repeat;
    background-size: contain;
    #{$componentClass}-inner:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
    }

    @include media($media_s) {
      background-size: cover;
    }
  }

  &--imageLeft {
    background-position: top left;
    #{$componentClass}-inner {
      position: relative;
      background: linear-gradient(to right, rgba(0,0,0,.5) 0%, rgba(0,0,0,1) 100%);
      &:before {
        content: '';
        position: absolute;
        left: -100%;
        right: 100%;
        background: linear-gradient(to right, rgba(0,0,0,0) 85%, rgba(0,0,0,.5) 100%);
      }
    }
  }

  &--imageRight {
    background-position: top right;
    #{$componentClass}-inner {
      position: relative;
      background: linear-gradient(to left, rgba(0,0,0,.5) 0%, rgba(0,0,0,1) 100%);
      &:before {
        content: '';
        position: absolute;
        left: 100%;
        right: -100%;
        background: linear-gradient(to left, rgba(0,0,0,0) 85%, rgba(0,0,0,.5) 100%);
      }
    }
  }

  &--imageCenter {
    background-position: top center;
    background-size: cover;
  }
}

[data-color-mode="dark"] {
  #{$componentClass} {
    background-color: $color-black-darkmode;
    color: $color-white-darkmode;
    &#{$componentClass}--borderTop,
    &#{$componentClass}--borderBottom {
      border-color: $color-white;
    }
  }
  #{$componentClass}--adjacent #{$componentClass}-inner {
    border-bottom: 2px solid $color-black-darkmode;
  }
}
