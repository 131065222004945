/** @define TariffOptionTeaser */
$componentClass: '.TariffOptionTeaser';
.TariffOptionTeaser {
  > * + * {
    margin-left: rem-calc(10);
  }

  .FormOptionBox {
    display: inline;
  }
}
