/** @define ShoppingCart */
$componentClass: '.ShoppingCartRow';
.ShoppingCartRow {

  &-col {
    transition: color $timing-duration $timing-easing;
  }

  &-inner {
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: background-color $timing-duration $timing-easing;
      z-index: -1;
    }
    .FormOptionBox-input + .FormOptionBox-label {
      transition: color $timing-duration $timing-easing;
    }
  }

  &--compact,
  &--widget {
    padding-top: $global-padding * .5;
    #{$componentClass}-inner {
      &::after {
        left: -$global-padding * .5;
        right: -$global-padding * .5;
      }
    }
  }

  &--compact,
  &--widget,
  &--full {
    &#{$componentClass}--adjacentTop {
      border-top: none !important;
      margin-top: 0;
      #{$componentClass}-inner::after {
        top: 0;
      }
      #{$componentClass}-col {
        padding-top: 0;
      }
    }

    &#{$componentClass}--adjacentBottom {
      #{$componentClass}-inner::after {
        bottom: 0;
      }
      #{$componentClass}-col {
        padding-bottom: 0;
      }
    }
  }

  &--compact {
    .TextUnit {
      line-height: rem-calc(20);
    }
  }

  &--widget {
    #{$componentClass}-col {
      border: none;
    }
  }

  &--full {
    #{$componentClass}-col {
      padding: $global-padding $global-padding * .5;

      &:not(:first-child) {
        padding-left: 0;
      }

      @include media($media_xs) {
        border: none;

        &:first-child {
          padding-left: rem-calc(10);
          padding-right: 0;
        }

        &.u-sCol12:first-child {
          padding-right: rem-calc(10);
        }

        &:not(:first-child) {
          text-align: right;
          padding-right: rem-calc(10);
          padding-left: 0;
        }
      }

      @include media($media_m-l) {
        &:first-child {
          padding-left: $global-padding;
          padding-right: $global-padding;
        }
        &:not(:first-child) {
          padding-right: $global-padding;
        }
      }
    }

    @include media($media_m-l) {
      #{$componentClass}-inner {
        &::after {
          top: $global-padding * .5;
          bottom: $global-padding * .5;
          left: $global-padding * .5;
          right: $global-padding * .5;
        }
      }
    }
  }

  &--highlight {
    #{$componentClass}-inner {
      &::after {
        background: $gradient-secondary;
        border-radius: $border-radius-m;
      }
    }

    .ShoppingCartRow-col:not(:first-child) {
      color: $color-black;
    }
    .FormOptionBox-input + .FormOptionBox-label {
      color: $color-black;
    }
    sup {
      color: $color-black;
      transition: color $timing-duration $timing-easing;
    }
    .TextInfo {
      .Icon-highlight,
      .Icon-base {
        fill: $color-black;
        transition: fill $timing-duration $timing-easing;
      }
    }
  }

  &--mark {
    #{$componentClass}-inner {
      &::after {
        background: $color-primary-highlight;
        border-radius: $border-radius-m;
      }
    }
  }


  &.is-disabled {
    #{$componentClass}-col:not(:first-child) {
      color: $color-gray100;
    }

    &#{$componentClass}--highlight {
      #{$componentClass}-col:not(:first-child) {
        color: rgba($color-black, .6);
      }
    }
  }
}
