/** @define UIYoutubePlayer */
$componentClass: '.UIYoutubePlayer';
.UIYoutubePlayer{
  position: relative;
  width:100%;
  height: 0;
  padding-bottom: 56.34%;
  margin-bottom: 1rem;
  overflow: hidden;
  border: 1px solid $color-gray-dark;
  background-color: $color-black;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
