/** @define ContentStage */

$componentClass: '.ContentStage';
$contentStageBorderDesktop: 16px;
$contentStageBorderMobile: 8px;
$contentStageFixedHeightViewportS: 560px;
$contentStageFixedHeightViewportL: 640px;

.ContentStage {
  overflow:hidden;
  margin: $contentStageBorderDesktop;

  &-textContent--light &-link, &-textContent--light &-copyLine {
    color: $color-white;
  }

  &-textContent--dark &-link, &-textContent--dark &-copyLine {
    color: $color-black;
  }

  &-container {
    height: $contentStageFixedHeightViewportL;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 0;

    #{$componentClass}-textContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      position: relative;
      z-index: 20;

      #{$componentClass}-header {
        padding-top: rem-calc(32);
        padding-left: rem-calc(24);
        padding-right: rem-calc(24);
        display:flex;
        flex-direction: column;
        flex-shrink: 0;
        align-items: center;
        text-align: center;

        .TextInfo {
          display: inline-block;

          .TextLink {
            display: flex;
            flex-direction: row-reverse;
            align-items: baseline;

            &-icon {
              width: rem-calc(14);
              padding-left: rem-calc(4);

              svg {
                width: rem-calc(14);
                height: rem-calc(14);
              }
            }

            &-text {
              font-family: $font-family-condensed;
              font-size: rem-calc(16);
              font-weight: 700;
              line-height: rem-calc(16);
              letter-spacing: rem-calc(3);
              text-transform: uppercase;
            }
          }
        }

        .TextHeadline {
          padding-top: rem-calc(16);
          font-size: rem-calc(48);
          font-weight: 700;
          line-height: rem-calc(48);
          text-transform: uppercase;
        }

        &--simpleHint {
          padding: 0;

          .TextLink-text {
            font-family: $font-family-condensed;
            font-size: rem-calc(16);
            font-weight: 700;
            line-height: rem-calc(16);
            letter-spacing: rem-calc(3);
            text-transform: uppercase;
          }
        }
      };

      #{$componentClass}-copyLine {
        padding-top: rem-calc(16);
        padding-left: rem-calc(24);
        padding-right: rem-calc(24);
        font-family: $font-family-condensed;
        font-size: rem-calc(32);
        font-weight: 400;
        line-height: rem-calc(32);
        text-align: center;
        letter-spacing: 0.01em;
      };

      #{$componentClass}-footer {
        padding-top: rem-calc(24);
        padding-bottom: $global-padding;
        display: flex;
        flex-shrink: 0;
        align-items: center;

        a {
          min-width: rem-calc(160);
          height: rem-calc(42);
          border-radius: rem-calc(6);
          font-size: rem-calc(16);
          font-weight: 700;
          line-height: rem-calc(23);
          letter-spacing: 1.07px;
          text-transform: uppercase;
        }

        .TextLink:first-of-type {
          margin-right: $global-padding * .75;
        }

        .TextLink:only-child {
          margin-right: 0;
        };
      };
    }

    #{$componentClass}-media {
      height: 100%;
      width: calc(100% - 48px);
      position: relative;
      z-index: 30;
      overflow: hidden;
    }

    //----------------------------------------------------------------------------
    // Container Modifiers
    //----------------------------------------------------------------------------

    &--withNoHint {
      .ContentStage-textContent{
        .ContentStage-header {
          .TextHeadline {
            padding-top: rem-calc(33);
          }
        }
      }
    }

    &--withWithFullWidthImg {
      .ContentStage-media{
        width: 100%;
        height: 100%;
      }
    }

    &--imageOverGradient {
      position: absolute;
      z-index: 10;
      width: 100%;
    }

    //----------------------------------------------------------------------------
    // ContentStage Container Media Queries
    //----------------------------------------------------------------------------

    @include media($media_s-m) {
      #{$componentClass}-textContent {
        #{$componentClass}-header {
          padding-top: rem-calc(16);
          .TextLink-text{
            font-size: rem-calc(12);
            line-height: rem-calc(12);
            letter-spacing: 2.25px;
          }

          .TextHeadline {
            padding-top: rem-calc(16);
            font-size: rem-calc(32);
            line-height: rem-calc(32);
          }
        }
        #{$componentClass}-copyLine {
          padding-top: rem-calc(8);
          font-size: rem-calc(24);
          line-height: rem-calc(24);
        };

        #{$componentClass}-footer {
          padding-top: rem-calc(16);
          flex-direction: column;

          .TextLink:first-of-type {
            margin-right: 0;
          }
          .TextLink:nth-of-type(2){
            margin-right: 0;
            margin-top: rem-calc(16);
          }
        }
      }
    }

    @include media($media_height) {
      height: $contentStageFixedHeightViewportS;
    };
  }

  &--dividedContentStage {
    display: flex;
    justify-content: center;
    overflow: hidden;

    #{$componentClass}-splitContentStageItem{
      width: 50%;

      &:first-of-type{
        border-right: $contentStageBorderMobile solid white;
      }

      &:nth-of-type(2){
        border-left: $contentStageBorderMobile solid white;
      }
    };

    @include media($media_s-m) {
      flex-direction: column;
      #{$componentClass}-splitContentStageItem {
        width: 100%;

        &:first-of-type{
          border-right: none;
          border-bottom: $contentStageBorderMobile solid white;
        }

        &:nth-of-type(2){
          border-left: none;
        }
      }
    }
  }

  //----------------------------------------------------------------------------
  // ContentStage Media Queries
  //----------------------------------------------------------------------------

  @include media($media_s-m) {
    margin: $contentStageBorderMobile;
  }
};



