/** @define OrderSummary */
$componentClass: '.OrderSummary';
.OrderSummary {
  width: 100%;

  @include media($media_l) {
    display: flex;
    flex-direction: row;
  }

  &-item {

    > *:first-child {
      .TextInfo {
        margin-left: .2em;
      }
    }

    > *:not(:last-child) {
      margin-bottom: $global-padding * .25;
    }

    @include media($media_s-m) {
      @include borderDashed($color: $color-gray50, $top: true);
      padding: $global-padding 0;
    }

    @include media($media_l) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 33%;
      &:not(:last-child) {
        padding-right: $global-padding;
      }

      #{$componentClass}-link {
        margin-top: auto;
      }
    }

    .ContractRenewalTariffInfo {
      .TextDataList {
        dt {
          width: 100%;
          text-align: left;
        }
        dd {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  &-list {
    list-style: none;
    padding: 0;
  }
}
