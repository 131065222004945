/** @define AccordionSection */

$componentClass: '.AccordionSection';
.AccordionSection {
    $m-themeLight: #{&}--themeLight;
    $m-themeMedium: #{&}--themeMedium;
    $m-themeDark: #{&}--themeDark;
    width: 100%;
    text-align: center;

    &-title {
      position: relative;
      padding: rem-calc(10) $global-padding * 0.5;
      cursor: pointer;
      width: 100%;
    }

    &-label {
      position: relative;
      padding-right: $global-padding * 0.5;
      transition: color $timing-duration $timing-easing;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: rem-calc(12);
        height: rem-calc(19);
        background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path class="Icon-base" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
        background-repeat: no-repeat;
        background-position: center center;
        transform-origin: center center;
        transition: all $timing-duration $timing-easing;
      }

      #{$componentClass}--arrowLeft & {
        padding-right: 0;
        padding-left: $global-padding * 0.25 * 3;

        &::after {
          right: auto;
          left: 0;
        }
      }
    }

    &-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: rem-calc(10);
    }

    &-content {
      overflow: hidden;
      height: 0;
      transition: all $timing-duration $timing-easing;
      text-align: left;

      #{$componentClass}--arrowLeft & {
        padding-left: $global-padding * 0.25 * 3;
      }
    }

    &-inner {
      width: 100%;
      padding: $global-padding * 0.5;
    }

    &--indented {
      > #{$componentClass}-title {
        padding-left: $global-padding;
        padding-right: $global-padding;
      }
    }

    &--centered {
      #{$componentClass}-title {
        text-align: center;
      }
      #{$componentClass}-label {
        display: inline-block;
        @include media($media_m-l) {
          padding-right: $global-padding;
        }
        &::after {
          position: relative;
          display: block;
          margin: 0 auto;
          @include media($media_m-l) {
            position: absolute;
            display: inline-block;
            right: 0;
          }
        }
      }
    }

    &--plusMinusIcons {
      > #{$componentClass}-title > #{$componentClass}-label::after {
        background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ><polygon class="Icon-base" points="22,9 13,9 13,0 9,0 9,9 0,9 0,13 9,13 9,22 13,22 13,13 22,13 "/></svg>');
      }

      &.is-open {
        > #{$componentClass}-title > #{$componentClass}-label::after {
          background-image: svg-url('<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 4" ><polygon class="Icon-base" points="22,0 0,0 0,4 22,4 "/></svg>');
        }
      }
    }

    &--inverted {
      > #{$componentClass}-content,
      > #{$componentClass}-title {
        background-color: $color-gray15;
      }
    }

    &--themePrimary {
      > #{$componentClass}-title {
        color: $color-primary;
      }

      #{$componentClass}-label {
        &::after {
          background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path fill="#{$color-primary}" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
        }
      }
    }

    &--themeLight {
      > #{$componentClass}-content,
      > #{$componentClass}-title {
        background-color: $color-white;
      }
    }

    &--themeMedium {
      > #{$componentClass}-content,
      > #{$componentClass}-title {
        background-color: $color-gray15;
      }
    }

    &--themeDark {
      color: $color-white;

      > #{$componentClass}-content,
      > #{$componentClass}-title {
        background-color: $color-black;
      }

      #{$componentClass}-label {
        &::after {
          background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path fill="#{$color-white}" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
        }
      }

      &#{$componentClass}--plusMinusIcons {
        > #{$componentClass}-title > #{$componentClass}-label::after {
          background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ><polygon fill="#{$color-white}" points="22,9 13,9 13,0 9,0 9,9 0,9 0,13 9,13 9,22 13,22 13,13 22,13 "/></svg>');
        }

        &.is-open {
          > #{$componentClass}-title > #{$componentClass}-label::after {
            background-image: svg-url('<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 4" ><polygon fill="#{$color-white}" points="22,0 0,0 0,4 22,4 "/></svg>');
          }
        }
      }
    }

    &--highlighted {
      &.is-open {
        > #{$componentClass}-content,
        > #{$componentClass}-title {
          background-color: $color-primary-highlight;
        }
      }
    }

    &--underlined {
      background-color: transparent;
      #{$componentClass}-inner,
      #{$componentClass}-title {
        border-bottom: 1px solid $color-gray50;
      }
      #{$componentClass}-label {
        &::after {
          background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path fill="#{$color-primary}" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
        }
      }
    }

    &--embedded {
      #{$componentClass}-title,
      #{$componentClass}-inner{
        padding: 0;
      }
    }

    &.is-open {
      > #{$componentClass}-content {
        height: 100%;
      }

      > .AccordionSection-title > #{$componentClass}-label::after {
        transform: rotate(-180deg);
      }
    }

  &--headlineInvisible {
    #{$componentClass}-label {
      &::after {
        background-image: none;
      }
    }
  }

  &--headlessStyle {
    .FAQ-group {
      padding: 0.75rem;
      background-color: white;

      &:not(:first-child, :last-child) {
        padding-top: 0;
      }
     &:not(:last-child) {
       margin-bottom: 0;
     }
      [class~='FAQ-question']:last-of-type {
        border-bottom: 2px dotted $color-gray15;
        padding-bottom: 0.75rem;
      }
    }
    #{$componentClass}-content {
      background-color: $color-gray15;
      .TextCopy {
        background-color: white;
        padding-top: 0.375rem;
      }
      #{$componentClass}-inner {
        padding-top: 0;
      }
    }

     [class~='FAQ-group']:last-of-type {
       [class~='FAQ-question']:last-of-type {
         padding-bottom: 0;
         border-bottom: none;
       }
    }
    [class~='FAQ-group']:first-of-type {
      [class~='FAQ-question']:first-of-type {
        padding-top: 0.75rem;
      }
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass} {
    &--themeLight {
      > #{$componentClass}-content,
      > #{$componentClass}-title {
        background-color:  $color-gray-light-darkmode;
      }
    }

    &--themeMedium {
      > #{$componentClass}-content,
      > #{$componentClass}-title {
        background-color: $color-gray-dark-darkmode;
      }
    }

    &--headlessStyle {
      .FAQ-group {
        background-color: $color-gray-light-darkmode;
      }
      > #{$componentClass}-title, #{$componentClass}-content {
        background-color: $color-gray-dark-darkmode;
      }


      #{$componentClass}-content {
        .TextCopy {
          background-color: $color-gray-light-darkmode;
          color: $color-white-darkmode;
        }
      }

      #{$componentClass}-inner {
        .TextCopy {
          @include listFormater($isThemeDark: true);
        }
      }
    }

    &-label {
      &::after {
        background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path fill="#{$color-white}" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
      }
    }

    &--plusMinusIcons {
      > #{$componentClass}-title > #{$componentClass}-label::after {
        background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ><polygon class="Icon-base" fill="#{$color-white}" points="22,9 13,9 13,0 9,0 9,9 0,9 0,13 9,13 9,22 13,22 13,13 22,13 "/></svg>');
      }
      &.is-open {
        > #{$componentClass}-title > #{$componentClass}-label::after {
          background-image: svg-url('<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 4" ><polygon class="Icon-base" fill="#{$color-white}" points="22,0 0,0 0,4 22,4 "/></svg>');
        }
      }
    }
  }
}

