$componentClass: ".MyData";
.MyData {
  &-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-icon {
    width: rem-calc(100);
    height: rem-calc(100);
    cursor: pointer;
    padding: rem-calc(20) rem-calc(25) rem-calc(20);
    margin-bottom:$global-padding*0.5;
    background-color:$color-white;
    border-radius: 50%;
    text-align: center;

    &--withAvatar {
      background: $gradient-primary;
      padding: 0;
      svg {
        width: rem-calc(100);
        height: rem-calc(100);
      }
    }
  }

  .MyData-dataSectionContent > .TextHeadline {
    width: 90%;
    @include media($media_xs) {
      width: 75%;
    }
  }

  .MyData-compEdit, .MyData-comp {
    margin-top: $global-padding;
  }

  .MyDataSim {
    &-info {
      .TextLink-icon {
        width: $copy_font-size;
      }
    }

    &-visibilityIcon {
      cursor: pointer;

      .Icon {
        width: $global-padding;
        height: $global-padding;
        display: flex;
        align-items: center;
      }
    }

    &-refreshButton {
      .TextLink-text,
      .TextLink-icon {
        transition: all $timing-duration $timing-easing;
      }
      .TextLink-icon {
        line-height: 1;
      }
    }

    .Icon-base,
    .Icon-highlight {
      fill: $color-primary;
    }

    .TextInfo, .TextLink-icon {
      margin-left: .2em;
    }

    &-status {
      &--success {
        color: $color-success;

        .TextInfo {
          .Icon-highlight,
          .Icon-base {
            fill: $color-success;
          }
        }
      }
    }
  }

  .MyDataBillingForm {
    .FieldMessenger-hint {
      color: $color-gray100;
    }
  }
  &-changeLink {
    position: absolute;
    right:0;
  }
  &-dataSectionContent {
    position: relative;
  }
  &-dataSection {
    &:not(:first-child) {
      margin-top:$global-padding;
    }
    h2 {
      color:$color-orange;
    }
    position: relative;

    border: 1px solid $color-gray50;
    @include media($media_s) {
      padding: $global-padding rem-calc(10);
    }

    @include media($media_m-l) {
      padding: $global-padding $global-padding*2;
    }
  }

  &--loyaltyLevel1{
    .MyData-header {
      .TextInfo {
        svg {
          .Icon-base,
          .Icon-highlight{
            fill:$color-silver;
          }
        }
      }
      .MyData-icon {
        border: 2px solid $color-silver;
      }
    }
  }

  &--loyaltyLevel2{
    .MyData-header {
      .TextInfo {
        svg {
          .Icon-base,
          .Icon-highlight {
            fill: $color-gold;
          }
        }
      }

      .MyData-icon {
        border: 2px solid $color-gold;
      }
    }
  }
  .MyDataAdConsentForm {
    .FieldCheckbox-label {
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.0175em;
    }
    .FieldCheckbox-label small {
      font-size: 10px;
      letter-spacing: 0.2px;
      line-height: 16px;
    }
    .FieldCheckbox-labelInner {
      pointer-events: auto;
      a:not([class]){
        @include RawTextLink();
      }
    }
    .FieldCheckbox-formOptional {
      display: none;
    }
  }
}
