/** @define GlobalInfoBar */

$componentClass: '.GlobalInfoBar';
.GlobalInfoBar {
  width: 100%;
  text-align: center;
  padding: $global-padding * 0.5 0;
  background-color: $color-gray150;
  color: white;
}
