/** @define MyDashboard */
@use "sass:math";

$componentClass: '.MyDashboard';
.MyDashboard {
  &--placeholder {
    background-position: top center;
    min-height: 380px;

    .Icon svg {
      width: 100%;
      height: auto;
    }
  }

  &-referralBanner {
    background: $gradient-secondary;
    padding: math.div($global-padding, 3) $global-padding;
    border-radius: $border-radius-m;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    #{$componentClass}-referralBannerButton {
      margin-left: auto;
    }

    .Icon {
      display: contents;
    }
  }

  &-scrollIndicator {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: $global-padding * 0.5 0;
    display: none;
    text-align: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $color-gray100 100%);
    z-index: 999;
    transform: translateY(100px);
    transition: all 0.5s ease 0.2s;

    &.is-visible {
      transform: translateY(0);
    }

    .TextCopy {
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
    }

    @include media($media_m-l) {
      display: block;
      padding: rem-calc(16) 0;
    }
  }
}
