/** @define UILinearProgressBar */

$componentClass: '.UILinearProgressBar';
.UILinearProgressBar{
  position: relative;
  width: rem-calc(320);
  transform: translateX(-50%);
  margin-bottom: rem-calc(10);
  margin-left: 50%;
  border-radius: rem-calc(6);
  padding: rem-calc(15) rem-calc(16) rem-calc(19);
  cursor: default;

  &--clickable {
    cursor: pointer;
  }

  &-info {
    width: rem-calc(16);
    height: rem-calc(16);
    position: absolute;
    top: rem-calc(18);
    right: rem-calc(16);
    .Icon {
      color: $color-orange;
    }
  }

  &-date {
    color: $color-black;
  }

  &-content {
    .TextCopy {
      font-size: rem-calc(12);
      line-height: rem-calc(20);
    }
  }

  .Icon {
    svg {
      filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }
  }

  $shapeSize: 295;

  .Icon-stroke2,
  .Icon-stroke3 {
    width: $shapeSize;
    transition: all $timing-duration * 6 $timing-easing;
  }

  @for $i from 0 through 100 {
    $offset: decimal-round($i * 0.01 * $shapeSize);
    $offsetSmall: decimal-round(3 * 0.01 * $shapeSize);
    &[data-to="#{$i}"] {
      .Icon-stroke2,
      .Icon-stroke3 {
        @if $i > 0 { width: #{$offset}px; }
        @if $i <= 3 and $i != 0 { width: #{$offsetSmall}px; }
        @if $i == 0 { width: 0; }
      }
      @if $i <= 10 { .Icon-stroke3 { opacity: 0; } }
    }
  }
}
