/** @define Tab */

.Tab {
  &--alternateBackgroundOdd {
    .Tab-contentBlock {
      &:nth-child(odd) {
        background: $color-gray15;
      }
    }
  }
  &--alternateBackgroundEven {
    .Tab-contentBlock {
      &:nth-child(even) {
        background: $color-gray15;
      }
    }
  }
}
