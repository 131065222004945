@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.SearchForm {

  /*---------------------------------------------------------------------------------------
  | Searchbox
  -----------------------------------------------------------------------------------------*/

  .QueryInputBox {
    display: flex;
    justify-content: center;
  }

  &-noResults{
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  &-innerBox {
    .MediaImage, .TextLink-icon {
      max-width: 32px;
      margin-right: 16px;
    }
  }

  &-clearInput {
    display: inline-block;
    width: 12px;
    height: 16px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    .Icon-base{
      fill: $color-black;
    }

    &--inverted {
      .Icon-base{
        fill: $color-inverted;
      }
    }
  }

  .QueryInput {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $global-padding;
    position: relative;

    input[type=text]{
      position: relative;
      border-radius: $border-radius-m;
      line-height: $global-padding*1.5;
      background-color: $color-gray15;
      color: $color-black;
      text-indent: 20px;
      width: 100%;
    }

    .IconWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0;
      margin-left: $global-padding * 0.25;

      .Icon {
        position: absolute;
      }
    }

    input[type=Submit] {
      border-radius: $border-radius-m;
      line-height: $global-padding*1.5;
      padding-left: $global-padding * 0.84;
      padding-right: $global-padding * 0.84;
      background:$gradient-primary;

      &:hover {
        cursor: pointer;
      }
    }

    .TextLink-text {
      text-transform: uppercase;
      font-family: $font-family-condensed;
    }
  }

  &-columnBox--serviceSearchColumnBox {
    @include media($media_s) {
      width: auto;
    }
  }

  &-hitcounter {
    font-weight: bold;
  }


  /*---------------------------------------------------------------------------------------
  | SearchResults
  -----------------------------------------------------------------------------------------*/

  .SearchResults, .SearchResultList {
    animation: fadeIn ease 2s;

    &-resultFilter {
      display: flex;
      justify-content: center;
    }

    &-resultFilterInner {
      display: flex;
      justify-content: center;
      text-align: center;
      text-transform: capitalize;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $color-gray15;
        border-radius: 1px;
      }
    }

    &-resultFilterLine {
      position: absolute;
      bottom: 0;
      height: 2px;
      background: $color-loyalty-l0;
      border-radius: 1px;
      transition: .3s ease-out;
    }

    &-resultFilterItem {
      font-weight: bold;
      cursor: pointer;
      transition: .3s ease-out;

      &:not(:last-child) {
        margin-right: 24px;
      }
      &--active {
        color: $color-orange;
        text-transform: capitalize;
      }
    }

    &-noResults {
      margin-top: $global-padding;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-showMoreButton {
      display: flex;
      align-content: center;
      justify-content: center;
    }

    .SearchResultItem {
      margin-top: $global-padding;
      margin-bottom: $global-padding;
      padding-top: $global-padding;
      padding-bottom: $global-padding;

      background-color: $color-gray15;
      border-radius: $border-radius-m;

      .TextLink {
        color: $color-black;
        width: 100%;
        .TextLink-text {
          display: flex;
          align-items: center;
          flex-shrink: 0;
        }
        &:hover{
          &::after {
            animation: none;
          }
        }
      }

      &-icon {
        width: 96px;
        height: 96px;
        padding: 0 $global-padding*0.34;

        #Icon-base-black {
          fill: $color-black;
        }
      }

      &-textcontent {
        width: 80%;
        margin-right: $global-padding*0.34;
        hyphens: auto;
        @include media($media_s) {
          width: 70%;
        }

        .TextHeadline {
          font-weight: bold;
        }
        .TextCopy {
          @include media($media_s) {
            line-height: 1.67;
          }
        }
      }
      &-url {
        word-break: break-all;
        font-size: $copy-small_font-size;

      }
      &-description {
        font-size: $copy-secondary_font-size;
        @include media($media_s) {
          font-family: $font-family-regular;
          font-weight: $font-weight-light;
        }
      }
    }
  }

  /*---------------------------------------------------------------------------------------
  | Service Search
  -----------------------------------------------------------------------------------------*/
  .SearchForm-innerBox--serviceSearchInnerBox {
    .SearchForm-cmsItems {
      margin-top: 8px;
    }
    .SearchForm-globalSearchSuggestions {
      display: block;
    }
    .SearchForm-columnBox--serviceSearchColumnBox {
      margin: auto;
      @include media($media_s) {
        width: 100%;
      }
    }
  }

  /*---------------------------------------------------------------------------------------
  | Global Search
  -----------------------------------------------------------------------------------------*/
.SearchForm--isGlobalSearch {
    .SearchForm-globalSearchSuggestions {
      .SearchForm {
        &-columnBox {
          @include media($media_m) {
            width: 70vw;
          }
          @include media($media_l) {
            width: 50vw;
          }
        }
        &-facet {
          @include media($media_m-l) {
            width: 50%;
          }
        }
      }
    }


  .SearchForm-oteloLogo {
    top: 2.4rem;
    left: 3rem;
    position: relative;
    .TextLink-icon {
      @include media($media_s-m) {
        display: none;
      }
    }
    svg {
      width: 96px;
    }
  }

  .SearchForm-columnBox {
    .SearchForm {
      width: 50%;
      @include media($media_s) {
        width: 100%;
      }
    }
    display: flex;
    justify-content: center;
    .TextLink {
      font-family: $font-family-condensed;
      .SearchForm-facetName {
        font-family: $font-family-regular;
      }
    }
  }

  .SearchForm-cmsItem {
    a svg g {
      fill: $color-black;
      use {
        fill: $color-black;
      };
      #Icon-highlight {
        fill: orangered;
      }
    }
  }
  .SearchForm-mostWanted {
    width: 100%;
  }

  .SearchForm-innerBox {
    .TextCopy {
      font-size: 16px;
      font-weight: $font-weight-bold;
      text-transform: initial;
      color: $color-black;
    }

    li {
      margin-bottom: 8px;
    }

    .SearchForm-cmsItemsBox {
      display: flex;
      flex-wrap: wrap;
      padding-top: 16px;
      padding-bottom: 32px;
      @include media($media_m) {
        width: 70vw;
      }
      @include media($media_l) {
        width: 50vw;
      }
    }

    .SearchForm-cmsItems {
      width: 100%;
      @include media($media_m-l) {
        width: 50%;
      }
    }
  }

  .SearchForm-headline {
    color: $color-black;
    &--globalSearch {
      line-height: $h-xs_line-height;
    }
  }

  .SearchForm-queryInputLightbox {
    display: flex;
    justify-content: flex-start;
  }

  .QueryInput input[type=text] {
    background-color: $color-white;
  }

  .SearchForm-clearInput {
    .Icon-base{
      fill: $color-gray100;
    }
  }

  .Lightbox-inner {
    padding: 0;
  }

  .Lightbox-content {
    overflow: hidden;
    height: min-content;
    width: 100vw;
    background: $gradient-primary;
    border-radius: 0;
    padding: 0 16px 16px 16px;
  }

  .SearchForm-facetName {
    color: $color-black;
    text-transform: capitalize;
    margin: 0 16px 16px 0;
    font-weight: $font-weight-normal;
  }

  @include media($media_s) {
    .Lightbox-content {
      overflow: hidden;
      height: fit-content;
      width: 100vw;
      background-color: $color-black;
      border-radius: 0;
      padding: 16px;
    }

    .SearchForm-globalSearchSuggestions {
      width: 100%;
      display: flex;
      justify-content: center;
      @include media($media_s_m) {
        justify-content: left;
      }
    }
  }
}


  /*---------------------------------------------------------------------------------------
  | Search Suggestions
  -----------------------------------------------------------------------------------------*/
   &-facetSuggestion {
    .TextCopy {
      font-family: $font-family-condensed;
      font-size: 1rem;
      font-weight: $font-weight-bold;
      line-height: 1.25;
      letter-spacing: normal;
      margin-top: 0;
      margin-bottom: 0.625rem;
      padding: 0;
      text-decoration: none;
    }
  }

  /*---------------------------------------------------------------------------------------
| Modified Lightbox
-----------------------------------------------------------------------------------------*/
  &-searchFormSuggestionBox {
    .Lightbox-content {
      overflow: hidden;
      height: auto;
      min-height: 362px;
      width: 672px;
      background-color: $color-gray15;
      border-radius: 6px;
      padding: 16px;
    }


    .QueryInput input[type=text] {
      width: 49vw;
      max-width: 30vw;
      background-color: $color-inverted;

      @media (min-width: 1024px) and (max-width: 1399px) {
        max-width: 40vw;
      }
      @media (min-width: 1400px) and (max-width: 1899px) {
        max-width: 31vw;
      }
      @media (min-width: 1900px) and (max-width: 2255px) {
        max-width: 25vw;
      }
      @media (min-width: 2256px) {
        max-width: 19vw;
      }
      @include media($media_m) {
        width: 56vw;
        max-width: 56vw;
      }
      @include media($media_s) {
        max-width: unset;
      }
    }
  }

  .QueryInput--globalSearchQueryInput input[type=text] {
    width: 55vw;
    max-width: 55vw;
    @include media($media_m) {
      width: 70vw;
    }
    @include media($media_s) {
      max-width: unset;
    }
  }

  /*---------------------------------------------------------------------------------------
 | CMS Search Suggestions
 -----------------------------------------------------------------------------------------*/
    &-lightboxContent {
      &--serviceStyle {
        .TextLink {
          margin-top: 8px;
          font-weight: bold;
          font-size: 13px;
        }
      }
     }

    &-externSearchQuery {
      display: flex;
      justify-content: center;
      align-items: baseline;
      .QueryInput {
        width: auto;
      }
      input[type=text] {
        position: relative;
      }
      .TextLink-text {
        font-family: $font-family-condensed;
        font-size: $copy_font-size;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }
   }

  &--isGlobalSearch {
    .TextLink {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: normal;
      color: $color-black;
    }
    .SearchForm-innerBox {
      display: flex;
      justify-content: center;
    }
  }

  &-innerBox {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 32px;

    &--serviceSearchInnerBox {
      .SearchForm-globalSearchSuggestions {
        .SearchForm {
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }
      padding: 0;
      ul {
        .TextCopy, .TextLink {
          color: $color-black;
          line-height: initial;
        }
      }
    }

    .SearchForm-globalSearchSuggestions {
      width: 100%;
      display: flex;
      justify-content: center;
      @include media($media_s) {
        width: 100%;
      }

      .SearchForm:first-of-type {
        @include media($media_s) {
          margin: 0;
        }
      }
    }

    .TextCopy {
      font-size: 16px;
      margin-bottom: 0;
      line-height: 2.5rem;
      text-transform: initial;
    }
    &--searchSuggestionInnerBox {
      flex-direction: column;
    }
  }

  .SearchForm-cmsSuggestionsHeadline {
    font-weight: $font-weight-normal;
    line-height: normal;
    margin-top: 8px;
  }

  &-cmsItem {
    display: flex;
    align-items: center;
    .TextLink {
      margin: 0;
    }

    .Icon svg {
      width: 32px;
      height: 32px;
      g {
        fill: $color-black;
        #Icon-highlight {
          fill: orangered;
        }
      }
    }
  }

  &-headline {
    letter-spacing: normal;
    font-family: $font-family-condensed;
    &--globalSearch {
      line-height: initial;
      color: $color-white;
    }
  }

  &-mostWanted {
    input[type=text] {
      margin-right: 16px;
    }

    .SearchForm-moreResultsButton {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      @-moz-document url-prefix() {
        margin-top: 0;
      }
      .TextLink {
        font-size: 16px;
      }
    }

    .TextLink--asButton {
      width: 310px;
    }
    &--mostWantedServiceSearch {
      margin: auto;

      @include media($media_m-l) {
        width: 50%;
      }
    }
  }

  @include media($media_s) {
    .QueryInput {
      width: auto;
      &--globalSearchQueryInput {
        width: auto;
      }
    }
    .QueryInputBox {
      display: unset;
    }

    &-mostWanted {
      &-externSearchQuery {
        justify-content: space-evenly;
      }
    }

    .Lightbox-content {
      justify-content: left;
    }
    .SearchForm-innerBox {
      justify-content: flex-start;
    }
    .SearchForm-searchFormSuggestionBox .Lightbox .QueryInput input[type=text] {
      width: 235px;
    }

    .TextLink {
      font-family: $font-family-condensed;
      font-weight: $font-weight-bold;
    }

    &-headline {
      font-size: $h-xs_font-size;
    }
    &-copy {
      font-size: $h-xxxs_font-size;
    }
    &-item {
      padding-right: 8px;
      margin-bottom: 8px;
    }
    &-facet {
      margin-bottom: 0;
    }
    /*---------------------------------------------------------------------------------------
    | Global Search Suggestions Mobile
    -----------------------------------------------------------------------------------------*/
    .SearchForm--isGlobalSearch {
      .SearchForm-columnBox {
        display: unset;
      }
    }
  }
};

