/** @define FieldDate */
$componentClass: '.FieldDate';
.FieldDate {
  width: 100%;
  &--themeCompact {
    .FieldGroup-label {
      margin-bottom: 0;
    }
  }

  &--themeDefault {

  }
}
