/** @define CheckoutCartNavigation */
$componentClass: '.CheckoutCartNavigation';
.CheckoutCartNavigation {
  @include media($media_m-l) {
    position: relative;
  }

  .UIOverlayWrapper {
    transition: opacity $timing-duration $timing-easing;
    text-align: left;
    z-index: -1;
    bottom: rem-calc(5);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);

    @include media($media_s) {
      height: calc(100vh - #{$NavMain_height});
      right: 0;
      left: 0;
      bottom: 0 !important;
      .UIOverlayWrapper-container {
        min-height: calc(100vh - #{$NavMain_height - 1});
        transform: translateY(-150%);
        transition: transform .3s $timing-easing .2s;
      }
      .UIOverlayWrapper-inner {
        display: block !important;
        padding: $global-padding !important;
        padding-bottom: $global-padding * 3;
      }
      .ShoppingCart {
        opacity: 0;
        transition: opacity $timing-duration $timing-easing .4s;
      }

      &.is-visible {
        .UIOverlayWrapper-container {
          transform: translateY(0);
        }

        .ShoppingCart {
          opacity: 1;
        }
      }
    }
    @include media($media_m-l) {
      .ShoppingCart--widget {
        min-width: rem-calc(320);
      }
      &:not(.is-visible) {
        opacity: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
      }
    }
  }

  &-inner {
    > .CheckoutCartLink {
      line-height: $NavMain_height;
    }
  }

  &-link {
    width: 100%;
    margin-top: $global-padding;
  }
}
