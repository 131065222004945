/** @define FormManager */
$componentClass: '.FormManager';
.FormManager {
  &-header {
    > * {
      margin-bottom: $global-padding;
    }

    .CheckoutProgressBar {
      margin-bottom: $global-padding * 2;
    }
  }

  &-headline {
    @include media($media_s) {
      text-align: center;
    }
  }

  &-note {
    padding-right: $global-padding *2;
    padding-bottom: $global-padding * 0.5;
  }

  &-noteInner {
    padding: $global-padding;
    background: $color-gray15;
    @include media($media_s) {
      margin-right: 0;
    }
  }

  &--withoutAdditionalInfo {
    #{$componentClass}-step {
      @include media($media_s) {
        margin-top: $global-padding * 1.2;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          margin-top: $global-padding * 1.2;
        }
      }
    }
    .CheckoutCart {
      margin-top: 0;
    }
  }

  &--hardwareSelected {
    .CheckoutCart {
      margin-bottom: -$global-padding * 2.5;
    }
    .FormFooter {
      margin-top: $global-padding * 4.5 !important;
    }
  }

  &-aside{
    position: static;

    &--contractRenewal {
      float: right;
    }

    &--checkoutCartSticky {
      position: sticky;
      top: $NavMain_height;
      z-index: 2;
      margin: -$global-padding * 3  rem-calc(-20);
      width: auto;
    }

    &--checkoutCartFaqFooter {
      margin-top: rem-calc(24);
    }
  }

  &--columns {

    #{$componentClass}-aside,
    #{$componentClass}-step {
      > * {
        width: 100%;
      }
    }

    @include media($media_m-l) {
      #{$componentClass}-step {
        padding-right: $global-padding;
      }

      #{$componentClass}-aside {
        top: $global-padding * 3 !important;
      }
    }
  }

  &--singlePageForm {
    &.FormManager--shipping, &.FormManager--payment{
      .FormHeader{
        color: $color-black;

        .TextHeadline {
          font-size: $h-xs_font-size;
          line-height: $h-s_line-height;

        }
        .TextCopy, .TextLink {
          font-size: $copy-secondary_font-size;
          line-height: $copy-secondary_line-height;
        }
      }
      .FieldCheckbox {
        .FieldCheckbox-label{
          color: $color-gray100;
          font-size: $copy_font-size;
          line-height: $copy-small_line-height;
          margin-bottom: 5px;
        }
        .FieldMessenger-hint{
          margin-top: 0;
        }
      }
    }

    &.FormManager--confirm{
      .FormHeader{
        .TextHeadline {
          color: $color-gray100;

        }
        .TextCopy, .TextLink {
          color: $color-gray100;
          font-size: $copy-small_font-size;
          line-height: $copy-small_line-height;
        }

        &:not(:first-of-type){
          margin-top: 1rem;
        }
        &:nth-of-type(2){
          margin-bottom: .5rem;
        }
      }
    }

    .ShippingPreview,.PaymentPreview {
      &-userData{
        color: $color-gray100;
        margin-left: 5px;
        width: 50%;

        &:last-child{
          margin-bottom: 40px;
        }
      }

      @include media($media_s) {
        &-userData {
          width: 100%;
          margin-left: 0;
        }
      }
    }

    .PaymentPreview {
      &-userData{
        width: 60%;
      }

      @include media($media_s) {
        &-list {
          margin-top: 5px;
          width: 100px;
        }
      }

      @include media($media_m) {
        &-userData {
          width: 50%
        }
      }
    }

    .FormSection{
      border: 0;
    }

    .FieldMessenger {
      &-hint{
        .TextCopy {
          font-size: $copy-small_font-size;
          line-height: $copy-small_line-height;
        }
      }
    }

    .FormFooter{
      margin-bottom: $global-padding;
      &-buttons{
        flex-direction: row;
        width: 100%;
      }

      &-link {
        width: 66.6666% !important;
        margin-left: 0 !important;
      }

      &-hint{
        text-align: left;
      }

      @include media($media_s) {
        &-hint{
          text-align: center;
        }

      }

      @include media($media_s-m) {
        &-link {
          width: 100% !important;
        }
      }
    }

    &#{$componentClass}--columns {

      @include media($media_m) {
        .FormFooter,
        #{$componentClass}-header,
        .FormWrapper-headline {
          width: calc(58.33333% - #{$global-padding});
        }
      }

      @include media($media_m-l) {
        .FormFooter {
          padding-left: $global-padding;
          padding-right: $global-padding;
        }
      }

      @include media($media_l) {
        .FormFooter,
        #{$componentClass}-header,
        .FormWrapper-headline {
          width: calc(66.66666% - #{$global-padding});
        }
      }
    }

    @include media($media_s) {
      .CheckoutCart {
        top: -100px;
      }

      .FormWrapper-headline {
        width: 100%;

      }

      .FormHeader{
        margin-top: 5px;
      }
    }

    @include media($media_m-l) {
      #{$componentClass}-aside {
        top: $global-padding * 3 !important;
      }

      .CheckoutCart {
        position: relative;
        top: -65px;
      }
    }
  }
}
