/** @define TariffFilter */

$componentClass: '.TariffFilter';
.TariffFilter {
  text-align: left;

  &-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: wrap;
    margin-top: rem-calc(30);
  }

  &-callouts {
    position: relative;
  }

  .OffersPanel-header {
    border-radius: $border-radius-l;
  }

  .FormOptionTab:not(:last-child) {
    margin-right: rem-calc(4);
  }

  &-subcontent {
    @include borderDashed($color: $color-gray100, $top: true);
    position: relative;
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(30);
    padding-top: rem-calc(30);
    .FormOptionBullet {
      display: block;
    }
    .ContractRenewalFeeInfo {
      @include media($media_s-m) {
        margin-top: rem-calc(15);
      }
      @include media($media_l) {
        position: absolute;
        bottom: rem-calc(-65);
        right: 0;
      }
    }
  }

  &-labels {
    position: absolute;
    top: rem-calc(30);
    left: rem-calc(30);
    .TextCopy {
      margin-bottom: rem-calc(2);
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: $global-padding;

    &:not(:root:root) {
      display: block;
    }

    @include media($media_s) {
      width: 100%;
      &:last-child {
        padding-bottom: 0;
      }
    }

    @include media($media_m-l) {
      width: 50%;

      &:last-child, &:nth-last-child(2):nth-child(2n-1) {
        padding-bottom: 0;
      }
    }

    @include media($media_m) {
      &:nth-child(2n) {
        padding-left: $global-padding * .5;
      }
      &:nth-child(2n-1) {
        padding-right: $global-padding * .5;
      }
    }

    @include media($media_l) {
      &:nth-child(2n) {
        padding-left: $global-padding;
      }
      &:nth-child(2n-1) {
        padding-right: $global-padding;
      }
    }
  }

  &--compact {
    display: inline-block;
    width: 35%;
    padding: rem-calc(20);
    background-color: $color-gray15;
    border-radius: rem-calc(8);

    @include media($media_s) {
      width: 100%;
      background-color: transparent;
    }
    #{$componentClass}-subcontent {
      padding-top: 0;
      border: none;
    }
    #{$componentClass}-labels {
      top: 0;
    }
    .Callout--sticker {
      position: absolute;
      top: -$global-padding * 0.5;
    }

    @include media($media_s) {
      .Callout--sticker {
        top: -$global-padding * 1.5;
        left: 0;
        }
    }
    .Callout--badge {
      display: inline-block;
      position: absolute;
      right: -$global-padding * 2.5;
      top: -$global-padding * 1.5;
    }
    @include media($media_s) {
      .Callout--badge {
        right: $global-padding;
        top: -$global-padding;
      }
    }
    @include media($media_xs) {
      .Callout--badge {
        right: 0;
      }
    }
  }
}
