/** @define HotlineDeals */
$componentClass: '.HotlineDeals';
.HotlineDeals {
  display: flex;
  flex-direction: column;

  @include media($media_s) {
    min-height: 40rem ;
  }

  @include media($media_m) {
    min-height: 34rem ;
  }

  @include media($media_l) {
    min-height: 36rem ;
  }

  &-headline {
    min-height: $global-padding * 2;
  }

  &-headline + &-copy {
    margin-bottom: $global-padding;
  }

  &-steps {
    margin-top: $global-padding;
    overflow: hidden;
  }

  &-step {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > * {
      width: calc(25% - #{$global-padding + rem-calc(4)});
      @include media($media_s) {
        margin: 0 $global-padding * .1;
      }
      @include media($media_m-l) {
        margin: 0 $global-padding * .5;
      }
    }

    @include media($media_s) {
      > * {
        width: calc(50% - #{$global-padding + rem-calc(4)});
        margin: 0 $global-padding * .5 $global-padding * .5;
        &:last-child, &:nth-last-child(2):nth-child(odd) {
          margin-bottom: 0;
        }
      }
    }
  }

  &-content {
    margin-top: auto;
  }

  &-option {
    background-color: $color-white;
    border: 2px solid rgba($color-orange-dark, 0);
    border-radius: $border-radius-m;
    height: rem-calc(140);
    padding: 0.75rem 0.25rem;
    box-sizing: border-box;
    transition: border-color $timing-duration $timing-easing;
    &.is-selected, &:hover {
      border-color: $color-orange;
    }

    min-width: rem-calc(144);
    @include media($media_s) {
      margin: rem-calc(3);
    }

  }

  &-optionInner {
    display: flex;
    flex-direction: column;
    height: 100%;
    > * {
      display: block;
    }
    .Icon {
      overflow: hidden;
      height: rem-calc(60);
      margin-bottom: $global-padding * .25;
      > svg {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        max-height: rem-calc(60);

      }
    }

    .TextCopy {
      margin: auto 0;
    }

  }

  &-result {
    @include media($media_m-l) {
      width: 60%;
      margin: 0 auto;
    }

    @include media($media_s) {
      width: 80%;
      margin: 0 auto 6rem;
    }

    #{$componentClass}-headline {
      margin: $global-padding 0 $global-padding * .5;
    }

    #{$componentClass}-hint {
      margin-top: $global-padding * .5;
      ;
    }
  }

  &-footer {
    margin: $global-padding 0 0;
    visibility: hidden;
    &.is-active {
      visibility: visible;
    }

    @include media($media_m-l) {
      overflow: hidden;
      margin: $global-padding 0 $global-padding * 2;
    }
  }

  &--results {
    #{$componentClass}-content {
      margin-bottom: auto;
    }
  }
}
