/** @define FieldCheckbox */
$componentClass: '.FieldCheckbox';
.FieldCheckbox {
  position: relative;

  .FieldMessenger {
    margin-left: rem-calc(28);
    > .FieldMessenger-error {
      margin-top: 0;
    }
  }

  &-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &-labelInner {
    display: inline-block;
    margin-left: rem-calc(28);
    pointer-events: none;
    /* set inner html Elements inline to get required marker behind p element */
    p {
      display: inline;
    }
  }

  &-checkmark {
    position: absolute;
    top: rem-calc(2);
    left: rem-calc(2);
    width: rem-calc(14);
    height: rem-calc(15);
    pointer-events: none;
    line-height: 1;
    .Icon-stroke {
      stroke: $color-white;
      stroke-dashoffset: 100px;
      stroke-dasharray: 100px;
    }

    .Icon, svg {
      width: inherit;
      height: inherit;
      vertical-align: top;
    }
  }

  &-input + &-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: color $timing-duration $timing-easing;
  }

  &-input:checked + &-label::before,
  &-input:not(:checked) + &-label::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: rem-calc(18);
    height: rem-calc(18);
    background-color: $color-white;
    border: 2px solid $color-gray100;
    box-shadow: inset 0 0 0 0 $color-primary;
    transition: border-color $timing-duration $timing-easing,
                box-shadow $timing-duration $timing-easing;
  }

  &-input:focus + &-label::before,
  &-input:hover + &-label::before {
    border-color: $color-primary;
  }

  &-input:checked + &-label::before {
    border-color: $color-primary;
    background-color: $color-primary;
    box-shadow: inset 0 0 0 9px $color-primary;
    transition: border-color $timing-duration $timing-easing;
  }

  &-input:checked + &-label + &-checkmark {

    background-image: url("/icons/content-check-white.svg");
    background-position-y: -2px;
    background-size: cover;

    .Icon-stroke {
      animation: ani-draw-path $timing-duration * 2 $timing-easing forwards;
    }
  }

  // IE11 Fix - no support for stroke-dashoffset animations
  @media all and (-ms-high-contrast:none) {
    &-input:checked + &-label + &-checkmark {
      .Icon-stroke {
        stroke-dashoffset: 0px !important;
      }
    }

    &-input:checked + &-label::before,
    &-input:not(:checked) + &-label::before {
      transition: background $timing-duration $timing-easing,
                  border-color $timing-duration $timing-easing;
    }
  }

  &--themeDefault {
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label {
      &::before {
        border-color: $color-gray50;
      }
    }

    #{$componentClass}-input:checked + #{$componentClass}-label,
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label {
      &::before {
        border-width: 1px;
      }
    }

    #{$componentClass}-input:focus + #{$componentClass}-label,
    #{$componentClass}-input:hover + #{$componentClass}-label {
      &::before {
        border-color: $color-black;
      }
    }
  }

  &--themeCompact {
    #{$componentClass}-label {
      color: $color-gray100;
    }
  }

  &--inverted {
    #{$componentClass}-label {
      color: $color-white;
    }

    #{$componentClass}-input:checked + #{$componentClass}-label::before,
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label::before {
      background-color: transparent;
    }

    &#{$componentClass}--themeDefault {
      #{$componentClass}-input:not(:checked):not(:hover) + #{$componentClass}-label::before {
        border-color: $color-gray100;
      }

      #{$componentClass}-input:focus + #{$componentClass}-label,
      #{$componentClass}-input:hover + #{$componentClass}-label {
        &::before {
          border-color: $color-gray50;
        }
      }
    }
  }

  &--controlled {
    #{$componentClass}-label {
      cursor: not-allowed;
    }

    #{$componentClass}-input:checked + #{$componentClass}-label::before,
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label::before {
      background-color: $color-gray15;
      border: 2px solid $color-gray50;
    }

    #{$componentClass}-input:checked + #{$componentClass}-label::before {
      border-color: transparent !important;
      background-color: transparent !important;
      box-shadow: none;
      transition: none;
    }

    .Icon-stroke {
      stroke: $color-primary;
    }
  }

  &--inactive {
    #{$componentClass}-input:checked + #{$componentClass}-label::before {
      border-color: transparent !important;
      background-color: $color-gray100 !important;
    }

    .Icon-stroke {
      stroke: $color-inverted;
    }
  }

  &--error {
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label {
      color: $color-red;
      &:before,
      &:after {
        border-color: $color-red;
      }
    }
  }
}
