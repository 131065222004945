/** @define HardwareDetailBlock */
.HardwareDetailBlock {
  padding-top: $global-padding * 2;
  padding-bottom: $global-padding * 2;

  &-inner {
    display: flex;
    flex-direction: column;
    @include outerContainer($max-width);
  }

  &-text {
    width: 50%;
    margin: 0 auto;
    padding-left: $global-padding * 0.5;
    padding-right: $global-padding * 0.5;
    @include media($media_s) {
      width: 100%;
      margin-bottom: $global-padding;
      text-align: left;
    }
  }

  &-headline {
    margin-bottom: $global-padding * 0.5;
  }

  &-image {
    margin: 0 auto;
    padding-left: $global-padding * 0.5;
    padding-right: $global-padding * 0.5;
    text-align: center;
    img {
      max-width: 100%;
      max-height: rem-calc(400);
    }
    @include media($media_s) { order: 2; }
    @include media($media_m-l) { width: 50%; }
  }

  &:not([class*='HardwareDetailBlock--imageAlign'] ) {

    .HardwareDetailBlock-image {
      display: none;
    }

    @include media($media_m-l) {
      .HardwareDetailBlock-text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .HardwareDetailBlock-headline {
        width: 40%;
      }

      .HardwareDetailBlock-copy {
        width: 50%;
      }

      &.HardwareDetailBlock--textColumns {
        .HardwareDetailBlock-copy {
          width: 100%;
          columns: 2;
          column-gap: $global-padding;
        }
      }
    }
  }

  &--imageAlignRight,
  &--imageAlignLeft {
    @include media($media_m-l) {
      .HardwareDetailBlock-inner {
        @include media($media_m-l) {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }

  &--imageAlignTop {
    @include media($media_m-l) {
      .HardwareDetailBlock-text {
        margin-bottom: $global-padding;
        text-align: center;
      }

      .HardwareDetailBlock-image {
        margin-bottom: $global-padding;
        order: -1;
      }
    }
  }

  &--imageAlignRight {}

  &--imageAlignBottom {
    @include media($media_m-l) {
      .HardwareDetailBlock-text {
        text-align: center;
        margin-bottom: $global-padding;
      }
    }
  }

  &--imageAlignLeft {

    @include media($media_m-l) {
      .HardwareDetailBlock-image {
        order: -1;
      }
    }
  }

}
