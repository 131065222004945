/** @define MyAvatarSelector */
$componentClass: '.MyAvatarSelector';

.MyAvatarSelector {
  .TextHeadline {
    text-align: center;
    margin-bottom: $global-padding;
  }

  &-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-item {
    @include media($media_s-m) {
      width: 33%;
      text-align: center;
    }

    & > button {
      position: relative;
      width: rem-calc(75);
      height: rem-calc(75);
      background: $gradient-primary;
      background-size: 100%;
      background-position: center;
      border-radius: 100%;
      margin: $global-padding * 0.25;

      svg {
        width: rem-calc(75);
        height: rem-calc(75);
      }
      @include media($media_m-l) {
        width: rem-calc(55);
        height: rem-calc(55);

        svg {
          width: rem-calc(55);
          height: rem-calc(55);
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: inherit;
        height: inherit;
        border: 0 solid rgba($color-orange, 0);
        border-radius: 100%;
        transition: border $timing-duration * 0.75 linear $timing-duration * 0.25;
      }

      &::after {
        content: '';
        position: absolute;
        vertical-align: middle;
        right: 0.5em;
        bottom: 0.5em;
        width: 0;
        height: 0;
        background-position: top center;
        background-repeat: no-repeat;
        border-radius: 100%;
        border: 0 solid $color-orange;
        background-color: $color-white;
        transform: translate(50%, 50%);
        transition: width $timing-duration $timing-easing, height $timing-duration $timing-easing, border-width $timing-duration $timing-easing;
      }
    }

    &.is-active {
      & > button {
        &::before {
          border: 4px solid rgba($color-orange, 1);
          transition: border $timing-duration * 0.75 $timing-easing;
        }

        &::after {
          width: 1em;
          height: 1em;
          background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="8" viewBox="0 0 39.56 29.99"><polygon fill="#{$color-orange}" stroke="#{$color-orange}" stroke-width="2" points="10.56 29.99 0 19.42 2.82 16.6 10.66 24.43 36.84 0 39.56 2.92 10.56 29.99"/></svg>');
          background-position: center center;
          background-repeat: no-repeat;
          border-radius: 100%;
          border-width: 2px;
          background-color: $color-white;
        }
      }
    }
  }
}
