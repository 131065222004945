/** @define SimTypeToggle */
$componentClass: '.SimTypeToggle';

#{$componentClass} {
  .FieldRadio-label::before {
    margin-top: rem-calc(4);
    display: inline-block;
    vertical-align: top;
  }

  #{$componentClass}Label-customLabel {
    display: inline-block;
    padding-right: rem-calc(10);
  }
  #{$componentClass}Label-customLabelHint {
    white-space: initial;
    font-size: rem-calc(14);
  }
}
