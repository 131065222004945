$componentClass: '.PromotionCodeForm';
.PromotionCodeForm {

  &-content {
    display: flex;
    align-items: center;
  }

  .TextLink {
    flex-shrink: 0;
  }

  @include media($media_s) {
    &-content {
      flex-direction: column;
    }

    &.is-active {
      .TextLink {
        margin-top: $global-padding * .5;
        margin-left: auto;
      }
      .FieldInput {
        width: 100%;
      }
      #{$componentClass}-content {
        align-items: stretch;
      }
    }
  }

  @include media($media_m-l) {
    &-content {
      .TextLink--asButton {
        margin-left: $global-padding * .5;
      }
    }
  }

  &--asDialog {
    #{$componentClass}-content {
      flex-direction: column;
    }
    &.is-active {
      .TextLink {
        margin-top: $global-padding * .5;
        margin-left: auto;
      }

      .TextLink--asButton {
        width: 100%;
      }
      .FieldInput {
        width: 100%;
      }
      #{$componentClass}-content {
        align-items: stretch;
      }
    }
  }

  &.is-active {
    #{$componentClass}-content {
      align-items: flex-start;
      .TextLink--asButton {
        height: rem-calc(48);
        line-height: 1.5em;
      }
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass}-content {
    .FieldInput--themeDefault .FieldInput-input {
      border-color: $color-gray-lighter-darkmode;
      color: $color-white-darkmode;
      + .FieldInput-label {
        color: $color-gray-lighter-darkmode;
      }
      &:focus {
        border-color: $color-white-darkmode;
      }
    }
    .FieldInput--error.FieldInput--themeDefault {
      .FieldInput-input {
        border-color: $color-red;
        + .FieldInput-label {
          color: $color-red;
        }
      }
    }
  }
}
