.CaptchaService {
  &-image {
    background-color: $color-gray15;
    margin: 0;
  }

  .FieldInput-formOptional, .FieldTextarea-formOptional {
    display: none;
  }

  &-inputFieldWrapper {
    max-width: 455px;
    position: relative;
    .FieldInput-input{
      width: 100%;
    }
    @include media($media_s-m) {
      max-width: 100%;
    }
  }
}
