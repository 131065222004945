/** @define MediaImage */
.MediaImage {
  display: inline-block;
  img{
    max-width: 100%;
  }
  &.is-lazy {
    opacity: 0;
    &.is-loaded {
      opacity: 1;
      transition: opacity 750ms ease-out;
    }
  }
}
