$componentClass: '.SimReplacementFormConfirm';
.SimReplacementFormConfirm {

  .FormWrapper .FormWrapper {
    margin-top: $global-padding;
    margin-bottom: 0;
  }

  .TextUnit {
    font-size: 1.2em;
    line-height: inherit;
  }

  .MediaImage {
    width: 50%;
    img { width: 100%; }
    @include media($media_m-l) {
      width: rem-calc(130);
    }
  }

  .FormWrapper .FormWrapper:last-of-type {
    .TextDataList {
      > *:nth-last-child(1),
      > *:nth-last-child(2) {
        color: $color-gray100;
      }
    }
  }

  @include media($media_m-l) {
    .FormWrapper-container {
      width: 75%;
    }
    .FormWrapper .FormWrapper:last-of-type {
      .TextDataList {
        width: 80%;
        > *:nth-last-child(3),
        > *:nth-last-child(4) {
          margin-top: $global-padding;
        }
      }
    }
  }


  &-header {
    @include media($media_m-l) {
      display: flex;
      .MediaImage {
        padding-right: $global-padding * .5;
      }
    }
  }

  &-link {
    margin-left: auto;
    @include media($media_s) {
      margin-top: $global-padding * .5;
    }
  }
}
