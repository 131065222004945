/** @define AdConsentUpdateForm */

.AdConsentUpdateForm {
  &-smallCheckboxLabel {
    .FieldCheckbox-labelInner p {
      font-size: $copy_font-size;
      letter-spacing: 0.28px;
      line-height: 24px;
    }
    .FieldCheckbox-labelInner {
      small {
        font-size: 11px;
        line-height: 18px;
      }
    }
    .FieldCheckbox-formOptional {
      display: none;
    }
  }
}
