/** @define CheckoutCartLink */
$componentClass: '.CheckoutCartLink';
.CheckoutCartLink {
  display: inline-block;
  position: relative;
  cursor: pointer;

  .TextLink {
    display: inline-block;

    .Icon {
      display: flex;
    }

    &:after {
      display: none;
    }
  }

  &.u-elementCountAfter {
    &::after {
      position: absolute;
      z-index: 1;
      top: $NavMain_height * .5;
      left: 0;
      transform: translate(50%, -100%);
      pointer-events: none;
    }
  }
}
