/** @define TextHeadlineCopyGroup */
.TextHeadlineCopyGroup{
  position: relative;
  text-align: left;

  > .TextHeadline {
    margin-bottom: $global-padding * .5;
  }

  > .TextCopy {
    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }

  &--alignCenter {
    text-align: center
  }
  &--alignRight {
    @include media($media_m-l) {
      text-align: right;
    }
  }
}
