/** @define BlogList */
$componentClass: '.BlogListItem';
.BlogListItem {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &-ctaOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &-image {
    height: rem-calc(270);
    margin-bottom: $global-padding;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  &-tags {
    margin-bottom: $global-padding * .25;
    > *:not(:first-child) {
      margin-left: rem-calc(5);
    }
    .TextLink {
      line-height: 1;
    }
  }

  &-headline {
    margin-bottom: $global-padding * .25;
    .TextHeadline {
      color: inherit;
    }
  }

  &-tags {
    color: $color-primary;
  }

  &-date {
    color: $color-gray50;
    margin-top: auto;
  }

  &-link {
    @include DefaultTextLink();
    color: $color-black;
    &:hover, &:focus {
      color: $color-primary;
    }
  }

  &-inner {
    height: 100%;
  }

  &:not(#{$componentClass}--featured) {
    #{$componentClass}-inner {
      display: flex;
      flex-direction: column;
    }

    #{$componentClass}-content {
      display: flex;
      flex-direction: column;
    }

    #{$componentClass}-image {
      .MediaImage {
        height: 100%;
        text-align: center;
        img {
          width: auto;
          height: 100%;
          max-width: 100%;
        }
      }
    }

    @supports (object-fit: cover){
      #{$componentClass}-image {
        .MediaImage img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &--featured {
    height: rem-calc(550);
    color: $color-white;

    #{$componentClass}-link {
      color: $color-white;
      &:hover {
        color: $color-primary;
      }
    }

    #{$componentClass}-inner {
      display: flex;
      flex-direction: column;
      background: linear-gradient(to bottom, rgba(0,0,0,0) 30%, rgba(0,0,0,.9) 100%);
    }

    #{$componentClass}-content {
      position: relative;
      max-width: $max-width;
      margin-top: auto;

      @include media($media_s) {
        padding: 0 $global-padding $global-padding * 2;
      }
      @include media($media_m-l) {
        padding: 0 $global-padding * 2 $global-padding * 2;
      }
      @include media($media_l) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
