/**
 * Display utilities
*/
.u-relative { @include position; }
.u-absolute { @include position('absolute'); }
.u-sticky { @include position('sticky'); }

.u-block { @include displayBlock; }
.u-inlineBlock { @include displayInlineBlock; }
.u-inline { @include displayInline; }
.u-flex { @include displayFlex; }
.u-row { @include displayRow; }

.u-hidden { @include displayHidden; }
.u-hiddenVisually { @include displayHiddenVisually; }

.u-dirRow { @include flexDirection($direction: 'row'); }
.u-dirRowReverse { @include flexDirection($direction: 'row-reverse'); }
.u-dirColumn { @include flexDirection($direction: 'column'); }
.u-dirColumnReverse { @include flexDirection($direction: 'column-reverse'); }

.u-justifyStart { @include flexJustifyContent($position: 'flex-start'); }
.u-justifyEnd { @include flexJustifyContent($position: 'flex-end'); }
.u-justifyBetween { @include flexJustifyContent($position: 'space-between'); }
.u-justifyAround { @include flexJustifyContent($position: 'space-around'); }
.u-justifyCenter { @include flexJustifyContent($position: 'center'); }
.u-justifyEvenly { @include flexJustifyContent($position: 'evenly'); }

.u-itemsStart { @include flexAlignItems($position: 'flex-start'); }
.u-itemsEnd { @include flexAlignItems($position: 'flex-end'); }
.u-itemsCenter { @include flexAlignItems($position: 'center'); }
.u-itemsBaseline { @include flexAlignItems($position: 'baseline'); }

.u-contentStart { @include flexAlignContent($position: 'flex-start'); }
.u-contentEnd { @include flexAlignContent($position: 'flex-end'); }
.u-contentBetween { @include flexAlignContent($position: 'space-between'); }
.u-contentAround { @include flexAlignContent($position: 'space-around'); }
.u-contentCenter { @include flexAlignContent($position: 'center'); }

.u-grow { flex-grow: 1; }
.u-shrink { flex-shrink: 1; }
.u-noGrow { flex-grow: 0; }
.u-noShrink { flex-shrink: 0; }
.u-selfStart { align-self: flex-start; }
.u-selfEnd { align-self: flex-end; }
.u-selfCenter { align-self: center; }
.u-selfBaseline { align-self: baseline; }

@include media($media_s) {
  .u-sBlock { @include displayBlock; }
  .u-sInlineBlock { @include displayInlineBlock; }
  .u-sInline { @include displayInline; }
  .u-sFlex { @include displayFlex; }
  .u-sRow { @include displayRow; }
  .u-sSticky { @include position('sticky'); }
  .u-sRelative { @include position; }
  .u-sAbsolute { @include position('absolute'); }

  .u-sHidden { @include displayHidden; }
  .u-sHiddenVisually { @include displayHiddenVisually; }

  .u-sDirRow { @include flexDirection; }
  .u-sDirRowReverse { @include flexDirection($direction: 'row-reverse'); }
  .u-sDirColumn { @include flexDirection($direction: 'column'); }
  .u-sDirColumnReverse { @include flexDirection($direction: 'column-reverse'); }

  .u-sJustifyStart { @include flexJustifyContent($position: 'flex-start'); }
  .u-sJustifyEnd { @include flexJustifyContent($position: 'flex-end'); }
  .u-sJustifyBetween { @include flexJustifyContent($position: 'space-between'); }
  .u-sJustifyAround { @include flexJustifyContent($position: 'space-around'); }
  .u-sJustifyCenter { @include flexJustifyContent($position: 'center'); }

  .u-sItemsStart { @include flexAlignItems($position: 'flex-start'); }
  .u-sItemsEnd { @include flexAlignItems($position: 'flex-start'); }
  .u-sItemsCenter { @include flexAlignItems($position: 'center'); }
  .u-sItemsBaseline { @include flexAlignItems($position: 'baseline'); }

  .u-sContentStart { @include flexAlignContent($position: 'flex-start'); }
  .u-sContentEnd { @include flexAlignContent($position: 'flex-start'); }
  .u-sContentBetween { @include flexAlignContent($position: 'space-between'); }
  .u-sContentAround { @include flexAlignContent($position: 'space-around'); }
  .u-sContentCenter { @include flexAlignContent($position: 'center'); }

  .u-sGrow { flex-grow: 1; }
  .u-sShrink { flex-shrink: 1; }
  .u-sNoGrow { flex-grow: 0; }
  .u-sNoShrink { flex-grow: 0; }
  .u-sSelfStart { align-self: flex-start; }
  .u-sSelfEnd { align-self: flex-end; }
  .u-sSelfCenter { align-self: center; }
  .u-sSelfBaseline { align-self: baseline; }
}

@include media($media_m) {
  .u-mBlock { @include displayBlock; }
  .u-mInlineBlock { @include displayInlineBlock; }
  .u-mInline { @include displayInline; }
  .u-mFlex { @include displayFlex; }
  .u-mRow { @include displayRow; }
  .u-mSticky { @include position('sticky'); }
  .u-mRelative { @include position; }
  .u-mAbsolute { @include position('absolute'); }

  .u-mHidden { @include displayHidden; }
  .u-mHiddenVisually { @include displayHiddenVisually; }

  .u-mDirRow { @include flexDirection; }
  .u-mDirRowReverse { @include flexDirection($direction: 'row-reverse'); }
  .u-mDirColumn { @include flexDirection($direction: 'column'); }
  .u-mDirColumnReverse { @include flexDirection($direction: 'column-reverse'); }

  .u-mJustifyStart { @include flexJustifyContent($position: 'flex-start'); }
  .u-mJustifyEnd { @include flexJustifyContent($position: 'flex-end'); }
  .u-mJustifyBetween { @include flexJustifyContent($position: 'space-between'); }
  .u-mJustifyAround { @include flexJustifyContent($position: 'space-around'); }
  .u-mJustifyCenter { @include flexJustifyContent($position: 'center'); }

  .u-mItemsStart { @include flexAlignItems($position: 'flex-start'); }
  .u-mItemsEnd { @include flexAlignItems($position: 'flex-start'); }
  .u-mItemsCenter { @include flexAlignItems($position: 'center'); }
  .u-mItemsBaseline { @include flexAlignItems($position: 'baseline'); }

  .u-mContentStart { @include flexAlignContent($position: 'flex-start'); }
  .u-mContentEnd { @include flexAlignContent($position: 'flex-start'); }
  .u-mContentBetween { @include flexAlignContent($position: 'space-between'); }
  .u-mContentAround { @include flexAlignContent($position: 'space-around'); }
  .u-mContentCenter { @include flexAlignContent($position: 'center'); }

  .u-mGrow { flex-grow: 1; }
  .u-mShrink { flex-shrink: 1; }
  .u-mNoGrow { flex-grow: 0; }
  .u-mNoShrink { flex-grow: 0; }
  .u-mSelfStart { align-self: flex-start; }
  .u-mSelfEnd { align-self: flex-end; }
  .u-mSelfCenter { align-self: center; }
  .u-mSelfBaseline { align-self: baseline; }
}

@include media($media_s-m) {
  .u-smBlock { @include displayBlock; }
  .u-smInlineBlock { @include displayInlineBlock; }
  .u-smInline { @include displayInline; }
  .u-smFlex { @include displayFlex; }
  .u-smRow { @include displayRow; }
  .u-smSticky { @include position('sticky'); }
  .u-smRelative { @include position; }
  .u-smAbsolute { @include position('absolute'); }

  .u-smHidden { @include displayHidden; }
  .u-smHiddenVisually { @include displayHiddenVisually; }

  .u-smDirRow { @include flexDirection; }
  .u-smDirRowReverse { @include flexDirection($direction: 'row-reverse'); }
  .u-smDirColumn { @include flexDirection($direction: 'column'); }
  .u-smDirColumnReverse { @include flexDirection($direction: 'column-reverse'); }

  .u-smJustifyStart { @include flexJustifyContent($position: 'flex-start'); }
  .u-smJustifyEnd { @include flexJustifyContent($position: 'flex-end'); }
  .u-smJustifyBetween { @include flexJustifyContent($position: 'space-between'); }
  .u-smJustifyAround { @include flexJustifyContent($position: 'space-around'); }
  .u-smJustifyCenter { @include flexJustifyContent($position: 'center'); }

  .u-smItemsStart { @include flexAlignItems($position: 'flex-start'); }
  .u-smItemsEnd { @include flexAlignItems($position: 'flex-start'); }
  .u-smItemsCenter { @include flexAlignItems($position: 'center'); }
  .u-smItemsBaseline { @include flexAlignItems($position: 'baseline'); }

  .u-smContentStart { @include flexAlignContent($position: 'flex-start'); }
  .u-smContentEnd { @include flexAlignContent($position: 'flex-start'); }
  .u-smContentBetween { @include flexAlignContent($position: 'space-between'); }
  .u-smContentAround { @include flexAlignContent($position: 'space-around'); }
  .u-smContentCenter { @include flexAlignContent($position: 'center'); }

  .u-smGrow { flex-grow: 1; }
  .u-smShrink { flex-shrink: 1; }
  .u-smNoGrow { flex-grow: 0; }
  .u-smNoShrink { flex-grow: 0; }
  .u-smSelfStart { align-self: flex-start; }
  .u-smSelfEnd { align-self: flex-end; }
  .u-smSelfCenter { align-self: center; }
  .u-smSelfBaseline { align-self: baseline; }
}

@include media($media_m-l) {
  .u-mlBlock { @include displayBlock; }
  .u-mlInlineBlock { @include displayInlineBlock; }
  .u-mlInline { @include displayInline; }
  .u-mlFlex { @include displayFlex; }
  .u-mlRow { @include displayRow; }
  .u-mlSticky { @include position('sticky'); }
  .u-mlRelative { @include position; }
  .u-mlAbsolute { @include position('absolute'); }

  .u-mlHidden { @include displayHidden; }
  .u-mlHiddenVisually { @include displayHiddenVisually; }

  .u-mlDirRow { @include flexDirection; }
  .u-mlDirRowReverse { @include flexDirection($direction: 'row-reverse'); }
  .u-mlDirColumn { @include flexDirection($direction: 'column'); }
  .u-mlDirColumnReverse { @include flexDirection($direction: 'column-reverse'); }

  .u-mlJustifyStart { @include flexJustifyContent($position: 'flex-start'); }
  .u-mlJustifyEnd { @include flexJustifyContent($position: 'flex-end'); }
  .u-mlJustifyBetween { @include flexJustifyContent($position: 'space-between'); }
  .u-mlJustifyAround { @include flexJustifyContent($position: 'space-around'); }
  .u-mlJustifyCenter { @include flexJustifyContent($position: 'center'); }

  .u-mlItemsStart { @include flexAlignItems($position: 'flex-start'); }
  .u-mlItemsEnd { @include flexAlignItems($position: 'flex-start'); }
  .u-mlItemsCenter { @include flexAlignItems($position: 'center'); }
  .u-mlItemsBaseline { @include flexAlignItems($position: 'baseline'); }

  .u-mlContentStart { @include flexAlignContent($position: 'flex-start'); }
  .u-mlContentEnd { @include flexAlignContent($position: 'flex-start'); }
  .u-mlContentBetween { @include flexAlignContent($position: 'space-between'); }
  .u-mlContentAround { @include flexAlignContent($position: 'space-around'); }
  .u-mlContentCenter { @include flexAlignContent($position: 'center'); }

  .u-mlGrow { flex-grow: 1; }
  .u-mlShrink { flex-shrink: 1; }
  .u-mlNoGrow { flex-grow: 0; }
  .u-mlNoShrink { flex-grow: 0; }
  .u-mlSelfStart { align-self: flex-start; }
  .u-mlSelfEnd { align-self: flex-end; }
  .u-mlSelfCenter { align-self: center; }
  .u-mlSelfBaseline { align-self: baseline; }
}

@include media($media_l) {
  .u-lBlock { @include displayBlock; }
  .u-lInlineBlock { @include displayInlineBlock; }
  .u-lInline { @include displayInline; }
  .u-lFlex { @include displayFlex; }
  .u-lRow { @include displayRow; }
  .u-lSticky { @include position('sticky'); }
  .u-lRelative { @include position; }
  .u-lAbsolute { @include position('absolute'); }

  .u-lHidden { @include displayHidden; }
  .u-lHiddenVisually { @include displayHiddenVisually; }

  .u-lDirRow { @include flexDirection; }
  .u-lDirRowReverse { @include flexDirection($direction: 'row-reverse'); }
  .u-lDirColumn { @include flexDirection($direction: 'column'); }
  .u-lDirColumnReverse { @include flexDirection($direction: 'column-reverse'); }

  .u-lJustifyStart { @include flexJustifyContent($position: 'flex-start'); }
  .u-lJustifyEnd { @include flexJustifyContent($position: 'flex-end'); }
  .u-lJustifyBetween { @include flexJustifyContent($position: 'space-between'); }
  .u-lJustifyAround { @include flexJustifyContent($position: 'space-around'); }
  .u-lJustifyCenter { @include flexJustifyContent($position: 'center'); }

  .u-lItemsStart { @include flexAlignItems($position: 'flex-start'); }
  .u-lItemsEnd { @include flexAlignItems($position: 'flex-start'); }
  .u-lItemsCenter { @include flexAlignItems($position: 'center'); }
  .u-lItemsBaseline { @include flexAlignItems($position: 'baseline'); }

  .u-lContentStart { @include flexAlignContent($position: 'flex-start'); }
  .u-lContentEnd { @include flexAlignContent($position: 'flex-start'); }
  .u-lContentBetween { @include flexAlignContent($position: 'space-between'); }
  .u-lContentAround { @include flexAlignContent($position: 'space-around'); }
  .u-lContentCenter { @include flexAlignContent($position: 'center'); }

  .u-lGrow { flex-grow: 1; }
  .u-lShrink { flex-shrink: 1; }
  .u-lNoGrow { flex-grow: 0; }
  .u-lNoShrink { flex-grow: 0; }
  .u-lSelfStart { align-self: flex-start; }
  .u-lSelfEnd { align-self: flex-end; }
  .u-lSelfCenter { align-self: center; }
  .u-lSelfBaseline { align-self: baseline; }
}
