/** @define MyESimInfoDialog */
$componentClass: '.MyESimInfoDialog';
.MyESimInfoDialog {
  max-height: 60vh;
  overflow-y: auto;

  .AccordionSection-label {
    text-align: left;
  }
}
