/** @define PresalesCancellationForm */
$componentClass: '.PresalesCancellationForm';
.PresalesCancellationForm {
  .TextInfo {
    display: flex;
    margin-left: rem-calc(8);
  }

  .FieldInput + .TextCopy {
      margin-bottom: 0;
  }

  .FieldInput-formOptional, .FieldTextarea-formOptional {
    display: none;
  }

  .FieldTextarea-label {
    font-size: rem-calc(16);
    color: inherit;
  }
}
