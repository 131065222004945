/** @define MyESimActivation */
$componentClass: '.MyESimActivation';
.MyESimActivation {
  background: $gradient-secondary;
  border-radius: $border-radius-m;
  margin: rem-calc(16);
  padding: rem-calc(32);
  position: relative;

  &-inner {
    @include outerContainer($max-width);
  }

  &-section {
    position: relative;
    padding: $global-padding;
    background-color: $color-white;
    border-radius: $border-radius-m;

    .Tag {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      left: 0.75rem;
      cursor: pointer;

      .Tag-copy {
        text-transform: initial;
      }
    }
  }

  &-visibilityIcon,
  &-copyIcon {
    cursor: pointer;

    .Icon {
      display: flex;
      align-items: center;
    }
  }

  &-visibilityIcon {
    .Icon {
      width: $global-padding;
      height: $global-padding;
    }
  }

  &-infoIcon {
    .TextLink-icon {
      width: $copy_font-size;
    }

    .Icon-base,
    .Icon-highlight {
      fill: $color-primary;
    }
  }

  &-copyIcon {
    .Icon {
      width: rem-calc(20);
      height: rem-calc(20);
    }
  }

  &-section--push {
    .is-withoutPush & {
      display: none;
    }

    @include media($media_s) {
      order: 1;
    }
  }

  &-section--manually {
    #{$componentClass}-sectionBottom {
      .TabsWrapper {
        border-top: 2px solid $color-gray15;
      }

      .TabsWrapper-labelButton {
        font-weight: $font-weight-bold;
      }

      #{$componentClass}-dataText {
        > span:first-of-type {
          /*text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;*/
        }
      }

      .AccordionSection--centered {
        display: flex;
        flex-direction: column-reverse;

        .AccordionSection-label {
          padding-right: 0;

          &::after {
            background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path class="Icon-base" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
            display: block;
            margin: 0 auto;
            position: relative;
          }
        }
      }
    }

    @include media($media_s) {
      order: 1;
    }
  }

  &-section--qr {
    #{$componentClass}-sectionTop {
      border-bottom: 2px solid $color-gray15;
    }

    @include media($media_s) {
      order: 3;
    }
  }

  .TextMessage {
    word-break: break-word;
    margin-bottom: $global-padding;
  }
}
