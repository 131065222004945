/** @define GiveMeYourPriceChatDialog */
$componentClass: '.GiveMeYourPriceChatDialog';
.GiveMeYourPriceChatDialog {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #141414;

  &-header {
    text-align: center;
    padding: $global-padding * .5;
    border-bottom: 1px solid $color-gray100;
    flex-grow: 0;
    z-index: 999;
  }

  &-chat {
    margin-top: auto;
    padding: 0 $global-padding * .5 $global-padding * .5;
    .Chat-footer {
      position: relative;
      flex-shrink: 0;
      background: #141414;
      &::before {
        content: '';
        position: absolute;
        height: $global-padding * 1.1;
        top: -$global-padding;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba(#141414,0) 0%, rgba(#141414, 1) 100%);
      }
    }
  }
}
