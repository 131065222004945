/** @define FooterSupport */
.FooterSupport{

    background: $color-gray15;
    padding: $global-padding $global-padding*0.5;

    @include media($media_l) {
        padding: $global-padding 0;
    }

    #{&}-wrapper{
        @include outerContainer($max-width);

        @include media($media_l) {
            display: flex;
        }
    }

    #{&}-icon{
        display: block;
        @include media($media_l) {
            display: inline-block;
            vertical-align: top;
        }
    }

    #{&}-content{
        display: inline-block;
        padding-left: $global-padding*0.25;

        .Headline--m{
            margin-top: -0.1em;
        }
    }

    #{&}-item{
        width: 100%;
        text-align: center;

        @include media($media_s-m) {
            @include borderDashed($top: true);
            &:first-of-type{
                @include borderDashed($top: false);
            }
        }

        @include media($media_l) {
            width: 33.3333%;
            display: flex;
            text-align: left;
            @include borderDashed($left: true);

            &:last-of-type{
                @include borderDashed($left: true, $right: true);
            }
        }


        padding: $global-padding*0.5;
    }
}
