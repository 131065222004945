/** @define ContentGroup */
$componentClass: '.ContentGroup';
.ContentGroup {
  @include globalPadding($topRatio: 2, $bottomRatio: 2 );
  &-inner {
    @include outerContainer($max-width);
  }

  @include media($media_s) {
    .ContentGroupItem:not(:last-child) {
      margin-bottom: $global-padding * 2;
    }
  }

  &--cols2,
  &--cols3{
    @include media($media_m-l) {
      #{$componentClass}-inner {
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
      }

      .ContentGroupItem {
        display: flex;
        margin-bottom: $global-padding * 2;
      }

    }
  }

  &--cols1 {
    @include media($media_m-l) {
      .ContentGroupItem:not(:last-child) {
        margin-bottom: $global-padding * 2;
      }
    }
  }

  &--cols2 {
    @include media($media_m-l) {
      .ContentGroupItem {
        width: calc(50% - #{$global-padding * .5});
        &:nth-child(odd) {
          margin-right: $global-padding * .5;
        }
        &:nth-child(even) {
          margin-left: $global-padding * .5;
        }

        &:nth-child(2n+1):nth-last-child(-n+2),
        &:nth-child(2n+1):nth-last-child(-n+2) ~ .ContentGroupItem {
          margin-bottom: 0;
        }

        // if total items uneven then render the first item 100%
        &:nth-child(1):nth-last-child(1),
        &:nth-child(1):nth-last-child(3),
        &:nth-child(1):nth-last-child(5),
        &:nth-child(1):nth-last-child(7),
        &:nth-child(1):nth-last-child(9),
        &:nth-child(1):nth-last-child(11),
        &:nth-child(1):nth-last-child(13) {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  &--cols3 {
    @include media($media_m-l) {
      .ContentGroupItem {
        width: calc(33.33333% - #{$global-padding * .68});
        &:nth-child(3n+1) {
          margin-right: $global-padding * .5;
        }
        &:nth-child(3n+3) {
          margin-left: $global-padding * .5;
        }
        &:nth-child(3n+2):nth-child(3n+2) {
          margin-left: $global-padding * .5;
          margin-right: $global-padding * .5;
        }

        &:nth-child(3n+1):nth-last-child(-n+3),
        &:nth-child(3n+1):nth-last-child(-n+3) ~ .ContentGroupItem {
          margin-bottom: 0;
        }
      }
    }
  }

  &--themeDark {
    color: $color-white;
  }

  &--themeLight {
    color: $color-black;
  }

  &--adjacent {
    padding: 0;
  }
}

.GlobalSection--expanded .ContentGroup-inner {
  @include globalPadding($vertical: false);
}
