/** @define FieldTextarea */
$componentClass: '.FieldTextarea';
.FieldTextarea {
  width: 100%;

  .FieldMessenger-hint {
    color: $color-gray-dark;
  }

  &-field {
    position: relative;
    &::after {
      content: '';
      position: absolute;
    }
  }

  &-input{
    position: relative;
    display: block;
    padding: $global-padding * .25;
    width: 100%;
    border: 1px solid $color-gray50;
  }

  &-label {
    color: $color-gray100;
    font-size: $copy-secondary_font-size;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }

  &-label + &-field {
    margin-top: $global-padding * .5;
  }

  &-border {
    position: absolute;
    height: rem-calc(2);
    bottom: 0;
    left: 0;
    right: 0;
    transition:all $timing-duration $timing-easing;

    &:before, &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(100%);
      margin-top:-1.5em;
      border-bottom: 1px solid $color-gray50;
    }

    &:after{
      width: 0;
      left: 50%;
      border-bottom: 3px solid $color-primary;
      transition:all $timing-duration $timing-easing;
    }
  }



  &--themeCompact {
    #{$componentClass}-input{
      font-weight: $font-weight-bold;
    }

    #{$componentClass}-input:focus + #{$componentClass}-border {
      &:after {
        width: 100%;
        left: 0;
      }
    }
  }

  &--themeDefault {
    #{$componentClass}-border {
      display: none;
    }
    #{$componentClass}-input{
      padding: rem-calc(13) rem-calc(15);
      border-color: $color-gray100;
      border-radius: $border-radius-s;
      transition: border-color $timing-duration $timing-easing;
      &:focus {
        border-color: $color-black;
      }
    }
  }

  &--inverted {
    #{$componentClass}-input {
      color: $color-white;
    }

    &#{$componentClass}--themeDefault {
      #{$componentClass}-input {
        border-color: $color-gray100;
        &:focus {
          border-color: $color-gray50;
        }
      }
    }
  }

  &--error{
    &#{$componentClass}--themeCompact {
      #{$componentClass}-input + #{$componentClass}-border {
        color: $color-red;
        &:before,
        &:after {
          left: 0;
          border-color: $color-red;
        }
      }
    }

    &#{$componentClass}--themeDefault {
      #{$componentClass}-input{
        border-color: $color-red;
      }
      #{$componentClass}-field:after {
        right: rem-calc(15);
        top:  rem-calc(15);
        width: rem-calc(11);
        height: rem-calc(11);
        background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.5 14.5"><path fill="#{$color-red}" d="M14.09,13.29a0.59,0.59,0,0,1,0,.8,0.58,0.58,0,0,1-.8,0L7.25,8l-6,6.05a0.58,0.58,0,0,1-.8,0,0.59,0.59,0,0,1,0-.8l6-6-6-6a0.59,0.59,0,0,1,0-.8,0.59,0.59,0,0,1,.8,0l6,6.05,6-6a0.59,0.59,0,0,1,.8,0,0.59,0.59,0,0,1,0,.8L8,7.25Z"/></svg>');
        background-repeat: no-repeat;
        background-position: right bottom;
      }
    }
  }

  &--dirty {}

  &--valid {

    #{$componentClass}-field:after {
      width: rem-calc(15);
      height: rem-calc(11);
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.56 29.99"><polygon fill="#{$color-success}" points="10.56 29.99 0 19.42 2.82 16.6 10.66 24.43 36.84 0 39.56 2.92 10.56 29.99"/></svg>');
      background-repeat: no-repeat;
      background-position: right bottom;
    }

    &#{$componentClass}--themeCompact {
      #{$componentClass}-field:after {
        right: $global-padding * .1;
        bottom:  $global-padding * .25;
      }
    }

    &#{$componentClass}--themeDefault {
      #{$componentClass}-field:after {
        right: rem-calc(15);
        top:  rem-calc(15);
      }
    }
  }
}


