/** @define TariffItem */

$componentClass: '.TariffItemCompact';
.TariffItemCompact {
  width: 100%;
  @include media($media_s) {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    #{$componentClass}-content {
      width: 60%;
    }
  }
  &-header {
    overflow: hidden;
    width: 100%;
    @include media($media_s) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-bottom: $global-padding * 0.5;
      #{$componentClass}-priceMonthly {
        text-align: right;
        align-self: flex-end;
        width: 35%;
      }

      #{$componentClass}-title {
        width: 65%;
      }
    }
  }
  &-content {
    @include media($media-m-l) {
      position: relative;
    }
    > .Callout--badge {
      position: absolute;
      right: 3%;
      top: 50%; //rem-calc(-15);
      transform: scale(0.75) translateY(-50%);
      transform-origin: right top;

      @include media($media-s) {
        top: 0;
        right: 30%;
        transform: scale(0.75);
        transform-origin: right top;
      }
    }
  }
  &-name {
    overflow: hidden;
  }
  &-title {
    overflow: hidden;
  }

  #{$componentClass}-headline {
    margin-bottom: 0.25em;
  }

  #{$componentClass}-subcontent {
    align-items: center;
  }

  @include media($media_s-m) {
    #{$componentClass}-type {
      margin-bottom: 0.15em;
    }
  }

  @include media($media_m-l) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: nowrap;
    #{$componentClass}-content,
    #{$componentClass}-title,
    #{$componentClass}-buttons,
    #{$componentClass}-priceMonthly {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //align-content: center;
    }
    #{$componentClass}-priceMonthly {
      margin-right: $global-padding * 0.5;
    }
    #{$componentClass}-header,
    #{$componentClass}-subcontent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      flex-wrap: nowrap;
    }
    #{$componentClass}-header {
      padding-right: $global-padding * 0.5;
      width: 33%;
    }
    #{$componentClass}-content,
    #{$componentClass}-subcontent {
      width: 37.5%;
      @include borderDashed($color-gray100, false, false, false, true);
    }
    #{$componentClass}-content {
      padding: 0 ($global-padding * 0.5);
    }
    #{$componentClass}-subcontent {
      padding: 0 0 0 ($global-padding * 0.5);
    }
    #{$componentClass}-link {
      text-align: center;
      margin-bottom: rem-calc(5);
    }
  }
  @include media($media_l) {
    #{$componentClass}-type,
    #{$componentClass}-name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //align-content: center;
    }
    #{$componentClass}-title {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-content: stretch;
      flex-wrap: nowrap;
      width: 100%;
    }
    #{$componentClass}-type {
      width: 35%;
      padding-right: $global-padding * 0.5;
    }
    #{$componentClass}-name {
      width: 65%;
      padding-left: $global-padding * 0.5;
      @include borderDashed($color-gray100, false, false, false, true);
    }
  }
}
