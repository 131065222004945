.TextDataList {
  & > dt, & > dd {
    font-weight: inherit;
    overflow:hidden;

    @include media($media_m-l) {
      &:not(:first-of-type) {
        margin-top:$global-padding*0.25;
      }
    }

    > p, > div {
      text-overflow: inherit;
      white-space: inherit;
    }
    > * {
      display: inline-block;
    }
    .TextCopy, .TextHeadline {
      margin-top: 0;
    }
  }
  & > dt {
    vertical-align: top;
    width:30%;
    @include media($media_s) {
      width:100%;
    }
  }
  & > dd {
    margin-left:5%;
    width:65%;
    @include media($media_s) {
      width:100%;
    }
  }

  &--ellipses {
    & > dt, & > dd {
      > p, > div {
        text-overflow: ellipsis;
      }
    }

    @include media($media_m-l) {
      & > dt, & > dd {
        > p, > div {
          white-space: nowrap;
        }
      }
    }

  }

  &--breakpoints {
    @include media($media_s) {
      & > dt, & > dd {
        display: block;
        margin-left: 0;
      }
      & > dt {
        margin-top:$global-padding * .5;
        color: $color-gray100;
        &::after {
          content: ':';
          display: inline-block;
          color: inherit;
        }
      }

      & > *:first-child,
      & > dd {
        margin-top: 0;
      }
    }
  }
}
