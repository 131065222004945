/** @define ContractRenewalOptionsDialog */
$componentClass: '.ContractRenewalOptionsDialog';
.ContractRenewalOptionsDialog {

  &-link + &-comparison.is-open {
    margin-top: $global-padding * .5;
  }

  &-comparison {
    display: flex;
    flex-wrap: wrap;
    background-color: $color-gray15;
    border: 1px solid $color-gray50;
    margin-bottom: $global-padding * .5;

    &:not(.is-open) {
      height: 0;
      overflow: hidden;
      padding: 0;
      border: none;
    }

    > * {
      width: 50%;
    }
  }

  &-item {
    &:first-child {
      border-right: 1px solid $color-gray50;
    }
    #{$componentClass}-headline {
      font-size: rem-calc(12);
      text-align: center;
      padding: $global-padding * .25;
      border-bottom: 1px solid $color-gray50;
    }

    #{$componentClass}-copy {
      font-size: rem-calc(12);
      padding: $global-padding * .5
    }
  }
}
