/** @define ProcessHeader */
$componentClass: '.ProcessHeader';
.ProcessHeader {
  background: $gradient-neutral;


  &-inner {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    @include media($media_s-m) {
      @at-root .GlobalHeader--poweredBy ~ .GlobalContent & > *:first-child .ProcessHeaderItem {
        padding-top: $global-padding;
      }
    }

    @include media($media_l) {
      @at-root .GlobalHeader--poweredBy ~ .GlobalContent & {
        padding-top: $global-padding * 0.5;
      }
    }
  }

  &-item {
    display: flex;
  }

  &-selector {
    display: none;
    position: absolute;
    left: 0;
    bottom: rem-calc(-10);
    width: 33.33333%;
    transition: transform $timing-duration;
    color: $gradient-primary;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: rem-calc(10);
      background: $gradient-primary;
    }

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;

      border-left: rem-calc(10) solid transparent;
      border-right: rem-calc(10) solid transparent;
      border-top: rem-calc(10) solid $color-orange-light;

      position: absolute;
      margin: auto;

      left: 0;
      right: 0;
      bottom: rem-calc(-9);
    }
  }

  @include media($media_l) {
    &-inner {
      @include outerContainer($max-width);
      flex-direction: row;
    }
    &-item {
      width: 33.33333%;

      &:nth-child(2) {
        padding-left: rem-calc(2);
        padding-right: rem-calc(1);
      }

      &:nth-child(3) {
        padding-left: rem-calc(1);
      }
    }
    .ServiceStickyContent & {
      padding-top: $global-padding;
      margin-bottom: $global-padding;
    }
    &.is-selectedFirst &-selector,
    &.is-selectedSecond &-selector {
      display: block;
    }
    &.is-selectedSecond &-selector {
      padding-right: rem-calc(1);
      padding-left: rem-calc(1.5);
      transform: translateX(100%);
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass} {
    background: $color-gray15;
  }
}

section.ProcessHeader-item:focus-within {
  outline: 2px solid black;
  z-index: 1;
}
