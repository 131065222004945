/** @define FooterMain */
.FooterMain{

    &-nav{
      @include outerContainer($max-width);
      @include globalPadding($topRatio: 2, $bottomRatio: 2);
    }

    &-meta{}

    &-legals{
      @include globalPadding($topRatio: 0, $bottomRatio: 0);
      color: $color-black;
      margin: 0 auto;
      background-color: $color-gray15;

      .AccordionSection-content,
      .AccordionSection-title {
        background-color: transparent;
      }
    }

    &--extraPadding {
      padding-bottom: $global-padding * 2.5;
    }
}
