/** @define DashboardStatus */
$componentClass: '.DashboardStatus';
.DashboardStatus {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > .TextHeadline {
    text-align: center;
    margin-bottom:  $global-padding * 1.5;
    @include media($media_s) {
      display: none;
    }
  }

  &-balance,
  &-flatrates {
    text-align: center;
  }

  &-consumptions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }

  &-consumption {
    text-align: center;
    flex-grow: 1;
    &:first-child:nth-last-child(3) {
      width: 100%;
    }

    &:nth-child(3):nth-last-child(2),
    &:nth-child(2):nth-last-child(2),
    &:nth-child(1):nth-last-child(4),
    &:nth-child(1):nth-last-child(2) {
      padding-right: $global-padding * .25;
    }

    &:nth-child(2):nth-last-child(3),
    &:nth-child(4):nth-last-child(1),
    &:nth-child(3):nth-last-child(1),
    &:nth-child(2):nth-last-child(1) {
      padding-left: $global-padding * .25;
    }

    &:nth-child(2):nth-last-child(3),
    &:nth-child(1):nth-last-child(4),
    &:nth-child(1):nth-last-child(3) {
      padding-bottom: $global-padding * .5;
    }
  }

  &-consumptionsIndividual {
    > :first-child {
      margin-top: rem-calc(25);
    }
  }

  &-consumptions + &-flatrates {
    position: relative;
    padding-top: $global-padding * 1.75;
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 1.375rem;
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ><polygon fill="#{$color-primary}" points="22,9 13,9 13,0 9,0 9,9 0,9 0,13 9,13 9,22 13,22 13,13 22,13"/></svg>');
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &-flatrate {
    text-align: center;
    margin-bottom: $global-padding * .5;
    &:last-child {
      margin-bottom: 0;
    }

    .TextHeadline {
      margin-bottom: $global-padding * .25;
    }
  }

  &-content {
    margin: 0 auto;
    > * + * {
      padding-top: $global-padding * .75;
      margin-top: $global-padding * .75;
      @include borderDashed($color: $color-gray100, $top: true);
    }

    @include media($media_s) {
      width: rem-calc(300);
    }
    @include media($media_m) {
      width: rem-calc(270);
    }
    @include media($media_l) {
      width: rem-calc(320);
    }
  }

  *:not(:first-child) + &-content {
    padding-top: $global-padding * .75;
    margin-top: $global-padding * .75;
    @include borderDashed($color: $color-gray100, $top: true);
  }

  &-balance {
    .TextUnit {
      display: block;
      font-size: $h-l_font-size;
      margin-bottom: $global-padding * .25;
    }
  }

  &-topup {
    padding: 0;
    border: none;
    > * + * {
      padding-top: $global-padding * .75;
      margin-top: $global-padding * .75;
      @include borderDashed($color: $color-gray100, $top: true);
    }

    > *:last-child {
      padding-bottom: $global-padding * .75;
      @include borderDashed($color: $color-gray100, $top: true, $bottom: true);
    }
  }

  &-topups {
    display: flex;
    text-align: center;
    margin-top: $global-padding * .5;
    justify-content: center;
    flex-flow: row-reverse wrap-reverse;
  }

  &-topupButton {
    width: 33.3333%;
    flex-grow: 0;
    padding: $global-padding * .5 $global-padding * .25 0;
    .TextLink {
      width: 100%;
      padding: rem-calc(3) rem-calc(2) rem-calc(1);
      box-sizing: border-box;
    }

    .TextLink-text {
      line-height: 1rem;
      > small {
        position: relative;
        display: block;
        width: 100%;
        font-size: 60%;
        text-transform: none;
        z-index: 1;
      }
    }
  }

  &-topupLink {
    .TextLink {
      width: 100%;
      text-align: left;
      @include headline($size: $h-xs_font-size, $line-height: $h-xs_line-height);
      text-transform: uppercase;
      padding-right: $global-padding * .5;
      margin: 0;
      box-sizing: border-box;
      &::after {
        content: '>';
        position: absolute;
        display: inline-block;
        width: $global-padding * .5;
        left: auto;
        right: 0;
        top: 0;
        bottom: auto;
        height: auto;
        color: $color-black;
        text-align: right;
        background: none;
        animation: none;
        transition: color $timing-duration $timing-easing;
      }

      &:hover {
        color: $color-orange;
        &::after {
          color: $color-orange;
          transition: color $timing-duration $timing-easing;
        }
      }
    }

    .TextLink + * {
      margin-top: $global-padding * .1;
    }

    .TextCopy {
      padding-right: $global-padding * .5;
    }
  }

  &-cumulativeConsumption {
    .TextHeadline {
      font-weight: $font-weight-light;
      margin-bottom: 0;
    }
    p:nth-of-type(2) {
      font-size: $h-xxxs_font-size
    }
    p:nth-of-type(5) {
      font-size: $h-xxxs_font-size
    }
  }

  &-cumulativeConsumptionMonth {
    font-family: $font-family-condensed;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    span:last-child {
      font-weight: $font-weight-bold;
    }
  }


  &[data-consumptions="2"],
  &[data-consumptions="3"],
  &[data-consumptions="4"] {
    #{$componentClass}-consumptions {
      margin-left: 50%;
      transform-origin: top center;
      @include media($media_s-m) {
        transform: translateX(-50%) scale(0.75);
        width: 418px;
        margin-left: 50%;
        .TextHeadline {
          font-size: 2.7rem;
          line-height: 2.8rem;
        }
        .TextCopy {
          font-size: 1rem;
        }
      }
      @include media($media_l) {
        transform: translateX(-50%) scale(0.85);
        width: 418px;
        .TextCopy {
          font-size: .9rem;
        }
      }
    }
  }

  &-link {
    padding-top: $global-padding;
    margin-top: $global-padding * .75;
    @include borderDashed($color: $color-gray100, $top: true);
    .TextLink {
      width: 100%;
      box-sizing: border-box;
    }
  }

  &[data-consumptions="1"],
  &[data-consumptions="2"] {
    #{$componentClass}-consumptions {
      min-height: rem-calc(158);
    }
  }

  &[data-consumptions="2"] {
    #{$componentClass}-consumptions + * {;
      @include media($media_s-m) {
        margin-top: -$global-padding;
      }
      @include media($media_l) {
        margin-top: -$global-padding * .1;
      }
    }
  }

  &[data-consumptions="3"],
  &[data-consumptions="4"] {
    #{$componentClass}-consumptions {
      min-height: rem-calc(316);
    }
    #{$componentClass}-consumptions + * {
      margin-top: -$global-padding * 3;
      @include media($media_l) {
        margin-top: -$global-padding * 1.5;
      }
    }
  }
}
