/** @define HardwareTariffsTabs */
$componentClass: '.HardwareTariffsTabs';

.HardwareTariffsTabs {
  position: relative;
  &-navigation {
    display: flex;
    margin-right: $global-padding * 1.3;
    font-size: $h-s_font-size;
    font-family: $subline_font-family;
    line-height: 30px;
    color: $color-black;
    &--active {
      border-bottom: 3px solid $color-orange-dark;
    }
    &--themeDark {
      color: $color-white;
    }
    &--themeLight {
      color: $color-black;
    }
    &--themeNone {
      color: $color-black;
    }

    &--withCheck {
      height: rem-calc(32);
      padding: rem-calc(6) rem-calc(16) rem-calc(6) rem-calc(6);
      margin-right: rem-calc(8);
      font-size: $copy_font-size;
      font-weight: $font-weight-bold;
      line-height: rem-calc(22);
      border-radius: 19.5px;
      color: $color-black;
      background-color: $color-gray15;
      &:hover {
        color: $color-black;
        background-color: $color-gray50;
      }

      .TextLink-text {
        display: flex;
        .Icon {
          margin-right: 8px;
        }
      }

      &:focus {
        outline: 2px solid $color-primary;
      }
    }
    &--withCheck#{$componentClass}-navigation--active {
      background: $gradient-primary !important;
      border-bottom: none;
    }
    &--withCheck#{$componentClass}-navigation--themeDark {
      color: $color-white;
      background-color: $color-gray-dark;
      &:hover {
        color: $color-white;
        background-color: $color-gray150;
      }
    }
    &--withCheck#{$componentClass}-navigation--themeLight {
      color: $color-white;
      background-color: $color-gray15;
      &:hover {
        color: $color-white;
        background-color: $color-gray50;
      }
    }
  }
  @include media($media_s) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
