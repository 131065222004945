/** @define Checkout Form */
$componentClass: '.FormWrapper';
.FormWrapper {
  *:not(.u-grid) {
    > .FormHeader {
      margin-bottom: $global-padding;
    }
  }
  .FormHeader {
    &:not(:first-of-type) {
      margin-top: $global-padding * 2;
    }

    + .FormHeader {
      margin-top: $global-padding;
    }

    @include media($media_s) {
      + .u-grid.u-sCollapse {
        margin-top: $global-padding;
      }
    }
  }

  &-headline {
    background-color: $color-gray150;
    padding: $global-padding * 0.5;
    margin-bottom: $global-padding * 0.5;
    border-radius: rem-calc(2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color:$color-gray200;

    .TextHeadline{
      line-height: 2rem;
      display: flex;
    }

    span {
      margin-right: 10px;
    }

    &--active {
      color: $color-inverted;
      background-color: $color-orange;
    }

    &--inactive {
      color: $color-gray50;
      background-color: $color-white;
      border: 2px solid $color-gray50;
    }
    &--editable {
      background-color: $color-gray50;
    }
  }

  &-orderNumber {
    color: $color-inverted;
    background-color: $color-gray100;
    border-radius: 50%;
    width: rem-calc(20);
    height: rem-calc(20);
    line-height: rem-calc(20);
    display: inline-flex;
    text-align: center;
    font-size: 0.75em;
    font-weight: $font-weight-bold;
    align-items: center;
    justify-content: center;
    align-self: center;

    &--active {
      color: $color-orange;
      background-color: $color-inverted;
    }

    &--inactive {
      color: $color-inverted;
      background-color: $color-gray50;
    }
  }

  &-headline h2{
    margin-bottom: 0;
    // display: inline;
  }

  .FormFooter {
    margin-top: $global-padding;
  }

  .FormManager {
    &--hardwareSelected {
      .FormFooter {
        margin-top: $global-padding * 5;
      }
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      &--hardwareSelected {
        .FormFooter {
          margin-top: $global-padding * 5;
        }
      }
    }
  }

  &-brandedContent {
    margin-bottom: $global-padding;
    @include media($media_m-l) {
      display: flex;
      align-items: center;
      > * + * {
        margin-left: $global-padding * .5;
      }
    }
    img {
      width: rem-calc(100);
    }
  }

  &-brandedFooter {
    margin-top: $global-padding;
  }
}
