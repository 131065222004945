/** @define ContractRenewalTariffInfo */
$componentClass: '.ContractRenewalTariffInfo';
.ContractRenewalTariffInfo {
  margin-bottom: rem-calc(20);
  .TextDataList {
    dt {
      width: 45%;
      text-align: left;
    }
    dd {
      width: 50%;
    }
  }
}
