/** @define GlobalHeader */
$componentClass: '.GlobalHeader';
.GlobalHeader {
  // inner is used twice. As a place holder and wrapper once our
  // clientside is initially rendered
  &-inner {
    min-height: $NavMain_height;
  }

  // hide placeholder once content is rendered
  > * + #{$componentClass}-inner {
    display: none;
  }
}
