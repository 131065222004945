/** @define MyPromoBannerRotation */
$componentClass: '.MyPromoBannerRotation';
.MyPromoBannerRotation{
  .GlobalSection-inner{
    max-width: rem-calc(520);
  }
  .Slider {
  /*  border: solid $color-gray150 1px; */
    overflow: hidden;
    img {
      width: 100%;
    }
    .Slider-pagination {
      position: absolute;
      width: 100%;
      bottom: $global-padding * .3;
      @include media($media_m-l) {
        bottom: $global-padding * .5;
      }
      text-align: center;
      .swiper-pagination-bullet {
        width: rem-calc(10);
        height: rem-calc(10);
        margin: 0 rem-calc(5);
        background: $color-gray100;
        &.swiper-pagination-bullet-active {
          background: $color-white;
        }
      }
    }
  }
}
