/** @define DashboardSummaryItem */
$componentClass: '.DashboardSummaryItem';
.DashboardSummaryItem {
  $spaceSmall: rem-calc(8);
  &:not(:last-child) {
    padding-bottom: $spaceSmall * 2;
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: $copy-secondary_font-size;

    > *:not(.sr-only) {
      position: relative;
    }
  }

  &-titleText {
    padding-right: $spaceSmall * 2.5;
    max-width: 66.666%;
  }

  &-titleInfoIcon {
    position: absolute;
    right: 0;
    top: $spaceSmall * 0.25;

    .TextLink-icon {
      width: $copy-secondary_font-size;

      .Icon-highlight,
      .Icon-base {
        fill: $color-primary;
      }
    }
  }

  &-volumePostfix,
  .TextUnit-postfix span {
    font-size: $label-small_font-size;
    width: $global-padding * 1.5;
    display: inline-block;
    text-align: right;
  }

  .TextUnit {
    font-family: $copy_font-family;
  }
}
