/* Text Format Utilities */
.u-headlineXXXL { @include headline($size: $h-xxxl_font-size, $line-height: $h-xxxl_line-height, $margin-top: 0, $margin-bottom: 0); }
.u-headlineXXL { @include headline($size: $h-xxl_font-size, $line-height: $h-xxl_line-height, $margin-top: 0, $margin-bottom: 0); }
.u-headlineXL { @include headline($size: $h-xl_font-size, $line-height: $h-xl_line-height, $margin-top: 0, $margin-bottom: 0); }
.u-headlineL { @include headline($size: $h-l_font-size, $line-height: $h-l_line-height, $margin-top: 0, $margin-bottom: 0); }
.u-headlineM { @include headline($size: $h-m_font-size, $line-height: $h-m_line-height, $margin-top: 0, $margin-bottom: 0); }
.u-headlineS { @include headline($size: $h-s_font-size, $line-height: $h-s_line-height, $margin-top: 0, $margin-bottom: 0); }
.u-headlineXS { @include headline($size: $h-xs_font-size, $line-height: $h-xs_line-height); }
.u-headlineXXS { @include headline($size: $h-xxs_font-size, $line-height: $h-xxs_line-height); }
.u-subline { @include headlineFormat($type: 'subline', $margin-top: 0, $margin-bottom: 0); }
.u-subheading { @include headlineFormat($type: 'subheading', $margin-top: 0, $margin-bottom: 0); }

.u-copy { @include copyTextFormat($margin-top: 0, $margin-bottom: 0); }
.u-copySecondary { @include copyTextFormat($type: 'secondary', $margin-top: 0, $margin-bottom: 0); }
.u-copyTertiary { @include copyTextFormat($type: 'tertiary', $margin-top: 0, $margin-bottom: 0); }
.u-copySmall { @include copyTextFormat($type: 'small', $margin-top: 0, $margin-bottom: 0); }

.u-textCopyBewStylingLight {
  font-size: $h-xxxs_font-size;
  color: $color-gray200;
  letter-spacing: 0.2px;
  line-height: 16px;
}

.u-checkboxLabelBewStyling {
  font-size: $copy_font-size;
  letter-spacing: 0.28px;
  line-height: 24px;
}

.u-textSizeXXL {
  font-size: $h-xxl_font-size;
  line-height: $h-xxl_line-height;
  letter-spacing: $h_letter-spacing;
}

.u-textSizeXL {
  font-size: $h-xl_font-size;
  line-height: $h-xl_line-height;
  letter-spacing: $h_letter-spacing;
}

.u-textSizeL {
  font-size: $h-l_font-size;
  line-height: $h-l_line-height;
  letter-spacing: $h_letter-spacing;
}

.u-textSizeM {
  font-size: $h-m_font-size;
  line-height: $h-m_line-height;
  letter-spacing: $h_letter-spacing;
}

.u-textSizeS {
  font-size: $h-s_font-size;
  line-height: $h-s_line-height;
  letter-spacing: $h_letter-spacing;
}

.u-textSizeXS {
  font-size: $h-xs_font-size;
  line-height: $h-xs_line-height;
  letter-spacing: $h_letter-spacing;
}

.u-textSizeXXS {
  font-size: $h-xxs_font-size;
  line-height: $h-xxs_line-height;
  letter-spacing: $h_letter-spacing;
}

.u-textSizeSubline {
  font-size: $subline_font-size;
  line-height: $subline_line-height;
  letter-spacing: $h_letter-spacing;
}

.u-textSizeSubheading {
  font-size: $subheading_font-size;
  line-height: $subheading_line-height;
  letter-spacing: $subheading_letter-spacing;
}

.u-textSizeCopy {
  font-size: $copy_font-size;
  line-height: $copy_line-height;
  letter-spacing: $copy_letter-spacing;
}

.u-textSizeSecondary {
  font-size: $copy-secondary_font-size;
  line-height: $copy-secondary_line-height;
  letter-spacing: $copy-secondary_letter-spacing;
}

.u-textSizeTertiary {
  font-family: $copy-tertiary_font-family;
  font-size: $copy-tertiary_font-size;
  line-height: $copy-tertiary_line-height;
}

.u-textSizeSmall {
  font-size: $copy-small_font-size;
  line-height: $copy-small_line-height;
  letter-spacing: $copy-small_letter-spacing;
}

.u-textSizeInherit {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.u-fontRegular { font-family: $font-family-regular; }
.u-fontCondensed { font-family: $font-family-condensed; }

/* Whitespace Utilities */
.u-textNoWrap { white-space: nowrap !important; }
.u-textWrapWord {
  white-space: normal !important;
  word-break: break-all;
}

.u-textBreakWord {
  white-space: normal !important;
  word-break: break-word;
}

/* Text Transform Utilities */
.u-uppercase { text-transform: uppercase; }
.u-lowercase { text-transform: none; }

/* Font weight utilities */
.u-weightBold { font-weight: $font-weight-bold; }
.u-weightNormal { font-weight: $font-weight-normal; }

@include media($media_s) {
  .u-sWeightBold { font-weight: $font-weight-bold; }
}
@include media($media_s-m) {
  .u-smWeightBold { font-weight: $font-weight-bold; }
}
@include media($media_m) {
  .u-mWeightBold { font-weight: $font-weight-bold; }
}
@include media($media_m-l){
  .u-mlWeightBold { font-weight: $font-weight-bold; }
}
@include media($media_l) {
  .u-lWeightBold { font-weight: $font-weight-bold; }
}

/* Text Alignment Utilities */
.u-alignCenter { text-align: center !important; }
.u-alignLeft { text-align: left !important; }
.u-alignRight { text-align: right !important; }
.u-alignMiddle { vertical-align: middle !important; }
.u-alignTop { vertical-align: top !important; }
.u-alignBottom { vertical-align: bottom !important; }
.u-alignBaseline { vertical-align: baseline !important; }

@include media($media_s) {
  .u-sAlignCenter { text-align: center !important; }
  .u-sAlignLeft { text-align: left !important; }
  .u-sAlignRight { text-align: right !important; }
  .u-sAlignMiddle { vertical-align: middle !important; }
  .u-sAlignTop { vertical-align: top !important; }
  .u-sAlignBottom { vertical-align: bottom !important; }
  .u-sAlignBaseline { vertical-align: baseline !important; }
}

@include media($media_s-m) {
  .u-smAlignCenter { text-align: center !important; }
  .u-smAlignLeft { text-align: left !important; }
  .u-smAlignRight { text-align: right !important; }
  .u-smAlignMiddle { vertical-align: middle !important; }
  .u-smAlignTop { vertical-align: top !important; }
  .u-smAlignBottom { vertical-align: bottom !important; }
  .u-smAlignBaseline { vertical-align: baseline !important; }
}

@include media($media_m) {
  .u-mAlignCenter { text-align: center !important; }
  .u-mAlignLeft { text-align: left !important; }
  .u-mAlignRight { text-align: right !important; }
  .u-mAlignMiddle { vertical-align: middle !important; }
  .u-mAlignTop { vertical-align: top !important; }
  .u-mAlignBottom { vertical-align: bottom !important; }
  .u-mAlignBaseline { vertical-align: baseline !important; }
}

@include media($media_m-l) {
  .u-mlAlignCenter { text-align: center !important; }
  .u-mlAlignLeft { text-align: left !important; }
  .u-mlAlignRight { text-align: right !important; }
  .u-mlAlignMiddle { vertical-align: middle !important; }
  .u-mlAlignTop { vertical-align: top !important; }
  .u-mlAlignBottom { vertical-align: bottom !important; }
  .u-mlAlignBaseline { vertical-align: baseline !important; }
}

@include media($media_l) {
  .u-lAlignCenter { text-align: center !important; }
  .u-lAlignLeft { text-align: left !important; }
  .u-lAlignRight { text-align: right !important; }
  .u-lAlignMiddle { vertical-align: middle !important; }
  .u-lAlignTop { vertical-align: top !important; }
  .u-lAlignBottom { vertical-align: bottom !important; }
  .u-lAlignBaseline { vertical-align: baseline !important; }
}

/**
 * Enables font kerning in all browsers.
 * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
 *
 * 1. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
 * 2. Chrome (not Windows), Firefox, IE 10+
 * 3. Safari 7 and future browsers
 */

.u-textKern {
  text-rendering: optimizeLegibility; /* 1 */
  font-feature-settings: "kern" 1; /* 2 */
  font-kerning: normal; /* 3 */
}

.u-textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Reset link Styles to "hide" a link */
.u-anchorReset {
  text-decoration: none;
  color: inherit;
  transition: none;
  animation: none;
  &:hover,
  &:active,
  &:focus,
  &:visited {
    text-decoration: none !important;
    color: inherit !important;
    transition: none !important;
    animation: none !important;
  }
}

/* Cross out text with diagonal line. Stroke takes currentColor, border around stroke needs value for background-color */

.u-strikethrough {
  position: relative;
  &::before {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(to bottom right, transparent calc(50% - 2px), transparent calc(50% - 1.5px), transparent calc(50% - 1px), currentColor calc(50%), currentColor calc(50% + 1px), transparent calc(50% + 2px), transparent calc(50% + 2.5px), transparent calc(50% + 3px));
  }
}
/* Browser-hack/fix for IE 11 */
@media all and (-ms-high-contrast:none)
{
  *::-ms-backdrop, .u-strikethrough::before {
    display: none;
  }
  *::-ms-backdrop, .u-strikethrough {
    background: linear-gradient(to bottom right, transparent calc(50% - 2px), $color-inverted calc(50% - 1.5px), $color-inverted calc(50% - 1px), currentColor calc(50%), currentColor calc(50% + 1px), $color-inverted calc(50% + 2px), $color-inverted calc(50% + 2.5px), transparent calc(50% + 3px));
  }
}


.GlobalSection--themeDark,
.u-colorInverted {
  .u-strikethrough {
    &::before {
      background-image: linear-gradient(to bottom right, transparent calc(50% - 2px), $color-default calc(50% - 1.5px), $color-default calc(50% - 1px), currentColor calc(50%), currentColor calc(50% + 1px), $color-default calc(50% + 2px), $color-default calc(50% + 2.5px), transparent calc(50% + 3px));
    }
  }
}

.ShoppingCartRow--highlight.is-disabled {
  .u-strikethrough {
    &::before {
      background-image: linear-gradient(to bottom right, transparent calc(50% - 2px), $color-primary calc(50% - 1.5px), $color-primary calc(50% - 1px), rgba(255, 255, 255, 0.6) calc(50%), rgba(255, 255, 255, 0.6) calc(50% + 1px), $color-primary calc(50% + 2px), $color-primary calc(50% + 2.5px), transparent calc(50% + 3px));
    }
  }
}

.ShoppingCart-option {
  .u-strikethrough {
    &::before {
      background-image: linear-gradient(to bottom right, transparent calc(50% - 2px), $color-inverted calc(50% - 1.5px), $color-inverted calc(50% - 1px), $color-default calc(50%), $color-default calc(50% + 1px), $color-inverted calc(50% + 2px), $color-inverted calc(50% + 2.5px), transparent calc(50% + 3px));
    }
  }
}

.UIOverlayWrapper {
  &--themeLight {
    .u-strikethrough {
      &::before {
        background-image: linear-gradient(to bottom right, transparent calc(50% - 2px), $color-inverted calc(50% - 1.5px), $color-inverted calc(50% - 1px), currentColor calc(50%), currentColor calc(50% + 1px), $color-inverted calc(50% + 2px), $color-inverted calc(50% + 2.5px), transparent calc(50% + 3px));
      }
    }
  }
  &--themeMedium {
    .u-strikethrough {
      &::before {
        background-image: linear-gradient(to bottom right, transparent calc(50% - 2px), $color-gray-dark calc(50% - 1.5px), $color-gray-dark calc(50% - 1px), currentColor calc(50%), currentColor calc(50% + 1px), $color-gray-dark calc(50% + 2px), $color-gray-dark calc(50% + 2.5px), transparent calc(50% + 3px));
      }
    }
  }
  &--themeCustom,
  &--themeDark {
    .u-strikethrough {
      &::before {
        background-image: linear-gradient(to bottom right, transparent calc(50% - 2px), $color-default calc(50% - 1.5px), $color-default calc(50% - 1px), currentColor calc(50%), currentColor calc(50% + 1px), $color-default calc(50% + 2px), $color-default calc(50% + 2.5px), transparent calc(50% + 3px));
      }
    }
  }
}
