/** @define GlobalNotifications */

$componentClass: '.GlobalNotifications';
.GlobalNotifications {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 998;
}
