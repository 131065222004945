/** @define ServiceStickyContent */
$componentClass: '.ServiceStickyContent';
.ServiceStickyContent {
  &-canvas {
    position: relative;
    z-index: 10;
    left: 0;
    right: 0;
    transition: transform 450ms 60ms;
  }
  &--header {
    #{$componentClass}-canvas {
      z-index: 11;
    }
  }
  &--processHeader {
    background: $gradient-neutral;
  }
}

[data-color-mode="dark"] {
  #{$componentClass}--processHeader {
    background: $color-gray-light-darkmode;
  }
}
