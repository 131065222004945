/** @define TextLink */
$componentClass: '.TextLink';
.TextLink {
  @include Link();
  &--l {
    font-size: rem-calc(24);
  }

  &--small {
    font-size: $copy-small_font-size;
    line-height: $copy-small_line-height;
    letter-spacing: $copy-small_letter-spacing;
  }

  &--secondary {
    font-size: $copy-secondary_font-size;
    line-height: $copy-secondary_line-height;
    letter-spacing: $copy-secondary_letter-spacing;
  }

  &--icon {
    text-decoration: none;
    .TextLink-icon {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      z-index: 1;
    }
    .TextLink-text {
      vertical-align: middle;
    }

    .TextLink-icon + .TextLink-text {
      margin-left: 0.25em;
    }

  }

  &--asButton, &--asShareButton, &--asShareButtonInverted {
    @include Button();
  }

  &--asButtonS, &--asShareButtonS, &--asShareButtonInvertedS {
    @include media($media_s) {
      @include Button();
    }
  }

  &--asButtonSM, &--asShareButtonSM, &--asShareButtonInvertedSM {
    @include media($media_s-m) {
      @include Button();
    }
  }

  &--asButtonM, &--asShareButtonM, &--asShareButtonInvertedM {
    @include media($media_m) {
      @include Button();
    }
  }

  &--asButtonML, &--asShareButtonML, &--asShareButtonInvertedML {
    @include media($media_m-l) {
      @include Button();
    }
  }

  &--asButtonL, &--asShareButtonL, &--asShareButtonInvertedL {
    @include media($media_l) {
      @include Button();
    }
  }

  &--asShareButton, &--asShareButtonInverted{
    border: 2px solid $color-default;
    color: inherit !important;

    .MediaImage {
      width: 20px;
      margin-right: 5px;
    }

    @include media($media_m-l) {
      display:none;
    }

  }

  &--asShareButtonInverted {
    border: 2px solid $color-inverted;
    color: $color-inverted !important;
  }

  &--noStyle {
    &::before, &::after {
      display: none;
      transition: none;
      animation: none;
    }

    .TextLink-text {
      &::before, &::after {
        display: none;
        transition: none;
        animation: none;
      }
    }

    &:hover, &:focus {
      //transition: none;
      animation: none;
      &::before, &::after{
        transition: none;
        animation: none;
      }
    }
  }

  &--noHover:hover {
    color: inherit;
  }

  &-loadingIcon {
    position: absolute;
    top: 50%;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $global-padding;
    height: $global-padding;
    svg {
      width: $global-padding;
      height: $global-padding;
      animation: ani-rotate 1s linear infinite;
      transform-origin: center;
      .Icon-stroke {
        fill: none;
        stroke: $color-primary;
      }
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass} {
    &--asButton:not(.ButtonCustomerService) {
      @include Button($isThemeDark: true);
    }
    &--asShareButton {
      border-color: $color-white-darkmode;
    }
  }
}
