/** @define NavigationTopBar */
.NavigationTopBar {
  position: relative;
  background: $color-gray15;
  border-bottom: 1px solid $color-gray50;
  /*height: $NavTopBar_height;*/
  z-index: 999;
  &-inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    @include outerContainer($max-width);
    .Icon {
      width: rem-calc(31);
      height: rem-calc(31);
      margin-right: rem-calc(3);
      svg {
        width: inherit;
        height: inherit;
        margin-top: rem-calc(2);
      }
    }
    .TextHeadline {
      line-height: rem-calc(40);
      white-space: nowrap;
    }
  }
}

.ServiceStickyContent--topbar .ServiceStickyContent-canvas {
  z-index: 999;
}
