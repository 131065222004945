/** @define Checkout Form confirm */
$componentClass: '.CheckoutForm';
.CheckoutForm {

  @include media($media_s) {
    @include globalPadding($rightRatio: 2, $leftRatio: 2 );
  }


  @include media($media_m) {
    .FormFooter-link--primary {
      width: 41.66667%;
    }
  }

  @include media($media_l) {
    .FormFooter-link--primary {
      width: 33.33333%;
    }
  }

  .CheckoutFormConfirm {
    &-summary {
      @include borderDashed($color: $color-gray50, $bottom: true);
      @include media($media_l) {
        padding-bottom: $global-padding;
      }
    }

    &-dataProcessingHint {
      color: $color-gray100;
      font-size: $copy-small_font-size;
      line-height: $copy-small_line-height;
      margin-top: $global-padding;
    }

    .FormHeader {
      &:not(:first-child) {
        margin-top: $global-padding;

        .TextHeadline {
          font-size: $h-xs_font-size;
          line-height: $h-xs_line-height;
          color: $color-gray100;
        }

        .TextCopy {
          color: $color-gray100;
        }

        .TextCopy, .TextLink {
          font-size: $copy-small_font-size;
          line-height: $copy-small_line-height;
        }
      }
    }

    &--bewStyling {
      .FormHeader:not(:first-child) .TextHeadline {
        @include bewTextStyling($color-default, $h-xs_font-size, $font-family-condensed, 0.2px, inherit );
      }

      .FormHeader:not(:first-child) .TextCopy {
        @include bewTextStyling($color-default, $h-xxxs_font-size, $font-family-regular, 0.2px, 18px);
      }

      .TextCopy--small {
        @include bewTextStyling($color-default, $h-xxxs_font-size, $font-family-regular, 0.2px, 18px);
        margin-top: 0.75rem;
      }

      .CheckoutFormConfirm-dataProcessingHint:not(:first-child) {
        @include bewTextStyling($color-default, $h-xxxs_font-size, $font-family-regular, 0.2px, 18px);
      }

      .FieldMessenger-hint {
        .TextCopy {
          @include bewTextStyling($color-default, $h-xxxs_font-size, $font-family-regular, 0.2px, 18px);
        }
      }

      .FieldCheckbox-labelInner {
        @include bewTextStyling($color-default, $h-xxxs_font-size, $font-family-regular, 0.2px, 18px);
      }
    }
    .FieldCheckbox-labelInner {
      pointer-events: auto;
      a:not([class]){
        @include RawTextLink();
      }
      small {
        font-family: $font-family-regular;
        font-size: $label-small_font-size;
        color: $color-gray200;
        letter-spacing: 0.2px;
        line-height: 16px;
      }
      .FieldCheckbox-formOptional {
        display: none;
      }
    }

    &-list {
      @include listFormater($checkmarks: false, $inherit: true, $size: 5px);
      .TextCopy {
        &--small {
          margin-top: 0;
        }
      }
      .TextLink {
         font-size: rem-calc(12);
         line-height: rem-calc(12);
      }
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass} {
   .CheckoutFormConfirm--bewStyling {
      .FormHeader:not(:first-child) .TextHeadline, .TextCopy {
        color: $color-white-darkmode;
      }
     .FieldCheckbox-labelInner {
       color: $color-white-darkmode;
     }
    }
  }
}
