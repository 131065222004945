/** @define Sitemap */
$componentClass: '.Sitemap';
.Sitemap{

    @include media($media_l) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: flex-start;
    }

    &-sections{
      width: 100%;
    }

    &-sponsors{
      @include media($media_s-m) {
        text-align: center;
        margin-top: $global-padding * 2;
      }

      @include media($media-l) {
        width: 30%;
        padding-left: $global-padding;
      }
    }

    &-sponsors &-logos {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-end;

      @include media($media_s-m) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
      }
    }

    &-sponsors &-logo {
      margin-bottom: rem-calc(10);

      @include media($media_s-m) {
        margin-left: rem-calc(5);
        margin-right: rem-calc(5);
      }
    }
}
