/** @define FriendReferralStatusCard */
@use "sass:math";

$componentClass: '.FriendReferralStatusCard';
.FriendReferralStatusCard {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: $border-radius-m;
  padding: rem-calc(16) $global-padding;
  background: $color-white;

  &-icon {
    margin-right: rem-calc(16);
  }

  &--themeLight {
    background: $color-gray15;
  }

  @include media($media_m-l) {
    padding: rem-calc(16);

    &-icon {
      margin-right: math.div($global-padding, 6);
    }
  }

  @include media($media_l) {
    &-icon {
      margin-right: $global-padding;
    }
  }

  &-info {
    display: inline-block;
    width: rem-calc(14);
    height: rem-calc(14);
    margin-left: math.div($global-padding, 6);
    vertical-align: text-top;
    cursor: pointer;

    &--success {
      .Icon-highlight,
      .Icon-base {
        fill: $color-success;
      }
    }

    &--pending {
      .Icon-highlight,
      .Icon-base {
        fill: $color-primary;
      }
    }
  }
}
