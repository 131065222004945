/** @define HardwareTariffs */
@use "sass:math";

$componentClass: '.HardwareTariffs';

.HardwareTariffs {
  $borderSize: 2px;
  $itemGap: 4px;

  &-header {
    @include media($media_l) {
      padding-bottom: math.div($global-padding, 3);
    }
  }

  &-footer {
    @include media($media_l) {
      padding-top: $global-padding;
    }
  }

  &-headline {
    margin-bottom: rem-calc($global-padding);

    @include media($media_m-l) {
      margin-bottom: rem-calc($global-padding * 0.5);
    }
  }

  &-copy {
    margin-bottom: rem-calc(15);
    color: $color-gray100;
  }

  &-inner {
    @include media($media_l) {
      display: flex;
      flex-direction: row;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;

    @include media($media_s) {
      margin: 0 rem-calc(-10);
    }
  }

  &-contenttabs {
    display: flex;
    padding: rem-calc(16) 0 rem-calc(24px);
    @include media($media_s-m) {
      display: flex;
      justify-content: center;
      padding-bottom: rem-calc(13);
    }
  }

  &-content {
    width: 100%;
    margin-bottom: $global-padding*2;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -$global-padding*1.5;
      width: 100%;
      height: 1.375rem;
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ><polygon fill="#{$color-orange}" points="22,9 13,9 13,0 9,0 9,9 0,9 0,13 9,13 9,22 13,22 13,13 22,13"/></svg>');
      background-repeat: no-repeat;
      background-position: center center;
    }

    @include media($media_m) {
      &::after {
        bottom: -$global-padding*1.25;
      }
    }

    @include media($media_l) {
      width: 25%;
      margin: 0;
      padding: 1rem $global-padding*3 0 0;
      &::after {
        right: $global-padding;
        top: 0;
        bottom: 0;
        width: 1.375rem;
        height: auto;
        background-position: center left;
      }
    }
  }

  &-subcontent {
    display: block;
    width: 100%;
    overflow: hidden;

    @include media($media_l) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: stretch;
      flex-wrap: wrap;
      overflow: visible;
    }
  }

  &-subcontent &-item {
    display: flex;
    flex-direction: row;
    position: relative;
    box-sizing: border-box;
    background: $gradient-neutral no-repeat;
    border: 2px solid transparent;
    border-top: 0;
    border-bottom: 0;

    .Callout--sticker {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      left: 1.5rem;
      @include media($media_s) {
        left: 0.75rem;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: $borderSize solid rgba($color-orange, 0);
      border-bottom: $borderSize solid rgba($color-orange, 0);
    }

    @include media($media_l) {
      width: calc(100% / 3 - #{$itemGap});
      padding: $global-padding 0;
      &:not(:last-child) {
        margin-right: $itemGap;
      }
      &.u-lBlock {
        display: flex !important;
      }
    }

    @include media($media_s-m) {
      padding: $global-padding*0.5 0;
    }
    @include media($media_m) {
      padding: $global-padding 0;
    }
  }

  &-slider {

    @include media($media_s) {
      margin: 0 rem-calc(-10);
    }

    @include media($media_s-m) {
      padding-bottom: 1.5rem;
      overflow: hidden;

      .Slider-pagination {
        margin-bottom: rem-calc(32);
      }

      .swiper-pagination-bullet {
        width: fit-content;
        height: 100%;
        border-radius: 6px;
        padding: rem-calc(8) rem-calc(10);
        color: $color-black;
        font-weight: bold;
      }
    }

    @include media($media_m) {
      width: 100%;

      .Slider-slide.swiper-slide {
        max-width: 304px;
      }
    }

    @include media($media_l) {
      position: relative;
      width: 100%;
      &.u-lBlock {
        display: flex !important;
      }

      .Slider {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
      }

      .Slider-slides {
        display: flex;
        flex-grow: 1;
        overflow: hidden;
        padding-left: rem-calc($borderSize);
        padding-top: 1rem;
      }

      .Slider-slide {
        max-width: calc(100% / 3);
        display: flex;
        height: auto;
      }

      .Slider-slide:first-child .HardwareTariffs-item {
        @include borderDashed($color: $color-gray100, $left: true, $right: true);
      }

      .Slider-buttons {
        left: -$global-padding * 1.75;
        right: -$global-padding * 1.75;
        top: 3.5%;
      }

      .Slider-wrapper {
        align-items: stretch;
        height: auto;
      }

      .HardwareTariffs-item {
        width: 100%;
      }
    }
  }

  @include media($media_s-m) {
    &-subcontent &-item {
      margin-top: 11px;
    }
    &-subcontent &-item.is-selected {
      border: 2px solid transparent;
      border-image: $gradient-primary 1;
      transition: border $timing-duration * 2 $timing-easing;
    }
  }

  @include media($media_l) {
    &-subcontent:hover &-item.is-selected {
      &::before {
        border: none;
      }
    }
    &-subcontent:hover &-item.is-selected:hover,
    &-subcontent &-item.is-selected,
    &-subcontent &-item:hover {
      &::before {
        border: 2px solid transparent;
        border-image: $gradient-primary 1;
        transition: border $timing-duration * 2 $timing-easing;
      }
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass}-item {
    background: $color-gray-light-darkmode;
  }
}
