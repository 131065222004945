/** @define MyNavigation */
$componentClass: '.MyNavigation';
.MyNavigation {
  display: flex;
  flex-direction: column;

  &-header {
    margin-bottom: $global-padding;
  }

  &-links {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: $global-padding;
    margin-bottom: $global-padding * .55;
    @include borderDashed($color: $color-gray100, $top: true);

    > * + * {
      margin-top: $global-padding * .25;
    }

    .TextLink {
      width: 100%;
      color: $color-black;
      font-weight: $font-weight-bold;

      &:after {
        animation: none;
      }

      &:hover {
        color: $color-orange;
      }
    }
  }

  &-footer {
    margin-bottom: $global-padding * .55;
  }

  &-logos {
    padding-top: .8rem;

    img {
      width: 70%;
      @include media($media_s) {
        width: 50%;
      }
    }
  }
}
