/** @define ContentGroupItem */
$componentClass: '.ContentGroupItem';

.ContentGroupItem {
  position: relative;
  img {
    max-width: 100%;
  }

  &-image {
    margin: 0 auto;
    text-align: center;
    .MediaImage {
      width: 100%;
    }
  }

  &-content {
    width: 100%;
  }

  .TextCopy h4,
  .TextCopy h5,
  .TextCopy h6,
  .TextCopy p,
  &-headline {
    &:not(:last-child) {
      margin-top: 0;
      margin-bottom: $global-padding * .5;
    }
  }

  &--imageWidth25 {
    #{$componentClass}-image {
      width: 25%;
    }

    @include media($media_m-l) {

      #{$componentClass}-content {
        width: 75%;
      }
    }
  }

  &--imageWidth33 {
    #{$componentClass}-image {
      width: 33%;
    }

    @include media($media_m-l) {
      #{$componentClass}-content {
        width: 66%;
      }
    }
  }

  &--imageWidth50 {
    #{$componentClass}-image {
      width: 50%;
    }

    @include media($media_m-l) {
      #{$componentClass}-content{
        width: 50%;
      }
    }
  }

  &--imageWidth75 {
    #{$componentClass}-image {
      width: 75%;
    }

    @include media($media_m-l) {
      #{$componentClass}-content {
        width: 25%;
      }
    }
  }

  &--imageWidth100 {
    #{$componentClass}-image {
      width: 100%;
    }

    @include media($media_m-l) {
      #{$componentClass}-content {
        padding: 0 $global-padding * .5;
        @include media($media_m-l) {
          padding: 0 $global-padding;
        }
      }
    }
  }

  &--alignCenter {
    text-align: center;
  }

  &--alignRight {
    @include media($media_m-l) {
      text-align: right;
    }
  }

  &--imageAlignRight,
  &--imageAlignLeft,
  &--imageAlignBottom {
    @include media($media_s) {
      #{$componentClass}-content {
        margin-bottom: $global-padding;
      }
    }
  }

  &--imageAlignBottom,
  &--imageAlignTop {
    display: flex;
    flex-direction: column;
  }

  &--imageAlignRight,
  &--imageAlignLeft {
    @include media($media_m-l) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &--imageAlignTop {
    #{$componentClass}-image {
      order: -1;
      flex-shrink: 0;
      margin-bottom: $global-padding;
    }
    @include media($media_m-l) {
      #{$componentClass}-content {
        width: auto;
      }
    }
  }

  &--imageAlignBottom {
    @include media($media_m-l) {
      #{$componentClass}-content {
        width: auto;
        margin-bottom: $global-padding;
      }
    }
  }

  &--imageAlignLeft {
    @include media($media_m-l) {
      #{$componentClass}-image {
        order: -1;
        margin-right: $global-padding;
      }
    }
  }

  &--imageAlignRight {
    @include media($media_m-l) {
      #{$componentClass}-content {
        margin-right: $global-padding;
      }
    }
  }

  &--textShadows {
    #{$componentClass}-headline,
    #{$componentClass}-copy {
      text-shadow: 1px 1px 2px rgba(0, 0, 0, .75);
      .CMSButton {
        text-shadow: none;
      }
    }
  }

  &--inverted {
    color: $color-white;
  }

  .Callout {
    position: absolute;
    right: rem-calc(0);
    top: 0;
    @include media($media_s-m) {
      transform: scale(.75) translateY(70%);
      transform-origin: right top;
    }
  }

}
