/** @define FormOptionBox */
$componentClass: '.FormOptionBox';
.FormOptionBox {
  position: relative;
  display:inline-block;
  text-align: left;
  &-input {
    position: absolute;
    top: 0;
    left: 0;
    &:checked,
    &:not(:checked) {
      background: transparent;
      border: none;
      appearance: none;
      opacity: 0;
    }
  }

  &-labelInner {
    display: inline;
    vertical-align: top;
    pointer-events: none;

    a {
      pointer-events: all;
    }
  }

  &-checkmark {
    position: absolute;
    top: rem-calc(2);
    left: rem-calc(2);
    width: rem-calc(14);
    height: rem-calc(15);
    pointer-events: none;
    line-height: 1;
    .Icon-stroke {
      stroke: $color-white;
      stroke-dashoffset: 100px;
      stroke-dasharray: 100px;
    }
    .Icon, svg {
      width: inherit;
      height: inherit;
      vertical-align: top;
    }
  }

  &-input + &-label {
    vertical-align: top;
    cursor: pointer;
    padding-left: rem-calc(28);
    line-height: rem-calc(20);
    transition: color $timing-duration $timing-easing;
  }
  &-input:checked + &-label::before,
  &-input:not(:checked) + &-label::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: rem-calc(18);
    height: rem-calc(18);
    background-color: $color-white;
    border: 2px solid $color-gray100;
    box-shadow: inset 0 0 0 0 $color-primary;
    transition: border-color $timing-duration $timing-easing,
                box-shadow $timing-duration $timing-easing;
  }
  &-input:hover + &-label::before,
  &-input:focus + &-label::before {
    border-color: $color-primary;
  }
  &-input:checked + &-label::before {
    border-color: $color-orange;
    background-color: $color-orange;
    box-shadow: inset 0 0 0 9px $color-orange;
    transition: border-color $timing-duration $timing-easing;
  }

  &-input:checked + &-label + &-checkmark {
    .Icon-stroke {
      animation: ani-draw-path $timing-duration * 2 $timing-easing forwards;
    }
  }

  // IE11 Fix - no support for stroke-dashoffset animations
  @media all and (-ms-high-contrast:none) {
    &-checkmark {
      .Icon-stroke {
        stroke-dashoffset: 0px !important;
      }
    }

    &-input:checked + &-label::before,
    &-input:not(:checked) + &-label::before {
      transition: background $timing-duration $timing-easing,
                  border-color $timing-duration $timing-easing;
    }
  }

  &-input:disabled + &-label {
    color: $color-gray100;
    cursor: auto;

    &::before {
      border-color: $color-gray50;
      background-color: $color-gray50;
      box-shadow: inset 0 0 0 9px $color-gray50;
      transition: border-color $timing-duration $timing-easing;
    }
  }

  &--list{
    display:block;
    width:100%;
    height: rem-calc(34);

    &:not(:last-child){
      border-bottom: 1px solid $color-gray50;
    }

    #{$componentClass}-input:checked + #{$componentClass}-label::before,
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label::before {
      content: '';
      display:none;
    }

    #{$componentClass}-label{
      display:block;
      height:inherit;
      line-height: rem-calc(34);
      padding-left: 0;
    }

    #{$componentClass}-input:hover + #{$componentClass}-label,
    #{$componentClass}-input:active + #{$componentClass}-label {
      opacity: 0.75;
    }

    #{$componentClass}-checkmark {
      top: auto;
      bottom: 0;
      left: auto;
      right:rem-calc($global-padding*0.5);
      width: rem-calc(20);
      height: rem-calc(34);
      .Icon-stroke {
        stroke: $color-orange;
      }
    }

    #{$componentClass}-input:checked + #{$componentClass}-label + #{$componentClass}-checkmark {
      .Icon-stroke {
        stroke-dashoffset: 0px;
        animation: none;
      }
    }
  }
}
