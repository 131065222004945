/** @define HardwareSelection */

$componentClass: '.HardwareSelection';
.HardwareSelection {
    position: relative;
    margin: 0 auto;
    &-item {
      @include media($media_m-l) {
        padding-left: $global-padding * 2.5;
      }
    }
    &-socialLinks {
      position: absolute;
      left: 0;
      top: 0;
    }
}
