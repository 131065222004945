/** @define GlobalDialog */

$componentClass: '.GlobalDialog';
.GlobalDialog {

  @include media($media_m-l) {
    max-width: rem-calc(450);
    width: 100%;
  }

  &-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    > .TextHeadline + #{$componentClass}-copy {
      margin-top: $global-padding * .5;
    }

    > .TextHeadline + #{$componentClass}-content,
    #{$componentClass}-copy + #{$componentClass}-content {
      margin-top: $global-padding;
    }

    #{$componentClass}-copy + #{$componentClass}-footer,
    #{$componentClass}-content + #{$componentClass}-footer {
      padding-top: $global-padding;
    }
  }

  &-footer {
    @include media($media_s) {
      margin-top: auto;
    }
  }

  &:not(#{$componentClass}--horizontalNav) {
    #{$componentClass}-link {
      text-align: center;
    }
    #{$componentClass}-footer {
      > *, .TextLink--asButton {
        width: 100%;
      }
      > * + * {
        margin-top: $global-padding * .5;
      }
    }
  }

  &.UIOverlayWrapper--padding {
    .UIOverlayWrapper-inner  {
      padding: $global-padding * 2 $global-padding $global-padding * 2.5;
    }

    @include media($media_m-l) {
      .UIOverlayWrapper-inner  {
        padding: $global-padding;
      }
    }
  }

  &--expanded {
    @include media($media_m-l) {
      max-width: rem-calc(700);
    }
  }

  &--horizontalNav {
    @include media($media_s) {
      #{$componentClass}-link {
        text-align: center;
      }
      #{$componentClass}-footer {
        > *, .TextLink--asButton {
          width: 100%;
        }
        > * + * {
          margin-top: $global-padding * .5;
        }
      }
    }
    @include media($media_m-l) {
      #{$componentClass}-link--asButton:nth-child(2) {
        margin-right: auto;
      }
      #{$componentClass}-footer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        > * + * {
          margin-right: $global-padding;
        }
      }
    }

  }
}

[data-color-mode="dark"] {
  #{$componentClass}-copy {
    .TextCopy {
      @include listFormater($isThemeDark: true);
    }
  }
}
