@import "helpers/unit";
// Global
// - - - - - - - - - - - - - - - - - - - - - - - - -

$max-width: 960px !default;
$max-width-l: 1440px !default;
$global-padding: rem-calc(24) !default;
$global-gutter: -zf-bp-to-em($global-padding) !default;
$grid-columns: 12;

$border-radius-s: rem-calc(3);
$border-radius-m: rem-calc(6);
$border-radius-l: rem-calc(8);

$NavMain_height: rem-calc(54) !default;

// Breakpoints
// - - - - - - - - - - - - - - - - - - - - - - - - -

$breakpoint_xs-to-s: 375px !default;
$breakpoint_s-to-m: 768px !default;
$breakpoint_m-to-l: 1024px !default;
$breakpoint_height: 775px !default;
$media_xs: new-breakpoint(max-width #{$breakpoint_xs-to-s - 1});
$media_s: new-breakpoint(max-width #{$breakpoint_s-to-m - 1});
$media_s-m: new-breakpoint(max-width #{$breakpoint_m-to-l - 1});
$media_m: new-breakpoint(min-width $breakpoint_s-to-m max-width $breakpoint_m-to-l - 1);
$media_m-l: new-breakpoint(min-width #{$breakpoint_s-to-m});
$media_l: new-breakpoint(min-width $breakpoint_m-to-l);
$media_height: new-breakpoint(max-height $breakpoint_height);

// Font
// - - - - - - - - - - - - - - - - - - - - - - - - -

$font-family-regular: "Interstate-Regular", Helvetica, Roboto, Arial, sans-serif;
$font-family-condensed: "Interstate-Condensed", Helvetica, Roboto, Arial, sans-serif;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

// Typography
// - - - - - - - - - - - - - - - - - - - - - - - - -

// TextUnit settings

$price-font-family: $font-family-condensed;
$price-l_font-size: rem-calc(100);
$price-m_font-size: rem-calc(72);
$price-s_font-size: rem-calc(52);

// TextCopy Settings
// - - - - - - - - - - - - - - - - - - - - - - - - -

$copy_font-family: $font-family-regular;
$copy_font-style: normal;
$copy_line-height: rem-calc(24);
$copy_letter-spacing: 0.0175em;
$copy_top-margin: 0; // rem-calc(5);
$copy_bottom-margin: rem-calc(10);
$copy_font-size: rem-calc(16);
$copy-secondary_font-size: rem-calc(14);
$copy-secondary_line-height: rem-calc(20);
$copy-secondary_letter-spacing: 0.027em;
$copy-tertiary_font-family: $font-family-condensed;
$copy-tertiary_font-size: rem-calc(18);
$copy-tertiary_line-height: rem-calc(24);
$copy-small_font-size: rem-calc(11);
$copy-small_line-height: rem-calc(17);
$copy-small_letter-spacing: 0.027em;
$label-small_font-size: rem-calc(10);

// TextHeadline Settings
// - - - - - - - - - - - - - - - - - - - - - - - - -

$h_font-family: $font-family-condensed;
$h_font-weight: $font-weight-normal;
$h_letter-spacing: 0.01em;
$h_top-margin: 0;
$h_bottom-margin: rem-calc(10);

$h-xxxl_font-size: rem-calc(104);
$h-xxxl_line-height: $h-xxxl_font-size;

$h-xxl_font-size: rem-calc(72);
$h-xxl_line-height: $h-xxl_font-size;

$h-xl_font-size: rem-calc(48);
$h-xl_line-height: $h-xl_font-size;

$h-l_font-size: rem-calc(40);
$h-l_line-height: $h-l_font-size;
$h-l_letter-spacing: 0;

$h-m_font-size: rem-calc(30);
$h-m_line-height: $h-m_font-size;

$h-s_font-size: rem-calc(24);
$h-s_line-height: $h-s_font-size;

$h-xs_font-size: rem-calc(20);
$h-xs_line-height: $h-xs_font-size;

$h-xxs_font-size: rem-calc(18);
$h-xxs_line-height: $h-xxs_font-size;

$h-xxxs_font-size: rem-calc(12);

$subline_font-family: $font-family-condensed;
$subline_font-size: rem-calc(24);
$subline_line-height: $subline_font-size;

$subheading_font-family: $font-family-regular;
$subheading_font-size: rem-calc(18);
$subheading_line-height: rem-calc(24);
$subheading_letter-spacing: 0.025em;

/* ---------------- Start Colors ----------------- */

$color-white: #ffffff !default;
$color-black: #000000 !default;
$color-orange: #FA5A14 !default;
$color-orange-dark: #E65312 !default;
$color-orange-light: #fc8f19 !default;
$color-mint: #00A9B2 !default;
$color-gray-dark: #1f1f1f !default;
$color-gray-light: #EBEBEB !default;
$color-gray250: #2C2C2C !default;
$color-gray200: #333333 !default;
$color-gray150: #555555 !default;
$color-gray100: #878787 !default;
$color-gray50: #c3c3c3 !default;
$color-gray15: #ededed !default;
$color-red: #D70000 !default;
$color-green: #12B959 !default;
$color-silver: #B2B2B2 !default;
$color-gold: #D1AD57 !default;

// Dark mode colors
$color-white-darkmode: #f1f1f1 !default;
$color-black-darkmode: #121212 !default;
$color-gray-dark-darkmode: #1F1F1F !default;
$color-gray-light-darkmode: #3F3F3F !default;
$color-gray-lighter-darkmode: #A1A1A1 !default;



$color-default: $color-black !default;
$color-inverted: $color-white !default;
$color-primary: $color-orange !default;
$color-primary-dark: $color-orange-dark !default;
$color-tertiary: $color-mint !default;
$color-success: $color-green !default;
$color-error: $color-red !default;
$color-alert: $color-red !default;

$color-loyalty-l0: $color-orange-dark !default;
$color-loyalty-l1: $color-silver !default;
$color-loyalty-l2: $color-gold !default;

// Gradients
$gradient-primary: linear-gradient(90deg, #FF7800 0%, #FAA532 100%) !default;
$gradient-primary-hover: linear-gradient(90deg, #EB6F01 0%, #E7992F 100%) !default;
$gradient-secondary: linear-gradient(90deg, #FABE3C 0%, #FAD732 100%) !default;
$gradient-tertiary: linear-gradient(90deg, #007D91 0%, #00AF9B 100%) !default;
$gradient-neutral: linear-gradient(90deg, #EDEDED 0%, #F1F1F1 100%) !default;

// Highlights
$color-primary-highlight: rgba(250, 90, 20, 0.1) !default;

// Hover
$color-primary-hover: $color-primary-dark !default;
$color-black-hover: $color-gray200 !default;
$color-white-hover: $color-gray-light !default;

$color-svg-orange: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path fill="#{$color-orange}" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
$color-svg-white: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path fill="#{$color-white}" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');

// Borders

$border-white: 2px solid $color-white;

/* ---------------- End Colors Redesign ----------------- */


// Animation
// - - - - - - - - - - - - - - - - - - - - - - - - -
$timing-duration: 0.2s;
$timing-easing: ease-in-out;

$transition-duration: 0.7s;
$transition-easing: ease-in-out;
