/** @define BlogList */
$componentClass: '.BlogList';
.BlogList {
  background-color: $color-white;
  // Set TextLink default state

  .TextLink--asButton {
    &::before, &::after {
      display: none;
    }
    &:hover {
      color: $color-primary-dark;
    }
  }

  &-pageLink:not(.is-active),
  &-tag:not(.is-active) {
    .TextLink--asButton {
      border-color: $color-gray50;
      color: $color-gray50;
      transition: color $timing-duration $timing-easing, border-color $timing-duration $timing-easing;
      &:hover {
        border-color: $color-gray100;
        color: $color-gray100;
      }
    }
  }

  &-featured {
    width:100%;
    margin-bottom: $global-padding * 2;
    overflow: hidden;
    background-color: $color-black;
    .Slider {
      .Slider-pagination {
        position: absolute;
        width: 100%;
        bottom: $global-padding * .5;
        text-align: center;
        .swiper-pagination-bullet {
          width: rem-calc(15);
          height: rem-calc(15);
          margin: 0 rem-calc(5);
          background: $color-white;
          opacity: .5;
          &.swiper-pagination-bullet-active {
            background: $color-white;
            opacity: 1;
          }
        }
        @include media($media_m-l) {
          bottom: $global-padding;
        }
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include outerContainer($max-width);

    @include media($media_m) {
      padding: 0 $global-padding * .5;
    }
    @include media($media_l) {
      padding: 0;
    }

    #{$componentClass}-item {
      width: 50%;
      margin-bottom: $global-padding * 2;
      padding: 0 $global-padding * .5;
      @include media($media_s) {
        width: 100%;
        padding: 0 rem-calc(10);
      }
    }

    #{$componentClass}-item--featured {
      width: 100%;
      margin-bottom: $global-padding;
    }
  }

  &-filters {
    margin-bottom: $global-padding;
  }

  &-pagination,
  &-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > * {
      margin: 0 $global-padding * .25 $global-padding * .25;
    }
  }

  &-pagination {
    padding-top: $global-padding;
  }
}
