/** @define TeaserOffer */
@use "sass:math";

$componentClass: ".TeaserOffer";
.TeaserOffer{
  position: relative;
  cursor: pointer;
  color: $color-black;
  border-radius: 0.5rem;

  &--large {
    #{$componentClass}-headline,
    #{$componentClass}-subline,
    #{$componentClass}-volume,
    #{$componentClass}-unit {
      @include media($media_s) {
        width: 60%;
      }
    }
  }

  > * {
    color: $color-black;
  }

  &-inner {
    padding: rem-calc(16) $global-padding * 0.5 math.div($global-padding, 3);

    @include media($media_m-l) {
      padding: rem-calc(16) $global-padding $global-padding * 0.5;
    }
  }

  &-ctaOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: rem-calc(78);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    border-radius: 0.5rem;
  }

  &-headline {
    margin-bottom: $global-padding * 0.5;
    font-size: $h-s_font-size;

    @include media($media_m-l) {
      font-size: $h-m_font-size;
      line-height: rem-calc(28);
    }

    @include media($media_l) {
      width: 60%;
    }
  }

  &-subline {
    font-size: $copy-secondary_font-size;
    line-height: rem-calc(18);

    @include media($media_m-l) {
      font-size: $h-s_font-size;
      line-height: rem-calc(27);
    }

    @include media($media_l) {
      width: 60%;
    }
  }

  &-volume,
  &-unit {
    margin-top: rem-calc(16);
    margin-bottom: math.div($global-padding, 3);
    font-size: $price-s_font-size;
    line-height: 1;

    .TextUnit {
      @include media($media_s) {
        font-size: $h-l_font-size;
      }
    }

    @include media($media_s) {
      font-size: $h-l_font-size;
    }
  }
}
