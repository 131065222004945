/** @define DashboardSummary */
$componentClass: '.DashboardSummary';
.DashboardSummary {
  $fontSizeSmall: rem-calc(12);
  $spaceSmall: rem-calc(8);
  &-header {
    padding: $spaceSmall $spaceSmall * 2;
    border-radius: 6px 6px 0 0;
  }

  &-header,
  &-sections,
  &-options{
    background-color: $color-inverted;
  }

  > .TextHeadline {
    text-align: center;

    @include media($media_s) {
      display: none;
    }
  }

  > .ContentLoader {
    margin-top: $global-padding;
    &.is-loading {
      justify-content: flex-start;
    }
    .TextLink {
      box-sizing: border-box;
      @include media($media_s) {
        width: 100%;
      }
      @include media($media_m-l) {
        width: rem-calc(300);
      }
    }
  }

  &-hint {
    font-size: $fontSizeSmall;
  }

  &-hintLink {
    cursor: pointer;
    text-decoration: underline;
  }

  &-hintIcon {
    .TextLink-icon {
      width: $copy-secondary_font-size;

      .Icon-highlight,
      .Icon-base {
        fill: $color-primary;
      }
    }
  }

  &-links {
    margin-top: $global-padding * .25;

    > * + * {
      margin-top: $global-padding * .5;
    }
  }

  .AccordionSection {
    &-title {
      font-weight: bold;
      line-height: 1.4;
      padding: $spaceSmall $global-padding * .5 $spaceSmall $spaceSmall;
    }
    &-label {
      text-align: left;
    }
    &-label::after {
      background-image: svg-url('<svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m17.1 8.6 1.4 1.4-6 6-6-6 1.4-1.4 4.6 4.6 4.6-4.6Z" fill="#000"/></svg>');
    }
  }

  &-sections {
    padding: $spaceSmall $spaceSmall 0 $spaceSmall;
    border-radius: 0 0 $border-radius-m $border-radius-m;

    #{$componentClass}-section {
      padding-bottom: $spaceSmall;
    }
    .AccordionSection {
      &-title {
        font-size: $copy-secondary_font-size;
        border-radius: $border-radius-m $border-radius-m 0 0;
      }
      &-content {
        border-radius: 0 0 $border-radius-m $border-radius-m;
      }
      &-inner {
        padding-top: 0;
        padding-left: $spaceSmall * 2;
      }
    }
  }

  &-options {
    padding: $spaceSmall * 0.5 $spaceSmall;
    border-radius: $border-radius-m;
    overflow: hidden;

    .AccordionSection {
      &-inner {
        padding: 0 $spaceSmall $spaceSmall;
      }
    }
  }

  &-items {
    padding-top: $spaceSmall;
  }

  &-footer {
    > * + * {
      margin-top: $global-padding * .5;
    }

    > .ContentLoader {
      &.is-loading {
        justify-content: flex-start;
      }
    }

    .TextLink.TextLink--asButton {
      box-sizing: border-box;
      width: 100%;
    }
  }
}
