/** @define TeaserNba */
@use "sass:math";

$componentClass: ".TeaserNba";
.TeaserNba{
  position: relative;
  border-radius: 0.5rem;
  padding: rem-calc(16) $global-padding * 0.5 math.div($global-padding, 3);
  background: $gradient-neutral;

  @include media($media_m-l) {
    padding: rem-calc(16) $global-padding $global-padding * 0.5;
  }

  > * {
    color: $color-black;
  }

  &-headline {
    margin-bottom: $global-padding * 0.5;
    font-size: $h-s_font-size;

    @include media($media_m-l) {
      font-size: $h-m_font-size;
      line-height: rem-calc(28);
    }
  }

  &-volume,
  &-unit {
    margin-top: rem-calc(16);
    margin-bottom: math.div($global-padding, 3);
    font-size: $price-s_font-size;
    line-height: 1;

    .TextUnit {
      @include media($media_s) {
        font-size: $h-l_font-size;
      }
    }

    @include media($media_s) {
      font-size: $h-l_font-size;
    }
  }

  &-productSection {
    @include borderDashed($color-gray100, false, false, true);
  }

  &-product {
    width: 70%;
  }

  &-productSticker {
    display: flex;
    flex-wrap: wrap;

    .Callout {
      margin-right: math.div($global-padding, 3);
    }
  }

  &-productImage {
    position: relative;
    width: 30%;
  }

  &-productInformation {
    font-size: $h-xxxs_font-size;
    color: $color-black;
  }

  &-productBadge {
    position: absolute;
    top: -10px;
    right: -10px;

    .Callout {
      display: none;

      &:first-of-type {
        display: block;
      }
    }
  }

  &-paymentInfo {
    .TextUnit {
      font-size: $h-xs_font-size;
    }

    .TextUnit-postfix,
    .TextUnit-prefix {
      font-weight: $font-weight-bold;
    }
  }

  &-paymentMonthlyBundleCopy {
    font-size: $h-xxxs_font-size;
    line-height: 16px;
  }

  &-button {
    margin-left: auto;
  }
}
