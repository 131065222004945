/** @define DashboardWrapper */
$componentClass: '.DashboardWrapper';
.DashboardWrapper {
  padding-bottom: $global-padding * 1.5;

  @include media($media_s) {
    .Slider {
      padding-top: $global-padding * 2.5;
    };
  }

  .DashboardNavigation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @include media($media_m-l) {
      display: none;
    }
  }
}

[data-module-type="MyDashboard"] {
  @include media($media_s) {
    overflow: hidden;
  }
}

