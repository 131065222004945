.u-colorInherit { color: inherit; }
.u-colorDefault { color: $color-default; }
.u-colorInverted { color: $color-inverted; }

.u-colorPrimary { color: $color-primary; }
.u-colorPrimaryDark { color: $color-primary-dark; }
.u-colorTertiary { color: $color-tertiary; }
.u-colorSuccess { color: $color-success; }
.u-colorError { color: $color-error; }
.u-colorAlert { color: $color-alert; }

.u-colorLoyaltyLevel0 { color: $color-loyalty-l0; }
.u-colorLoyaltyLevel1 { color: $color-loyalty-l1; }
.u-colorLoyaltyLevel2 { color: $color-loyalty-l2; }

.u-colorGrayDark { color: $color-gray-dark; }
.u-colorGray200 { color: $color-gray200; }
.u-colorGray100 { color: $color-gray100; }
.u-colorGray50 { color: $color-gray50; }
.u-colorGray15 { color: $color-gray15; }

.u-bgColorInherit { background-color: inherit; }
.u-bgColorDefault { background-color: $color-default; }
.u-bgColorInverted { background-color: $color-inverted; }

.u-bgColorPrimary { background-color: $color-primary; }
.u-bgColorPrimaryDark { background-color: $color-primary-dark; }
.u-bgColorTertiary { background-color: $color-tertiary; }
.u-bgColorSuccess { background-color: $color-success; }
.u-bgColorError { background-color: $color-error; }
.u-bgColorAlert { background-color: $color-alert; }

.u-bgColorGrayDark { background-color: $color-gray-dark; }
.u-bgColorGray200 { background-color: $color-gray200; }
.u-bgColorGray100 { background-color: $color-gray100; }
.u-bgColorGray50 { background-color: $color-gray50; }
.u-bgColorGray15 { background-color: $color-gray15; }

.u-fillInherit { fill: currentColor; }
.u-fillDefault { fill: $color-default; }
.u-fillInverted { fill: $color-inverted; }

.u-fillPrimary { fill: $color-primary; }
.u-fillPrimaryDark { fill: $color-primary-dark; }
.u-fillTertiary { fill: $color-tertiary; }
.u-fillSuccess { fill: $color-success; }
.u-fillError { fill: $color-error; }
.u-fillAlert { fill: $color-alert; }

.u-fillGrayDark { fill: $color-gray-dark; }
.u-fillGray200 { fill: $color-gray200; }
.u-fillGray100 { fill: $color-gray100; }
.u-fillGray50 { fill: $color-gray50; }
.u-fillGray15 { fill: $color-gray15; }

.u-strokeInherit { stroke: currentColor; }
.u-strokeDefault { stroke: $color-default; }
.u-strokeInverted { stroke: $color-inverted; }

.u-strokePrimary { stroke: $color-primary; }
.u-strokePrimaryDark { stroke: $color-primary-dark; }
.u-strokeTertiary { stroke: $color-tertiary; }
.u-strokeSuccess { stroke: $color-success; }
.u-strokeError { stroke: $color-error; }
.u-strokeAlert { stroke: $color-alert; }

.u-strokeGrayDark { stroke: $color-gray-dark; }
.u-strokeGray200 { stroke: $color-gray200; }
.u-strokeGray100 { stroke: $color-gray100; }
.u-strokeGray50 { stroke: $color-gray50; }
.u-strokeGray15 { stroke: $color-gray15; }

.u-bgGradientPrimary { background: $gradient-primary no-repeat; }
.u-bgGradientSecondary { background: $gradient-secondary no-repeat; }
.u-bgGradientTertiary { background: $gradient-tertiary no-repeat; }
.u-bgGradientNeutral { background: $gradient-neutral no-repeat; }

