/** @define ActivationLandingPage */
.LandingPage {
  padding: $global-padding;
  position: relative;
  height: calc(95vh - #{$global-padding});
  display: flex;
  flex-direction: column;

  &-brandLogo {
    margin-top: $global-padding;
    margin-bottom: $global-padding * 2;
    text-align: center;
  }
  &-headline {
    text-align: center;
    margin-bottom: $global-padding * 2;
  }
  &-hint {
    margin-bottom: $global-padding;
    text-align: center;
  }
  &-copy {
    margin-bottom: $global-padding * 4;
    text-align: center;
  }
  &-link {
    text-align: center;
  }
  &-footer {
    margin-bottom: $global-padding * 2;
    margin-top: auto;
  }
}