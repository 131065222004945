/** @define CheckoutProgressBar */
$componentClass: '.CheckoutProgressBar';
.CheckoutProgressBar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;

  &-step:not(.is-current) {
    display: none;
  }

  &-step.is-current {
    margin: 0 auto;
    text-align: center;

    #{$componentClass}-label {
      color: $color-black;
    }

    .TextHeadline {
      font-size: 1.5rem;
    }
  }

  &-progressSteps {
    display: none;
    margin-top: $global-padding*0.5;
    font-size: 1rem;
  }

  @include media($media_m-l) {
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: rem-calc(-2);
      width: 100%;
      height: rem-calc(2);
      background-color: $color-gray50;
    }

    &-label {
      margin-left: $global-padding * 1.1;
      color: $color-gray50;
      transition: all $timing-duration $timing-easing;
    }

    &-step {
      min-height: rem-calc(18);
      padding: 0 $global-padding * .5;
      background-color: $color-white;
      z-index: 1;
      display: block !important;

      &:first-child {
        padding: 0 $global-padding * .5 0 0;
      }

      &:last-child {
        padding: 0 0 0 $global-padding * .5;
      }

      &:before {
        content: '';
        position: absolute;
        width: rem-calc(18);
        height: rem-calc(18);
        border: 2px solid $color-gray100;
        border-radius: 100%;
        background-color: transparent;
        transition: all $timing-duration $timing-easing;
        box-shadow: inset 0 0 0 0 $color-gray100;
      }

      .TextHeadline {
        font-size: 1.125rem;
      }
    }

    &-step.is-done {
      &:before {
        background-color: $color-gray100;
        box-shadow: inset 0 0 0 9px $color-gray100;
      }
    }
    &-step.is-disabled {
      &:before {
        border: 1px solid $color-gray50;
        transition: color $timing-duration $timing-easing;
        cursor: auto;
      }
    }
    &-step.is-current {
      text-align: left;
      margin: 0;
      &:before {
        border-color: $color-orange;
      }

      .TextHeadline {
        font-size: 1.125rem;
      }
    }
    &-step.is-disabled {
      .TextHeadline {
        cursor: auto;
      }
    }
  }

}
