$componentClass: '.LoginForm';
.LoginForm {

  max-width: rem-calc(294);
  margin: 0 auto;

  > .TextHeadline,
  > .TextCopy {
    text-align:center;
  }

  > .TextHeadline {
    margin-bottom: $global-padding;
  }

  .FieldInput-input {
    font-weight: $font-weight-normal;
  }

  &-linkList {
    text-align: center;
    display:flex;
    flex-direction: column;
    padding-top: $global-padding;
    .TextLink:not(:hover) {
      color: $color-gray100;
    }
  }

  .TabsWrapper {
    &-labels {
      border-top:none;
      border-bottom:none;
    }
    &-label {
      &:first-child {
        @include borderDashed($color: $color-gray100, $right: true);
        padding-right:0.8rem;
        margin-right:0.5rem;
      }
    }
    &-labelButton {
      &::after {
        height: 2px;
        margin-top: rem-calc(3);
        border-radius: 1px;
      }
    }
  }

  &--widget {
    max-width: none;
    #{$componentClass}-linkList {
      padding-top: $global-padding * .5;
    }
  }
}
