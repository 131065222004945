$componentClass: '.LoginFormStep';
.LoginFormStep {

  .FormWrapper {
    &-formFull {
      margin-bottom:$global-padding*0.5;
    }
  }

  .FormFooter {
    &-copy {
      display: none;
    }
    &-primaryButtons,
    &-buttons {
      width: 100%;
      text-align: center;
    }

    .TextLink--asButton {
      width: 100%;
    }

    &-link {
      width:100%;
      margin-top: 0;
      margin-bottom:$global-padding*0.5;
      &:last-of-type {
        margin-bottom:0;
      }
    }
  }

  &--stepPwd {
    .FormWrapper {
      margin-bottom: 0;
    }
    .FormSection-inner {
      position: relative;
    }
  }

  &-stepPwdIcons {
    position: absolute;
    right: 0;
    bottom: 20px;
    cursor: pointer;
  }

  .FieldInput--valid .FieldInput-inner:after {
    content: none;
  }
}
