/** @define Checkout Form */
$componentClass: '.FormHeader';
.FormHeader {
  width: 100%;

  &-subline {
     margin-bottom: $global-padding;
  }

  &-copy {
     margin-top: $global-padding * .5;
  }

  &--maxwidth {
    width: 75%;
    margin-right: 25%;
  }

}
