/** @define HardwareItemCompact */

$componentClass: '.HardwareItemCompact';
.HardwareItemCompact {
  position: relative;
  @include media($media_m) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //justify-content: center;
    align-items: stretch;
  }

  @include media($media_l) {
    max-width: rem-calc(300);
    margin: 0 auto;
    text-align: center;
  }
  /******************************
   CONTAINERS
   *****************************/
  &-visuals {
    @include media($media_s-m) {
      margin-right: rem-calc($global-padding * 0.5);
      flex: 0 0 auto;
    }

    @include media($media_l) {
      text-align: center;
      padding: 0 $global-padding;
      margin-bottom: rem-calc(10);
    }
  }
  &-inner{
    @include media($media_m) {
      flex: 1 1 auto;
      padding: $global-padding*0.5 0;
    }
  }
  &-hardwareSelection {
    margin-bottom: rem-calc(8);
    @include media($media_s-m) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
  &-title {
    @include media($media_s-m) {
      flex: 1 1 auto;
    }
    @include media($media_s) {
      padding-top: rem-calc(8);
    }
    @include media($media_l) {
      margin-bottom: rem-calc(10);
    }
  }
  &-selector {
    @include media($media_s-m) {
      flex: 0 0 100%;
      order: 3;
    }
    @include media($media_l) {
      margin-bottom: rem-calc(10);
    }
  }
  &-prices {
    @include media($media_s-m) {
      flex: 0 1 auto;
      text-align: right;
    }

    @include media($media_s) {
      padding-top: rem-calc(8);
    }
    @include media($media_l) {
      text-align: center;
    }
  }
  /******************************
   elements
   *****************************/
  &-image {
    margin: 0 auto;
    img {
      width: 100%;
    }
    @include media($media_s) {
      img {
        max-width: rem-calc(25);
      }
    }

    @include media($media_m) {
      img {
        max-width: rem-calc(85);
      }
    }
  }
  &-unit {
    @include media($media_s-m) {
      font-size: 1em;
    }
  }

  &-copy,
  &-link {
    @include media($media_l) {
      margin-bottom: $global-padding * .25;
    }
  }

  &-headline {
    .TextHeadline {
      @include media($media_s-m) {
        display: inline;
      }
    }
    .Callout--sticker {
      .TextCopy {
        width: max-content;
      }
    }
  }

  .Callout--badge {
    position: absolute;
    transform: scale(0.75) translate(-50%, -50%);
    transform-origin: right center;
    top: 0;
    left: 50%;
    z-index: 3;
    @include media($media_m) {
      position: absolute;
      top: auto;
      bottom: 0;
      left: rem-calc(-5);
      right: auto;
      transform: scale(0.75);
      transform-origin: left bottom;
    }
    @include media($media_l) {
      position: absolute;
      top: $global-padding * 2;
      bottom: auto;
      left: auto;
      right: rem-calc(-12);
      transform: scale(0.75);
      transform-origin: right top;
    }
  }
  .Callout--sticker {
    display: inline;
  }

  &-infoIcon {
    .TextLink-icon {
      width: $copy-secondary_font-size;
    }

    .Icon-base,
    .Icon-highlight {
      fill: $color-gray100;
    }
  }
}
