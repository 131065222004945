$componentClass: ".MyInbox";
.MyInbox {

  #{$componentClass}-contentWrapper {
    width:720px;
    margin:auto;
    @include media($media_s) {
      width:100%;
    }
  }
  #{$componentClass}-iframeWrapper {
    width:100%;
    background-color:$color-black;

    // padding:0 156px;
    @include media($media_s) {
      padding:0;
    }
  }
  #{$componentClass}-detail {
    &--isLoading {
      position:relative;
      height:200px;
      #{$componentClass}-loader {
        position:absolute;
        width:100%;
        height:100%;
        display:flex;
        justify-content: center;
        align-items:center;
      }

      #{$componentClass}-iframeWrapper {
        opacity: 0;
      }
    }

  }

  #{$componentClass}-itemList {
    width:100%;
  }

   #{$componentClass}-itemListEmpty {
    width:100%;
    height:300px;
    @include media($media_s) {
      height:100px;
    }
    display: flex;
    justify-content: center;
    align-items: center;

  }

  #{$componentClass}-header {
    .TextLink {
      margin-top: $global-padding * 0.5;
      width:100%;
      text-align: left;
      border-radius:0;

      &:before {
        border-radius: 0;
      }
    }
  }

  #{$componentClass}-item {
    text-align:left;
    #{$componentClass}-icon {
      width: rem-calc(30);
      height: rem-calc(30);
      .Icon-base {
        fill: $color-orange;
      }
    }

    display:flex;
    justify-content: space-between;
    flex-direction: row;
    align-items:center;

    cursor: pointer;
    width:100%;
    background-color:$color-gray15;
    padding: $global-padding * 0.5 $global-padding;

    #{$componentClass}-content {
      position: relative;
      &:not(#{$componentClass}-content--read) {
        &:before {
          content: "";
          border-radius:5px;
          position: absolute;
          left: -12px;
          top:12px;
          margin-top:-5px;
          width:10px;
          height:10px;
          background-color:$color-orange;
        }
      }
      padding-left:5px;
      width:calc(100% - 70px);
      word-wrap:break-word;
    }
    #{$componentClass}-subcontent {
      height:60px;
      min-width:70px;
      display:flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      #{$componentClass}-meta {
        text-align:center;
      }
    }

    &:not(:first-child) {
      margin-top: $global-padding * 0.25;
    }
  }
  .GlobalSection {
    &:first-child .GlobalSection-inner {
      padding-bottom:0;
    }
    &:not(:first-child) .GlobalSection-inner {
      padding-top:$global-padding * 0.25;
    }
  }
  &.MyInbox--document {
    .GlobalSection {
      &:not(:first-child) .GlobalSection-inner {
        padding-top:0;
      }
    }
  }
}
