/** @define TeaserLayerDialog */
$componentClass: '.TeaserLayerDialog';
.TeaserLayerDialog {
  position: relative;

  @include media($media_s) {
    width: 100%;
    height: 100%;
  }

  .UIOverlayWrapper--themeLight {
    background-color: $color-gray15;
    color: $color-black;
    .UIOverlayWrapper-close {
      background-color: $color-gray15;
    }
  }
  .UIOverlayWrapper--themeDark {
    background-color: $color-black;
    color: $color-white;
    .UIOverlayWrapper-close {
      background-color: $color-black;
    }
  }

  &-inner {
    background-color: $color-white;
    color: $color-black;
    border: 2px solid $color-gray-dark;
  }

  &-inner,
  &-content,
  &-back,
  &-front {
    height: 100%;
  }

  &-close,
  &-front,
  &-back {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform: rotateX(0deg)
  }

  &-front {
    position: relative;
    z-index: 2;
  }

  &-back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateX(180deg);
    @include media($media_s) {
      display: none;
    }
    .Icon {
      display: block;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      margin: $global-padding * 4;
      border-radius: 100%;
      background-color: $color-gray-dark;
      svg {
        max-width: rem-calc(250);
        max-height: rem-calc(250);
      }
    }
  }

  &-content {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    @include media($media_s) {
      > * {
        min-height: 100%;
      }
    }
  }

  &-close {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: $global-padding * .25 $global-padding * .5;
    text-align: right;
    z-index: 3;
    background-color: $color-white;
    border: 2px solid $color-gray-dark;

    .ButtonCloseIcon {
      display: block;
      position: initial;
      padding: 0;
    }
    .Icon {
      vertical-align: middle;
    }
  }

  $ease-back: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  $ease-back2: cubic-bezier(0.520, 0.775, 0.420, 1.195);
  $ease-back3: cubic-bezier(0.520, 0.775, 0.405, 1.105);
  $animation-delay: 500ms;

  @include media($media_m-l) {
    &[data-animation="scale"] {
      transform: scale(0);
      transform-origin: center;
      animation: ani-zoom-in 750ms $ease-back2 $animation-delay forwards;
    }

    &[data-animation="flip"] {
      perspective: 1000px;
      #{$componentClass}-inner {
        position: relative;
        transform-style: preserve-3d;
        transform-origin: center;
        visibility: hidden;
        animation: ani-flip 2s $ease-back $animation-delay forwards;
      }
    }

    &[data-animation="pivot"] {
      perspective: 1000px;
      #{$componentClass}-inner {
        position: relative;
        transform-style: preserve-3d;
        transform-origin: top center;
        visibility: hidden;
        animation: ani-pivot 1s $ease-back $animation-delay forwards;
        background-color: transparent;
        border: none;
      }
      #{$componentClass}-front {
        background-color: $color-white;
        border: 1px solid $color-gray-dark;
      }
      .UIOverlayWrapper--themeLight .UIOverlayWrapper-container {
        background-color: transparent;
        #{$componentClass}-front {
          background-color: $color-gray15;
          color: $color-black;
        }
      }
      .UIOverlayWrapper--themeDark .UIOverlayWrapper-container {
        background-color: transparent;
        #{$componentClass}-front {
          background-color: $color-gray15;
          color: $color-black;
        }
      }
    }

    &[data-animation="fall"] {
      visibility: hidden;
      animation: ani-fall 750ms ease-in $animation-delay forwards;
    }

    &[data-animation="slide"] {
      visibility: hidden;
      animation: ani-slide 1s $ease-back2 $animation-delay forwards;
    }
  }

  @keyframes ani-flip {
    0% {
      visibility: visible;
      transform: scale(1) translateY(0) rotateX(90deg);
    }

    50% {
      transform: scale(.7) translateY(-10%) rotateX(180deg);
    }

    100% {
      visibility: visible;
      transform: scale(1) translateY(0) rotateX(0deg);
    }
  }

  @keyframes ani-pivot {
    0% {
      visibility: visible;
      transform: scale(.85) translateY(20%) rotateX(-135deg);
    }

    100% {
      visibility: visible;
      transform: scale(1) translateY(0) rotateX(0deg);
    }
  }

  @keyframes ani-slide {
    0% {
      visibility: visible;
      transform: translateX(-200%);
    }

    100% {
      visibility: visible;
      transform: translateX(0);
    }
  }

  @keyframes ani-fall {
    50%, 80%, 100% {
      visibility: visible;
      transform: translateY(0);
    }

    0% {
      visibility: visible;
      transform: translateY(-200%);
    }

    65% {
      transform: translateY(-15%);
    }

    90% {
      transform: translateY(-3%);
    }
  }

}

[data-color-mode="dark"] {
  #{$componentClass} {
    &-inner {
      background-color: $color-gray-light-darkmode;
      border: $color-gray-light-darkmode;
      color: $color-white-darkmode;
    }
    .ButtonCloseIcon {
      background-color: $color-gray-light-darkmode;
      .Icon .Icon-base {
        fill: $color-white-darkmode;
      }
    }
  }
}
