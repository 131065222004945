/** @define FriendReferralHistoryDialog */
$componentClass: '.FriendReferralHistoryDialog';
.FriendReferralHistoryDialog {
  $itemGapM: $global-padding * 0.5;
  $itemMinWidth: 200;
  $itemsMaxHeight: 400;

  &-items {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: auto;
    max-height: calc(100vh - #{$itemsMaxHeight}px);
  }

  &-item {
    width: 100%;
    margin-top: $itemGapM;
  }

  @include media($media_m-l) {
    &-item {
      min-width: rem-calc($itemMinWidth);
      max-width: calc(33.33% - calc((#{$itemGapM} * 2) / 3));

      &:not(:nth-child(3n)) {
        margin-right: $itemGapM;
      }

      .FriendReferralStatusCard-icon {
        margin-right: calc($itemGapM / 3);
      }
    }
  }
}
