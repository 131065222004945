/** @define FriendReferralStatusContainer */
@use "sass:math";

$componentClass: '.FriendReferralStatusContainer';
.FriendReferralStatusContainer {
  $cardGapL: $global-padding;
  $cardGapM: $global-padding * 0.5;

  &-inner {
    @include outerContainer($max-width);

    background: $gradient-neutral;
    border-radius: $border-radius-m;
    padding: $global-padding;
  }

  &-cards {
    display: flex;
    flex-wrap: wrap;
  }

  &-card {
    width: 100%;
  }

  &-bottomText {
    a {
      cursor: pointer;
    }
  }

  @include media($media_s) {
    &-card {
      &:not(:last-of-type) {
        margin-bottom: $global-padding;
      }
    }
  }

  @include media($media_m) {
    &-card {
      max-width: calc(33.33% - #{math.div($cardGapM * 2, 3)});
      margin-top: $cardGapM;

      &:not(:nth-child(3n)) {
        margin-right: $cardGapM;
      }
    }
  }

  @include media($media_l) {
    &-card {
      max-width: calc(33.33% - #{math.div($cardGapL * 2, 3)});
      margin-top: $cardGapL;

      &:not(:nth-child(3n)) {
        margin-right: $cardGapL;
      }
    }
  }
}
