/** @define Tag */

$componentClass: '.Tag';

.Tag {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.8rem;
  &-copy {
    display: inline-block;
    text-transform: lowercase;
    border-radius: 5px;
    padding: 0 rem-calc(10);
    vertical-align: super;
    font-size: inherit;
    letter-spacing: rem-calc(1);
    font-family: $font-family-condensed;
    font-weight: $font-weight-normal;
    text-decoration: none;
    text-shadow: none;

    p {
      display: inline;
      text-transform: uppercase;
    }
  }
  &--primary {
    #{$componentClass}-copy {
      color: $color-white;
      background-color: $color-primary;
    }
  }
  &--secondary {
    #{$componentClass}-copy {
      color: $color-black;
      background-color: $color-white;
    }
  }
  &--tertiary {
    #{$componentClass}-copy {
      color: $color-white;
      background-color: $color-tertiary;
    }
  }
}
