.u-borderDashed {

  @include borderDashed($color-gray50, true, true, true, true);

  &Top {
    @include borderDashed($color-gray50, true);
  }

  &Right {
    @include borderDashed($color-gray50, false, true, false, false);
  }

  &Bottom {
    @include borderDashed($color-gray50, false, false, true, false);
  }

  &Left {
    @include borderDashed($color-gray50, false, false, false, true);
  }

  &Horizontal {
    @include borderDashed($color-gray50, false, true, false, true);
  }

  &Vertical {
    @include borderDashed($color-gray50, true, false, true, false);
  }

  &Dark {
    @include borderDashed($color-gray100, true, true, true, true);
  }

  &TopDark {
    @include borderDashed($color-gray100, true);
  }

  &RightDark {
    @include borderDashed($color-gray100, false, true, false, false);
  }

  &BottomDark {
    @include borderDashed($color-gray100, false, false, true, false);
  }

  &LeftDark {
    @include borderDashed($color-gray100, false, false, false, true);
  }

  &HorizontalDark {
    @include borderDashed($color-gray100, false, true, false, true);
  }

  &VerticalDark {
    @include borderDashed($color-gray100, true, false, true, false);
  }
}

.u-noBorder {
  border: none;
}
