/** @define GlobalContent */

$componentClass: '.GlobalContent';
.GlobalContent {
  width: 100%;
  scroll-margin-top: 100px;

  > *:first-child[data-module-theme="dark"]:not([data-module-type="ContentStage"]),
  > *:first-child > .GlobalSection--themeDark,
  > *:first-child > * > .Callout--label:first-child + .GlobalSection--themeDark,
  > *:first-child > * > .GlobalSection--themeDark:first-child {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: $NavMain_height * 6;
      background-color: $color-black;
      left: 0;
      top: -$NavMain_height * 6;
      z-index: -1;
    }
  }

  > .u-triggerParent {
    &[data-module-theme="dark"] {
      background-color: $color-black;
    }
    &[data-module-theme="light"] {
      background-color: $color-gray15;
    }
  }
}

[data-color-mode="dark"] {
  background-color: $color-black-darkmode;
}
