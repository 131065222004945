/** @define Teaser */
.Teaser{
  position: relative;
  cursor: auto;
  @include media($media_s) {
    @include globalPadding($leftRatio: .5, $rightRatio: .5, $vertical: false, $queries: false);
  }
  @include media($media_m-l) {
    padding: 0 $global-padding;
    @include globalPadding($vertical: false, $queries: false);
  }

  &-link {
    padding-top: $global-padding * .25;
    text-align: center;
  }

  &-ctaOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &--inverted{
    background-color: $color-black;
    color: $color-white;
  }

  &--banner {
    padding-top: $global-padding;
    padding-bottom: $global-padding;
  }

  &--imageBanner {
    padding: 0;
    text-align: center;

    img,
    .Teaser-image {
      display: block;
    }
  }

  &--background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: rem-calc(200);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: $color-white;

    .TextCopy {
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
    }

  }


}
