/* element utilities */

.u-elementCheckmarkBefore {
  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    margin-right: .2em;
    background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 39.56 29.99"><polygon fill="#{$color-primary}" points="10.56 29.99 0 19.42 2.82 16.6 10.66 24.43 36.84 0 39.56 2.92 10.56 29.99"/></svg>');
    background-position: left top;
    background-repeat: no-repeat;
  }
}

ul.u-elementUnorderedList {
  > li::before {
    background-image: none !important;
  }
  li {
    padding-left: 0;
  }
  list-style-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 39.56 29.99"><polygon fill="#{$color-primary}" points="10.56 29.99 0 19.42 2.82 16.6 10.66 24.43 36.84 0 39.56 2.92 10.56 29.99"/></svg>');
  padding: 0 1.45rem;
}

.u-elementSuccessBefore {
  @include elementMarkBefore('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.56 29.99"><polygon fill="#{$color-success}" points="10.56 29.99 0 19.42 2.82 16.6 10.66 24.43 36.84 0 39.56 2.92 10.56 29.99"/></svg>');
  &::before{
    background-size: contain;
    background-position: left center;
  }
}
.u-elementErrorBefore {
  @include elementMarkBefore('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.5 14.5"><path fill="#{$color-error}" d="M14.09,13.29a0.59,0.59,0,0,1,0,.8,0.58,0.58,0,0,1-.8,0L7.25,8l-6,6.05a0.58,0.58,0,0,1-.8,0,0.59,0.59,0,0,1,0-.8l6-6-6-6a0.59,0.59,0,0,1,0-.8,0.59,0.59,0,0,1,.8,0l6,6.05,6-6a0.59,0.59,0,0,1,.8,0,0.59,0.59,0,0,1,0,.8L8,7.25Z"/></svg>');
  &::before{
    width: .75em;
  }
}


.u-elementWarningBefore {
  &::before {
    content: '!';
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    margin-right: .2em;
    padding: 0;
    background-color: $color-primary;
    border-radius: 100%;
    font-size: 1em;
    font-weight: $font-weight-bold;
    color: $color-white;
    text-align: center;
    vertical-align: middle;
  }
}

.u-elementIndentBefore {
  &::before {
    content: '';
    display: inline-block;
    margin-right: .2em;
    width: 1.1em;
    height: .9em;
    background-image: svg-url('<svg viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg"> <g> <path fill="#{$color-primary}" d="M10.6568542,12 L10.6568542,14.8137085 L17.3137085,11.1568542 L10.6568542,7.5 L10.6568542,10 L2,10 L2,12 L10.6568542,12 Z M0,2 L2,2 L2,12 L0,12 L0,2 Z"></path></g></svg>');
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.u-elementCountAfter {
  &::after {
    content: attr(data-count);
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    margin-left: .7em;
    line-height: 1.5em;
    font-size: .75em;
    font-weight: $font-weight-bold;
    text-align: center;
    color: $color-white;
    background: $color-primary;
    border-radius: 50%;
  }
}

.u-elementWarningBox {
  border: $color-orange solid 2px;
  padding: $global-padding * 0.5;
  border-radius: $global-padding * 0.25;
}

.u-elementErrorBox {
  border: $color-error solid 2px;
  padding: $global-padding * 0.5;
  border-radius: $global-padding * 0.25;
}

.u-trigger,
.u-triggerParent > * {
  opacity: 0;
}

.u-enter,
.u-enterParent > * {
  opacity: 1;
  transition: opacity 750ms ease-out;
}

.u-elementInfoAfter {
  @include elementInfoIAfter;
}

.u-elementInfoAfterInverted {
  @include elementInfoIAfter($color-inverted);
}

.u-elementInfoAfterHighlight {
  @include elementInfoIAfter($color-primary);
}

.u-elementInfoAfterGray {
  @include elementInfoIAfter($color-gray100);
}

.u-elementMarked {
  @include elementMarked();
}
