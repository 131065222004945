/** @define HardwareItemFull */

$componentClass: '.HardwareItemFull';
.HardwareItemFull {
  display: flex;
  flex-direction: row;

  @include media($media_s) {
    flex-wrap: wrap;
    max-width: rem-calc(362);
    margin: 0 auto;
  }
  @include media($media_m) {
    max-width: rem-calc(1024);
  }

  &-visuals {
    position: relative;
    text-align: center;
    width: 45%;
    flex-shrink: 1;
    flex-grow: 0;
    padding-right: $global-padding;
    @include media($media_m-l) {
      padding-right: $global-padding * 2;
      width: rem-calc(270);
    }
  }
  &-content {
    width: 55%;
    @include media($media_l) {
      width: calc(100% - #{rem-calc(270)});
      display: flex;
      flex-grow: 0;
      justify-content: space-between;
    }
  }
  &-mainContent {
    @include media($media_l) {
      flex-shrink: 1;
      flex-grow: 0;
    }
  }
  &-subContent {
    @include media($media_l) {
      width: 49%;
      text-align: right;
      padding-left: $global-padding;
      flex-shrink: 0;
      flex-grow: 1;
    }
  }
  &-selector {
    margin: $global-padding 0;
    @include media($media_s) {
      max-width: rem-calc(154);
    }
  }
  &-prices {
    @include media($media_l) {
      margin-bottom: rem-calc(36);
      text-align: right;
    }
  }

  &-prices + &-accessory,
  &-prices + &-buttons {
    margin-top: $global-padding * .5;
  }

  &-buttons {
    margin-bottom: $global-padding * .5;
    @include media($media_s-m) {
      width: 100%;
      margin-top: $global-padding;
    }
    @include media($media_l) {
      text-align: right;
    }
  }

  &-accessory {
    img {
      max-width: rem-calc(250);
    }
    @include media($media_s-m) {
      width: 100%;
    }
    @include media($media_l) {
      text-align: right;
    }
  }

  &-image {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    img {
      width: 100%;
    }
  }

  &-headline {
    margin-bottom: $global-padding * .25;
  }

  &-review {
    margin-bottom: $global-padding * .3;
    @include media($media_m-l) {
      margin-bottom: $global-padding * .25;
    }
  }

  &-description {
    @include media($media_s) {
      margin: $global-padding * .5 0;
    }
  }

  &-copy {
    margin-bottom: $global-padding;
    &--delivery {
      color: $color-gray50;
      margin-bottom: 0 !important;
      font-size: 1.1em;
    }
  }

  &-paymentFee {
    margin-bottom: $global-padding * 0.25;
    @include media($media_m-l) {
      font-size: 1.2em;
    }
  }

  &-singlePaymentFee {
    @include media($media_s) {
      .TextUnit {
        font-size: 3.6rem;
      }
    }
  }

  &-button {
    margin-bottom: rem-calc(10);

    .TextLink {
      width: 100%;
    }

    @include media($media_m-l) {
      .TextLink {
        max-width: rem-calc(260);
      }
    }

    @include media($media_m-l) {
      .TextLink {
        min-width: rem-calc(192);
      }
    }
  }

  &-galleryLink {
    margin-top: $global-padding * 0.25;
  }

  .TextMessage {
    text-align: center;
  }

  .Callout--badge {
    position: absolute;
    top: rem-calc(-20);
    right: 0;
    transform: scale(0.75);
    transform-origin: right top;
    @include media($media_m-l) {
      top: rem-calc(-20);
      right: 0;
      left: auto;
      transform: none;
    }
  }

  .Callout--label {
    margin-bottom: $global-padding * .5;
  }

  &-infoIcon {
    .TextLink-icon {
      width: $copy-secondary_font-size;
    }

    .Icon-base,
    .Icon-highlight {
      fill: $color-gray100;
    }
  }
}
