/** @define TariffDetails */

$componentClass: '.TariffDetails';
.TariffDetails {
  position: relative;

  &-header {
    padding-bottom: $global-padding;
    @include media($media_l) {
      width: 70%;
    }
    .Callout {
      display: inline-block;
      margin-top: 10px;

      @include media($media_s) {
        margin-top: 0;
      }
    }
  }

  &-type {
    margin-right: $global-padding * .5;
    display:inline-block;
  }

  &-content {
    @include media($media_m-l) {
      padding: $global-padding 0;
    }

    @include media($media_m) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      @include borderDashed($color:$color-gray100, $top: true, $bottom: true);
    }

    @include media($media_l) {
      width: 70%;
    }
  }

  &-subcontent {
    > * + * {
      margin-top: $global-padding * .75;
      @include media($media_s) {
        margin-top: $global-padding;
      }
    }

    @include media($media_m-l) {
      text-align: right;
    }

    @include media($media_l) {
      position: absolute;
      right: 0;
      top: $global-padding;
      width: 30%;
    }
  }

  &-footer {
    text-align: center;
    color: $color-gray100;
    padding-top: $global-padding * 2;
  }

  &-links {

    @include media($media_m-l) {
      #{$componentClass}-link {
        display: inline-block;
      }
      #{$componentClass}-link + #{$componentClass}-link {
        &::before {
          content: '|';
          display: inline-block;
          font-size: $copy-secondary_font-size;
          padding: 0 $global-padding * .25;
        }
      }
    }
  }

  &-features {
    @include media($media_s) {
      padding: $global-padding 0;
      margin-bottom: $global-padding;
      @include borderDashed($color:$color-gray100, $top: true, $bottom: true);
    }

    @include media($media_l) {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      .TextCopy {
        margin-right: $global-padding;
      }
    }
  }

  &-prices {
    margin-bottom: rem-calc(10);
    #{$componentClass}-copy {
      margin-top: $global-padding * .25;
    }
    .ContractRenewalFeeInfo {
      margin-bottom: rem-calc(15);
    }
  }

  &-buttons {

    > * + * {
      margin-top: $global-padding * .5;
    }
  }

  &-headline {
    margin-bottom: $global-padding;

    @include media($media_s){
      display:none;
    }
  }

  &-name {
    display: inline-block;
    margin-right: $global-padding * .5;
    @include media($media_s) {
      margin-bottom: $global-padding * .5;
    }
  }

  &-feature {
    .TextCopy {
      @include listFormater($checkmarks: true, $inherit: true);
    }
    @include media($media_s-m) {
      &:not(:last-child) {
        margin-bottom: $global-padding * 0.5;
      }
    }
    @include media($media_l) {
      flex-grow: 0;
      flex-shrink: 0;
      width: 33.3333%;
      padding: 0 $global-padding;
      &:last-child {
        padding-right: 0;
      }
      &:first-child {
        padding-left: 0;
      }
      &:not(:last-child) {
        @include borderDashed($color:$color-gray100, $right: true);
      }
    }
  }
  &-button {
    .TextLink {
      width: 100%;
      min-width: rem-calc(184);
    }
  }

  &-hint {
    @include media($media_s) {
      text-align: center;
    }
  }

  .Callout--badge {
    position: absolute;
    top: 0;
    right: 0;

    @include media($media_s) {
      transform: scale(.75);
      transform-origin: right top;
    }

    @include media($media_l) {
      right: 22%;
      top: rem-calc(18);
    }
  }

  &--myotelo {
    #{$componentClass}-consumptions {
      margin-top: $global-padding * .5;
    }

    #{$componentClass}-button {
      .TextLink {
        width: 100%;
      }
      .TextCopy {
        margin-top: $global-padding * .25;
      }
    }

    #{$componentClass}-stickers {
      @include media($media_m-l) {
        margin-right: $global-padding * .5;
      }
      .Callout:first-child {
        margin-top: rem-calc(10);
      }
      .Callout:not(:first-child) {
        margin-top: 0;
        margin-bottom: rem-calc(10);
      }
      .Callout:not(:last-child) {
        margin-right: $global-padding * 0.25;
      }
    }

    @include media($media_s) {
      .TariffDetails-header {
        display: flex;
        flex-direction: column;
      }
    }

    @include media($media_m-l) {
      #{$componentClass}-buttons {
        max-width: rem-calc(400);
      }

    }

    @include media($media_m) {
      .Callout--badge {
        transform: translateY(-25%);
      }
    }

    @include media($media_l) {
      display: flex;
      flex-direction: column;
      min-height: rem-calc(320);
      margin-bottom: rem-calc(80);
      #{$componentClass}-footer {
        margin-top: auto;
        width: 70%;
      }
      #{$componentClass}-feature {
        flex-grow: 1;
      }
      #{$componentClass}-subcontent {
        top: 0;
        width: 30%;
      }
      .Callout--badge {
        top: -$global-padding * .5;
      }
    }
  }

  &--bookTariff {
    width: 65%;
    display: inline-block;
    vertical-align: top;

    @include media($media_s) {
      width: 100%;
    }
    @include media($media_m) {
      width: 62%;
      margin-right: 3%;
    }

    #{$componentClass}-header {
      width: 100%;
    }
    #{$componentClass}-content {
      width: 100%;
    }
    #{$componentClass}-footer {
      width: 100%;
    }
  }
}
