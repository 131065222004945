/** @define TeaserProducts */
$componentClass: '.TeaserProducts';
.TeaserProducts {

  .Slider-pagination {
    margin-top: $global-padding * 1.5;
  }

  .Slider {
    position: inherit;
    &-buttonPrev {
      margin-left: $global-padding;
    }
    &-buttonNext {
      margin-right: $global-padding;
    }
  }

  &-content {
    overflow: hidden;
    padding-top: 12px;
  }

  &-product {
    height: 100%;
    .TeaserProduct,
    .TeaserProduct-inner {
      height: inherit;
    }
  }

  &--bundle {
    .Slider-slide {
      height: auto;
      width: auto;
      opacity: 0;
      transition: opacity $timing-duration * 2 $timing-easing;
      pointer-events: none;

      &.swiper-slide-active {
        opacity: 1;
        pointer-events: auto;
      }

      @include media($media_s) {
        .TeaserProduct-content {
          opacity: 0;
          transition: opacity $timing-duration * 2 $timing-easing;
        }
        &.swiper-slide-active {
          .TeaserProduct-content {
            opacity: 1;
          }
        }
        &.swiper-slide-prev,
        &.swiper-slide-next {
          opacity: 1;
        }
      }

      @include media($media_l) {
        min-width: rem-calc(450);
        margin-right: 5%;
        &:last-child {
          margin-right: 0;
        }
        &.swiper-slide-next {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }

  &--hardware {
    .Slider-slide {
      width: rem-calc(168);
    }
  }
}
