/** @define MyFriendReferralDashboard */
$componentClass: '.MyFriendReferralDashboard';
.MyFriendReferralDashboard {
  &-headerLink {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: rem-calc(-16);
      width: rem-calc(12);
      height: rem-calc(19);
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"> <path class="Icon-base" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z" fill="#FA5A14"/> </svg>');
      background-repeat: no-repeat;
      background-position: center center;
      transform: translateY(-50%);
    }
  }
}
