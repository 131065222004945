/** @define ContentLoader */
$componentClass: '.ContentLoader';
.ContentLoader{
  width: 100%;
  height: 100%;
  position: relative;

  // IE 11 min-height hack
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   .UIContentSpinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }

  &.is-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    &::before, &::after {
      display: none !important;
    }
    > * {
      border: none !important;
    }
  }

  &.is-loaded {
    opacity: 0;
    animation: ani-fade-in $timing-duration * 2.5 $timing-easing $timing-duration forwards;
  }
}
