/** @define ProcessHeaderPrice */
.ProcessHeaderPrice {
  &-key {
    width: 65%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: normal;
    vertical-align: top;


    @include media($media_s) {
      width: 55%;
      margin-bottom: $global-padding * .25;
      .TextCopy {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: .95em;
        line-height: 1.1rem;
      }

    }
  }

  &-value {
    width: 35%;
    text-align: right;
    padding-left: 5%;
    font-size: rem-calc(18);
    line-height: rem-calc(22);
    vertical-align: top;

    @include media($media_s) {
      width: 45%;
      margin-top: rem-calc(-2);
      font-size: .92em;
    }
  }

  &-shipping {
    font-size: rem-calc(12);
  }
}
