/** @define ProcessHeaderItem */
$componentClass: '.ProcessHeaderItem';
.ProcessHeaderItem {
  @include globalPadding($topRatio: 0, $bottomRatio: 0);
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  background: $color-white;
  color: $color-black;
  border-top: 2px solid $color-white;
  border-bottom: 2px solid $color-white;


  .TextLink--asButton {
    width: 100%;
  }

  @include media($media_s-m) {
    align-items: center;
    flex-wrap: wrap;
    padding-top: $global-padding * .5;
    padding-bottom: $global-padding * .5;
  }

  @include media($media_l) {
    min-height: $global-padding * 5.75;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    width: 33.333%;
    padding: $global-padding * .5;
    border-left: 2px solid $color-white;
    border-right: 2px solid $color-white;

    &:not(.ProcessHeaderItem:last-child) {
      width: calc(33.333% - 2px);
      margin-right: rem-calc(2);
    }
  }

  &-cta {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba($color-black, .75);
    text-align: center;
    display: flex;
    cursor: pointer;
    opacity: 0;
    transition: opacity $timing-duration $timing-easing;
    z-index: 3;
    &::after {
      content: none !important;
    }
    .TextLink {
      margin: auto;
      &::after {
        content: "";
        position: absolute;
        height: 1px;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $color-white;
        display: block;
        animation: none;
      }
    }
  }

  &-header,
  &-content {
    transition: filter $timing-duration $timing-easing;
    width: 50%;
    @include media($media_l) {
      width: auto;
    }
  }

  &-header {
    padding-right: $global-padding*0.5;

    > * + * {
      margin-top: $global-padding * .1;
    }

    @include media($media_s) {
      .TextHeadline strong {
        display: block;
      }
      .TextHeadline.u-elementIndentBefore {
        font-size: 1em;
      }
      .TextCopy {
        line-height: 1.2rem;
      }
    }

    // Custom breakpoint for better line break rendering rendering
    @media screen and (max-width: 388px) {
      .TextHeadline.u-elementIndentBefore {
        font-size: .75em;
      }
    }

    @include media($media_l) {
      padding-right: 0;
      > *:last-child {
        margin-bottom: $global-padding * .4;
      }
    }
  }

  &-content {
    padding-left: $global-padding*0.5;
    text-align: right;
    @include media($media_l) {
      position: relative;
      text-align: left;
      padding-left: 0;
      z-index: 2;
    }
  }

  &.is-hoverable {
    .ProcessHeaderItem-content,
    .ProcessHeaderItem-header,
    .ProcessHeaderItem-cta {
      overflow: hidden;
      transform: translateZ(0);

      /**
      // former fix for FF, not necessary anymore ?
      @media screen and (-webkit-min-device-pixel-ratio:0){
        filter: blur(.3px);
      }
      */
    }

    .ProcessHeaderItem-cta {
      display: flex;
      align-items: center;
      .TextLink {
        color: $color-white;
        cursor: pointer;
      }
    }

    &:hover, &:focus-within {
      .ProcessHeaderItem-header,
      .ProcessHeaderItem-content {
        filter: blur(1px);
      }

      .ProcessHeaderItem-cta {
        opacity: 1;
      }
    }
  }

  &--selected, &.is-selected {
    @include media($media_l) {
      border-image: $gradient-primary 1;
    }
  }

  &--selected {
    background: $color-white;
  }

  &--default {
    border-top: 2px solid $color-gray15;
    border-bottom: 2px solid $color-gray15;
    @include media($media_l) {
      border-left: 2px solid $color-gray15;
      border-right: 2px solid $color-gray15;
    }
  }

  &--summary {
    background-color: $color-white;
    border-top: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
    @include media($media_l) {
      border-left: 2px solid $color-white;
      border-right: 2px solid $color-white;
    }
    .ProcessItemHeader {
      flex-grow: 1;
      @include media($media_s) {
        display: flex;
        align-items: center;
      }
    }
    .ProcessItemContent {
      @include media($media_s) {
        flex-shrink: 0;
        width: 100%;
      }
    }
    .ProcessHeaderPrice {
      font-family: $font-family-condensed;
      margin-right: $global-padding*0.5;
      flex-grow: 1;
      @include media($media_l) {
        margin-right: 0;
        margin-bottom: $global-padding*0.5;
      }
    }

    .ProcessHeaderPrice-key {
      font-family: $font-family-condensed;
    }

    .ProcessHeaderPrice-value {
      @include media($media_l) {
        font-size: rem-calc(24);
      }
    }
  }

  &--iconAddition {
    $indicatorSize: 34;
    $distance: 4;
    margin-bottom: rem-calc($distance);
    @include media($media_l) {
      margin-bottom: 0;
    }
    &::after {
      content: "+";
      font-family: $font-family-condensed;
      color: $color-white;
      position: absolute;
      display: block;
      margin: auto;
      bottom: rem-calc(-$indicatorSize*0.5 - $distance*0.5);
      left: 0;
      right: 0;
      font-weight: bold;
      text-align: center;
      font-size: rem-calc($indicatorSize);
      line-height: #{$indicatorSize + 'px'};
      z-index: 1;
      height: rem-calc($indicatorSize);
      width: rem-calc($indicatorSize);
      border-radius: 50%;
      padding: 0;
      background: $gradient-primary;
      pointer-events: none;
      transform: translateZ(0);
      overflow: hidden;
      @include media($media_l) {
        margin-bottom: 0;
        bottom: auto;
        top: 50%;
        margin-top: rem-calc(-$indicatorSize*0.5);
        left: auto;
        right: #{-$indicatorSize*0.5 - $distance*0.5 + 1}px;
      }
    }
  }

  .Callout--sticker {
    @include media($media_l) {
      z-index: 4;
      position: absolute;
      top: 0;
      transform: translateY(-50%);
    }
    @include media($media_s-m) {
      margin-bottom: rem-calc(3);
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass} {
    background: $color-black-darkmode;
    color: $color-white;
    border-color: $color-black-darkmode;
  }
  #{$componentClass}--selected {
    background: $color-black-darkmode;
  }
  #{$componentClass}--summary {
    background: $color-black-darkmode;
    color: $color-white-darkmode;
    border-color: $color-black-darkmode;
  }
}
