/** @define CheckoutCart */
$componentClass: '.CheckoutSummary';
.CheckoutSummary {
  &-header {
    margin-bottom: $global-padding * 2;

    .TextHeadline {
      margin-bottom: $global-padding * 3;
    }
  }
}
