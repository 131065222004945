/** @define TariffItem */

@use "sass:math";

$componentClass: '.TariffItemComplete';
.TariffItemComplete {
  width: 100%;
  padding: $global-padding math.div($global-padding, 1.5);
  position: relative;
  background: $gradient-neutral;
  border-radius: 6px;
  @include media($media_s) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &--light {
    background: $color-white;
  }

  &-header {
    overflow: hidden;
    width: 100%;

    #{$componentClass}-title {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
    }
    .Callout--content {
      height: fit-content;
      margin-top: auto;
      border-radius: 3px;
    }
  }

  &-name {
    padding: 10px 14px 8px 0;
    display: inline-block;
    .TextHeadline {
      font-size: 58px;
    }
    @include media($media_s) {
      padding-right: 12px;
    }

    @include media($media_xs) {
      .TextHeadline {
        font-size: 48px;
      }
    }
  }

  &-headline {
    margin-bottom: 0.25em;
  }

  &-header {
    padding-bottom: $global-padding * 0.5;
    @include borderDashed($color-gray100, false, false, true);
  }

  &-type {
    margin-top: 12px;
    margin-bottom: 0.3em;
  }

  @include media($media_s) {
    &-content {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding-top: $global-padding * 0.5;
      margin-bottom: $global-padding * 0.5;
      #{$componentClass}-link {
        align-self: flex-end;
      }
    }
    &-subcontent {
      width: 100%;
    }
  }

    &-text {
      width: 100%;
      margin-bottom: $global-padding * 0.5;
    }

    &-header {
      flex: 0 0 auto;
      padding-bottom: rem-calc(15);
    }
    &-headline {
      margin-bottom: 0.5em;
    }
    &-content {
      flex: 1 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: rem-calc(20) 0 0;
      @include borderDashed($color-gray100, false, false, true);
      height: 100% !important;

      ul.u-elementUnorderedList li {
        padding-bottom: 0;
        margin-bottom: 12px;
      }
    }
    &-subcontent {
      flex: 0 0 auto;
      padding-top: rem-calc(16);
      text-align: center;
    }

  #{$componentClass}-button {
    .TextLink--asButton {
      width: 100%;
    }
  }

    &-copy,
    &-headline {
      width: 100%
    }

    &-unit {
      margin-bottom: rem-calc(12);

      .TextUnit-postfix {
        display: inline-block;
        font-size: 52px;
        font-weight: bold;
        > span {
          font-size: rem-calc(14);
          font-weight: initial;
        }
      }
    }

    &-priceMonthly {
      margin-top: 16px;

      .TextUnit-raised {
        margin: 0;
      }

      .TextUnit-subunit {
        display: inline-block;
        font-size: inherit;
      }
    }

    &-info {
      font-family:$price-font-family;
      line-height:1;
      white-space: nowrap;
    }
    &-link {
      align-self: flex-end;
      margin-top: $global-padding * 0.5;
    }

  .Callout--badge {
    @include media($media_s) {
      transform: scale(0.75);
      transform-origin: left center;
    }
    @include media($media_m-l) {
      width: 100%;
      text-align: right;
      margin-top: $global-padding * .5;
      transform: scale(0.95);
      transform-origin: right bottom;
    }
  }

  &-productInformation {
    margin-top: rem-calc(16);
    text-align: center;
    font-size: rem-calc(12);
    letter-spacing: 0.02px;
    text-decoration: underline;
    color: $color-default;
    width: 100%;

    &:hover, &:focus {
      color: $color-default;
    }

    &::after {
      content: none;
    }

    &--colorInverted {
      color: $color-inverted;

      &:hover, &:focus {
        color: $color-inverted;
      }
    }

    @include media($media_s) {
      margin: rem-calc(12) auto 0;
    }
  }
}
