/** @define TextHeadlineGroup */
.TextHeadlineGroup {
  position: relative;
  text-align: center;

  &--alignLeft {
    text-align: left;
  }
  &--alignCenter {}
  &--alignRight {
    text-align: right;
  }
  .Callout {
    position: absolute;
    right: rem-calc(0);
    top: 0;

    @include media($media_s) {
      transform: scale(.75) translateY(50%);
      transform-origin: right top;
    }
  }
}
