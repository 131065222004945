/** @define FormFooter */
$componentClass: '.FormFooter';
.FormFooter {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-hint {
    margin-bottom: $global-padding * .75;
    text-align: center;
    @include media($media_m-l) {
      text-align: right;
    }
  }

  &-buttons {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    @include media($media_s) {
      flex-direction: column;
    }
    .TextLink {
      font-size: 1.125rem;
      line-height: 1.125rem;
    }

    #{$componentClass}-hint {
      margin-bottom: $global-padding * .5;
    }

    #{$componentClass}-link {

      &:first-child:last-child{
        margin-left: auto;
      }

      .TextCopy {
        display: block;
      }

      @include media($media_s) {
        width:100%;
        text-align: center;
        + #{$componentClass}-link {
          margin-top: $global-padding;
        }
        > * + * {
          margin-top: $global-padding * .5;
        }
      }
    }

    #{$componentClass}-link--primary {
      .TextLink {
        width: 100%;
      }
      @include media($media_s) {
        > * + * {
          margin-top: $global-padding;
        }
      }
      @include media($media_m-l) {
        display: flex;
        > * + * {
          margin-left: $global-padding;
        }
      }
    }

    #{$componentClass}-submit {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      > * + * {
        margin-top: $global-padding * .5;
      }
    }

    #{$componentClass}-link--secondary {
      @include media($media_m-l) {
        display: flex;
        align-items: center;
        height: rem-calc(42);
      }
    }
  }

  &--alignBetween {
    #{$componentClass}-buttons {
      justify-content: space-between;
    }
  }

  &--alignRight {
    #{$componentClass}-buttons {
      justify-content: flex-start;
    }
  }

  &--alignLeft {
    #{$componentClass}-buttons {
      justify-content: flex-end;
    }
  }

  &--alignBetween,
  &--alignRight,
  &--alignLeft {
    #{$componentClass}-buttons {
      @include media($media_s) {
        justify-content: center;
      }
    }
  }

  &--alignRight,
  &--alignLeft {
    #{$componentClass}-buttons {
      @include media($media_m-l) {
        > * + * {
          margin-right: $global-padding * .5;
        }
      }
    }
  }

  &--asStack {
    #{$componentClass}-buttons {
      flex-direction: column;
      #{$componentClass}-link {
        width:100%;
        text-align: center;
        + #{$componentClass}-link {
          margin-top: $global-padding;
        }
      }
    }
  }
}
