/** @define FormOptionTab */
$componentClass: '.FormOptionTab';
.FormOptionTab {
  position: relative;
  display:inline-block;

  min-width: rem-calc(67);
  height: rem-calc(30);


  &-input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  &-label{
    line-height:1;
    font-size: .95em;
    pointer-events: none;
  }
  &-labelInner{
    position:absolute;
    left:0;
    right:0;
    top:50%;
    text-align: center;
    transform: translateY(-50%);
    transition: color $timing-duration $timing-easing;
  }
  &-labelIcon{
    position:absolute;
    left: 0;
    right: 0;
    width:100%;
    height:100%;
    padding:.4em .7em;
    text-align: center;
    svg{
      width:100%;
      height:100%;
    }
  }

  &-bullet{
    position: absolute;
    left:rem-calc(10);
    top:rem-calc(16);
    width: rem-calc(12);
    height: rem-calc(12);
    border-radius:  rem-calc(12);
    border: 1px solid #ccc;
    background: $color-white;
  }

  &-input:checked + &-label::before,
  &-input:not(:checked) + &-label::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $border-radius-m;
    border: 1px solid $color-gray15;
    background: $color-white;
    transition: border-color $timing-duration $timing-easing;
  }

  &-input:checked + &-label::before {
    border-width: 2px;
    border-color: $color-primary;
  }

  &-input:focus + &-label::before,
  &-input:hover + &-label::before {
    border-color: $color-gray100;
  }

  &--round {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: rem-calc(32);
    padding: 0 rem-calc(15);
    margin-bottom: rem-calc(10);
    font-size: $copy-secondary_font-size;

    #{$componentClass}-input + #{$componentClass}-label::before {
      border-radius: rem-calc(20);
      border: none;
      background-color: $color-gray15;
      transition: background-color $timing-duration $timing-easing;
    }
    #{$componentClass}-input:hover + #{$componentClass}-label::before {
      background-color: $color-gray50;
    }
    #{$componentClass}-input + #{$componentClass}-label {
      font-weight: $font-weight-bold;
    }
    #{$componentClass}-input:checked {
      color: $color-white;
    }
    #{$componentClass}-input:checked + #{$componentClass}-label::before {
      background: $gradient-primary;
    }
    #{$componentClass}-label {
      display: flex;
    }
    #{$componentClass}-labelInner {
      position: static;
      transform: none;
      z-index: 1;
    }
  }
  &--round.is-selected {
    color: $color-black;
  }

  &--dark {
    color: $color-white;
    #{$componentClass}-input + #{$componentClass}-label::before {
      background-color: $color-gray150;
    }
    #{$componentClass}-input:hover + #{$componentClass}-label::before {
      background-color: $color-gray200;
    }
  }

  &--large{
    min-width: rem-calc(94);
    height: rem-calc(40);
    #{$componentClass}-input:checked + #{$componentClass}-label::before,
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label::before {
      border-color: $color-gray50;
    }

    #{$componentClass}-input:hover + #{$componentClass}-label::before {
      border-color: $color-gray100;
    }

    #{$componentClass}-input:checked + #{$componentClass}-label::before {
      border-color: $color-primary;
    }
  }

  &--bulletTab{
    min-width: rem-calc(122);
    height: rem-calc(44);
    #{$componentClass}-labelInner{
      position:absolute;
      left: $global-padding * 1.25;
      right:0;
      text-align: left;
    }
  }

  &.is-disabled {
    #{$componentClass}-input + #{$componentClass}-label::before {
      border-width: 1px;
      border-color: $color-gray15;
    }
    #{$componentClass}-input + #{$componentClass}-label #{$componentClass}-labelInner {
      color: $color-gray50;
    }
  }
  &.is-selected {
    #{$componentClass}-input + #{$componentClass}-label::before {
      border-width: 2px;
      border-color: $color-primary;
    }
  }
}
