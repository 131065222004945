.GiveMeYourPrice {

  @include media($media_m-l) {
    display: flex;
    flex-wrap: wrap;
  }

  .MediaImage img {
    width: 100%;
  }

  &-list {
    color: white;
  }

  &-image {
    position: relative;
    margin: -3rem 0 -6rem;
  }

  &-image:before {
    content: '';
    width: 100%;
    padding-top: 100%;
    display: block;
  }

  &-image > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &-list > li {
    margin-bottom: $global-padding * 0.25;
  }

  &-header {
    text-align: center;
    margin: 0 $global-padding;
    margin-bottom: $global-padding;

    @include media($media_m-l) {
      margin-bottom: $global-padding * 2;
    }

    > * + * {
      margin-top: $global-padding;
    }
    .TextHeadline {
      strong {
        color: $color-white;
      }
      em {
        color: $color-orange;
      }
    }

    .TextHeadline + .TextHeadline {
      margin-top: $global-padding * .5;
      line-height: 1.5rem;
    }
  }

  &-content {
    @include media($media_m-l) {
      width: 44%;
      padding-right: $global-padding;
      height: rem-calc(510);
    }

    > * + * {
      margin-top: $global-padding * .5;
    }
  }

  &-details {
    margin: $global-padding * 0.75 0;
  }

  &-text {
    .TextHeadline {
      margin-bottom: $global-padding * 0.5;
    }
    @include media($media_m-l) {
      position: relative;
      margin-top: rem-calc(-80);
      margin-left: $global-padding * 2;
    }

    @include media($media_s) {
      position: relative;
      margin-top: rem-calc(-100);
      margin-left: $global-padding;
      margin-bottom: $global-padding;
    }
  }

  &-link {
    display: inline-block;
    &:not(:last-child) {
      margin-right: $global-padding * .5;
    }
  }

  &-footer {
    height: rem-calc(80);
    width: 100%;
  }

  &-chat {
    position: relative;
    width: 56%;
    padding: 0 $global-padding $global-padding * 2;
    background: #141414;
    border-radius: $border-radius-m;

    @include media($media_s) {
      display: none;
    }

    @include media($media_m-l) {
      .Chat-content {
        height: rem-calc(400);
      }
      .Chat-messages {
        min-height: rem-calc(400);
      }
    }

    $overlayHeight: rem-calc(16);

    &::before {
      content: '';
      position: absolute;
      height: $global-padding;
      top: 0;
      left: $global-padding;
      right: $global-padding * 1.75;
      background: linear-gradient(to top, rgba(#141414,0) 0%, rgba(#141414, .75) 100%);
    }

    .Chat-message:first-child {
      margin-top: $global-padding * 2;
    }

    .Chat-footer {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        height: $overlayHeight;
        top: -$overlayHeight;
        left: -$global-padding * 2;
        right: -$global-padding * .25;
        background: linear-gradient(to bottom, rgba(#141414,0) 0%, rgba(#141414, .75) 100%);
      }
    }
  }

  &-startButton {
    padding: 0 $global-padding;
    margin-bottom: $global-padding;
    .TextLink--asButton {
      width: 100%;
      word-wrap: normal;
    }
  }
}
