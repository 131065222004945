/** @define NpsForm */
$componentClass: '.NpsForm';
.NpsForm {
  &-reasons,
  &-field:not(:first-child) {
    padding-top: $global-padding;
    @include borderDashed($color: $color-gray15, $top: true);
  }

  &-reasons {
    margin-top: $global-padding;
    .TextCopy {
      margin-bottom: $global-padding;
    }

    > * + *:not(:nth-child(2)) {
      margin-top: $global-padding * .5;
    }
  }

  .FormWrapper {
    &-formFull {
      @include media($media_m-l) {
        display: flex;
      }
    }

    &-formHalf {
      margin-bottom: $global-padding;
      &:first-child {
        .TextHeadline {
          line-height: 0.85em;
        }
        > * + * {
          padding-left: $global-padding;
        }
        > p {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .FieldGroup {
    width: 100%;
    &-inner {
      justify-content: space-between;
    }
  }
}

