/** @define FieldRadio */
$componentClass: '.FieldRadio';
.FieldRadio {
  position: relative;

  .FieldMessenger {
    margin-left: rem-calc(27);
  }

  &-input {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    opacity: 0;
    margin: 0;
    padding: 0;
  }

  &-label {
    display: inline-block;
    white-space: nowrap;
  }

  &-labelInner {
    display: inline-block;
    width: calc(100% - 27px);
    vertical-align: top;
    margin-top: rem-calc(-1);
    white-space: initial;
    pointer-events: none;
  }
  &-input + &-label {
    cursor: pointer;
    transition: color $timing-duration $timing-easing;
  }
  &-input:checked + &-label::before,
  &-input:not(:checked) + &-label::before {
    content: '';
    display: inline-block;
    width: rem-calc(17);
    height: rem-calc(17);
    margin-right: rem-calc(10);
    border-radius: 100%;
    border: 2px solid $color-black;
    background: $color-white;
    box-shadow: inset 0 0 0 0 $color-primary;
    transition: border-color $timing-duration $timing-easing,
                box-shadow $timing-duration $timing-easing;
  }

  &-input:focus + &-label::before,
  &-input:hover + &-label::before {
    border-color: $color-primary;
  }

  &-input:checked + &-label::before {
    box-shadow: inset 0 0 0 3px $color-primary;
    border-color: $color-primary;
  }

  &-input + &-label + &-error {
    margin-left: 1.5em;
  }

  &-input:not(:checked) + &-label--asToggleButton,
  &-input:checked + &-label--asToggleButton {
    display: inline-block;
    //min-width: rem-calc(72);
    box-sizing: border-box;
    padding: 0.5em 1em;
    font-family: $font-family-condensed;
    font-size: 1.125rem;
    line-height: 1.125rem;
    color: $color-gray50;
    border-radius: $border-radius-s;
    border: 2px solid $color-gray50;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-align: center;
    transition: color $timing-duration $timing-easing, border-color $timing-duration $timing-easing,
                border-color $timing-duration $timing-easing, border-color $timing-duration $timing-easing;
    &::before {
      display: none;
      margin: 0;
    }

    #{$componentClass}-labelInner {
      width: 100%;
      margin: 0;
    }
  }

  &-input:checked + &-label--asToggleButton {
    color: $color-primary;
    border-color: $color-primary;
  }

  &--themeDefault {
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label {
      &::before {
        border-color: $color-gray100;
      }
    }

    #{$componentClass}-input:checked + #{$componentClass}-label::before {
      box-shadow: inset 0 0 0 5px $color-primary;
      border-color: $color-primary;
    }

    #{$componentClass}-input:checked + #{$componentClass}-label,
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label {
      &::before {
        border-width: 1px;
      }
    }

    #{$componentClass}-input:focus + #{$componentClass}-label,
    #{$componentClass}-input:hover + #{$componentClass}-label {
      &::before {
        border-color: $color-black;
      }
    }
  }

  &--inverted {
    #{$componentClass}-label {
      color: $color-white;
    }

    #{$componentClass}-input:checked + #{$componentClass}-label::before,
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label::before {
      background: transparent;
    }

    &#{$componentClass}--themeCompact {
      #{$componentClass}-input:not(:checked):not(:hover) + #{$componentClass}-label::before {
        border-color: $color-gray100;
      }
    }

    &#{$componentClass}--themeDefault {
      #{$componentClass}-input:not(:checked):not(:hover) + #{$componentClass}-label::before {
        border-color: $color-gray100;
      }

      #{$componentClass}-input:focus + #{$componentClass}-label,
      #{$componentClass}-input:hover + #{$componentClass}-label {
        &::before {
          border-color: $color-gray50;
        }
      }
    }
  }

  &--error {
    #{$componentClass}-input:checked + #{$componentClass}-label,
    #{$componentClass}-input:not(:checked) + #{$componentClass}-label {
      color: $color-red;
      &::before {
        border-color: $color-red;
      }
    }
  }
}
