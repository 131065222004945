/** @define OffersPanel */
@use "sass:math";

$componentClass: '.OffersPanel';
.OffersPanel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height:100%;
  width: 100%;
  @include borderDashed($color: $color-gray50, $bottom: true);

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background: $gradient-primary;
    padding: $global-padding $global-padding;
    overflow: visible;
    border-top-left-radius: $border-radius-l;
    border-top-right-radius: $border-radius-l;
  }

  &-labelCallout {
    width: 100%;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    padding: $global-padding * 0.5 $global-padding $global-padding;
    width: 100%;
    background-color: $color-white;
    @include media($media_s) {
      padding: rem-calc(10);
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: $global-padding;

    > .TextCopy  {
      flex-grow: 1;
      flex-basis: 50%;
    }

    .Callout--badge {
      flex-grow: 0;
      transform-origin: right top;
    }

    @include media($media_m-l) {
      flex-direction: row;
      > .TextCopy  {
        padding-right: $global-padding;
      }
      .Callout--badge {
        margin-left: auto;
        transform: scale(.85);
      }
    }
  }

  &-stickers {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem-calc(15);

    .Callout:not(:last-child) {
      margin-right: math.div($global-padding, 6);
    }
    .Callout {
      margin-bottom: math.div($global-padding, 6);
    }
    @include media($media_m-l) {
      .Callout:not(:last-child) {
        margin-right: $global-padding * 0.25;
      }
      .Callout {
        margin-bottom: $global-padding * 0.25;
      }
    }
  }

  &-productInformation {
    flex-basis: 100%;
    margin-top: rem-calc(24);
    color: $color-default;
    text-decoration: underline;
    letter-spacing: 0.02px;

    &:hover, &:focus {
      color: $color-default;
    }

    &::after {
      content: none;
    }

    @include media($media_s) {
      margin-top: rem-calc(8);
    }
  }

  &-subcontent {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    @include media($media_s) {
      text-align: center;
      .TextLink--asButton {
        width: 100%;
      }
      > * {
        width: 100%;
        + * {
          margin-top: $global-padding * .5;
        }
      }
    }

    @include media($media_m-l) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      > * {
        flex-grow: 0;
        + * {
          margin-left: $global-padding;
        }
      }
    }
  }
  &-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    @include media($media_m) {
      width: 61%;
    }
  }

  &-basicFee {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    flex-shrink: 0;
  }

  &-transferFee {
    width: 100%;
  }
}
