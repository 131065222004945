/** @define SuccessInfo */
$componentClass: '.SuccessInfo';
.SuccessInfo{
  margin: 0 auto;
  text-align: center;
  @include media($media_s) {
    max-width: rem-calc(380);
  }
  @include media($media_m-l) {
    max-width: rem-calc(650);
  }

  &-pdfLink {
    margin-bottom: 2rem;
  }

  * + &-diagram {
    margin-top: $global-padding * 2;
  }

  &-diagram {
    position: relative;

    &::after {
      content: ">";
      position: absolute;
      font-weight: bold;
      color: $color-gray50;
    }
    @include media($media_s) {

      padding-left: $global-padding * .5;
      &::after {
        bottom: 0;
        left: $global-padding * .5;
        transform: translate(-50%, 50%) rotate(90deg) ;
        transform-origin: center;
      }
    }
    @include media($media_m-l) {

      &::after {
        top: 1px;
        right: 0;
        transform: translate(50%, -50%);
      }
    }
  }

  &-line {
    position:absolute;
    background-color: $color-gray50;
    &::before {
      content: '';
      position: absolute;
      background-color: $color-primary;
    }

    &::after {
      content: '';
      position: absolute;
      border: 1px dashed $color-white;
    }

    @include media($media_s) {
      top: 0;
      bottom: 0;
      width: 2px;
      &::before {
        top: 0;
        height: 10%;
        width: 2px;
        animation: ani-line-vert 3s $timing-easing forwards;
      }
      &::after {
        bottom: 2%;
        height: 8%;
        width: 2px;
      }
    }

    @include media($media_m-l) {
      left: 0;
      right: 0;
      height: 2px;
      &::before {
        left: 0;
        width: 12%;
        height: 2px;
        animation: ani-line-hori 3s $timing-easing forwards;
      }
      &::after {
        right: 2%;
        width: 10%;
        height: 2px;
      }
    }
  }

  &-steps {
    display: flex;
    @include media($media_s) {
      flex-direction: column;
      padding-left: $global-padding * .5;
    }

    @include media($media_m-l) {
      padding-top: $global-padding;
      justify-content: center;
    }
  }

  &-step {
    position: relative;
    &::before {
      content: attr(data-index);
      position: absolute;
      transform: translate(-50%, -50%);
      width: 2em;
      height: 2em;
      line-height: 2em;
      font-size: .75em;
      font-weight: $font-weight-bold;
      text-align: center;
      color: $color-white;
      background: $color-gray100;
      border-radius: 50%;
    }

    .TextCopy {
      > * + * {
        margin-left: $global-padding * .2;
      }
    }

    @include media($media_s) {
      display: flex;
      align-items: center;
      &::before {
        top: 50%;
        left: -$global-padding * .5;
      }
    }

    @include media($media_m-l) {
      width: 33%;
      text-align: center;
      .TextLink {
        text-align: center;
      }
      &::before {
        top: -$global-padding;
        left: 50%;
      }
    }
  }

  &-info {
    padding: $global-padding;
    background-color: $color-gray15;
    margin: $global-padding 0;
    .TextCopy + .TextCopy {
      margin-top: $global-padding * .5;
    }
  }

  &-icon {
    margin-bottom: $global-padding;
    svg {
      max-width: rem-calc(220);
    }
  }

  &-links {
    text-align: center;
    .TextLink {
      margin-top: $global-padding * .5;
    }
  }

  &--new,
  &--exists,
  &--canceled,
  &--canceledWithout {
    margin-bottom: $global-padding * 2;
  }

  &--canceled,
  &--canceledWithout {
    #{$componentClass}-line {

      @include media($media_s) {
        &::before {
          animation: ani-line-vert 3s $timing-easing forwards;
        }
      }
      @include media($media_m-l) {
        &::before {
          animation: ani-line-hori 3s $timing-easing forwards;
        }
      }

      @keyframes ani-line-hori {
        0% { width: 0; }
        100% { width: 15%; }
      }

      @keyframes ani-line-vert {
        0% { height: 0; }
        100% { height: 8%; }
      }
    }

    #{$componentClass}-diagram {
      &::after {
        display: none;
      }
    }
  }

  &--checkoutPrepaid,
  &--activationPrepaid {
    #{$componentClass}-line {

      @include media($media_s) {
        &::before {
          animation: ani-line-vert-prepaid 3s $timing-easing forwards;
        }
      }
      @include media($media_m-l) {
        &::before {
          animation: ani-line-hori-prepaid 3s $timing-easing forwards;
        }
      }

      @keyframes ani-line-hori-prepaid {
        0% { width: 0; }
        100% { width: 30%; }
      }

      @keyframes ani-line-vert-prepaid {
        0% { height: 0; }
        100% { height: 30%; }
      }
    }
  }

  &--canceledWithout {
    #{$componentClass}-line {
      @include media($media_s) {
        bottom: 22%;
        &::after {
          left: 0;
          top: 30%;
          height: 15%;
        }
      }

      @include media($media_m-l) {
        right: 35%;
        &::after {
          left: 70%;
          width: 15%;
        }
      }
    }
  }

  &--canceled {
    #{$componentClass}-line {
      @include media($media_s) {
        bottom: 15%;
        &::after {
          left: 0;
          top: 30%;
          height: 15%;
        }
      }

      @include media($media_m-l) {
        right: 16%;
        &::after {
          left: 32%;
          width: 15%;
        }
      }
    }
  }
}
