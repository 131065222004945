/** @define TariffOptionsTariff */

$componentClass: '.TariffOptionsTariff';

.TariffOptionsTariff {
  width: 100%;
  &-content {
    margin-bottom: $global-padding;
    @include media($media_s) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &-subcontent {
    @include media($media_s) {
      display: none;
    }
  }

  &-title {
    @include media($media_m-l) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &-type {
    @include media($media_m-l) {
      margin-right: rem-calc(5);
    }
  }

  &-name {
    .TextHeadline {
      white-space: nowrap;
    }
  }

  &-price {
    @include media($media_s) {
      font-size: 1.3em;
    }
  }

  &-copy {
    margin: $global-padding 0;
    @include media($media_s) {
      order: 1;
      width: 100%;
      margin-bottom: 0;
    }
    .TextCopy {
      @include listFormater($checkmarks: true, $inherit: true);
    }
  }

  &-deliveryHint {
    @include media($media_s) {
      order: 2;
    }
  }
}
