/** @define Callout */

$componentClass: '.Callout';
.Callout {
  // pointer-events: none;
  z-index: 1;

  a {
    // color: $color-white;
    &:hover, &:focus {
      border-color: transparent;
    }
  }

  &-inner {
    position: relative;
    display: inline-block;
  }

  &--cta {
    pointer-events: all;
    cursor: pointer;
  }

  &--badge {
    position: relative;
    color: $color-white;

    #{$componentClass}-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      transform: scale(1);
      .TextCopy {
        p {
          display: inline;
          margin: $global-padding * .15;
        }
      }
    }

    #{$componentClass}-content #{$componentClass}-badge {
      background-color: $color-black;
      border-radius: 100%;
      transition: transform .3s ease-out;
      #{$componentClass}-copy {
        padding: rem-calc(19);
      }

      .TextCopy {
        line-height: 1.3rem;
        // margin-top: rem-calc(-1.5);
      }
    }

    #{$componentClass}-subcontent {
      position: absolute;
      top: 0;
      z-index: 3;
    }

    #{$componentClass}-subcontent #{$componentClass}-badge {
      color: $color-white;
      background-color: $color-black;
      border-radius: 100%;
      transform-origin: center;
      transition: transform .3s ease-out .1s;
      #{$componentClass}-copy {
        padding: rem-calc(8);
      }
      .TextCopy {
        font-size: 1rem;
        line-height: 1.1rem;
        margin-top: rem-calc(-5);
      }
    }
  }

  &--label {
    color: $color-white;
    background-color: $color-black;
    border-radius: 8px 8px 0 0;
    text-align: center;
    padding: $global-padding * .25;

    #{$componentClass}-label {
      .TextCopy {
        p {
          display: inline;
        }
      }
    }
  }

  &-content {
    .with-info-icon {
      @include elementInfoIAfter;
    }

    button {
      cursor: pointer;
    }
    button:focus {
      border: 2px solid black;
    }
  }

  &--content {
    display: inline-block;
    color: $color-white;
    background-color: $color-black;
    text-align: left;
    padding: $global-padding * .25 $global-padding * .5;
  }

  &--left {
    #{$componentClass}-subcontent {
      left: 50%;
      transform: translate(-110%, -50%);
    }
  }

  &--right {
    #{$componentClass}-subcontent {
      right: 50%;
      transform: translate( 110%, -50%);
    }
  }

  &--expanded {
    &#{$componentClass}--label {
      border-radius: 0;
    }
  }

  &--border {
    &#{$componentClass}--label {
      &#{$componentClass}--secondary {
        border: 2px solid $color-white;
      }
    }
  }

  &--info {
    cursor: pointer;
  }

  &--sticker {
    &#{$componentClass}--primary {
      .Tag {
        .Tag-copy {
          color: $color-white;
          background: $gradient-primary;
        }
      }
    }
    &#{$componentClass}--secondary {
      .Tag {
        .Tag-copy {
          color: $color-white;
          background: $gradient-neutral;
        }
      }
    }
    &#{$componentClass}--tertiary {
      .Tag {
        .Tag-copy {
          color: $color-white;
          background: $gradient-tertiary;
        }
      }
    }
  }

  &--inverted {
    &#{$componentClass}--badge #{$componentClass}-badge,
    &#{$componentClass}--label,
    &#{$componentClass}--content {
      color: $color-black;
      background-color: $color-white;
    }
    &#{$componentClass}--border {
      &#{$componentClass}--label,
      &#{$componentClass}--content {
        &#{$componentClass}--secondary {
          border-color: $color-black;
        }
      }
    }

    &#{$componentClass}--sticker {
      &#{$componentClass}--secondary {
        .Tag {
          .Tag-copy {
            color: $color-black;
            background-color: $color-white;
          }
        }
      }
    }
  }

  &--primary, &--secondary {
    #{$componentClass}-content {
      .with-info-icon {
        @include elementInfoIAfter;
      }
    }
  }

  &--primary {
    &#{$componentClass}--content,
    &#{$componentClass}--label {
      color: $color-black;
      background: $gradient-primary;
    }

    &#{$componentClass}--badge #{$componentClass}-content #{$componentClass}-badge {
      color: $color-black;
      background: $gradient-primary;
    }
  }

  &--secondary {
    &#{$componentClass}--label {
      color:$color-black;
      background: $gradient-neutral;
    }
  }

  &--tertiary {
    #{$componentClass}-content {
      .with-info-icon {
        @include elementInfoIAfter($color-inverted);
      }
    }
    &#{$componentClass}--content,
    &#{$componentClass}--label {
      color: $color-white;
      background: $gradient-tertiary;
    }

    &#{$componentClass}--badge #{$componentClass}-content #{$componentClass}-badge {
      color: $color-white;
      background: $gradient-tertiary;
    }
  }

  &.is-hidden {
    visibility: hidden;
    animation: none;
    #{$componentClass}-badge {
      transform: scale(0);
    }
  }
}
