/** @define TeaserWrapper */
$componentClass: '.TeaserWrapper';
.TeaserWrapper {
  &-content {
    @include outerContainer($max-width);
    @include media($media_s) {
      text-align: center;
    }
  }
  &-headline,
  &-footer {
    @include globalPadding($vertical: false);
  }
  &-headline {
    color: inherit;
    text-align: center;
    margin-bottom: $global-padding;
  }
  &-footer {
    color: inherit;
    text-align: center;
    padding-top: $global-padding;
  }
  &-teasers {
    display: flex;
    flex-direction: column;
    align-content: center;
    @include media($media_m-l) {
      flex-direction: row;
    }
  }

  &--teaserBanner,
  &--teaserFacts,
  &--teaserIconText {
    text-align: center;
  }

  &--teaserText,
  &--teaserFacts,
  &--teaserIconText {
    #{$componentClass}-col {
      @include media($media_s) {
        &:not(:last-child) {
          padding-bottom: $global-padding;
        }
      }
    }
  }

  &--teaserText,
  &--teaserFacts {
    @include media($media_s) {
      #{$componentClass}-col {
        @include borderDashed($bottom: true);
        &:last-of-type {
          @include borderDashed($bottom: false);
        }

        &:first-child {
          padding-bottom: $global-padding;
        }
        &:last-child {
          padding-top: $global-padding;
        }
        &:not(:first-child):not(:last-child) {
          padding-top: $global-padding;
          padding-bottom: $global-padding;
        }
      }
    }
    @include media($media_m-l) {
      #{$componentClass}-col {
        @include borderDashed($left: true);
      }
    }
    @include media($media_m) {
      #{$componentClass}-col {
        &:first-child {
          @include borderDashed($left: false);
        }
      }
    }
  }

  &--teaserFacts {
    @include outerContainer($max-width);
    @include media($media_m-l) {
      #{$componentClass}-col {
        &:first-child {
          @include borderDashed($left: false);
        }
      }
    }
  }

  &--teaserBanner {
    .Slider {
      overflow: hidden;
    }
    .Slider-pagination {
      position: absolute;
      left: 0;
      right: 0;
      bottom: $global-padding * .5;
      text-align: center;
      margin: 0;
    }
  }

  &--themeLight,
  &--themeDark {
    #{$componentClass}-headline {
      padding-top: $global-padding;
    }
    #{$componentClass}-footer {
      padding-bottom: $global-padding;
    }
    #{$componentClass}-content {
      @include globalPadding($horizontal: false, $topRatio: 1.5, $bottomRatio: 1.5);
    }
  }

  &--themeDark {
    background: $color-black;
    color: white;
  }

  &--themeLight {
    background: $color-gray15;
  }

  &--adjacent {
    #{$componentClass}-headline {
      padding-top: 0;
    }
    #{$componentClass}-footer {
      padding-bottom: 0;
    }
    #{$componentClass}-content {
      padding: 0;
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass}--teaserFacts {
    background-color: $color-gray200;
  }
}
