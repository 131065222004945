/** @define ContentGroupSplitScreen */
$componentClass: '.ContentGroupSplitScreen';

.ContentGroupSplitScreen {
  background: linear-gradient(90deg, rgba(255,120,0,1) 50%, rgba(251,209,53,1) 50%);
  margin: 0 16px;
  border-radius: 6px;
  min-height: 200px;
  &-row {
    max-width: $max-width-l;
    margin-left: auto;
    margin-right: auto;
  }
}

.Cgsc {
  position: relative;
  width: 100%;
  &-text {
    position: absolute;
    height: 100%;
    width: 100%;
    &--right {
      text-align: end;
    }
  }
  &-subline {
    font-size: $h-m_font-size;
    line-height: 32px;
    font-weight: bold;
    margin-top: 0;
    &--leftImg {
      padding: 30% 36% 0 7%;
    }
    &--leftNoImg {
      padding: 5% 36% 0 7%;
    }
    &--rightImg {
      padding: 30% 7% 0 50%;
    }
    &--rightNoImg {
      padding: 5% 7% 0 50%;
    }
  }
  &-copy {
    font-size: $copy_font-size;
    line-height: $copy_line-height;
    &--left {
      padding: 0 43% 0 7%;
    }
    &--right {
      padding: 0 7% 0 58.5%;
    }
  }

  &-imageMobile {
    width: 100%;
  }
  &-image {
    width: 100%;

    &--left {
      border-top-left-radius: $border-radius-m;
      border-bottom-left-radius: $border-radius-m;
    }
    &--right {
      border-top-right-radius: $border-radius-m;
      border-bottom-right-radius: $border-radius-m;
    }
  }
}

.cgsc-copy > a > img {
  vertical-align: initial;
}

@media screen and (max-width: $breakpoint_m-to-l) {
  .ContentGroupSplitScreen {
    background: none;
    margin: 0 8px;
  }
  .ContentGroupSplitScreen-inner {
    padding-right: 0;
    padding-left: 0;
  }
  .Cgsc {
    &--left {
      background: linear-gradient(45deg, rgb(255, 120, 0) 0%, rgb(250, 165, 50) 100%);
      border-top-left-radius: $border-radius-m;
      border-top-right-radius: $border-radius-m;
    }
    &--right {
      background: linear-gradient(90deg, rgba(250,190,60,1) 0%, rgba(250,215,50,1) 100%);
      border-bottom-left-radius: $border-radius-m;
      border-bottom-right-radius: $border-radius-m;
    }
    &-text {
      position: relative;
      padding: 24px 16px 0;
      text-align: left !important;
      height: auto;
      width: auto;
    }

    &-image {
      display: none;
    }
    &-imageMobile {
      display: block;
      margin: 0 auto;
    }
  }
  .Cgsc-subline--left, .Cgsc-subline--right, .Cgsc-copy--left, .Cgsc-copy--right {
    padding: 0 !important;
  }
}

@media screen and (min-width: 1025px) {
  .Cgsc {
    &-imageMobile {
      display: none;
    }
    &-image {
      display: block;
    }
  }
}

@media screen and (min-width: $breakpoint_s-to-m) and (max-width: $breakpoint_m-to-l) {
  .Cgsc {
    &--left {
      border-top-left-radius: $border-radius-m;
      border-bottom-left-radius: $border-radius-m;
      border-top-right-radius: 0;
    }
    &--right {
      border-top-right-radius: $border-radius-m;
      border-bottom-right-radius: $border-radius-m;
      border-bottom-left-radius: 0;
      background: linear-gradient(90deg, rgba(250,215,50,1) 0%, rgba(250,190,60,1) 100%);
    }
    &-imageMobile {
      &--left {
        border-bottom-left-radius: $border-radius-m;
        position: absolute;
        bottom: 0;
      }
    }
  }
}
