$componentClass: '.SimReplacementFormActivationStyle';
.SimReplacementFormActivationStyle {

  .FormWrapper .FormWrapper {
    margin-top: $global-padding;
    margin-bottom: 0;
  }

  .MediaImage {
    width: 50%;
    img { width: 100%; }
    @include media($media_m-l) {
      width: rem-calc(130);
    }
  }

  @include media($media_m-l) {
    .FormWrapper-container {
      width: 75%;
    }
  }

  &-header {
    @include media($media_m-l) {
      display: flex;
      .MediaImage {
        padding-right: $global-padding * .5;
      }
    }
  }
}
