$componentClass: '.ServiceToolTip';
.ServiceToolTip {

  $shadow-blur: 10px;
  $shadow-color: rgba(0, 0, 0, 0.2);
  $tip-size: rem-calc(14);

	padding: rem-calc(10);
	position: absolute;
	cursor: default;
	animation: .5s ani-fade-in;
  font-size: $copy-small_font-size;
  line-height: $copy-small_line-height;
  letter-spacing: $copy-small_letter-spacing;
  z-index: 2;

  @include media($media_s) {
    width: 100%;
    left: 0 !important;
  }

  @include media($media_m-l) {
    max-width: rem-calc(320);
  }

  &-inner {
    position: relative;
  }

  &-tip {
    display: block;
    position: absolute;
    width: $tip-size;
    height: $tip-size;
    background: $color-white;
    box-shadow: 3px 3px $shadow-blur $shadow-color;
    z-index: -1;
  }

  &-content {
    position: relative;
    padding: rem-calc(10);
    border-radius: $border-radius-s;
    background: $color-white;
    color: $color-black;
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      border-radius: $border-radius-s;
      box-shadow: 0 3px $shadow-blur $shadow-color;
    }
  }

  &--top,
  &--bottom {
    #{$componentClass}-tip {
      left: 50%;
      transform:
        translateX(-50%)
        rotate(45deg);
    }

    #{$componentClass}-content {
      &::before,
      &::after {
        width: 50%;
        top: 0;
        bottom: 0;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
  }

  &--bottom {
    padding-top: $tip-size;
    #{$componentClass}-tip {
      top: -$tip-size * 0.5;
    }

    #{$componentClass}-content {
      &::before {
        border-top-right-radius: $tip-size;
      }

      &::after {
        border-top-left-radius: $tip-size;
      }
    }
  }

  &--top {
    padding-bottom: $tip-size;
    #{$componentClass}-tip {
      bottom: -$tip-size * 0.5;
    }

    #{$componentClass}-content {
      &::before {
        border-bottom-right-radius: $tip-size;
      }

      &::after {
        border-bottom-left-radius: $tip-size;
      }
    }
  }

  &--left,
  &--right {
    #{$componentClass}-tip {
      top: 50%;
      transform:
        translateY(-50%)
        rotate(45deg);
    }

    #{$componentClass}-content {
      &::before,
      &::after {
        height: 50%;
        width: 100%;
        left: 0;
        right: 0;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }
  }

  &--left {
    padding-right: $tip-size;
    #{$componentClass}-tip {
      right: -$tip-size * 0.5;
    }

    #{$componentClass}-content {

      &::before {
        border-bottom-right-radius: $tip-size;
      }

      &::after {
        border-top-right-radius: $tip-size;
      }
    }
  }

  &--right {
    padding-left: $tip-size;
    #{$componentClass}-tip {
      left: -$tip-size * 0.5;
    }

    #{$componentClass}-content {

      &::before {
        border-bottom-left-radius: $tip-size;
      }

      &::after {
        border-top-left-radius: $tip-size;
      }
    }
  }
}
