/** @define MyESimStatus */
$componentClass: '.MyESimStatus';
.MyESimStatus {
  position: fixed;
  top: auto;
  bottom: 0;
  transform: translateY(150px);
  transition: transform $transition-duration $timing-easing;
  width: 100%;
  z-index: 990;
  background: $gradient-primary;

  &-refreshButton {
    .TextLink-text,
    .TextLink-icon {
      transition: all $timing-duration $timing-easing;
    }
    .TextLink-icon {
      line-height: 1;
    }
  }

  &.is-shown {
    transform: translateY(0);
  }
}
