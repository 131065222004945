/** @define HardwareSelector */

$componentClass: '.HardwareSelector';
.HardwareSelector {
  &-copy {
    margin-bottom: $global-padding * 0.5;
  }

  &--default {
    text-align: center;
    #{$componentClass}-option {
      margin-top: $global-padding*0.5;
      .FormOptionTab {
        margin: 0 rem-calc(5);
      }
      .FormOptionBullet {
        margin: 0 rem-calc(4);
      }
    }
  }
  &--full {
    #{$componentClass}-option {
      .FormOptionTab {
        margin: 0 rem-calc(5) 0 0;
      }
      &:first-child {
        margin-bottom: $global-padding * 0.5;
        @include media($media_l) {
          padding: rem-calc($global-padding * 0.5) 0;
          @include borderDashed($color: $color-gray50, $top: true, $bottom: true);
        }
      }
    }
  }

  &--compact {
    #{$componentClass}-option {
      &:first-child {
        margin-bottom: rem-calc($global-padding * 0.5);
      }
    }
  }
}
