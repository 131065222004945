/** @define UICircularProgressBar */

$componentClass: '.UICircularProgressBar';
.UICircularProgressBar{
  position: relative;
  display: inline-block;
  text-align: center;
  width: rem-calc(202);
  height: rem-calc(154);
  cursor: pointer;

  &-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -35%);
  }

  .TextHeadline {
    margin-bottom: $global-padding * .25;
  }

  .TextCopy {
    //line-height: 1.2em;
  }

  .Icon {
    svg {
      filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    }
  }

  $shapeSize: 396;

  .Icon-stroke2,
  .Icon-stroke3 {
    stroke-dashoffset: #{$shapeSize}px;
    stroke-dasharray: #{$shapeSize}px;
    transition: all $timing-duration * 6 $timing-easing;
  }

  &[data-from="100"] {
    .Icon-stroke2,
    .Icon-stroke3 {
      stroke-dashoffset: #{$shapeSize*2}px;
      stroke-dasharray: #{$shapeSize}px;
    }
  }

  @for $i from 0 through 100 {
    $offset: decimal-round($i * 0.01 * $shapeSize + $shapeSize);
    &[data-to="#{$i}"] {
      .Icon-stroke2,
      .Icon-stroke3 {
        @if $i < 4 and $i != 0 { stroke-dashoffset: 405px; }
        @if $i >= 4 and $i != 0{ stroke-dashoffset: #{$offset}px; }
        @if $i == 0 { stroke-dashoffset: #{$shapeSize}px; }
      }
      @if $i < 10 { .Icon-stroke3 { opacity: 0; } }
    }
  }
}
