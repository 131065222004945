/** @define FormSelectBox */
$componentClass: '.FormSelectBox';
.FormSelectBox {
  &-select {
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    width: 100%;
  }
  &-input {
    width: 100%;
    min-width: rem-calc(132);
    padding: rem-calc(10) rem-calc(20) rem-calc(9) rem-calc(12);
    box-shadow: none;
    background-color: $color-white;
    appearance: none;
    border: 2px solid $color-black;
    border-radius: rem-calc(5);
    &:focus {
      outline: 0;
    }
  }

  &-arrow,
  &-bullet{
    position: absolute;
    pointer-events: none;
  }

  &-arrow{
    width: rem-calc(12);
    height: rem-calc(22);
    right: rem-calc(9);
    bottom: rem-calc(10);
  }

  &--m{
    min-width: rem-calc(142);
  }

  &--underlined{
    #{$componentClass}-input{
      min-width: rem-calc(150);
      border: 2px solid $color-primary;
      border-radius:10px;
    }
  }

  &--valueAsBulletColor{

    #{$componentClass}-bullet{
      left:rem-calc(10);
      top:rem-calc(15);
      width: rem-calc(12);
      height: rem-calc(12);
      border-radius:  rem-calc(12);
      border: 1px solid #ccc;
      background: $color-white;
    }

    #{$componentClass}-input{
      padding-left: $global-padding * 1.15;
    }
  }

  &--inverted {
    #{$componentClass}-input{
      color: $color-white;
      border-color: $color-gray100;
      background-color: $color-black;

    }

    #{$componentClass}-bullet{
      border: 1px solid $color-gray100;
    }
  }

  .Icon-base{
    fill: $color-primary;
  }

  &.has-error {
    #{$componentClass}-input{
      color: $color-red;
      border-color: $color-red;
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass}-input {
    background-color: $color-black-darkmode;
    color: $color-white-darkmode;
    border: 2px solid $color-white-darkmode;
  }
}
