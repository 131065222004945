/** @define Lightbox */

/**
 * SEO: changed width and height from 100% to 0
 * @see https://jira.db-n.com/browse/OP-2429
 */
$componentClass: '.Lightbox';
.Lightbox{
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 999;
  opacity: 0;

  &-backdrop{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.8);
    z-index: -1;
    > * {
      height: 100%;
      width: 100%;
    }

    canvas {
      opacity: 0;
      animation: ani-fade-in 2s 250ms forwards;
    }
  }

  &-inner{
    display: flex;
    align-items: center;
    width: inherit;
    height: 100%;
    overflow-y: hidden;

    padding: $global-padding * 2.5 $global-padding $global-padding;

    @include media($media_s) {
      padding: $global-padding * 2.5 rem-calc(10) $global-padding;
    }
  }

  &-content {
    @include media($media_s) {
      width: 100%;
    }
  }

  &--scrollable {
    .Lightbox-inner {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }

  &--alignLeft { .Lightbox-content { margin-right: auto; }}
  &--alignRight {.Lightbox-content { margin-left: auto; }}
  &--alignBottom { .Lightbox-content { margin-top: auto; }}
  &--alignTop { .Lightbox-content { margin-bottom: auto; }}
  &--alignCenter { .Lightbox-content { margin-left: auto; margin-right: auto; }}
  &--alignMiddle { .Lightbox-content { margin-top: auto; margin-bottom: auto; }}

  &-close,
  .UIOverlayWrapper-close,
  .TeaserLayerDialog-close {
    position: absolute;
    right: 0;
    top: rem-calc(-40);
    padding: rem-calc(8);
    background-color: $color-white;
    border-radius: $border-radius-s;
    z-index: 3;

    .Icon {
      display: block;
      width: 15px;
      height: 15px;
      svg {
        width: inherit;
        height: inherit;
      }
    }
  }

  .UIOverlayWrapper--themeDark .UIOverlayWrapper-close {
    background-color: $color-black;
    border: 2px solid $color-gray-dark;
  }

  .TeaserLayerDialog-close > * > *:not(.Icon) {
    display:none;
  }

  &--contained {

    .Lightbox-inner{
      @include media($media_s) {
        padding: $global-padding * 2.5 rem-calc(10) rem-calc(10);
      }
    }

    .Lightbox-content{
      position: relative;
      padding: $global-padding $global-padding;
      background: $color-white;
    }

    .Lightbox-content {
      height: 100%;
    }

    @include media($media_m-l) {
      .Lightbox-content {
        width: 70vw;
        height: 70vh;
      }
    }
  }
}
