$componentClass: '.ContentAccordion';
.ContentAccordion {
  .AccordionSection-label {
    font-family: $font-family-condensed;
    font-weight: $font-weight-normal;
    font-size: $h-xxs_font-size;
    color: $color-orange;
    letter-spacing: 0.02em;
    line-height: $subheading_line-height;

    &::after {
      top: 10%;
    }

    &:hover {
      &::after {
        background-image: $color-svg-orange;
      }
    }
  }

  .AccordionSection-inner {
    padding: 0;
  }

  .AccordionSection-content,
  .AccordionSection-title {
    background-color: transparent;
  }

  &--themeDark {
    .AccordionSection-label {
      &::after {
        background-image: $color-svg-white;
      }
    }
  }
}
