/** @define TeaserFreeForm */
$componentClass: '.TeaserFreeForm';
.TeaserFreeForm {
  border-radius: $border-radius-m;
  margin-right: rem-calc(16);
  margin-left: rem-calc(16);
  padding-right: rem-calc(20);
  padding-left: rem-calc(20);
  background: $gradient-primary;

  &-inner {
    @include outerContainer($max-width-l);

    flex-wrap: wrap;
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-image {
    display: flex;
    align-items: flex-end;

    img {
      width: 100%;
    }
  }

  &--themeLight {
    background: $gradient-neutral;
  }

  @include media($media_m-l) {
    &-content {
      margin-right: 0;

      #{$componentClass}--contentLeft & {
        margin-right: $global-padding * 2;
        margin-left: 0;
      }
    }
  }

  @include media($media_s-m) {
    margin-right: rem-calc(8);
    margin-left: rem-calc(8);
  }

  @include media($media_s) {
    &-inner {
      #{$componentClass}--mobileContentTop & {
        flex-wrap: wrap-reverse;
      }
    }
  }
}
