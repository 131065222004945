.Chat {
  display: flex;
  flex-direction: column;

  &-content {
    flex-grow: 1;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: $color-gray-dark;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #555;
    }
  }

  &-messages {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
  }

  &-message {
    display: flex;
    width: 85%;
    margin-top: $global-padding * .5;
    min-height: rem-calc(30);

    &:first-child {
      margin-top: $global-padding;
    }

    &:last-child {
      margin-bottom: $global-padding;
    }

    @include media($media_m-l) {
      width: 60%;
    }
    .Chat-messageIcon {
      flex-shrink: 0;
      width: rem-calc(30);
      margin: 0 $global-padding * .5 0 0;
      align-self: flex-start;
      color: $color-white;
    }

    &:not(.Chat-message--chatbot) {
      margin-left: auto;
      margin-right: $global-padding * .5;
      justify-content: flex-end;
      .Chat-messageIcon {
        margin: 0 0 0 $global-padding * .5;
      }
    }

    &:not(.Chat-message--chatbot) + &--chatbot,
    &--chatbot + &:not(.Chat-message--chatbot) {
      margin-top: $global-padding;
    }

    &--isTyping {
      .Chat-messageText > *:nth-child(1) {
        display: none;
      }
      .Chat-messageText > *:nth-child(2) {
        display: inline-block !important;
      }
    }
  }

  &-messageText {
    background-color: $color-white;
    border-radius: 10px;
    padding: rem-calc(9) rem-calc(14);
    text-align: left;

    em { color: $color-primary; }
    strong { font-weight: $font-weight-bold }
    > *:nth-child(1)  {
      line-height: rem-calc(24);
    }
    > *:nth-child(2) {
      display: none;
      font-size: 2rem;
      line-height: 0;
      > * {
        display: inline-block;
        animation: wave 2s infinite ease-in-out;
      }

      > *:nth-child(1) { animation-delay: 0s; }
      > *:nth-child(2) { animation-delay: 0.1s; }
      > *:nth-child(3) { animation-delay: 0.2s; }
    }
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  &-footer {
    animation: fadein 1s;
    flex-grow: 0;

    @include media($media_s) {
      padding-top: $global-padding * .25;
      > * {
        width: 100%;
      }
      > * + * {
        margin-top: $global-padding * .5;
      }
    }

    @include media($media_m-l) {
      display: flex;
      margin: 0 $global-padding 0 $global-padding * 1.75;
      padding-top: $global-padding * .5;
      > * {
        flex-grow: 1;
      }
      > * + * {
        margin-left: $global-padding;
      }
    }

    .TextLink--asButton.is-disabled,
    .TextLink--asButton.is-disabled:hover {
      border-color: $color-gray-dark !important;
      color: $color-gray100 !important;
      background-color: $color-gray-dark !important;
    }
  }

  &-form {
    display: flex;
    .TextLink {
      flex-grow: 0;
      align-self: flex-end;
    }

  }

  &-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .TextLink {
      width: rem-calc(80);
    }
  }

  &-field {
    position: relative;
    margin-right: $global-padding * .5;
    flex-grow: 1;

    &::after {
      content: '€';
      position: absolute;
      right: $global-padding * .5;
      bottom: .75em;
      color: $color-white;
    }

    @include media($media_s) {
      > * + * {
        margin-top: $global-padding * .25;
      }
      input {
        width: 100%;
      }
    }

    @include media($media_m-l) {
      display: flex;
    }

    label {
      color: $color-white;
      flex-grow: 0;
      align-self: center;
      margin-right: $global-padding * .5;
    }

    input {
      padding: $global-padding * .5;
      border-radius: $border-radius-m;
      background-color: $color-gray-dark;
      color: $color-white;
      font-weight: $font-weight-bold;
      text-align: center;
      flex-grow: 1;
    }
  }


  @keyframes wave {
    0%, 60%, 100% {
      transform: translateY(0);
    }

    20% {
      transform: translateY(2px);
    }

    40% {
      transform: translateY(-6px);
    }
  }
}
