/** @define OffersList */
$componentClass: '.OffersList';
.OffersList {
  position: relative;
  margin-bottom: $global-padding;

  > #{$componentClass}-additionalHeader {
    margin-bottom: $global-padding * 1.5;
  }

  > #{$componentClass}-header {
    margin-bottom: $global-padding;
  }

  > #{$componentClass}-header,
  > #{$componentClass}-additionalHeader {
    > * + * {
      margin-top: $global-padding * .5;
    }

    @include media($media_m-l) {
      margin-bottom: $global-padding * 2;
    }
  }

  &-list {
    display: block;
    + #{$componentClass}-list {
      margin-top: $global-padding * 3;
    }
    #{$componentClass}-header {
      display: flex;
      align-items: flex-end;
      margin-bottom: $global-padding;
      > * + * {
        margin-left: $global-padding * .25;
      }

      .Icon {
        width: rem-calc(60);
        line-height: 0;
        svg {
          width: inherit;
          height: auto;
        }
      }
    }
  }

  &-items {
    @include media($media_m-l) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: stretch;
      flex-wrap: wrap;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: $global-padding;

    &:not(:root:root) {
      display: block;
    }

    @include media($media_s) {
      width: 100%;
      &:last-child {
        padding-bottom: 0;
      }
    }

    @include media($media_m-l) {
      width: 50%;

      &:last-child, &:nth-last-child(2):nth-child(2n-1) {
        padding-bottom: 0;
      }
    }

    @include media($media_m) {
      &:nth-child(2n) {
        padding-left: $global-padding * .5;
      }
      &:nth-child(2n-1) {
        padding-right: $global-padding * .5;
      }
    }

    @include media($media_l) {
      &:nth-child(2n) {
        padding-left: $global-padding;
      }
      &:nth-child(2n-1) {
        padding-right: $global-padding;
      }
    }
  }

  &-link {
    text-align: center;
    @include media($media_s) {
      margin-top: $global-padding;
      .TextLink {
        width: 100%;
      }
    }
    @include media($media_m-l) {
      margin-top: $global-padding * 2;
      .TextLink {
        min-width: rem-calc(250);
      }
    }
  }

  &-callouts {
    position: relative;
  }

  .Callout--label {
    height: 100%;
  }
}
