/** @define FieldInput */
$componentClass: '.FieldInput';
.FieldInput{

  .FieldMessenger-hint {
    color: $color-gray-dark;
  }

  &-inner {
    position:relative;
  }

  &-input{
    position: relative;
    display: block;
    font-size: 1em;
    font-weight: $font-weight-normal;
    width: 100%;
  }

  &-label{
    color: $color-gray100;
    pointer-events: none;
  }

  &-input + &-label{
    position:absolute;
    line-height: 1rem;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition:all $timing-duration $timing-easing;
  }

  &-input:focus + &-label {
    top: 0;
    font-size: $copy-secondary_font-size;
    &:after {
      width: 100%;
      left: 0;
    }
  }

  &--themeDefault {
    #{$componentClass}-input{
      color: $color-black;
      padding: rem-calc(21) rem-calc(15) rem-calc(6);
      border: 1px solid $color-gray100;
      border-radius: $border-radius-s;
      transition: border-color $timing-duration $timing-easing;
      &:focus {
        border-color: $color-black;
      }
    }
    #{$componentClass}-input + #{$componentClass}-label {
      top:50%;
      left: rem-calc(15);
      transform: translateY(-50%);
      color: $color-gray100;
    }
  }

  &--themeCompact {
    #{$componentClass}-input{
      padding:1.75em 0 .5em 0;
      font-weight: $font-weight-bold;
    }
    #{$componentClass}-label{
      font-weight: $font-weight-bold;
    }
    #{$componentClass}-input + #{$componentClass}-label {
      top:50%;
      right:0;
      bottom:0;
      left:0;
      &:before, &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100%);
        margin-top:-1.5em;
        border-bottom: 1px solid $color-gray50;
      }
      &:after{
        width: 0;
        left: 50%;
        border-bottom: 3px solid $color-primary;
        transition:all $timing-duration $timing-easing;
      }
    }
    #{$componentClass}-input:focus + #{$componentClass}-label {
      &:after {
        width: 100%;
        left: 0;
      }
    }
  }

  &--inverted {
    #{$componentClass}-input {
      color: $color-white;
    }
    &#{$componentClass}--themeDefault {
      #{$componentClass}-input {
        border-color: $color-gray100;
        &:focus {
          border-color: $color-gray50;
        }
      }
    }
  }

  &--error{

    #{$componentClass}-input:focus + #{$componentClass}-label {
      color: $color-gray100;
    }

    &#{$componentClass}--themeDefault {
      #{$componentClass}-input {
        border-color: $color-red;
        padding-right: rem-calc(30);
      }
      #{$componentClass}-input + #{$componentClass}-label {
        color: $color-red;
      }
      #{$componentClass}-inner:after {
        content: '';
        position: absolute;
        right: rem-calc(15);
        top: 50%;
        width: rem-calc(24);
        height: rem-calc(24);
        background-image: svg-url('<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.75 7v6h-1.5V7h1.5ZM12.005 15c.565 0 .99.428.995.998a.962.962 0 0 1-.267.7 1.029 1.029 0 0 1-.75.302.978.978 0 0 1-.983-.995A.994.994 0 0 1 12.005 15Z" fill="#D70000"/><circle cx="12" cy="12" r="10.5" stroke="#D70000"/></svg>');
        background-repeat: no-repeat;
        background-position: right bottom;
        transform: translateY(-50%);
      }
    }

    &#{$componentClass}--themeCompact {
      #{$componentClass}-input {
        color: $color-red;
      }
      #{$componentClass}-input + #{$componentClass}-label {
        color: $color-red;
        &:before,
        &:after {
          left: 0;
          border-color: $color-red;
        }
      }
    }
  }

  &--dirty {
    #{$componentClass}-input + #{$componentClass}-label {
      top: 0;
      font-size: $copy-secondary_font-size;
      color: $color-gray100;
    }

    &#{$componentClass}--themeDefault {
      #{$componentClass}-input + #{$componentClass}-label {
        top: rem-calc(6);
        font-size: rem-calc(9);
        transform: translateY(0);
      }
    }
  }

  &--valid {

    #{$componentClass}-inner:after {
      content: '';
      position: absolute;
      width: rem-calc(15);
      height: rem-calc(11);
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.56 29.99"><polygon fill="#{$color-success}" points="10.56 29.99 0 19.42 2.82 16.6 10.66 24.43 36.84 0 39.56 2.92 10.56 29.99"/></svg>');
      background-repeat: no-repeat;
      background-position: right bottom;
    }

    &#{$componentClass}--themeCompact {
      #{$componentClass}-input {
        padding-right: 17px;
      }
      #{$componentClass}-inner:after {
        right: 0;
        bottom: .7em;
      }
    }

    &#{$componentClass}--themeDefault {
      #{$componentClass}-input {
        padding-right: rem-calc(30);
      }
      #{$componentClass}-inner:after {
        right: rem-calc(15);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &--readOnly {
    &#{$componentClass}--themeDefault {
      #{$componentClass}-input {
        color: $color-gray50 !important;
        border-color: $color-gray50 !important;
      }

      #{$componentClass}-input + #{$componentClass}-label {
        color: $color-gray50 !important;
      }

      &:not(.FieldInput--dirty) #{$componentClass}-input + #{$componentClass}-label {
        top: rem-calc(6);
        font-size: rem-calc(9);
        transform: translateY(0);
      }
    }

    &#{$componentClass}--themeCompact {
      #{$componentClass}-input {
        color: $color-gray50 !important;
      }

      #{$componentClass}-input + #{$componentClass}-label {
        color: $color-gray50 !important;
        &:before {
          border-color: $color-gray50 !important;
        }
        &:after {
          display: none;
        }
      }

      &:not(.FieldInput--dirty) #{$componentClass}-input + #{$componentClass}-label {
        top: 0;
        font-size: $copy-secondary_font-size;
      }
    }

    #{$componentClass}-inner:after {
      display: none;
    }
  }
}
