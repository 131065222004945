/** @define MediaImageGallery */

$componentClass: '.MediaImageGallery';
.MediaImageGallery {
  width: 100%;
  height: 100%;
  padding-bottom: $global-padding * 2;
  .Slider {
    height: 100%;
  }
  .Slider-slides {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    overflow: hidden;
    @include media($media_m-l) {
      width: calc(100% - 120px);
      border: 1px solid $color-gray15;
    }
  }

  .Slider-slide {
    .MediaImage {
      width: 100%;
      height: 100%;
      text-align: center;
      img {
        height: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  @supports (object-fit: contain){
    .Slider-slide {
      .MediaImage img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

}
