/** @define SkipLink */
$componentClass: '.SkipLink';

.SkipLink {
  display: flex;
  justify-content: center;

  &-link {
    color: $color-primary;
    border: solid 2px;
    border-radius: 6px;

    width: 320px;
    height: 48px;
    padding: 12px 16px;
    margin: 10px;

    text-align: center;
    font-family: $font-family-regular;
    font-weight: 700;
    font-size: 19px;

    &--menuToggle {
      @include media($media_l) {
        display: none;
      }
    }
  }

  &-link:not(:focus-within) {
    @include media($media-s-m) {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }
  }

  /*&--facelift {
    margin: 0px 0px rem-calc(9) * -1 0px;

    @include media($media_l) {
      margin: 0px 0px rem-calc(16) * -1 0px;
    }
  }*/
}

.SkipLink:not(:focus-within) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
