/** @define CancellationHeader */
$componentClass: '.CancellationHeader';
.CancellationHeader {
  width: 100%;
  text-align: center;

  &-icon {
    svg {
      max-width: rem-calc(175);
      max-height: rem-calc(175);
    }
    @include media($media_m-l) {
      margin-bottom: $global-padding;
      svg {
        max-width: rem-calc(250);
        max-height: rem-calc(250);
      }
    }
  }

  &-headline {
    margin-bottom: $global-padding;
  }
}
