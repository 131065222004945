/** @define UIDialogLayer */
$componentClass: '.UIDialogLayer';
.UIDialogLayer{
  width:100%;
  position: relative;
  padding: $global-padding $global-padding;
  text-align: center;
  background-color: $color-white;
  border: rem-calc(5) solid $color-black;

  @include media($media_s) {
    max-width: rem-calc(310);
  }

  @include media($media_m) {
    width: 50vw;
  }

  @include media($media_l) {
    width: 30vw;
  }

  &-copy {
    margin-bottom: $global-padding;
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
  }

  &-close{
    position: absolute;
    right: $global-padding * .5;
    top: 0;
    width: rem-calc(25);
    height: rem-calc(25);
    background-color: $color-black;
    text-align: center;
    border-radius: 0 0 rem-calc(5) rem-calc(5);

    button{
      width: inherit;
      height: inherit;
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      outline: 0;
      text-align: center;
      svg {
        width: rem-calc(10);
      }
      .Icon-base{
        fill: $color-gray50;
        transition: fill $timing-duration $timing-easing;
      }
    }

    button:hover{
      .Icon-base{
        fill: $color-gray100;
      }
    }
  }

}
