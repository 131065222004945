/** @define AccountLink */
$componentClass: '.AccountLink';
.AccountLink {
  display: inline-block;
  position: relative;
  cursor: pointer;

  .TextLink {
    display: inline-block;

    &:after {
      display: none;
    }

    .TextLink-icon + .TextLink-text {
      margin-left: $global-padding * .75;
    }

    .TextLink-icon {
      width: rem-calc(30);
      height: rem-calc(30);
      border-radius: 50%;
      padding: rem-calc(3) 0;
      text-align: center;
    }

    .TextLink-text {
      @include media($media_s-m) {
        display: none;
      }
    }

    .Icon {
      display: inline-block;
      height: inherit;
    }

    .Icon-base {
      fill: $color-black !important;
    }
  }

  &.u-elementCountAfter {
    &::after {
      position: absolute;
      z-index: 1;
      top: $NavMain_height * .5;
      left: 0;
      transform: translate(50%, -100%);
      pointer-events: none;
    }
  }

  &--withAvatar {
    .TextLink {
      .TextLink-icon {
        background-color: $color-gray150;
        padding: 0;

        svg {
          width: 100%;
          vertical-align: middle;
        }
      }
    }
  }

  &--loyaltyLevel1 {
    .TextLink {
      .TextLink-icon {
        border: 2px solid $color-loyalty-l1;
      }
    }
  }

  &--loyaltyLevel2 {
    .TextLink {
      .TextLink-icon {
        border: 2px solid $color-loyalty-l2;
      }
    }
  }
}
