/** @define Checkout Form */
$componentClass: '.ActivationForm';

.ActivationForm {
  .ActivationFormConfirm {
    &-summaryListValue {
      display:flex;
      flex-direction:row;
      justify-content: space-between;
    }
    &-summaryDataListView {
      li {
        &:not(:first-child) {
          margin-top:$global-padding * 0.25;
        }
      }
    }

    &-list {
      @include listFormater($inherit: true, $size: 5px);
      margin-bottom: 0;
      .TextLink {
        font-size: rem-calc(12);
        line-height: rem-calc(12);
      }
    }

    &--bewStyling {
      .FormHeader {
        .FormHeader-copy {
          .TextCopy {
            @include bewTextStyling($color-default, $h-xxxs_font-size, $font-family-regular, 0.2px, 18px)
          }
          .TextLink {
            font-size: $h-xxxs_font-size;
          }
        }

        .TextHeadline {
          @include bewTextStyling($color-primary, $h-xs_font-size, $font-family-condensed, 0.2px, inherit );
        }

        .TextCopy {
          @include bewTextStyling($color-default, $h-xxxs_font-size, $font-family-regular, 0.2px, 18px);
        }
      }

      .FieldCheckbox {
        @include bewTextStyling($color-default, $h-xxxs_font-size, $font-family-regular, 0.2px, 18px);

        &-formOptional {
          display: none;
        }

        .TextCopy {
          @include bewTextStyling($color-default, $h-xxxs_font-size, $font-family-regular, 0.2px, 18px);
        }
      }

      .FormSection-inner {
        .TextCopy--small {
          @include bewTextStyling($color-default, $h-xxxs_font-size, $font-family-regular, 0.2px, 18px)
        }
      }
    }

    .FieldCheckbox-labelInner {
      small {
        @include bewTextStyling($color-gray200, 10px, $font-family-regular, 0.2px, 16px)
      }
    }
  }

  .ActivationFormSimData {
    &-instructions {
      img {
        display: block;
        width: 100%;
      }
    }

    .FormSection {
      position: relative;
    }

    @include media($media_s) {
      &-instructions {
        margin-top: $global-padding;
      }
    }

    @include media($media_m-l) {
      &-instructions {
        position: absolute;
        top: 0;
        right: 0;
        width: 60%;
        padding: $global-padding;
      }
    }
  }

  // &-header {

  //   margin: $global-padding * 2 0;
  //   padding: $global-padding $global-padding * .75;
  //   background-color: $color-gray15;
  //   height: 9em;

  //   .combo {
  //     width: 50%;
  //     float: left;

  //     div:first-child {
  //       font-size: 0.8em;
  //       line-height: 2em;
  //     }

  //     div:last-child {
  //       font-weight: bold;
  //       font-size: 1em;
  //     }
  //   }

  // }
}
