/** @define MyPromoDetails */
$componentClass: '.MyPromoDetails';
.MyPromoDetails{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  &-content,
  &-header {
    @include outerContainer($max-width);
  }

  &-header,
  &-content,
  &-background,
  .MediaImage {
    width: 100%;
  }

  &-header {
    @include globalPadding($topRatio: 2);
    .TextHeadline--xs {
      line-height: rem-calc(24);
    }
  }

  &-background {
    display: flex;
    align-items: center;
    background-size: cover;

    @include media($media_m-l) {
      min-height: rem-calc(330);
      margin-bottom: $global-padding;
    }
  }

  

  &-content {
    @include globalPadding($horizontal: false, $topRatio: 0, $bottomRatio: 2);
    align-self: flex-end;
    > * + * {
      margin-top: $global-padding;
    }
  }

  * + &-content {
    @include globalPadding($horizontal: false, $bottomRatio: 2);
  }

  &-buttons {
    @include media($media_s) {
      > * {
        width: 100%;
      }

      > * + * {
        margin-top: $global-padding * .5;
      }
    }
    @include media($media_m-l) {
      display: flex;
      justify-content: flex-start;
      > * + * {
        margin-left: $global-padding;
      }
    }
  }

  &-button {
    .MyPromoDetails-hint,
    .TextLink {
      display: block;
      width: 100%;
    }
    @include media($media_m-l) {
      .MyPromoDetails-hint {
        margin-top: $global-padding * .25;
      }
    }
  }

  &-hints {
    margin-top: $global-padding * .5;
    @include media($media_s) {
      text-align: center;
    }
  }

}

.MyPromoDetails .FormManager {
  width: 100%;
}
