/** @define ServiceLottieAnimation */
$componentClass: '.ServiceStickyContent';
.ServiceLottieAnimation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  visibility: hidden;

  svg {
    height: auto !important;
  }

  &--alignTop {
    align-items: flex-start;
  }

  &--alignBottom {
    align-items: flex-end;
  }

  &--sizeCover {
    svg {
      position: absolute;
      width: auto !important;
    }
    &#{$componentClass}--alignTop {
      svg {
        top: 0;
      }
    }

    &#{$componentClass}--alignBottom {
      svg {
        bottom: 0;
      }
    }
  }

  &.is-loaded {
    visibility: visible;
  }
}
