/** @define MyESimPushForm */
$componentClass: '.MyESimPushForm';
.MyESimPushForm {
  &-infoIcon {
    .TextLink-icon {
      width: $copy_font-size;
    }

    .Icon-base,
    .Icon-highlight {
      fill: $color-primary;
    }
  }
}
