/** @define TextInfo */
$componentClass: '.TextInfo';
.TextInfo {
  &-link {
    color: $color-primary;
    transition: color $timing-duration $timing-easing;
    line-height: 1.05em;
    vertical-align: middle;
    text-align: left;
    .TextLink-icon {
      transform: translateY(6%);
    }
    svg {
      width: 1.05em;
      height: 1.05em;
    }
    .Icon-highlight,
    .Icon-base {
      fill: $color-primary;
      transition: fill $timing-duration $timing-easing;
    }

    &:hover, &:focus {
      color: $color-primary-dark;
      .Icon-highlight,
      .Icon-base {
        fill: $color-primary-dark;
      }
    }
  }

  &-content {
    > * + * {
      margin-top: $global-padding * .25;
    }
  }


  &--withContentBox {
    #{$componentClass}-content {
      padding: $global-padding * .5;
      background-color: $color-gray15;
      border: 1px solid $color-gray50;
    }
  }

  &--inverted {
    &#{$componentClass}--withContentBox #{$componentClass}-content {
      background-color: $color-gray-dark;
      border: 1px solid $color-gray200;
    }
  }


  &.is-open {
    #{$componentClass}-link + #{$componentClass}-content {
      margin-top: $global-padding * .5;
    }
  }

  &:not(.is-open) {
    #{$componentClass}-content {
      height: 0;
      padding: 0;
      border: none;
      overflow: hidden;
    }
  }
}
