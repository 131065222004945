$toggle-nav_line-size: 3;
$toggle-nav_gutter-size: 5;
$toggle-nav_color: $color-black;
$toggle-nav_timing-duration: .5s;
$toggle-nav_width: 28;
$toggle-nav_height: $toggle-nav_line-size * 3 + $toggle-nav_gutter-size * 2;
$toggle-nav_middle-offset: $toggle-nav_height * .5 - $toggle-nav_line-size * .5;

@keyframes line-m-in-ani {
  0%   { transform: translateX(0); opacity:1;}
  30% { transform: translateX(10%); opacity:1;}
  100% { transform: translateX(-100%); opacity:0; }
}

@keyframes line-t-in-ani {
  0%   { transform: translate(0, 0) rotate(0);}
  25% { transform: translate(0, -#{$toggle-nav_line-size}px) rotate(-2deg);}
  85% { transform: translate(0, #{$toggle-nav_middle-offset}px) rotate(45deg); }
  100% { transform: translate(0, #{$toggle-nav_middle-offset}px) rotate(45deg); }
}

@keyframes line-b-in-ani {
  0%   { transform: translate(0, 0) rotate(0);}
  25% { transform: translate(0, #{$toggle-nav_line-size}px) rotate(2deg);}
  85% { transform: translate(0, -#{$toggle-nav_middle-offset}px) rotate(-45deg); }
  100% { transform: translate(0, -#{$toggle-nav_middle-offset}px) rotate(-45deg); }
}

@keyframes line-m-out-ani {
  0% { transform: translateX(-100%); opacity:0; }
  100%   { transform: translateX(0); opacity:1;}

}

@keyframes line-t-out-ani {
  0% { transform: translate(0, #{$toggle-nav_middle-offset}px) rotate(45deg); }
  100% { transform: translate(0, 0) rotate(0); }
}

@keyframes line-b-out-ani {
  0% { transform: translate(0, -#{$toggle-nav_middle-offset}px) rotate(-45deg); }
  100% { transform: translate(0, 0) rotate(0); }
}

/** @define ButtonToggleIcons */
.ButtonToggleIcons{
    $toggle-nav_height: $toggle-nav_line-size * 3 + $toggle-nav_gutter-size * 2;
    position: relative;
    display: inline-block;
    width: rem-calc($toggle-nav_width);
    height: rem-calc($toggle-nav_height);
    cursor: pointer;
    background-color: transparent;
    border:none;
    outline: 0;
    box-sizing: content-box;

    &-line{
        display:block;
        width:rem-calc($toggle-nav_width);
        height: rem-calc($toggle-nav_line-size);
        background-color: $toggle-nav_color;
        transform: translate(0, 0);
        transform-origin: 50%;

        &:nth-child(1){
            animation:line-t-out-ani $toggle-nav_timing-duration cubic-bezier(0.6, -0.28, 0.735, 0.045);
        }
        &:nth-child(2){
            margin: rem-calc($toggle-nav_gutter-size) auto;
            animation:line-m-out-ani $toggle-nav_timing-duration cubic-bezier(0.6, -0.28, 0.735, 0.045);
        }
        &:nth-child(3){
            animation:line-b-out-ani $toggle-nav_timing-duration cubic-bezier(0.6, -0.28, 0.735, 0.045);
        }
    }

    &.is-cross &-line{

        &:nth-child(1){
            animation:line-t-in-ani $toggle-nav_timing-duration cubic-bezier(0.6, -0.28, 0.735, 0.045) .15s;
            animation-fill-mode: forwards;
        }
        &:nth-child(2){
            animation:line-m-in-ani $toggle-nav_timing-duration cubic-bezier(0.6, -0.28, 0.735, 0.045);
            animation-fill-mode: forwards;
        }
        &:nth-child(3){
            animation:line-b-in-ani $toggle-nav_timing-duration cubic-bezier(0.6, -0.28, 0.735, 0.045) .15s;
            animation-fill-mode: forwards;
        }
    }
}

