/** @define HeaderStageItem */
.HeaderStageItem {
  position: relative;
  display: flex;
  width: 100%;
  color: $color-white;
  background-color: $color-black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .TextCopy h1,
  .TextCopy h2,
  .TextCopy h3,
  .TextCopy h4,
  .TextCopy h5,
  .TextCopy h6,
  .TextCopy p {
    &:not(:last-child) {
      margin-top: 0;
      margin-bottom: $global-padding * .25;
    }
    &:last-child {
      margin: 0;
    }
  }

  &-ctaOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    cursor: pointer;
    .HeaderStageItem-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem-calc(60);
      transform: translate(-50%, -50%);
    }
    .Icon-base{
      fill: $color-white;
      transition: fill $timing-duration $timing-easing;
    }
    &:hover {
      .Icon-base{
        fill: $color-orange;
      }
    }
  }

  &-inner {
    width: 100%;
    overflow: hidden;
    @include outerContainer($max-width);
    @include globalPadding();

    @include media($media_l) {
      @include globalPadding($queries: false);
    }
    &:hover {
      .Icon-base {
        fill: $color-orange;
      }
    }
  }

  &-image {
    text-align: center;
    img {
      max-width: 100%;
      width: 100%;
    }
  }

  &-copy {
    .HeaderStageItem-headline:not(:only-child) {
      > *:first-child:only-child {
        display: inline;
      }
    }
    .Tag {
      font-size: 70%;
    }
  }

  &-copy,
  &-paymentFee,
  &-singlePaymentFee {
    .TextUnit,
    .TextCopy {
      text-shadow: 1px 1px 2px rgba(0, 0, 0, .75);
    }

    .TextLink > * {
      text-shadow: none;
    }
  }

  &-paymentFee,
  &-singlePaymentFee {
    margin-bottom: $global-padding * .25;
  }

  &--typeTariff,
  &--typeVideo,
  &--typeInfo,
  &--typeHardware:not(.HeaderStageItem--featured) {
    text-align: center;
    .HeaderStageItem-inner {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
    }
    .HeaderStageItem-content {
      width: 100%;
    }
  }

  &--typeHardware:not(.HeaderStageItem--featured) {
    .HeaderStageItem-inner {
      display: flex;
      justify-content: flex-start;
    }

    .HeaderStageItem-content {
      position: absolute;
      bottom: 0;
      left: 0;
      @include globalPadding($queries: false);
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,.85) 100%);
    }

    .HeaderStageItem-image {
      max-width: rem-calc(230);
    }
  }

  &--typeBundle {

    .HeaderStageItem-inner {
      display: flex;
    }

    .HeaderStageItem-prices {
      margin-bottom: $global-padding * .5;
    }

    @include media($media_s) {
      .HeaderStageItem-inner {
        flex-direction: column;
      }
      .HeaderStageItem-content {
        order: -1;
        text-align: center;
        margin-bottom: $global-padding;
      }

      .HeaderStageItem-copy {
        margin-bottom: $global-padding * .5;
      }

      .HeaderStageItem-image {
        max-height: rem-calc(342 * .75);
        //overflow: hidden;
        img {
          width: auto;
          max-height: rem-calc(342);
        }
      }
    }

    @include media($media_m-l) {
      .HeaderStageItem-inner {
        justify-content: center;
      }
      .HeaderStageItem-content {
        display: flex;
        flex-direction: column;
        width: 66.6666%;
      }
      .HeaderStageItem-copy {
        margin-bottom: $global-padding * 2;
      }
      .HeaderStageItem-image {
        width: 33.3333%;
        margin-right: $global-padding * 2;
      }
    }
  }

  &--featured {
    min-height: 100%;
    .HeaderStageItem-inner {
      position: relative;
    }

    // IE11 Fix - Force redraw
    .HeaderStageItem-content {
      min-height: 1px;
    }

    @include media($media_s) {
      .HeaderStageItem-inner {
        padding-top: $global-padding * 2;
        padding-bottom: $global-padding * 2;
      }
    }

    @include media($media_m) {
      .HeaderStageItem-inner {
        padding-top: $global-padding * 2.5;
        padding-bottom: $global-padding * 2.5;
      }
    }

    @include media($media_l) {
      .HeaderStageItem-inner {
        padding-top: $global-padding * 3;
        padding-bottom: $global-padding * 3;
      }
    }

    // FEATURED TYPE INFO
    &.HeaderStageItem--typeInfo {
      .HeaderStageItem-inner {
        display: flex;
        justify-content: center;
      }
      .HeaderStageItem-link {
        margin-top: $global-padding;
      }
    }

    // FEATURED TYPE HARDWARE
    &.HeaderStageItem--typeHardware {
      .HeaderStageItem-inner {
        display: flex;
        justify-content: center;
      }

      .HeaderStageItem-copy,
      .HeaderStageItem-prices {
        margin-bottom: $global-padding * .5;
      }

      .HeaderStageItem-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      .HeaderStageItem-subcontent {
        margin-top: auto;
        margin-bottom: $global-padding * .5;
      }

      @include media($media_s) {
        .HeaderStageItem-inner {
          flex-direction: column;
        }
        .HeaderStageItem-content {
          order: -1;
          text-align: center;
          margin-bottom: $global-padding;
        }

        .HeaderStageItem-image {
          max-height: rem-calc(342 * .75);
          //overflow: hidden;
          img {
            width: auto;
            max-height: rem-calc(342);
          }
        }
      }

      @include media($media_m-l) {
        .HeaderStageItem-image {
          flex-grow: 0;
          margin-right: $global-padding * 2;
          max-width: rem-calc(230);
        }
      }
    }
  }

  &--alignLeft {
    &.HeaderStageItem--featured {
      @include media($media_s) {
        text-align: center;
      }
      @include media($media_m-l) {
        text-align: left;
      }
    }
  }
  &--alignRight {

    &.HeaderStageItem--featured {
      @include media($media_s) {
        text-align: center;
      }
      @include media($media_m-l) {
        text-align: right;
      }
    }
  }
  &--alignCenter {
    &.HeaderStageItem--featured {
      text-align: center;

      &.HeaderStageItem--typeBundle {
        .HeaderStageItem-inner {
          position: relative;
        }
        .HeaderStageItem-content {
          @include media($media_m) {
            padding-bottom: $global-padding * 2.5;
          }
          @include media($media_l) {
            padding-bottom: $global-padding * 3;
          }

          @include media($media_m-l) {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
          }
        }

        .HeaderStageItem-image {
          margin: 0;
        }
      }
    }
  }

  &--positionTop {}
  &--positionBottom {
    &.HeaderStageItem--featured {
      .HeaderStageItem-inner {
        justify-content: flex-end;
      }
    }
  }
  &--positionCenter {
    &.HeaderStageItem--featured {
      .HeaderStageItem-inner {
        justify-content: center;
      }
    }
  }

  .Callout {
    position: absolute;
    top: rem-calc(10);
    right: rem-calc(10);
    @include media($media_m_l) {
      top: rem-calc(-15);
    }
    .HeaderStage-item--featured & {
      bottom: rem-calc(12);
      top: auto;
      right: rem-calc(40);
      @include media($media_m_l) {
        right: auto;
        left: 0;
        bottom: auto;
        top: rem-calc(40);
      }
    }
  }
}
