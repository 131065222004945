.EECCCheckSummaryConsentDialogCopy {
  &-container:nth-child(2){
    margin-top: $global-padding;
  }

  &-container:only-child {
    margin-top: -15px;
  }

  &-copyLink {
    color: $color-orange;

    &:hover {
      color: $color-orange-dark;
      cursor: pointer;
      border-bottom: 2px solid $color-orange-dark;
    }
    &:visited {
      color: $color-orange-dark;
    }
  }
}
