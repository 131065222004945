/** @define TariffOptions */

$componentClass: '.TariffOptions';
.TariffOptions {
  $borderSize: 2px;
  overflow: hidden;

  @include media($media_m-l) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &-content {
      width: calc(50% - 50px);
    }
    &-subcontent {
      width: calc(50% + 50px);
    }
  }

  &-subcontent {
    @include media($media_s) {
      display: flex;
      flex-direction: column;
    }
  }

  &-options {
    @include media($media_s) {
      order: -1;
      margin-bottom: $global-padding;
    }

    .Slider {
      margin-bottom: $global-padding;
    }
  }
  &-footer {
    width: 100%;
    color: $color-gray100;

    @include media($media_m-l) {
      padding-top: $global-padding * 2;
      text-align: center;
    }
  }
  &-headline {
    margin-bottom: rem-calc(10);
    @include media($media_s) {
      display: none;
    }
  }
  &-copy {
    margin-bottom: rem-calc(15);
    padding: 0 $global-padding;
    color: $color-gray100;
  }
  &-option {
    position: relative;
    padding: $global-padding $global-padding;

    @include media($media_s) {
      &:not(.is-selected) {
        @include borderDashed($color-gray100, true, true, true, true);
      }
    }
    @include media($media_m-l) {
      border: 2px solid transparent;
      &:not(.is-selected) {
        &:before {
          content: '';
          position: absolute;
          top: -$borderSize;
          bottom: -$borderSize;
          left: $global-padding;
          right: $global-padding;
          @include borderDashed($color: $color-gray100, $bottom: true);
        }
      }
    }

    &:first-child {
      &:before {
        @include borderDashed($color: $color-gray100, $top: true, $bottom: true);
      }
    }

    @include media($media_s) {
      &.is-upselling {
        @include borderDashed($color-orange, true, true, true, true);
      }
    }

    @include media($media_m-l) {
      &:nth-child(2) {
        transform: translateY(-$borderSize);
      }
      &:nth-child(3) {
        transform: translateY(-$borderSize*2);
      }
      &:nth-child(4) {
        transform: translateY(-$borderSize*3);
      }
      &.is-upselling &:before {
        @include borderDashed($color: $color-orange, $bottom: true);
      }
    }

    &.is-selected {
      border: $borderSize solid $color-orange;
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        top: rem-calc(-12);
        left: calc(50% - 11px);
        width: rem-calc(22);
        height: rem-calc(22);
        background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" ><polygon fill="#{$color-orange}" points="22,9 13,9 13,0 9,0 9,9 0,9 0,13 9,13 9,22 13,22 13,13 22,13"/></svg>');
        background-repeat: no-repeat;
        background-position: center left;
        border: none;
      }

      @include media($media_s) {
        &:before {
          content: '';
          position: absolute;
          top: rem-calc(-12);
          left: calc(50% - 20px);
          width: rem-calc(40);
          height: rem-calc(22);
          background-color: $color-black;
          border: none;
        }
      }

      @include media($media_m-l) {
        &:after {
          top: 0;
          bottom: 0;
          left: rem-calc(-42);
          height: auto;
        }
      }
    }

    &.is-upselling {
      padding: $global-padding * 1.25 $global-padding $global-padding;
    }

  }

  &-links {
    margin-bottom: $global-padding * 0.5;

    @include media($media_s) {
      text-align: center;
    }

    @include media($media_m-l) {
      #{$componentClass}-link {
        display: inline-block;
      }
      #{$componentClass}-link + #{$componentClass}-link {
        &::before {
          content: '|';
          display: inline-block;
          font-size: $copy-secondary_font-size;
          padding: 0 $global-padding * .25;
        }
      }
    }
  }
}
