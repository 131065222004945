/** @define HardwareFilter */
$componentClass: '.HardwareFilter';
.HardwareFilter {
  @include media($media_s) {
    width: 100%;
  }

  &-brands {
    padding: rem-calc(15) rem-calc(15) rem-calc(15) 0;
    background-color: inherit;
  }

  &-hardware {
    margin-top: rem-calc(2);
    padding: rem-calc(15);
    background-color: $color-gray15;
  }

  &-headline {
    margin-bottom: $global-padding * .25;
  }

  .FormOptionTab:not(:last-child) {
    margin-bottom: rem-calc(10);
    margin-right:rem-calc(10);
  }

  .FormOptionBox:not(:last-child) {
    margin-right:rem-calc(26);
  }
}
