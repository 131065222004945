/** @define ContractRenewalFeeInfo */
$componentClass: '.ContractRenewalFeeInfo';
.ContractRenewalFeeInfo {
  display: inline-block;
  position: relative;
  padding: rem-calc(10) rem-calc(15);
  border: rem-calc(1) solid $color-gray50;
  border-radius: rem-calc(6);
  text-align: left;
  cursor: pointer;
  &-copy {
    margin: 0 rem-calc(25) 0 0;
  }
  &-info {
    width: rem-calc(16);
    height: rem-calc(16);
    margin: auto;
    position: absolute;
    top: 0;
    right: rem-calc(16);
    bottom: 0;
    .Icon {
      color: $color-orange;
    }
  }
}
