/** @define AccountNavigation */
$componentClass: '.AccountNavigation';
.AccountNavigation {
  display: inline-block;
  position: relative;

  &-flyout {
    pointer-events: none;
  }

  .UIOverlayWrapper {
    transition: opacity $timing-duration $timing-easing;
    text-align: left;
    z-index: -1;
    bottom: rem-calc(5);
    width: rem-calc(280);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);

    &:not(.is-visible) {
      opacity: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
    }
  }

  &-inner {
    > .TextLink {
      &:after {
        display: none;
      }
    }

    > .AccountLink {
      line-height: $NavMain_height;
    }
  }

  .TextLink-icon {
    .Icon {
      display: flex;
    }
  }

  &.is-loggedIn {
    .TextLink-icon {
      margin-top: 0;
    }
  }

  &.is-loggedIn {
    .UIOverlayWrapper {
      width: rem-calc(220);
      @include media($media_s-m) {
        height: auto;
      }
    }
  }
}
