/** @define SitemapLinkNavigation */
$componentClass: '.SitemapLinkNavigation';
.SitemapLinkNavigation{

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

  .AccordionSection-title {
    text-align: left;
  }

    .AccordionSection-label {
      font-family: $font-family-condensed;
      font-size: $subheading_font-size;
      line-height: $subheading_line-height;
      text-transform: uppercase;
    }

    &-section{
      &:not(:last-child){
        margin-bottom: $global-padding * 1.5;
      }
    }

    &-sectionLink{
      margin-bottom: $global-padding * .5;
      .TextLink {
        font-size: $subheading_font-size;
        line-height: $subheading_line-height;
      }
    }

    &-link{
      &:not(:last-child){
        margin-bottom: $global-padding * .5;
      }

      .TextLink {
        color: $color-black;
        .TextLink-text::after {
          color: $color-orange;
        }

        &:hover {
          color: $color-orange-dark;
          .TextLink-text::after {
            color: $color-orange-dark;
          }
        }
      }
    }

}
