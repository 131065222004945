@keyframes ani-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ani-fade-out {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ani-zoom-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes ani-textlink-enter {
  0% {
    background-color: $color-primary-dark;
    right: 50%;
    left: 50%;
  }

  100% {
    background-color: $color-primary-dark;
    right: 0;
    left: 0;
  }
}

@keyframes ani-textlink-leave {
  0% {
    background-color: $color-primary-dark;
    right: 0;
    left: 0;
  }

  100% {
    background-color:  rgba($color-primary-dark, 0);
    right: 0;
    left: 0;
  }

}

@keyframes ani-button-filled-enter {
  0% {
    background-color: $color-black;
    opacity: 0.08;
    right: 50%;
    left: 50%;
  }

  100% {
    background-color: $color-black;
    opacity: 0.08;
    right: 0;
    left: 0;
  }
}

@keyframes ani-button-filled-leave {
  0% {
    background-color: $color-black;
    opacity: 0.08;
    right: -2px;
    left: -2px;
  }

  100% {
    background-color: $color-black;
    opacity: 0;
    right: -2px;
    left: -2px;
  }

}

@keyframes ani-textlink-metanav-enter {
  0% {
    background-color: $color-white;
    right: 50%;
    left: 50%;
  }

  100% {
    background-color: $color-white;
    right: 0;
    left: 0;
  }
}

@keyframes ani-textlink-metanav-leave {
  0% {
    background-color: $color-white;
    right: 0;
    left: 0;
  }

  100% {
    background-color:  rgba($color-white, 0);
    right: 0;
    left: 0;
  }

}

@keyframes ani-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes ani-draw-path {
  0% {stroke-dashoffset: 100px;}
  100% {stroke-dashoffset: 0px;}
}
