/** @define FieldMessenger */
$componentClass: '.FieldMessenger';
.FieldMessenger {
  > *:first-child{
    margin-top: rem-calc(5);
  }

  &-error {
    color: $color-red;
  }

}


