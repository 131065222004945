/** @define Accordion */
$componentClass: '.Accordion';
.Accordion{
  width:100%;
}

$componentClassSlim: '.ListAccordion';
#{$componentClassSlim} {
  width: 100%;

  &Item-headline {
    background-color: $color-gray15;
    margin: 0;

    button {
      padding: 10px 15px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      #{$componentClassSlim}Item-label {
        width: 100%;
        font-family: $font-family-condensed;
        font-size: rem-calc(18);
        text-transform: uppercase;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &::after {
          content: ''; /* Necessary for pseudo-elements to render */
          width: rem-calc(12);
          height: rem-calc(19);
          background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path class="Icon-base" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
          background-repeat: no-repeat;
          background-position: center;
          transform-origin: center;
          transition: all $timing-duration $timing-easing;
        }
      }
    }
  }

  &Item-list--open {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 4px;
  }
}
