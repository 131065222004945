/** @define ButtonCustomerService */
.ButtonCustomerService{
  text-transform: none;
  border: none;
  border-radius: $border-radius-l * 3;
  &::before, &::after {
    border-radius: $border-radius-l * 3;
    border: none;
  }

  .TextLink--icon {
    svg g {
      fill: $color-black;
    }
  }

  @include media($media_s) {
    padding: $global-padding * .5;
    border: none;

    &::before, &::after {
      width: 138px;
    }
    .TextLink-text {
      padding-right: 16px;
    }
  }
}

