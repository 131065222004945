/** @define TariffItem */

$componentClass: '.TariffItemFull';
.TariffItemFull {
  width: 100%;
  padding: 0 $global-padding;
  position: relative;
  @include media($media_s) {
    padding: 0 $global-padding*0.5;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  &-header {
    overflow: hidden;
    width: 100%;
    #{$componentClass}-title {
      > *:nth-last-child(2) {
        margin-bottom: $global-padding * .25;
      }
    }
    @include media($media_s) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-bottom: $global-padding * 0.5;
      #{$componentClass}-priceMonthly {
        text-align: right;
        align-self: flex-end;
        width: 49%;
      }

      #{$componentClass}-title {
        width: 65%;
      }
      .Callout--content {
        display: inline-block;
      }
    }

    @include media($media_m-l) {
      #{$componentClass}-title {
        display: flex;
        flex-direction: column;
        height: 100%;
        > *:last-child {
          margin-top: auto;
        }
      }
      .Callout--content {
        width: 100%;
      }
    }
  }


  &-name {
    overflow: hidden;
  }
  &-title {
    overflow: hidden;
    @include media($media_s-m) {
      padding-top: 5px;
    }
  }

  &-headline {
    margin-bottom: 0.25em;
  }

  &-header {
    color: $color-black;
    padding-bottom: $global-padding * 0.5;
    @include borderDashed($color-gray100, false, false, true);
  }

  &-type {
    margin-bottom: 0.3em;
  }

  @include media($media_s) {
    &-content {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding-top: $global-padding * 0.5;
      margin-bottom: $global-padding * 0.5;
      #{$componentClass}-link {
        align-self: flex-end;
      }
    }
    &-subcontent {
      width: 100%;
    }
  }

  @include media($media_m-l) {
    display: flex;
    flex-direction: column;
    &-text {
      width: 100%;
      margin-bottom: $global-padding * 0.5;
    }

    &-header {
      flex: 0 0 auto;
      padding-bottom: rem-calc(15);
    }
    &-headline {
      margin-bottom: 0.5em;
    }
    &-content {
      flex: 1 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: rem-calc(20) 0;
      @include borderDashed($color-gray100, false, false, true);
    }
    &-subcontent {
      flex: 0 0 auto;
      padding-top: rem-calc(20);
      text-align: center;
    }
    &-copy,
    &-headline {
      width: 100%
    }
    &-priceMonthly {
      width: 50%;
      align-self: flex-end;

    }
    &-link {
      align-self: flex-end;
      margin-top: $global-padding * 0.5;
    }
  }
  .Callout--badge {
    @include media($media_s) {
      transform: scale(0.75);
      transform-origin: left center;
    }
    @include media($media_m-l) {
      width: 100%;
      text-align: right;
      margin-top: $global-padding * .5;
      transform: scale(0.95);
      transform-origin: right bottom;
    }
  }

  &-productInformation {
    margin-top: rem-calc(16);
    text-align: center;
    font-size: rem-calc(12);
    letter-spacing: 0.02px;
    text-decoration: underline;
    color: $color-default;

    &:hover, &:focus {
      color: $color-default;
    }

    &::after {
      content: none;
    }

    &--colorInverted {
      color: $color-inverted;

      &:hover, &:focus {
        color: $color-inverted;
      }
    }

    @include media($media_s) {
      margin: rem-calc(12) auto 0;
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass}-header,
  #{$componentClass}-type,
  #{$componentClass}-productInformation {
    color: $color-white-darkmode;
  }
}
