$componentClass: '.ContentTabs';

.ContentTabs {
  position: relative;
  &-list {
    @include outerContainer($max-width);
    display:flex;
    padding-bottom: 2px;

    &--alignLeft {
      justify-content: flex-start;
    }

    &--alignCenter {
      justify-content: center;
    }

    &--alignRight {
      justify-content: flex-end;
      #{$componentClass}-navigation:last-child{
        margin-right: $global-padding* 0;
      }
    }
    @include media($media_m) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }
  &-navigation {
    margin-right: $global-padding * 1.3;
    font-size: $h-s_font-size;
    font-family: $subline_font-family;
    line-height: 30px;
    color: $color-black;
    display: flex;
    &--active {
      border-bottom: 3px solid $color-orange-dark;
    }
    &--themeDark {
      color: $color-white;
    }
    &--themeLight {
      color: $color-black;
    }
    &--themeNone {
      color: $color-black;
    }

    &--withCheck {
      height: rem-calc(32);
      padding: rem-calc(6) rem-calc(16) rem-calc(6) rem-calc(6);
      margin-right: rem-calc(8);
      font-size: $copy_font-size;
      font-weight: $font-weight-bold;
      line-height: rem-calc(22);
      border-radius: 19.5px;
      color: $color-black;
      background-color: $color-gray15;
      &:hover {
        color: $color-black;
        background-color: $color-gray50;
        cursor: pointer;
      }

      &:focus {
        outline: 2px solid $color-primary;
      }

      .TextLink-text {
        display: flex;
        .Icon {
          margin-right: 8px;
        }
      }
    }
    &--withCheck#{$componentClass}-navigation--active {
      background: $gradient-primary !important;
      border-bottom: none;
    }
    &--withCheck#{$componentClass}-navigation--themeDark {
      color: $color-white;
      background-color: $color-gray-dark;
      &:hover {
        color: $color-white;
        background-color: $color-gray150;
      }
    }
  }
  @include media($media_s) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
