/** @define FieldSelectbox */
$componentClass: '.FieldSelectbox';
.FieldSelectbox {

  .FieldMessenger-hint {
    color: $color-gray-dark;
  }

  &-label {
    color: $color-gray100;
  }

  &-inner {
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    width: 100%;
  }

  &-input {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    appearance: none;
    border-radius: 0;
    border: none;

    &:focus {
      outline: 0;
    }
  }

  &-input + &-border {
    pointer-events: none;

    &::before, &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(100%);
      margin-top:-1.5em;
      border-bottom: 1px solid $color-gray50;
    }

    &::after{
      width: 0;
      left: 50%;
      border-bottom: 3px solid $color-primary;
      transition:all $timing-duration $timing-easing;
    }
  }

  &-input:focus + &-border {
    &::after {
      width: 100%;
      left: 0;
    }
  }

  &-option {
    font-weight: $font-weight-bold;
  }

  &-arrow {
    position: absolute;
    pointer-events: none;
    width: rem-calc(12);
    height: rem-calc(19);
    right: rem-calc(9);
    bottom: rem-calc(8);
    vertical-align: middle;
  }

  &--themeDefault {
    position: relative;

    .Icon-base {
      fill: $color-gray100;
    }

    #{$componentClass}-border {
      display: none;
    }

    #{$componentClass}-arrow {
      right: rem-calc(15);
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
    }

    #{$componentClass}-label {
      position: absolute;
      top: rem-calc(6);
      left: rem-calc(15);
      font-size: rem-calc(9);
      color: $color-gray100;
    }

    #{$componentClass}-input {
      padding: rem-calc(21) rem-calc(30) rem-calc(6) rem-calc(15);
      color: $color-gray100;
      border: 1px solid $color-gray100;
      border-radius: $border-radius-s;
      transition: border-color $timing-duration $timing-easing;
      &:focus {
        border-color: $color-black;
      }
    }

    #{$componentClass}-input:focus + #{$componentClass}-border + #{$componentClass}-arrow {
      .Icon-base {
        fill: $color-black;
      }
    }

    &#{$componentClass}--withoutLabel {
      #{$componentClass}-input {
        padding: rem-calc(14) rem-calc(30) rem-calc(13) rem-calc(15);
      }
      #{$componentClass}-arrow {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
      }
    }
  }

  &--themeCompact {
    #{$componentClass}-input {
      padding: rem-calc(8) rem-calc(20) rem-calc(8) 0;
      color: $color-gray100;
      font-weight: $font-weight-bold;
    }
    #{$componentClass}-label {
      font-size: $copy-secondary_font-size;
      margin-bottom: $global-padding * .15;
    }
  }

  &--s {
    width: rem-calc(65);
  }

  &--m {
    width: rem-calc(98);
  }

  &--l {
    width: rem-calc(170);
  }

  &--dirty {

    &#{$componentClass}--themeCompact {
      #{$componentClass}-input {
        color: $color-black;
        font-weight: $font-weight-bold;
      }
    }

    &#{$componentClass}--themeDefault {
      #{$componentClass}-input {
        color: $color-black;
      }
    }
  }

  &--inverted {
    .Icon-base {
      fill: $color-gray50 !important;
    }
    &#{$componentClass}--themeDefault {
      #{$componentClass}-input {
        border-color: $color-gray100;
        &:focus {
          border-color: $color-gray50;
        }
      }
    }
  }

  &--inverted#{$componentClass}--dirty {
    #{$componentClass}-input {
      color: $color-white;
    }
  }

  &--error{
    #{$componentClass}-input {
      color: $color-red;
    }
    #{$componentClass}-input + #{$componentClass}-border {
      &:before,
      &:after {
        left: 0;
        border-color: $color-red;
      }
    }

    .Icon-base {
      fill: $color-red;
    }

    &#{$componentClass}--themeDefault {
      #{$componentClass}-input {
        border-color: $color-red;
      }
    }
  }
}
