/** @define FieldValueSlider */
$componentClass: '.FieldValueSlider';
.FieldValueSlider{
  width: 100%;
  &-inner > &-label {
    margin-bottom: $global-padding * .5;
  }

  .rc-slider {
    cursor: pointer;
  }
  .rc-slider-with-marks + &-label {
    display: flex;
    justify-content: space-between;
    margin-top: $global-padding * 1.25;
    .TextCopy:first-child {
      text-align: left;
    }
  }
  &-input &-label {
    text-align: right;
  }
  .rc-slider-rail {
    background-color: $color-gray15;
    height: 2px;
  }
  .rc-slider-track {
    height: 2px;
    border-radius: 0;
    background-color: $color-primary;
  }
  .rc-slider-handle {
    margin-left: -7px;
    margin-top: -5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: none;
    background-color: $color-primary;
  }
  .rc-slider-handle:hover {
    border-color: $color-primary-dark;
  }
  .rc-slider-handle-active:active {
    box-shadow: 0 0 5px $color-primary-dark;
  }
  .rc-slider-dot {
    visibility: hidden;
  }

  .rc-slider-mark-text.rc-slider-mark-text-active {
    font-weight: $font-weight-bold;
  }

  &--withMarks {
    #{$componentClass}-input #{$componentClass}-label {
      display: flex;
      justify-content: space-between;
      margin-top: $global-padding;
      text-align: left;
    }
  }

  &--inverted {
    #{$componentClass}-label {
      color: $color-white;
    }
  }
}


