@use "sass:math";

$componentClass: '.CustomDropdown';
.CustomDropdown {
  position: relative;
  width: 100%;

  &-label {
    position: absolute;
    font-size: $copy-small_font-size;
    top: math.div($global-padding, 6);
    left: math.div($global-padding, 3);
  }

  &-input {
    padding: rem-calc(20) rem-calc(8) rem-calc(8);
    width: 100%;
    border: 1px solid $color-gray100;
    outline: none;
    border-radius: $border-radius-m;
    color: $color-default;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    #{$componentClass}.is-open & {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.is-selected #{$componentClass}-input {
    padding: rem-calc(14) rem-calc(64) rem-calc(14) rem-calc(8);
  }

  &-option {
    background-color: $color-white;
    padding: rem-calc(10) math.div($global-padding, 3);
    color: $color-default;
    margin-bottom: 1px;
    cursor: pointer;

    &:hover, &.is-active {
      background-color: $color-primary-highlight;
    }
  }

  &-arrow {
    position: absolute;
    pointer-events: none;
    width: rem-calc(12);
    top: 50%;
    right: rem-calc(8);
    transform: translateY(-50%);
    line-height: 0;
  }

  &-options {
    display: block;
    position: absolute;
    background-color: $color-white;
    border-radius: 0 0 $border-radius-m $border-radius-m;
    width: 100%;
    overflow-y: auto;
    z-index: 1;
    padding-top: math.div($global-padding, 3);
    max-height: rem-calc(170);
    opacity: 1;
    border: 1px solid $color-gray100;
    border-top: none;
    transition: all 0.3s ease-in-out;

    &.is-closed {
      padding-top: 0;
      max-height: 0;
      opacity: 0;
      border: none;
    }

    @include media($media_s) {
      max-height: rem-calc(128);
    }
  }
}
