/** @define BlogList */
$componentClass: '.BlogArticleNavigation';
.BlogArticleNavigation {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  width: 100%;
  margin-top: $global-padding;
  padding-top: $global-padding;
  @include borderDashed($color: $color-gray50, $top: true);

  @include media($media_s) {
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  &-link {
    @include media($media_s) {
      &:not(:last-child) {
        margin-bottom: $global-padding;
      }
    }
    @include media($media_m-l) {
      display: flex;
      align-items: flex-end;
      width: 33.3333%;
      margin: 0 auto;
      &:last-child {
        justify-content: flex-end;
      }
      &:nth-child(2) {
        justify-content: center;
      }
    }
  }
}
