/** @define NavigationMain */
$componentClass: '.NavigationMain';
.NavigationMain {

  $transition-duration-l: .22s;
  $transition-easing-l : ease-in-out;
  $transition-delay-l: .25s;

  $transition-duration-sm: .3s;
  $transition-easing-sm: ease-in-out;
  $transition-delay-sm: .2s;

  $ani-duration-sm: .25s;
  $ani-easing-sm: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  $ani-delay-sm: .4s;

  height: $NavMain_height;
  padding: 0;
  transition: .4s padding ease;

  #{$componentClass}-link > .TextLink, #{$componentClass}-link.TextLink {
    &:after {
      display: none;
    }
  }

  &-content {
    background: $gradient-primary;
    z-index: 5;
    border-radius: 0;
    padding: 0 rem-calc(40);
    transition: .4s border-radius ease, .4s height ease, .4s padding ease;
    @include media($media_s) {
      padding: 0 rem-calc(24);
    }

    > #{$componentClass}-inner {
      display: flex;
      align-items: center;
      max-width: rem-calc(1680);
      margin: 0 auto;
      @include media($media_s-m) {
        justify-content: space-between;
      }
    }
  }

  &-subcontent {
    position: relative;
    @include media($media_s-m) {
      pointer-events: none;
      @at-root .GlobalHeader--poweredBy & {
        padding-top: $global-padding;
      }
    }
  }

  &-flyout {
    width: 100%;
  }

  &-title {
    position: relative;
    flex-grow: 0;
    margin-right: $global-padding * 0.5;
    height: $NavMain_height;

    #{$componentClass}-link {
      width: 100%;
    }

    .TextLink {
      margin-top: 0;
      vertical-align: middle;
      line-height: normal;
    }

    #{$componentClass}-link {
      display: flex;
      align-items: center;
    }

    .TextLink-icon {
      svg {
        height: rem-calc(16);
        width: auto;
        margin-top: rem-calc(5);
        transition: .4s height ease;
      }
    }

    &--branded {
      .TextLink-icon {
        svg {
          height: rem-calc(45);
          vertical-align: middle;
        }
      }
    }
  }

  &-mainnavi,
  &-subnavi {
    @include media($media_s) {
      pointer-events: all;
    }

    #{$componentClass}-link > .TextLink, #{$componentClass}-link.TextLink {
      height: 100%;
      @include NavigationTextLink();
      line-height: $NavMain_height;
      vertical-align: middle;
      @include media($media_s-m) {
        width: 100%;
        font-size: rem-calc(24);
      }
    }
  }

  &-mainnavi {
    flex-grow: 1;
    position: relative;

    > #{$componentClass}-inner {
      margin: 0;

      @include media($media_s-m) {
        overflow-x: scroll;
      }
      @include media($media_l) {
        display: flex;
        justify-content: center;
        height: inherit;
      }
    }

    @include media($media_s-m) {
      display: block;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      overflow-x: hidden;
      pointer-events: none;
      #{$componentClass}-inner {
        display: flex;
        flex-wrap: nowrap;
        height: inherit;
        transform: translateX(0) translateY(-100%);
        transition: transform $transition-duration-sm $transition-easing-sm $transition-delay-sm;
      }

      #{$componentClass}-link {
        width: 100%;
        padding: 0;
      }

      #{$componentClass}-items {
        margin-top: 0;
        width: 100%;
        min-height: 100%;
        flex-shrink: 0;
        pointer-events: all;
        padding-bottom: $global-padding * 2.5;
        background: $color-white;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba($color-white,0.3);
          border-radius: 0;
          background-color: $color-white;
        }

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba($color-white,.3);
          background-color: $color-white;
        }
      }
    }

    @include media($media_l) {
      #{$componentClass}-link > * {
        padding: 0 $global-padding * .45;
      }

      #{$componentClass}-link--activeCategory {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          height: 2px;
          width: 100%;
          background-color: $color-default;
          border-radius: 1px;
        }
      }
    }
  }

  &-subnavi {
    display: flex;
    align-items: center;
    flex-grow: 0;
    margin-left: auto;

    > * > * {
      padding: 0 rem-calc(8);
    }

    > *:last-child > * {
      padding-right: 0;
    }

    .NavigationMain-link {
      &.is-hidden {
        visibility: hidden;
      }

      > .TextLink--icon {
        display: flex;
        align-items: center;

        .TextLink-icon {
          margin-top: rem-calc(1);
        }

        @-moz-document url-prefix() {
          .TextLink-icon {
            margin-top: rem-calc(2);
          }
        }

        .Icon {
          display: flex;
        }
      }
    }
  }

  &-items {
    margin-bottom: auto;
    background: $gradient-primary;
    @include media($media_s-m) {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      padding: 0;
    }
  }

 #{$componentClass}-line {
    width: 0;
    position: absolute;
    border-bottom: 2px solid $color-default;
    border-radius: 1px;
    transform: translateX(-60px);
    transition: .3s ease-out;
 }

  &-item {
    display: inline-block;
    font-size: 1.2rem;
    @include media($media_s-m) {
      display: block;
      text-align: center;
      width: 100%;
    }
  }

  &-toggle,
  &-link {
    display: inline-block;
    height: $NavMain_height;
    line-height: $NavMain_height;
  }

  &-link {
    position: relative;
    flex-grow: 0;
    text-transform: uppercase;
    text-decoration: none;
    color: $color-black;

    &--activeCategory {
      &TextLink, .TextLink {
        .TextLink-text {
          color: $color-default;

          @include media($media_s-m) {
            color: $color-primary;
          }
        }
      }
    }

    &--poweredBy {
      z-index: 1;
      width: 100%;
      height: auto;
      line-height: normal;
      padding: 0 rem-calc(10);
      text-align: center;
      border-bottom-left-radius: $border-radius-m;
      border-bottom-right-radius: $border-radius-m;
      background-color: $color-gray250;

      svg {
        width: 100%;
      }

      /* IE10 + IE11 FIX*/
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .TextLink-icon {
          display: inline;
        }
      }
    }
  }

  &-toggle > * {
    vertical-align: middle;
    @include media($media_l) {
      display: none;
    }
  }

  &--facelift {
    padding: rem-calc(9) rem-calc(9) 0;
    @include media($media_s-m) {
      height: rem-calc(63);
      padding: rem-calc(8) rem-calc(8) 0;
    }
    @include media($media_l) {
      height: auto;
      padding: rem-calc(16) rem-calc(16) 0;
    }

    &.is-open {
      @include media($media_s-m) {
        height: rem-calc(54);
        padding: 0;
      }

      #{$componentClass}-content {
        border-radius: 0;
        @include media($media_s-m) {
          padding: 0 rem-calc(24) 0 rem-calc(24);
        }
      }
    }

    #{$componentClass}-content {
      border-radius: rem-calc(6);

      @include media($media_s-m) {
        padding: 0 rem-calc(16);
      }
      @include media($media_l) {
        padding: 0 rem-calc(24);
      }
    }

    #{$componentClass}-title {
      .TextLink-icon {
        svg {
          height: rem-calc(16);
          margin-top: rem-calc(4);
        }
      }
    }
  }

  &--scrolled {
    padding: 0;
    @include media($media_s-m) {
      height: rem-calc(54);
    }

    #{$componentClass}-content {
      border-radius: 0;
      height: rem-calc(54);
      line-height: rem-calc(54);
      padding: 0 rem-calc(43);

      @include media($media_s-m) {
        padding: 0 rem-calc(24) 0 rem-calc(33);
      }
    }

    #{$componentClass}-title {
      height: rem-calc(54);

      #{$componentClass}-link {
        > .Icon {
          margin-left: rem-calc(-45);
          opacity: 0;
        }
      }
    }

    #{$componentClass}-toggle {
      > .ButtonToggleIcons {
        margin-top: rem-calc(-2);
      }
    }

    #{$componentClass}-line {
       display: none;
     }
  }

  &.is-open {
    @include media($media_s-m) {
      #{$componentClass}-mainnavi {
        > #{$componentClass}-inner {
          opacity: 1;
          transform: translateY(0);
        }
      }
      #{$componentClass}-item {
        opacity: 0;
      }
      .naviItemFadeIn {
        $elements: 10;
        animation: fadeInItem .5s $ani-easing-sm forwards;
        @for $i from 1 to $elements {
          &:nth-child(#{$i}) {
            animation-delay: $transition-duration-sm * .35 * $i;
          }
        }
      }
    }
  }

  &.is-moved{
    @include media($media_s-m) {
      #{$componentClass}-mainnavi > #{$componentClass}-inner {
        transform: translateX(-100%) translateY(-100%);
      }
    }
  }

  &.is-moved.is-open {
    @include media($media_s-m) {
       #{$componentClass}-mainnavi > #{$componentClass}-inner {
        transform: translateX(-100%) translateY(0);
      }
    }
  }

  &.is-reset {
    @include media($media_s-m) {
      #{$componentClass}-mainnavi > #{$componentClass}-inner {
        transform: translateX(0);
        transition: none;
      }
    }
  }

  @keyframes fadeInItem {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
