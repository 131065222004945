/** @define GlobalNotification */

$componentClass: '.GlobalNotification';
.GlobalNotification {
  bottom: 0;
  z-index: 999;

  &-link {
    text-align: right;
  }

  &.UIOverlayWrapper {
    position: fixed;
    height: auto;
  }

  &.UIOverlayWrapper--themeDark {
    #{$componentClass}-link .TextLink {
      color: $color-black;
      font-weight: $font-weight-bold;
    }
  }

  @include media($media_s) {
    &.UIOverlayWrapper--themeCustom .UIOverlayWrapper-container {
      width: 100%;
    }
  }

  @include media($media_m-l) {
    padding-bottom: $global-padding;
    &.UIOverlayWrapper .UIOverlayWrapper-inner {
      max-width: rem-calc(568);
      width: 100%;
    }

    &.UIOverlayWrapper--themeCustom .UIOverlayWrapper-container {
      box-shadow: 0 5px 25px 0px rgba($color-black, .5);
    }
  }

  @include media($media_m) {
    &.UIOverlayWrapper--themeCustom {
      padding: 0 $global-padding $global-padding;
      .UIOverlayWrapper-inner {
        max-width: none;
      }
    }
  }

  @include media($media_l) {
    &.UIOverlayWrapper--themeCustom .UIOverlayWrapper-inner {
      max-width: $max-width;
    }
  }
}
