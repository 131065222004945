/** @define TariffOptionsOption */

$componentClass: '.TariffOptionsOption';

.TariffOptionsOption {
  position: relative;
  width: 100%;

  &-inner{
    @include media($media_m-l) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
    }
  }


  &-content {
    margin-bottom:  rem-calc(30);
    @include media($media_m-l) {
      width: 60%;
      margin-bottom: 0;
    }
  }

  &-title {
    margin-bottom: 0.25em;
    @include media($media_m-l) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &-subcontent {
    display: flex;

    @include media($media_m-l) {
      width: 40%;
      flex-wrap: wrap;
    }
  }

  &-unit, &-button {
    width: 100%;
    text-align: right;

    @include media($media_s) {
      width: 50%;
    }
  }

  &-unit {
    font-size: 1.7em;
    margin-bottom: rem-calc(15);
    @include media($media_s) {
      text-align: left;
    }
  }

  &-type {
    @include media($media_m-l) {
      margin-right: rem-calc(5);
    }
  }

  &-copy {
    ul {
      padding-left: 0;
      list-style-type: none;
      li {
        padding-left: 0;
      }
      li::before {
        content:'';
      }
    }
  }

  &-warning {

    @include media($media_m-l) {
      position: absolute;
      top:0;
      left:0;
      padding-right: rem-calc(5);
      transform: translateY(-50%);
      background-color: $color-black;
    }
    .TextCopy{
      text-transform: uppercase;
    }
  }

  &-button {
    @include media($media_m-l) {
      align-self: flex-end;
    }
  }

  &:not(.is-selected) {
    #{$componentClass}-button {
      .Button {
        background-color: transparent;
      }
    }
  }

  &.is-upselling {

    #{$componentClass}-warning {
      display: block;
      color: $color-orange;
    }

    @include media($media_m-l) {
      padding-top: $global-padding;
      @include borderDashed($color: $color-orange, $top: true);
    }
  }

}
