/** @define GlobalCustomerService */

$componentClass: '.GlobalCustomerService';
.GlobalCustomerService {
  position: fixed;
  right: $global-padding * .5;
  bottom: $global-padding * 7;
  pointer-events: none;
  z-index: 980;
  @include media($media_s-m) {
    right: $global-padding * .2;
    bottom: $global-padding * .7;
  }
  @include media($media_s) {
    right: - $global-padding * .7;
    bottom: $global-padding * .7;
  }

  .ButtonCustomerService {
    pointer-events: all;
    width: auto;
    padding: 13px 16px 11px 12px;
    font-family: $font-family-condensed;
    font-size: 24px;
    line-height: 1rem;
    font-weight: $font-weight-bold;
    // Safari
    @media not all and (min-resolution:.001dpcm) {
      @supports (-webkit-appearance:none) {
        .TextLink-text {
          vertical-align: initial;
        }
      }
    }
  }
  .TextLink-icon {
    margin-right: 12px;
    @include media($media_s) {
      margin-right: 8px;
    }
  }
}
