/** @define DownloadListItem */
$componentClass: '.DownloadListItem';
#{$componentClass} {
  &-linkBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $global-padding;
    background-color: $color-gray15;
  }

  &-textCol {
    display: flex;
    flex-direction: column;
    color: $color-black;
    margin-bottom: 1.5rem;

    time {
      font-size: $copy-secondary_font-size;
      line-height: $copy-secondary_line-height;
      margin-bottom: $global-padding * .25;
    }
  }

  &-iconCol {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;

    .Icon {
      width: rem-calc(30);
      .Icon-base {
        fill: $color-orange;
      }
    }

    p {
      margin-top: $global-padding * .25;
      color: $color-gray100;
      font-size: $copy-secondary_font-size;
      line-height: $copy-secondary_line-height;
    }
  }
}

@media screen and (min-width: $breakpoint_s-to-m) {
  #{$componentClass} {
    &-linkBox {
      flex-direction: row;
      align-items: center;
    }

    &-textCol {
      flex: 1;
      margin-bottom: 0;
    }

    &-iconCol {
      flex-direction: column;
    }
  }
}
