/** @define TextHeadline */
.TextHeadline {
  &:not(.TextHeadline--xxl)
  &:not(.TextHeadline--xl)
  &:not(.TextHeadline--l)
  &:not(.TextHeadline--m)
  &:not(.TextHeadline--s)
  &:not(.TextHeadline--xs) {
    @include headline();
  }

  &--xxl { @include headline($size: $h-xxl_font-size, $line-height: $h-xxl_line-height); }
  &--xl { @include headline($size: $h-xl_font-size, $line-height: $h-xl_line-height); }
  &--l { @include headline($size: $h-l_font-size, $line-height: $h-l_line-height); }
  &--m { @include headline($size: $h-m_font-size, $line-height: $h-m_line-height); }
  &--s { @include headline($size: $h-s_font-size, $line-height: $h-s_line-height); }
  &--xs { @include headline($size: $h-xs_font-size, $line-height: $h-xs_line-height); }
  &--xxs { @include headline($size: $h-xxs_font-size, $line-height: $h-xxs_line-height); }

  &--subline { @include headlineFormat($type: 'subline'); }
  &--subheading { @include headlineFormat($type: 'subheading'); }



  &--inverted {
    color: $color-white;
    &.TextHeadline--highlight {
      color: $color-primary;
    }
  }

  &--embedded {
    margin: 0;
  }

  a {
    @include RawTextLink();
  }
}
