/** @define TextUnit */
$componentClass: '.TextUnit';
.TextUnit{
	font-family:$price-font-family;
	line-height:1;
  white-space: nowrap;
  &-prefix{
    display:inline;
    font-size:1em;
    margin-right: 0.22em;
    font-weight: normal;
  }

  &-prefixPrice {
    font-size: 0.7em;
    margin-right: 0.3em;
  }

  &-postfix{
    display:inline;
    font-size:1em;
    margin-left: 0.22em;
    font-weight:$font-weight-normal;
  }

  &--wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
    > .TextUnit {
      order: 0;
    }
    .TextUnit-prefixPrice {
      order: 1;
      font-size: inherit;
      margin: 0;
      color: $color-gray100;
    }
  }

  &--inverted{
    color: $color-white;
  }

  &--bold{
    font-weight:$font-weight-bold;
  }

  &--s,
  &--m,
  &--l{
    #{$componentClass}-prefix,
    #{$componentClass}-prefixPrice,
    #{$componentClass}-postfix{
      font-size: 0.25em;
    }

    #{$componentClass}-prefix{
      font-weight: normal;
    }

    #{$componentClass}-postfix{
      display:block;
      letter-spacing: 0.01em;
      margin-left: 0.15em;
    }

     #{$componentClass}-raised{
      display:inline-block;
      margin-top: 0.05em;
      margin-left: 0.02em;
      text-align: left;
      vertical-align: top;
    }

    #{$componentClass}-subunit{
      display:block;
      font-size:0.55em;
    }

    #{$componentClass}-seperator{
        font-size: 0.725em;
    }
  }

  &--s{
    font-size:$price-s_font-size;

    #{$componentClass}-raised{
      margin-left: 0.025em;
      margin-top: 0.1em;
    }
  }

  &--m{
    font-size:$price-m_font-size;

    #{$componentClass}-raised{
      margin-top: 0.08em;
    }
  }

  &--l{
    font-size:$price-l_font-size;

    #{$componentClass}-prefix{
      font-size: 0.25em;
    }

    #{$componentClass}-postfix{
      font-size: 0.2em;
    }

    #{$componentClass}-subunit{
      font-size: 0.6em;
    }
  }
}
