/** @define ShoppingCartProduct */
$componentClass: '.ShoppingCartProduct';
.ShoppingCartProduct {
  position: relative;

  @include media($media_m-l) {
    display: flex;
  }

  .Callout {
    position: absolute;
    bottom: 0;
    right: 0;
    @include media($media_s) {
      transform: scale(0.65);
      transform-origin: right bottom;
    }

    @include media($media_xs) {
      display: none;
    }

    @include media($media_m-l) {
      transform: scale(0.75);
    }
  }

  &-content {
    >  #{$componentClass}-copy + #{$componentClass}-copy {
      margin-top: $global-padding * .15;
    }

    #{$componentClass}-copy {
      color: $color-default;
    }

    .TextCopy .TextInfo{
      margin-left: rem-calc(2);
    }
  }

  &-headline {
    > *:not(:last-child) {
      margin-right: rem-calc(5);
    }
  }

  &-infoList {
    margin-right: -1rem;
  }

  &-image {
    margin-right: $global-padding;
    flex-shrink: 0;

    img {
      width: 100%;
      max-width: rem-calc(50);
    }

    @include media($media_s) {
      margin-bottom: $global-padding * 0.5;
    }
  }

  &-links {
    margin-left: auto;
    flex-shrink: 0;

    @include media($media_s) {
      margin-top: $global-padding * .25;
    }

    & > .TextLink {
      margin-right: $global-padding * .5;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-swatch {
    display: inline-block;
    margin-right: rem-calc(4);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: solid rem-calc(1) $color-gray100;
    vertical-align: middle;
  }

  &--compact,
  &--widget {
    #{$componentClass}-content {
      >  #{$componentClass}-copy + #{$componentClass}-copy {
        margin-top: 0;
      }
    }
  }

  &--compact,
  &--full {
    #{$componentClass}-data {
      font-size: $copy_font-size;
      font-weight: $font-weight-bold;
      color: $color-tertiary;
    }

    .Callout {
      &--content {
        position: initial;
        transform: none;
        padding: 0;
        background: transparent;
        color: $color-gray100;

        .TextHeadline--xs {
          font-size: $h-xxxs_font-size;
          font-family: $font-family-regular;
          font-weight: $font-weight-normal;
          text-transform: initial;
          margin: 0 0 rem-calc(-1) rem-calc(4);
        }
      }
      @include media($media_xs) {
        display: initial;
      }
    }
  }

  &--compact {
    position: initial;
    #{$componentClass}-headline {
      > .Callout--sticker {
        margin-top: rem-calc(3);
        margin-bottom: rem-calc(6);
      }

      > .TextHeadline--xxs {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(6);
      }
    }
    #{$componentClass}-copy {
      display: flex;
      align-items: center;
    }
    #{$componentClass}-delivery {
      position: absolute;
      margin-top: rem-calc(10);
    }

    .Callout {
      &--sticker {
        position: initial;
        transform-origin: left;
      }
      @include media($media_xs) {
        display: initial;
      }
    }
  }

  &--full {
    #{$componentClass}-content {
      flex: 66%;

      @include media($media_s) {
        flex: 100%;
      }
    }

    .Callout {
      position: relative;

      &.Callout--sticker {
        display: inline-block;
        transform-origin: left top;
        transform: scale(0.8);

        @include media($media_xs) {
          display: block;
        }
      }
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass} {
    &-copy {
      color: $color-white-darkmode;
    }
  }
}
