/** @define UIDialogLayer */
$componentClass: '.UIOverlayWrapper';
.UIOverlayWrapper{
  pointer-events: none;
  @include media($media_s) {
    width: 100%;
    height: 100%;
  }
  &-container {
    position: relative;
    height: 100%;
    flex-grow: 0;
    line-height: normal;
    text-transform: none;
    font-family: $font-family-regular;
    pointer-events: all;
  }
  &-inner {
    height: 100%;
  }

  &-close{
    position: absolute;
    right: 0;
    top: 0;
    padding: $global-padding * .5;
    z-index: 3;
  }

  &--scrollbar {
    #{$componentClass}-inner {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  &--alignLeft,
  &--alignRight,
  &--alignBottom,
  &--alignTop,
  &--alignMiddle,
  &--alignCenter {
    position: absolute;
  }

  &--alignLeft { left: 0; }
  &--alignRight { right: 0; }
  &--alignBottom { bottom: 0; }
  &--alignTop { top: 0; }

  &--alignMiddle {
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
  }

  &--alignCenter {
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
  }

  &--alignOutside {
    &#{$componentClass}--alignTop  {
      transform: translateY(-100%);
    }
    &#{$componentClass}--alignBottom {
      transform: translateY(100%);
    }
  }

  &--themeLight,
  &--themeCustom {
    #{$componentClass}-container {
      background-color: $color-white;
      color: $color-black;
    }
  }

  &--themeMedium {
    #{$componentClass}-container {
      background-color: $color-gray15;
      color: $color-black;
    }
  }

  &--themeDark {
    #{$componentClass}-container {
      background: $gradient-primary;
      color: $color-black;
    }
  }

  &--themeCookieconsent {
    #{$componentClass}-container {
      background-color: $color-inverted;
    }

    .UIOverlayWrapper-container {
      animation: timeout 0s 1s forwards;
      opacity: 0;
    }

    @keyframes timeout {
      to {
        opacity: 1;
      }
    }

    .GlobalDialog-copy {
      max-width: 95%;
    }

    @include media($media_s) {
      #{$componentClass}-container {
        background-color: $color-inverted;
        position: absolute;
        top: 0;
        left: 0;
      }

      #{$componentClass}-inner {
        overflow-x: scroll;
      }

      .GlobalDialog-content {
        padding-bottom: 4rem;
      }

      .GlobalDialog-footer {
        padding-bottom: $global-padding;
        background-image: linear-gradient(to top, white 68%, rgba(255, 255, 255, 7.92) 49%, rgba(255, 255, 255, 0) 100%);
      }

      .TextCopy {
        font-size: 0.875rem;
        line-height: 1.25rem;
        letter-spacing: 0.027em;
      }

      .TextCopy > *:last-child {
        padding-bottom: 1rem;
      }

      .TextHeadline::before {
        display: block;
        margin-bottom: 2rem;
        content: url(/files/2022-10/logos/otelo.png);
      }
      .GlobalDialog-content .GlobalDialog-copy + .GlobalDialog-footer {
        padding-top: 4rem;
      }
    }

    @include media($media_xs) {
      .GlobalDialog-copy {
        max-width: 100%;
      }
    }
  }

  &--themeCookieconsentsettings {
    #{$componentClass}-container {
      background-color: $color-inverted;
    }
    .FieldCheckbox-formOptional {
      display: none;
    }
    @include media($media_s) {
      #{$componentClass}-container {
        position: absolute;
        top: 0;
        left: 0;
      }
      .UIOverlayWrapper-inner {
        overflow: scroll;
      }
      .TextCopy {
        font-size: 0.875rem;
        line-height: 1.25rem;
        letter-spacing: 0.027em;
      }
      .TextHeadline::before {
        display: block;
        margin-bottom: 2rem;
        content: url(/files/2022-10/logos/otelo.png);
      }
      .GlobalSection-inner {
        padding: 0;
      }
    }
  }

  &--themeConsentinquiry {
    #{$componentClass}-container {
      background-color: $color-inverted;
    }
    @include media($media_s) {
      #{$componentClass}-container {
        position: absolute;
        top: 0;
        left: 0;
      }
      .UIOverlayWrapper-inner {
        overflow: scroll;
      }
      .GlobalSection-inner {
        padding: 0;
      }
    }
  }

  &--themeSimarticlelist {
    &.GlobalDialog {
      max-width: rem-calc(480);
      margin: 0 auto;
    }

    #{$componentClass}-container {
      background-color: $color-white;
      color: $color-black;
    }

    .UIOverlayWrapper-inner {
      max-height: rem-calc(480);
      overflow: auto;
    }

    @include media($media_s) {
      &#{$componentClass}.is-visible {
        &#{$componentClass}--padding {
          #{$componentClass}-container {
            #{$componentClass}-inner {
              padding: rem-calc(16);
            }
          }
        }
      }

      .UIOverlayWrapper-inner {
        max-height: rem-calc(512);
      }
    }
  }

  &:not(.is-visible) {
    #{$componentClass}-container {
      height: auto;
    }
    #{$componentClass}-inner  {
      display: none;
    }
  }

  &.is-visible {
    &#{$componentClass}--padding {
      #{$componentClass}-inner  {
        padding: $global-padding;
      }
    }
  }
}
