@import "../../settings";

/** @define TabsWrapper */

$componentClass: '.TabsWrapper';
.TabsWrapper{
  &-labels{
    margin: 0;
    padding: $global-padding 0;
    display: flex;
    border-top: 2px solid $color-gray15;
    border-bottom: 2px solid $color-gray15;
  }
  &-label{
    display: inline-block;
    line-height: 1.5rem;
    padding: 0 $global-padding * 0.25 ;

    .TabsWrapper-labelButton {
      position: relative;
      transition: all $timing-duration $timing-easing;
      &:after{
        background-color: $color-primary;
        content: '';
        display: block;
        height: 2px;
        margin-left:50%;
        transition: all $timing-duration $timing-easing;
        width: 0;
      }
    }
    &.is-active{
      .TabsWrapper-labelButton{

        color: $color-primary;
        &:after{
          width: 100%;
          margin-left:0;
        }
      }
    }
    &:hover{
      color: $color-primary;
    }
  }
  &-labelButton{
    color: inherit;
    display: inline-block;
    font-size: 18px;
    line-height: 1.5rem;
    background: none;
    border: none;
    text-transform: uppercase;
    font-family: $font-family-condensed;
    &:focus, &:active{
      outline: none;
    }
  }
  &--inverted {
    .TabsWrapper-labels {
      border-top: 2px solid $color-gray100;
      border-bottom: 2px solid $color-gray100;
    }
  }
  &--centered {
    .TabsWrapper-labels {
      justify-content: center;
    }
  }
  &--withoutBorder {
    .TabsWrapper-labels {
      border: 0;
    }
  }
  &:not(.TabsWrapper--centered) {
    .TabsWrapper-labels {
      margin: 0 - $global-padding * 0.25;
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass}-labels {
    border-color: $color-gray-dark-darkmode;
  }
}
