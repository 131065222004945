/** @define MyOfferTiles */
@use "sass:math";

$componentClass: '.MyOfferTiles';
.MyOfferTiles {
  &-grid {
    column-count: 2;
    column-gap: $global-padding;

    @include media($media_s) {
      column-gap: math.div($global-padding, 3);

      #{$componentClass}-tile,
      #{$componentClass}-tileNba {
        display: none;
      }

      #{$componentClass}-tile ~ #{$componentClass}-tile {
        display: block;
      }
    }
  }

  &-headline {
    margin-bottom: $global-padding;

    @include media($media_m_l) {
      margin-bottom: $global-padding * 2;
    }
  }

  &-tile,
  &-tileNba {
    margin-bottom: $global-padding;
    break-inside: avoid;
    page-break-inside: avoid;
    width: 100%;

    @supports (-moz-appearance:none) {
      display: inline-block;
    }

    @include media($media_s) {
      margin-bottom: math.div($global-padding, 3);
    }
  }

  @include media($media_s) {
    padding-top: $global-padding * 1.5;
  }
}
