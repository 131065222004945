/** @define TariffListWrapper */
$componentClass: '.TariffList';
.TariffList {
  position: relative;
  &-main {
    display: block;
    padding-bottom: rem-calc(30);
    @include media($media_m-l) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: stretch;
      flex-wrap: wrap;
    }
    @include media($media_m) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }


  &-header {
    margin-bottom: $global-padding;
    @include media($media_s-m) {
      text-align: center;
    }
  }

  &-footer {
    padding-bottom: $global-padding;
    @include media($media_s) {
      padding-top: $global-padding;
    }
  }

  &-main &-item {
    width: 100%;
  }

  &--boxed {
    #{$componentClass}-item--highlight {
      .TariffItemBox {
        background-color: $color-white;
        .TextUnit {
          color: $color-default;
        }
      }
      .Callout--content,
      .Callout--label {
        color: $color-white;
        background-color: $color-black;
      }

      .Callout--badge .Callout-content .Callout-badge {
        color: $color-white;
        background-color: $color-black;
      }
    }

  }

  &--full {
    @include media($media_m) {
      .TariffList-slider {
        width: 100%;
      }

      .Slider-slide.swiper-slide {
        max-width: 304px;
      }
    }

    @include media($media_s-m) {
      .TariffList-slider {
        overflow: hidden;
        margin: 0 rem-calc(-10);
      }

      .Slider-pagination {
        margin-bottom: rem-calc(32);
      }

      .Slider--dark .Slider-pagination .swiper-pagination-bullet {
        background-color: $color-gray-dark;
        color: $color-white;
      }

      .swiper-pagination-bullet {
        width: fit-content;
        height: 100%;
        border-radius: 6px;
        padding: rem-calc(8) rem-calc(10);
        color: $color-black;
        font-weight: bold;
      }
    }

    .Callout--label {
      border-radius: 8px;
      margin-bottom: $global-padding * .9;
    }

    #{$componentClass}-item {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;

      @include media($media_s) {
        margin-right: 16px;
      }

      .TariffItemFull {
        padding: $global-padding * .5;
        @include borderDashed($color: $color-gray100, $top: true, $bottom: true, $left: true, $right: true);
        border-image-source: none;
        border-radius: $border-radius-l;
      }

      &#{$componentClass}-item--highlight {
        .TariffItemFull {
          background-color: $color-default;
        }
      }

      &#{$componentClass}-item--highlight,
      &#{$componentClass}-item--callout {
        .TariffItemFull {
          border: 2px solid $color-default;
        }
      }

      &#{$componentClass}-item--callout {
        .TariffItemFull {
          border-radius: 0 0 $border-radius-l $border-radius-l;
        }
      }

      &#{$componentClass}-item--calloutPrimary {
        .TariffItemFull {
          border-color: $color-primary;
        }
      }
      &#{$componentClass}-item--calloutTertiary {
        .TariffItemFull {
          border-color: $color-tertiary;
        }
      }
    }

    #{$componentClass}-item--highlight#{$componentClass}-item--calloutSecondary {
      .TariffItemFull {
        position: relative;
        border-top: none;
        &:before {
          content: '';
          position: absolute;
          top: -1px;
          left: -2px;
          right: -2px;
          height: 2px;
          background-color: $color-white;
        }
      }
    }

    &:not(:root:root) {
      #{$componentClass}-item {
        display: block;
      }
    }

    @include media($media_s) {
      #{$componentClass}-footer {
        padding: 0 $global-padding*0.5 $global-padding $global-padding*0.5;
      }

      #{$componentClass}-item {
        &:not(:last-child) {
          margin-bottom: $global-padding;
        }
      }
    }
    @include media($media_l) {
      #{$componentClass}-item {
        width: calc(25% - #{$global-padding * .5});
        flex-grow: 1;
        border-image: none;

        .TariffItemFull {
          padding-bottom: $global-padding * .75;
          .TariffItemFull-button,
          .TariffItemFull-button .TextLink--asButton {
            width: 100%;
          }
          .TariffItemFull-header .Callout--content {
            align-self: flex-end;
          }
        }

        &:not(:last-child) {
          margin-right: $global-padding * .5;
        }
      }

      &#{$componentClass}--stairsAsc,
      &#{$componentClass}--stairsDesc {
        #{$componentClass}-main {
          align-items: flex-end;
        }
      }

      &#{$componentClass}--stairsAsc {
        @for $i from 0 through 3 {
          .TariffList-item:nth-child(#{$i + 1}) {
            .TariffItemFull-header .Callout--content {
              padding-top: $global-padding * .25 + rem-calc(4 * $i);
              padding-bottom: $global-padding * .25 + rem-calc(4 * $i);
            }
            .TariffItemFull-name .TextHeadline {
              font-size: $h-s_font-size + rem-calc(5 * $i);
              line-height: $h-s_font-size + rem-calc(5 * $i);
            }
          }
        }
      }

      &#{$componentClass}--stairsDesc {
        @for $i from 3 through 0 {
          .TariffList-item:nth-child(#{3 - $i + 1}) {
            .TariffItemFull-header .Callout--content {
              padding-top: $global-padding * .25 + rem-calc(4 * $i);
              padding-bottom: $global-padding * .25 + rem-calc(4 * $i);
            }
            .TariffItemFull-name .TextHeadline {
              font-size: $h-s_font-size + rem-calc(5 * $i);
              line-height: $h-s_font-size + rem-calc(5 * $i);
            }
          }
        }
      }
    }
  }

  &--compact &-main {
    @include borderDashed($color: $color-gray100, $top: true);
  }

  &--compact &-item {
    padding: $global-padding 0;
    @include borderDashed($color: $color-gray100, $bottom: true);
    @include media($media_m-l) {
      width: 100%;
    }
  }

  &#{$componentClass}--boxed {
    #{$componentClass}-main {
      display:flex;
      align-items: center;
      justify-content: space-between;
    }
    #{$componentClass}-item {
      width: calc(33% - #{$global-padding * .05});
      align-self: stretch;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;

      > * {
        width: 100%;
      }
    }

    @include media($media_s) {
      #{$componentClass}-item {
        width: calc(33% - #{$global-padding * .17});
      }
    }

    .Callout--label {
      border-radius: 8px;
      margin-bottom: $global-padding * .9;
    }

    #{$componentClass}-main--tariffs4 {
      @include media($media_s-m) {
        flex-direction: row;
        flex-wrap: wrap;
      }
      #{$componentClass}-item {
        width: calc(25% - #{$global-padding * .17});
      }
      @include media($media_s-m) {
        #{$componentClass}-item {
          width: calc(50% - #{$global-padding * .17});
          &:nth-child(n+3) {
            margin-top: $global-padding * .5;
          }
        }
      }
    }

    #{$componentClass}-main--tariffs2 {
      #{$componentClass}-item {
        width: calc(50% - #{$global-padding * .17});
      }
    }
  }

  &--panels &-item {
    display: flex;
    flex-direction: row;
    margin-bottom: $global-padding;
    @include media($media_s) {
      padding-bottom: $global-padding;
      @include borderDashed($color: $color-gray50, $bottom: true);
    }

    @include media($media_m-l) {
      width: 50%;
      margin-bottom: rem-calc(114);
      @include borderDashed($color: $color-gray50, $right: true);
      &:nth-child(2n-1) {
        @include borderDashed($color: $color-gray50, $left: true, $right: true);
      }
      // remove margin bottom for last row
      &:last-child, &:nth-last-child(2):nth-child(odd) {
        margin-bottom: 0;
      }
    }
  }

  &--adjacent {
    &#{$componentClass}--full {
      @include media($media_m-l) {
        #{$componentClass}-main {
          align-items: flex-start;
        }
        #{$componentClass}-item {
          &:not(#{$componentClass}-item--callout):not(#{$componentClass}-item--highlight) {
            &:not(:last-child) {
              .TariffItemFull {
                border-right: none;
              }
            }
            .TariffItemFull {
              border-radius: 0;
              padding-bottom: 0;
              border-top: none;
              border-bottom: none;
            }
          }
          &#{$componentClass}-item--callout,
          &#{$componentClass}-item--highlight {
            &:not(:last-child) {
              margin-right: $global-padding;
            }
          }
          .TariffItemFull {
            padding-left: $global-padding;
            padding-right: $global-padding;
            .TariffItemFull-button,
            .TariffItemFull-button .TextLink--asButton {
              width: auto;
            }
          }
        }
        &#{$componentClass}--highlights {
          #{$componentClass}-item {
            width: calc(25% - #{$global-padding});
          }
        }
      }
      @include media($media_m) {
        #{$componentClass}-item {
          .TariffItemFull {
            padding-left: $global-padding * .5;
            padding-right: $global-padding * .5;
          }
        }
        &#{$componentClass}--highlights {
          #{$componentClass}-item {
            width: calc(25% - #{$global-padding * .5});
          }
          #{$componentClass}-item--highlight:not(:last-child) {
            margin-right: $global-padding * .5;
          }
        }
      }
    }
  }

  &--inverted {
    &#{$componentClass}--full {
      #{$componentClass}-item--highlight {
        .TariffItemFull {
          background-color: $color-white;
        }
        &#{$componentClass}-item--calloutSecondary {
          .TariffItemFull:before {
            background-color: $color-black;
          }
        }
      }
      #{$componentClass}-item--calloutSecondary,
      #{$componentClass}-item--highlight {
        .TariffItemFull {
          border-color: $color-white !important;
        }
      }
    }
  }

  .Callout--label {
    height: 100%;
    padding-top: 0.6rem;
  }

  > .Callout--badge {
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(.9) translateY(-50%);
    transform-origin: right center;
    @include media($media_s) {
      transform: scale(.75) translateY(-80%);
    }
  }

  .Callout--sticker {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    left: 0.75rem;
  }
}
