/** @define FreeCodeWidget */
$componentClass: '.FreeCodeWidget';

.FreeCodeWidget {
  position: relative;
  height: 0;
  width: 100%;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    overflow:hidden;
  }
}
