/** @define FormBlockError */
$componentClass: '.FormBlockError';
.FormBlockError {
  &-button,
  &-button > .TextLink {
    margin-top: $global-padding * 0.5;
  }
  @include media($media_m-l) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-button {
      display: block;
      margin-top: 0;
    }
  }
}
