/** @define HardwareList */
$componentClass: '.HardwareList';

.HardwareList {
  $borderSize: 2px;
  margin-bottom: $global-padding * 2;

  &:hover {
    @include media($media_m-l) {
      #{$componentClass}-item.is-selected:not(:hover) {
        #{$componentClass}-itemInner {
          border: $borderSize dotted;
          border-color: rgba($color-gray50, 0) $color-gray50 rgba($color-gray50, 0) $color-gray50;
        }
      }
    }
  }

  &-labelCallout {
    top: 0;
    position: absolute;
    z-index: 1;
  }

  &-title {
    text-align: center;
    margin-bottom: $global-padding * 2;
  }

  &-warning {
    text-align: center;
    margin-bottom: $global-padding * 2;
  }

  &-headline {
    border-bottom: $borderSize solid $color-black;
    padding-bottom: $global-padding * .5;
    margin-bottom: $global-padding;
  }

  &-controls {
    display: flex;
    justify-content: space-between;
    @include media($media_s) {
      flex-wrap: wrap;
    }

    > .Dropdown {
      margin-top: rem-calc(20);
      @include media($media_s) {
        margin-top: rem-calc(8);
      }
    }
  }

  &-list {
    margin-bottom: $global-padding * 1.5;
    @include media($media_m-l) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    @include media($media_m) {
      width: calc(100% + #{$borderSize});
    }

    @include media($media_l) {
      width: calc(100% + #{$borderSize * 2});
    }
  }
  &-item {
    @include media($media_m) {
      margin-top: 1rem;
    }
    position: relative;
    text-align: center;
    border-top: $borderSize solid rgba($color-orange, 0);
    border-bottom: $borderSize solid rgba($color-orange, 0);

    #{$componentClass}-itemInner {
      padding: $global-padding 0;
      @include borderDashed($color-gray50, false, false, true);
    }
    @include media($media_m-l) {
      width: 50%;
      margin: 0;
      #{$componentClass}-itemInner {
        padding: $global-padding*2 $global-padding $global-padding $global-padding;
        @include borderDashed($color-gray50, false, true, false, true);
        border: $borderSize dotted;
        border-color: rgba($color-gray50, 0) $color-gray50 rgba($color-gray50, 0) $color-gray50;
      }
    }
    @include media($media_m-l) {
      margin-top: 1rem;
    }
    @include media($media_s-m) {
      #{$componentClass}-labelCallout {
        width: 100%;
      }
      &:nth-child(2n) {
        transform: translateX(-$borderSize);
      }
    }
    @include media($media_s) {
      #{$componentClass}-labelCallout {
        margin-top: 0.5rem;
      }
      .Callout--sticker {
        width: max-content;
        .Tag-copy {
          line-height: 1rem;
          padding: .4rem .4rem;
        }
      }
    }

    @include media($media_l) {
      width: 33.3333%;
      &:nth-child(3n) {
        transform: translateX(-$borderSize*2);
      }
      &:nth-child(3n-1) {
        transform: translateX(-$borderSize);
      }
    }
  }

  &-item.is-selected,
  &-item:hover {
    @include media($media_m-l) {
      .Callout--label {
        height: 34px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      z-index: 1;
      #{$componentClass}-itemInner {
        border: $borderSize solid $color-orange;
        border-image: linear-gradient(90deg, #FF7800 0%, #FAA532 100%) 1;
      }
    }
  }

  &-item--callout {
    @include media($media_s) {
      &:nth-child(1n) {
        #{$componentClass}-itemInner {
          margin-top: 2rem;
        }
      }
    }
    @include media($media_m-l) {
      padding-top: 0;
      #{$componentClass}-labelCallout {
        padding-top: 0;
        position: absolute;
        top: -0.5rem;
        width: 100%;
      }
      &.is-selected,
      &:hover {
        border-radius: 10px 10px 0 0;
        #{$componentClass}-headline {
          padding-top: 3.5rem;
        }
        #{$componentClass}-labelCallout {
          height: 35px;
          width: 100%;
          top: 0;
          z-index: -2;
        }
        &:nth-child(1n) {
          #{$componentClass}-itemInner {
            padding-top: 3.4rem;
          }
          .HardwareItem {
            padding-top: 5rem;
          }
        }
      }
    }
  }
}
