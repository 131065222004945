@font-face {
  font-family: 'Interstate-Regular';
  src: url('/fonts/Interstate-Light.woff2') format('woff2'),
       url('/fonts/Interstate-Light.woff') format('woff'),
       url('/fonts/Interstate-Light.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Regular';
  src: url('/fonts/Interstate-Regular.woff2') format('woff2'),
       url('/fonts/Interstate-Regular.woff') format('woff'),
       url('/fonts/Interstate-Regular.eot') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Condensed';
  src: url('/fonts/Interstate-LightCondensed.woff2') format('woff2'),
       url('/fonts/Interstate-LightCondensed.woff') format('woff'),
       url('/fonts/Interstate-LightCondensed.eot') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Condensed';
  src: url('/fonts/Interstate-BoldCondensed.woff2') format('woff2'),
       url('/fonts/Interstate-BoldCondensed.woff') format('woff'),
       url('/fonts/Interstate-BoldCondensed.eot') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}
