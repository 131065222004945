/** @define HardwareItem */

$componentClass: '.HardwareItem';
.HardwareItem {
  position: relative;

  @include media($media_s) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;

    &-visualsWrapper {
      width: 30%;
      text-align: center;
    }

    &-imageMobile {
      width: 100%;
    }

    .MediaImage > img {
      max-height: 158px;
    }
  }

  @include media($media_m-l) {
    display: block;
    padding-top: rem-calc(80);
    text-align: center;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media($media_s) {
      padding: 0 rem-calc($global-padding*0.5);
    }

    @include media($media_m-l) {
      width: 100%;
    }
  }

  &-subcontent {
    align-self: flex-end;
    margin-left: auto;
  }

  &-prices {
    @include media($media_m-l) {
      padding: rem-calc($global-padding*0.5) 0;
    }
  }
  &-buttons {
    @include media($media_m-l) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: rem-calc(25);
    }
  }

  &-image {
    height: auto;
    width: rem-calc(65);
    margin: 0 auto;

    .TextLink {
      &:hover {
        text-decoration: none;
        &:after {
          display: none;
        }
      }
    }

    img {
      width: 100%;
    }
    @include media($media_m-l) {
      width: 100%;
      height: rem-calc(195);
      img {
        width: auto;
        max-width: 100%;
        max-height: rem-calc(195);
      }
    }
  }
  &-headline {

    @include media($media_s) {
      max-width: rem-calc(170);
      margin-bottom: rem-calc(5);
    }
    @include media($media_m-l) {
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
    }

    &Wrapper {
      font-size: 1rem;
      &Name {
        @include headline($size: $h-m_font-size, $line-height: $h-m_line-height, $weight: $font-weight-bold);
        text-transform: initial;
      }
    }
  }

  .TextHeadline a {
    border-bottom: none;
    color: $color-black;
  }

  &-selector {
    margin-bottom: $global-padding * 0.5;
  }
  &-link,
  &-button {
    display: inline-block;
    align-self: center;
  }

  &-link--expanded {
    width: 100%;
  }

  &-button {
    .TextLink {
      min-width: rem-calc(130);
    }
  }
  &-price {
    @include media($media_s) {
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include media($media_m-l) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0;
    }
    #{$componentClass}-copy {
      margin: 0;
    }
  }
  &-unit {
    display: inline-block;
    font-weight: $font-weight-bold;
    font-size: 1.15em;
    > * {
      line-height: normal;
    }
    @include media($media_s) {
      line-height: 1rem;
    }
    @include media($media_m-l) {
      display: inline-block;
    }
  }
  .Callout--badge {
    position: absolute;
    transform: scale(0.75) translateY(-20%);
    transform-origin: right top;
    top: 0;
    right: 0;
    @include media($media_m-l) {
      transform: scale(0.75);
      transform-origin: left top;
      top: 4rem;
      left: 50%;
      right: auto;
    }
  }
  .Callout--sticker {
    display: inline;
  }
  .Tag {
    margin-top: rem-calc(3);
  }
}

[data-color-mode="dark"] {
  #{$componentClass}-headline {
    .TextHeadline a {
      color: $color-white-darkmode;
    }
  }
}
