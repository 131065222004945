/** @define MetaNavigation */
$componentClass: '.MetaNavigation';
.MetaNavigation {
  background: $gradient-primary;
  color: $color-black;

  @include media($media_s) {
    padding: $global-padding * 1.5 0;
  }

  @include media($media_m-l) {
    padding: $global-padding * 0.75 0;
  }

  &-wrapper {
    @include outerContainer($max-width);
    width:100%;
    text-align: center;
    @include media($media_l) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-title {
    > * + * {
      margin-top: $global-padding * 0.25;
    }

    .TextLink {
      vertical-align: middle;
      line-height: normal;
    }

    svg {
      vertical-align: top;
    }

    @include media($media_s-m) {
      margin-bottom: $global-padding;
    }
  }

  &-meta,
  &-social {
    text-align: center;
    @include media($media_l) {
      display: inline-block;
      text-align: left;
    }
  }

  &-meta {
    @include media($media_s-m) {
      margin-bottom: $global-padding;
    }

    @include media($media_l) {
      vertical-align: super;
    }
  }

  &-meta &-link {
    display: block;
    text-align: center;

    .TextLink {
      color: $color-black;

      &::after {
        background: rgba($color-white, 0);
        animation: ani-textlink-metanav-leave $timing-duration $timing-easing forwards;
      }

      &:hover, &:focus, &.is-active{
        color: $color-white;
        transition: color $timing-duration * .6 $timing-easing $timing-duration * .4;
        &::after {
          animation: ani-textlink-metanav-enter $timing-duration * .75 $timing-easing $timing-duration * .25 forwards;
        }
      }
    }

    @include media($media_s) {
      &:not(:last-child) {
        margin-bottom: $global-padding;
      }
    }
    @include media($media_m-l) {
      display: inline-block;
      padding: 0 $global-padding * .5;
      &:first-child {
        padding-left: 0;
      }
    }
  }

  &-social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &-social &-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $color-black;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    line-height: 0;
    margin: 0 $global-padding*0.5;
    transition: border-color $timing-duration $timing-easing;

    .TextLink {
      color: $color-black;
    }

    .Icon {
      text-align: center;
    }

    .Icon-base {
      fill: $color-black;
      transition: fill $timing-duration $timing-easing;
    }

    &:hover {
      border-color: $color-white;
      .Icon-base {
        fill: $color-white;
      }
    }
  }
}
