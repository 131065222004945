/** @define TariffDetailsFeature */

$componentClass: '.TariffDetailsFeature';
.TariffDetailsFeature {
    &-headline {
      @include media($media_s-m) {
        display: inline-block;
        margin-right: rem-calc(10);
      }

      @include media($media_l) {
        margin-bottom: rem-calc(5);
      }
    }
    &-subline {
      @include media($media_s-m) {
        display: inline-block;
      }
      @include media($media_l) {
        margin-bottom: $global-padding;
      }
    }
}
