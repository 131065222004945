/** @define Dropdown */
$componentClass: '.Dropdown';
.Dropdown {
  min-width: fit-content;
  @include media($media_s) {
    width: 100%;
  }

  &-label {
    margin-right: $global-padding;
  }

  &-select {
    border: none;
    position: relative;
    cursor: pointer;

    &:focus-visible {
      outline: none;
    }
  }

  &-selectWidth {
    visibility: hidden;
    display: block;
    position: absolute;
  }

  &-mobileSelect {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &-button {
    width: 100%;
    position: relative;
    padding: rem-calc(10) $global-padding * 0.5;
    background-color: $color-gray15;
    text-align: left;
    display: grid;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: $global-padding * 0.5;
      width: rem-calc(12);
      height: rem-calc(19);
      transform: translateY(-50%);
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path class="Icon-base" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &-buttonLabel {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: rem-calc(12);
  }

  // Safari
  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
      &-select {
        -webkit-appearance: none;
        padding-right: rem-calc(15);
        background: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path class="Icon-base" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>') no-repeat;
        background-size: rem-calc(9) rem-calc(18);
        background-position-x: calc(100% - 5px);
      }

      &-selectWidth {
        -webkit-appearance: none;
        box-sizing: content-box;
        padding-right: rem-calc(20);
      }

      &-mobileSelect {
        -webkit-appearance: none;
      }
    }
  }
}

[data-color-mode="dark"] {
  #{$componentClass}-button {
    background-color: $color-gray-light-darkmode;
    color: $color-white-darkmode;

    &::after {
      background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 15"><path class="Icon-base" fill="#{$color-white}" d="M12.5,15a1,1,0,0,1-.75-0.34l-11.5-13A1,1,0,1,1,1.75.34L12.5,12.49,23.25,0.34a1,1,0,1,1,1.5,1.32l-11.5,13A1,1,0,0,1,12.5,15Z"/></svg>');
    }
  }
}
