/** @define CustomerServiceDialog */
$componentClass: '.CustomerServiceDialog';
.CustomerServiceDialog {
  .TextCopy small {
    display: block;
  }

  @include media($media_m-l) {
    width: rem-calc(375);
  }

  .CustomerServiceDialog-contactOptions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    li {
      cursor: pointer;
      position: relative;
      .TextLink--asButton {
        width: 100%;
      }

      @keyframes fadeInUp {
        0%   { opacity: 0; }
        25%  { opacity: 0.25 * 0.25; } //math.pow(0,25, 2)
        50%  { opacity: 0.50 * 0.50; } //math.pow(0,5, 2)
        75%  { opacity: 0.75 * 0.75; } //math.pow(0,75, 2)
        100% { opacity: 1; }
      }
    }
    li:first-child { animation-delay: 400ms;}
    li:nth-child(2) { animation-delay: 300ms; }
    li:nth-child(3) { animation-delay: 200ms; }
  }


  .CustomerServiceDialog-option {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 256px;
    background-color: $color-inverted;
    border-radius: 6px;
    margin-bottom: 12px;
    padding: 12px;
    opacity: 0;
    animation: fadeInUp 200ms forwards;
    -webkit-animation: fadeInUp 200ms forwards;
  }

  .CustomerServiceDialog-optionLabel {
    font-family: $font-family-condensed;
    font-weight: $font-weight-bold;
    font-size: 1.25rem;
    margin-bottom: 12px;
  }

  .CustomerServiceDialog-hotlineOptions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 256px;
    padding: 16px 24px 24px 24px;
    border-radius: 6px;
  }

  .UIOverlayWrapper-container {
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    @include media($media_m) {
      bottom: 2rem;
    }
  }

  .UIOverlayWrapper-inner > .TextLink--asButton {
    margin-top: $global-padding * .75;
    width: 100%;

    @include media($media_m-l) {
      display: none;
    }
  }
}
