/** @define ShareLinkContainer */
@use "sass:math";

$componentClass: '.ShareLinkContainer';
.ShareLinkContainer {
  background: $gradient-secondary;
  padding: $global-padding $global-padding * 0.75;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-m;
  position: relative;
  max-width: 60%;
  width: 100%;

  &-links {
    margin: $global-padding auto math.div($global-padding, 3);
  }

  &-link {
    width: 100%;
    height: $global-padding * 2;
    display: flex;
    align-items: center;
    line-height: 1.2;
    background: $color-white;
    margin: 0 auto $global-padding * 0.5;

    .TextLink--icon .TextLink-icon + .TextLink-text {
      margin-left: $global-padding * 0.75;
    }
  }

  &-bottomHint {
    cursor: pointer;
    font-size: $label-small_font-size;
  }

  @include media($media_s) {
    max-width: 100%;
  }

  @include media($media_l) {
    &-links {
      max-width: 60%;
    }
  }
}
